import L from 'leaflet';
import { IRelationships } from 'standard/ontology/interfaces/IRelationships'
import { DraggablePoint as DraggablePoint } from '../@types/draggablePoint';
import {  Overlaps } from '../@types/relationships';
import { sha256 } from 'js-sha256';

export interface InteractionsCallBacks{
    onMouseUp: (e : L.LeafletMouseEvent) => void
    onMouseDown: (e : L.LeafletMouseEvent) => void
}

export const inBounds = (northEast : L.LatLng, southWest : L.LatLng, coordinates: number[]) : boolean => {
    for (var coord in coordinates) {
        if (coordinates[coord][0] > northEast.lat){
            return false;
        }
    }
    return true
}

export const getOverlapping = (layers : any[], relationship : string) : IRelationships => {
    const result : Overlaps = {name : relationship, items : []} as Overlaps

    for (let i = 0; i < layers.length; i++) {
        let ind1 : DraggablePoint = layers[i]
        let ind1Bounds = ind1.getBounds()
        for (let j = i + 1; j < layers.length; j++) {
            let ind2 : DraggablePoint = layers[j]
            if (ind1Bounds.overlaps(ind2.getBounds())){
                result.items.push(
                    {
                        relationship: relationship, 
                        individuals: [ind1.id!, ind2.id!],
                        data : {    
                            bounds1 : ind1Bounds,
                            bounds2 : ind2.getBounds(),
                        }
                    }
                )
            }
        }
    }
    // while (layers.length > 0){
    //     const shifted : DraggablePoint = layers.shift();
    //     const bounds = shifted!.getBounds()
        
    //     let overlappings = layers.filter((l) => { return l.id !== shifted.id &&  bounds.overlaps(l.getBounds())})
    //     for (var overlapping of overlappings){
    //         result.items.push({
    //             relationship: relationship, 
    //             individuals: [shifted.id!, overlapping.id],
    //             data : {    
    //                 bounds1 : shifted.getBounds(),
    //                 bounds2 : overlapping.getBounds(),
    //             }
    //         })
    //     }
    // }
    return result
}
export function GenerateLayerColor(layer : string, defaultColor : string) {    
    let hashCode : string = sha256(layer)
    var chunkSize : number =  layer.length / 3;

    // Array to store chunks
    var chunks : string[] = [];

    for (var i = 0; i < hashCode.length; i += chunkSize) {
        chunks.push(hashCode.slice(i, i + chunkSize));
    }

    var r = hashCodeToRGB(sha256(chunks[0]), 100, 200);
    var g = hashCodeToRGB(sha256(chunks[1]), 100, 200);
    var b = hashCodeToRGB(sha256(chunks[2]), 100, 200);

    let color = "rgb(" + r + "," + g + "," + b + ")";
    
    return color
}

function hashCodeToRGB(hashCode : string, minColor : number, maxColor : number){
    var num = 0;
    for (var i = 0; i < hashCode.length; i++) {
        num += hashCode.charCodeAt(i); 
    }

    let val = convertRange(num,4200,4600, minColor , maxColor)
    
    return val    
}

function convertRange(value : number, inputMin : number, inputMax : number, outputMin : number, outputMax : number) : number{
    
    // Convert value from input range to output range
    var outputValue = ((value - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin;

    // Display result
    return outputValue;
}

