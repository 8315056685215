import { useState, useEffect } from 'react';
import AlertMessage from 'standard/alert/components/alertMessage';
import { ActionTypes, IAlert } from "standard/alert";
import FileUploader from 'components/standard/inputs/FileUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { Mapping } from '../types/uploads';
import { getMapping } from 'components/dataTools/dataimport/importers/templates/readTemplate';

const AddTemplate =
    (props: {
        label: string,
        onSelect: (mapping: Mapping, replace : boolean) => any,
        alert?: IAlert
    }
    ) => {
        let [replaceMapping, setReplaceMapping] = useState<boolean>(false);
        let [alert, setAlert] = useState<IAlert>();

        useEffect(() => {

        }, [replaceMapping]);

        useEffect(() => {

        }, [alert]);

        const onUpload = (files: any[]) => {
            if (!files) {
                return
            }

            getMapping(files).then((mapping: Mapping) => {
                props.onSelect(mapping, replaceMapping);    
                setAlert({
                    message: "Template applied",
                    type: ActionTypes.SUCCESS,
                } as IAlert)           
            }).catch((err) => {
                setAlert({
                    message: "Unable to process the upload",
                    type: ActionTypes.ERROR,
                } as IAlert)
            })
        }

        const onCheck = (e: any) => {
            setReplaceMapping(true)
        }

        const onUncheck = (e: any) => {
            setReplaceMapping(false)
        }

        const onHandleStartingSelect = () => {
            setAlert(undefined)
        }

        return (
            <>
                <div className='columns'>
                    <div className={`column mr-0 pr-0`}>
                        {!replaceMapping || <FontAwesomeIcon className='checkbox has-text-success' icon={faToggleOn} onClick={(e: any) => onUncheck(e)} size='2x' />}
                        {replaceMapping || <FontAwesomeIcon className='checkbox has-text-dark' icon={faToggleOff} onClick={(e: any) => onCheck(e)} size='2x' />}
                    </div>
                    <div className={`column is-full ml-1 pl-0 mt-1`}>
                        <span className="smallText ml-1">Replace Mapping</span>
                    </div>
                </div>
                <div className='field mt-2'>
                    {/* <label className="label smallText" htmlFor="label">{props.label}</label>                     */}
                    <div className="control mt-2">
                        <FileUploader className="card-header-icon button is-link" title='Upload template' isMultiple={true} handleFile={onUpload} handleStartingSelect={onHandleStartingSelect}/>
                    </div>
                    <AlertMessage alert={alert} />
                </div>
            </>
        )
    }

export default AddTemplate;

