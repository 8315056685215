import { Features } from "standard/geojson/@types";

export const importJsonFile = (props : {
    features : Features, 
    res: (value: Features | PromiseLike<Features>) => void,
    rej: (reason?: any) => void,
    fileContent: string
    }) => {
        try {
            var data = JSON.parse(props.fileContent);
            let features = props.features;
            features.features = data.features;
            props.res(features);
        } catch (error) {
            props.rej(error)
        }
}