import { useEffect, useState } from 'react';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CustomToolBox = (props: {
	menuItems: IMenuItem[],
	onMenuItemClick: (item: IMenuItem) => void
	active?: Set<string>
}) => {
	useEffect(() => {

	}, [props.menuItems]);

	useEffect(() => {

	}, [props.active]);

	return <div className="box toolbar p-0 m-0" >
		{
			props.menuItems.map((item) =>
				<div key={item.name} className={`columns p-0 m-0 toolbar-item is-clickable 
					${props.active?.has(item.name) && 'has-background-link'}
					${props.active?.has(item.name) && 'has-text-white'}`}
					onClick={() => props.onMenuItemClick(item)}>
					{item.visible &&
						<span className="column pt-2 pb-2 pl-3 pr-3 m-0 ">
							<FontAwesomeIcon icon={item.icon!} />
						</span>
					}
				</div>
			)
		}
	</div>
}

export default CustomToolBox;

