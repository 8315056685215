import { useState, useEffect, useRef } from "react";
import { IStyling } from "standard/styling/IStyling";
import './styles.css';
import { IAlert } from "standard/alert";
import AlertMessage from "standard/alert/components/alertMessage";

const Autocomplete = (props: { 
        value : string
        onChange: (value : string) => void
        changeOnEnterKey?: boolean
        onBlur?: (e: any) => void
        suggestions: string[] 
        alert?: IAlert,
        showNoSuggestions?: boolean
        placeholder?: string        
        style?: IStyling
    }) => {
    const {onChange } = props;
    const showNoSuggestions : boolean = props.showNoSuggestions === undefined ? true : props.showNoSuggestions;
    const inputRef = useRef<HTMLInputElement>(null);
    const [activeSuggestion, setActiveSuggestion] = useState<number>(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useEffect(() => {     
        // inputRef.current!.select()
        setIsLoading(false)
    }, [])

    useEffect(() => {
        updateSuggestions(userInput);
    }, [props.suggestions])

    useEffect(() => {
        setUserInput(props.value)        
    }, [props.value])

    useEffect(() => {
        // inputRef.current!.focus()
    }, [userInput])

    useEffect(() => {      

    }, [filteredSuggestions])

    const showOptions = (filterString : string) => {
        const filteredSuggestions = getFilterSuggestions(filterString)
        setActiveSuggestion(0)
        setFilteredSuggestions(filteredSuggestions)        
        if (!isLoading){
            setShowSuggestions(true)
        }  
            
    }

    const updateSuggestions = (suggestion : string) => {        
        setUserInput(suggestion)
        showOptions(suggestion)  
    };

    const onTextChange = (e: any) => {        
        updateSuggestions(e.currentTarget.value)
        if (!props.changeOnEnterKey) { 
            onChange(e.currentTarget.value)
        }
    };



    const getFilterSuggestions = (filterString : string) : string[] => {
        const { suggestions } = props;
        
        if (suggestions === undefined) {
            return[]
        }
        // Filter our suggestions that don't contain the user's input
        if (filterString === '' || filterString === undefined){
            return suggestions
        }
        return suggestions.filter(
            suggestion =>
                suggestion.toString().toLowerCase().indexOf(filterString.toString().toLowerCase()) > -1
        );
    };

    const onClick = (e : any) => {   
        setActiveSuggestion(0)
        setFilteredSuggestions([])
        setShowSuggestions(false)
        setUserInput(e.currentTarget.innerText)
        onChange(e.currentTarget.innerText)
        onBlur(e)
    };

    const onFocus = (e : any) => {
        let newSuggestions = getFilterSuggestions("")
        setFilteredSuggestions(newSuggestions)        
        setActiveSuggestion(newSuggestions.findIndex((s : string) => s.toString().toLowerCase() === userInput.toString().toLowerCase()))
        setShowSuggestions(true)        
    };

    const onBlur = (e : any) => {    
        if(props.onBlur){
            props.onBlur(e)
        }
    };

    const onKeyDown = (e : any) => {
        // User pressed the enter key
        if (e.keyCode === 13) {
            setActiveSuggestion(0)
            setShowSuggestions(false)
            setUserInput(filteredSuggestions[activeSuggestion])
            if (props.changeOnEnterKey) { 
                onChange(e.currentTarget.value)
            }
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1)
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)            
        }
    };

    const suggestionsListComponent = () => {
        if (showSuggestions) {
            if (filteredSuggestions.length) {
                return (
                    <ul className="navbar-dropdown suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className : string = 'smallText mt-1 ml-1';
                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={`${className} `} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                    <hr className="m-0 p-0"/>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <>
                        {!showNoSuggestions || <div className="no-suggestions navbar-dropdown">
                            <em>No suggestions!</em>
                        </div>
                        }
                    </>
                );
            }
        }
    }

    return (
        <>
        <div className="navbar-menu clickable fullWidth fullHeight">
            <div className="navbar-start fullWidth" >
                <div className="navbar-item has-dropdown is-hoverable fullWidth">
                    <input
                        ref={inputRef}
                        className={`input fullWidth ${props.style && props.style.text}`}
                        type="text"
                        tabIndex={1000}
                        onChange={onTextChange}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        value={userInput}   
                        placeholder={props.placeholder || ""}
                        // onBlur={onBlur}                     
                    />
                    {suggestionsListComponent()}
                </div>
            </div>
        </div>
        <AlertMessage alert={props.alert} style={"smallText"} />
        </>
    );
}

export default Autocomplete;
