import { IAppContext } from '../interfaces/IAppContext';
import { actionTypes } from './actionsTypes';

let context : IAppContext | undefined

if (localStorage.getItem('appContext') !== null){
    context = JSON.parse(localStorage.getItem('appContext')!);
}
const initialState : IAppContext  = context || {institution : undefined};

export function appContext(state = initialState, action:any) : IAppContext  {
  switch (action.type) {
    case actionTypes.SET_INSTITUTION_SUCCESS:
      localStorage.setItem('appContext', JSON.stringify(action.context))
      return action.context
    case actionTypes.CLEAR_CONTEXT: 
        localStorage.removeItem('appContext')
        return initialState;
    default:
      return state
  }
}