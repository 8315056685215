export enum Tools{
    Override = 'override',
    MutliSelect = 'mutli-select',
    Delete = 'delete',
    Filter = 'filter',
}

export enum LayerEvents{
    Highlighted = 'highlighted',
    Dehighlighted = 'dehighlighted',
    Selected = 'selected',
    Deselected = 'deselected',
    OverrideSet = 'overrideset',
    OverrideUnset = 'overrideunset',
    MutliSelectSet = 'multiselectset',
    MutliSelectUnset = 'multiselectunset',
    Delete = 'delete',    
}