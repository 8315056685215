import { contextActions } from "standard/iam/context/actions";
import { Middleware } from 'redux';
import { actionTypes as userActionTypes } from "standard/iam/actionsTypes";

export const logoutMiddleware: Middleware<{}> = store => next => action => {
  if (action.type === userActionTypes.LOGOUT) {
    store.dispatch({ type: contextActions.clearContext()});
  }

  if (action.type === userActionTypes.TIMEOUT) {
    store.dispatch({ type: contextActions.clearContext()});
  }

  return next(action);
};

