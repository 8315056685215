/**
 * All shared state name
 */
export const BACKGROUND_CENTER = "BACKGROUND_CENTER";
export const DOMAIN_MODEL = "DOMAIN_MODEL";
export const DIGITAL_TWIN = "DIGITAL_TWIN";
export const PAINTER_TYPE = "PAINTER_TYPE";
export const IS_EDITING_METADATA = "IS_EDITING_METADATA";
export const IMPORTING_METADATA = "IMPORTING_METADATA";
export const IS_IMPORTING_METADATA = "IS_IMPORTING_METADATA";
export const DATA_FROM_WORKSHEET = "DATA_FROM_WORKSHEET";
export const DATA_KML = "DATA_KML";
export const CURRENT_DRAWING_GEO = "CURRENT_DRAWING_GEO";
export const DRAWED_GEO = "DRAWED_GEO";
export const DELETED_INDIVIDUALS = "DELETED_INDIVIDUALS";
export const EDITTED_INDIVIDUALS = "EDITTED_INDIVIDUALS";
export const IS_EDITING_POINT = "IS_EDITING_POINT";
export const IS_EDITING_POLYGON_POINT = "IS_EDITING_POLYGON_POINT";
export const IMPORTING_FEATURE = "IMPORTING_FEATURE";
export const QUERIED_METADATA = "QUERIED_METADATA";
export const EDITING_GEO_INDEX = "EDITING_GEO_INDEX";
export const IS_DISPLAY_ALIGN_LINE = "IS_DISPLAY_ALIGN_LINE";
export const IS_SINGLE_CLICK = "IS_SINGLE_CLICK";
export const DISPLAY_FILTER_OBJECT_POPUP = "DISPLAY_FILTER_OBJECT_POPUP";
export const DISPLAY_FILTER_OBJECT_LAYER = "DISPLAY_FILTER_OBJECT_LAYER";
export const DISTANCE_TO_CONNECT_TWO_LINE = "DISTANCE_TO_CONNECT_TWO_LINE";
export const SELECTED_FILTER_OBJECT_CLASS = "SELECTED_FILTER_OBJECT_CLASS";
export const SELECTED_FILTER_OBJECT_PROPS = "SELECTED_FILTER_OBJECT_PROPS";
export const MAP_CLICK_DISABLED = "MAP_CLICK_DISABLED";
export const IS_MAP_DISABLE = "IS_MAP_DISABLE";
export const EXPORT_FILE_FORMAT = "EXPORT_FILE_FORMAT";
export const IMPORTING_METADATA_TEMPLATES = "IMPORTING_METADATA_TEMPLATES";
export const MISSING_IMPORTING_OBJECTS = "MISSING_IMPORTING_OBJECTS";
