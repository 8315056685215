import BaseStructure from "./basestructure"
import { IDigitalTwin, IDomainModelProperty, IIndividual } from "../interfaces"
import { ILabelComment } from '../localization/interfaces';

export class DigitalTwin extends BaseStructure implements IDigitalTwin, ILabelComment {
  public uri: string
  public isprivate: boolean
  public type: number
  public imports: string[] | undefined
  public individuals?: IIndividual[] 
  public scopes: { [key: string]: string }

  constructor(i : IDigitalTwin) {
    super( i.labels, i.comments, i.uuid);
    this.uri = i.uri
    this.isprivate = i.isprivate
    this.type = i.type
    this.imports = i.imports
    this.individuals = i.individuals
    this.scopes = i.scopes
  }

  updateIndividual(withIndividual : IIndividual){
    let update: IIndividual[];
    if (this.individuals) {
        if (withIndividual.uuid !== undefined){
            this.individuals = this.individuals.filter(prop => prop.uuid === withIndividual.uuid!)
        }
        update = [...this.individuals, withIndividual]
    } else {
        update = [withIndividual];
    }
    this.individuals = update 
  }

  getDataProperties(uuid: string) { 
    if (!this.individuals ) return []

    let props : any[] = []
    for (var i in this.individuals) {
      for (var j in this.individuals[i].dataProperties) {
        if (j === uuid){
          props.push(this.individuals[i].dataProperties![j])
        }
      }      
    }
    return props

  }

  removeDataProperty(uuid: string) { 
    if (!this.individuals ) return

    for (var i in this.individuals) {
      for (var j in this.individuals[i].dataProperties) {
        if (j === uuid){
          delete this.individuals[i].dataProperties![j]
        }
      }      
    }
  }
}

export const addIndividuals = (DigitalTwin : DigitalTwin,  withIndividual : IIndividual) : DigitalTwin =>{
  let update: IIndividual[];
  if (DigitalTwin.individuals) {
      if (withIndividual.uuid !== undefined){
        DigitalTwin.individuals = DigitalTwin.individuals.filter(prop => prop.uuid !== withIndividual.uuid!)
      }
      update = [...DigitalTwin.individuals, withIndividual]
  } else {
      update = [withIndividual];
  }
  DigitalTwin.individuals = update 
  
  return DigitalTwin
}

export const removeIndividual = (DigitalTwin : IDigitalTwin,  id : string) : IDigitalTwin =>{
  if (DigitalTwin.individuals) {
    DigitalTwin.individuals = DigitalTwin.individuals.filter(prop => prop.uuid !== id)
  }
  return DigitalTwin
}