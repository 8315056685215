import { useEffect, useState, useCallback, useRef } from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Feature } from 'standard/geojson/@types';
import TextSummary from 'standard/UI/components/textSummary';
import { IOption } from 'standard/forms/interfaces/IOption';
import { IAlert } from 'standard/alert';
import Autocomplete from 'standard/autocomplete/autocomplete';

const Property = (props: {
    propertyName: string
    options: { [key: string]: IOption[] }
    idx: number
    isEdittable: boolean
    isDomainProperty: boolean
    feature: Feature | undefined
    canFilter: boolean
    canSelect: boolean
    disabled: boolean
    selected?: boolean
    value?: string
    onFilter: (filter: any) => void
    onSetFilterProperty?: (property : string) => void
    onSelected?: (item: string, value: boolean) => any
    onDeleteFilter: (id: string) => void
    onDeletePropertyFilters: (property: string) => void
    filters?: Record<string, any>
    onValueChange?: (uuid: string | undefined, key: string, value: any) => void
}) => {
    const [isEditting, setIsEditting] = useState<boolean>(false);
    const [isEditted, setIsEditted] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>();
    const [alerts, setAlerts] = useState<{ [key: string]: IAlert }>({});
    const [feature, setFeature] = useState<Feature>();
    const { propertyName } = props
    let [selected, setSelected] = useState<boolean>(props.selected || false);

    useEffect(() => {
        if (isEditted && feature) {
            if (props.onValueChange && feature) {
                props.onValueChange(feature.uuid, propertyName, value)
            }
            setIsEditted(false)
        }
    }, [isEditted, value])

    useEffect(() => {
        setFeature(props.feature)
    }, [props.feature])

    useEffect(() => {

    }, [feature])

    useEffect(() => {

    }, [props.options])

    useEffect(() => {

    }, [props.canFilter])

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {

    }, [propertyName, value])

    useEffect(() => {
        if (isEditting) {
            if (inputRef.current) {
                inputRef.current!.select()
            }
        }
    }, [isEditting])

    const onValueDoubleClick = (e: any, key: string) => {
        if (props.isEdittable === true) {
            setIsEditting(true);
        }
    }

    const onChecked = (e: any, item: string) => {
        setSelected(e.target.checked)
        props.onSelected!(item, e.target.checked)
    }

    const displayProperties = () => {
        let currFilters = props.filters ? Object.values(props.filters) : []
        let hasFilter = currFilters.findIndex((f: any) => { return f.property === props.propertyName })
        hasFilter = hasFilter !== undefined ? hasFilter : -1
        return <div key={props.propertyName} className={`columns p-0 m-0  ${(props.idx + 1) % 2 === 0 ? 'alternate' : ''} ${props.isDomainProperty ? 'domainModel': ''}`}>
            <div className="column m-0 p-0" >
                <div className='columns m-0 p-0'>
                    <div className='column pl-2 ml-2 mt-1 mb-1 m-0 p-0 is-four-fifths filter-hidden'>
                        <TextSummary value={props.propertyName} prefixLength={15} suffixLength={3} />
                    </div>
                    <div className='column m-0 mt-1 mb-1 p-0 is-pulled-right'>
                        <FontAwesomeIcon className={`ml-1 button headericon is-inverted ${hasFilter !== -1 ? 'is-danger' : ''}`} icon={faFilter} onClick={() => onShowFilter(props.propertyName)} />
                    </div>
                </div>
            </div>
        </div>
    }

    const displayPropertyWithValues = () => {
        let currFilters = props.filters ? Object.values(props.filters) : []

        let hasFilter = currFilters.findIndex((f: any) => { return f.property === propertyName })
        hasFilter = hasFilter !== undefined ? hasFilter : -1

        return <div key={props.propertyName} className={`columns p-0 m-0 ${(props.idx + 1) % 2 === 0 ? 'alternate' : ''} ${props.isDomainProperty ? 'domainModel': ''} `}>
            <div className="column m-0 p-0" >
                <div className='columns m-0 p-0'>
                    {props.canSelect &&
                        <div className={`column pl-1 ml-1 mt-1 mb-1 m-0 p-0`}>
                            {!props.disabled && 
                                <input type="checkbox" className="ml-1" checked={selected} onChange={(e : any) =>  onChecked(e, props.propertyName)}  />
                            }    
                        </div>
                    }
                    <div className='column pl-1 ml-1 mt-1 mb-1 m-0 p-0 is-one-third filter-hidden'>
                        <span title={props.propertyName}>
                            <TextSummary value={props.propertyName} prefixLength={15} suffixLength={3} />
                        </span>
                    </div>
                    <div className='column m-0 mt-1 mb-1 ml-1 mr-1 p-0'>
                        :
                    </div>
                    <div className='column m-0 mt-1 mb-1 p-0 is-two-fifths' onDoubleClick={(e) => onValueDoubleClick(e, propertyName)}>
                        {isEditting ? displayInput() : displayPropertyValue()}
                    </div>
                    {props.canFilter &&
                        <div className='column m-0 mt-1 mb-1 p-0 is-one-fifth is-pulled-right'>
                            <FontAwesomeIcon className={`ml-1 button headericon is-inverted ${hasFilter !== -1 ? 'is-danger' : ''}`} icon={faFilter} onClick={() => onShowFilter(propertyName)} />
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    const displayInput = () => {
        if (props.options[props.propertyName]) {
            let suggestions = props.options[propertyName] && Object.values(props.options[propertyName]).map(option => { return option.label })
            return <Autocomplete
                value={value || ''}
                suggestions={suggestions}
                onChange={(newValue) => onValueChange(newValue)}
                showNoSuggestions={false}
                alert={alerts[props.propertyName]}
                style={{ text: 'smallText' }}
                onBlur={onEditComplete}
            />
        }

        return <div className="pl-1 pr-1">
            <input type="text"
                className={`input fullWidth`}
                ref={inputRef}
                value={value || ''}
                onChange={onTextChange}
                onBlur={onEditComplete}
            />
        </div>
    }

    const displayPropertyValue = () => {
        return <TextSummary value={props.value || ''} prefixLength={15} suffixLength={3} />
    }

    const onEditComplete = (e: any) => {
        setIsEditting(false);
    }

    const onTextChange = (e: any) => {
        onValueChange(e.currentTarget.value)
    };

    const onValueChange = (value: string) => {
        let selectedValue =  Object.values(props.options[propertyName]).find(option => option.label === value)
        if (selectedValue){
            setIsEditted(true);
            setValue(selectedValue!.value)
        }
    };

    const onShowFilter = (column: string) => {
        if (props.onSetFilterProperty){
            props.onSetFilterProperty(column)
        }
    }

    return (<>
        {(feature ? displayPropertyWithValues() : displayProperties())}
    </>
    )
}

export default Property;