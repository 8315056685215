import { useState, useEffect } from 'react';
import { ActionTypes, IAlert } from 'standard/alert';
import AlertMessage from 'standard/alert/components/alertMessage';
import Autocomplete from 'standard/autocomplete/autocomplete';
import { Feature } from 'standard/geojson/@types';

const NodePropertyNew = (props: {
    feature : Feature
    properties : {[key : string] : Set<string>}
    onAdd: (key: string, value: string) => any
}) => {
    const [nameAlert, setNameAlert] = useState<IAlert>();
    let [name, setName] = useState<string>("");
    let [value, setValue] = useState<string>("");
    let [propertySuggestions, setPropertySuggestions] = useState<string[]>([]);
    let [valueSuggestions, setValueSuggestions] = useState<string[]>([]);
    let [processing, setProcessing] = useState<boolean>(false);

    useEffect(() => {
        setProcessing(false)
        if(name === ''){
            setValueSuggestions([])
        }
    }, [name])

    useEffect(() => {

    }, [value])

    useEffect(() => {
        let allProperties : string[] = Object.keys(props.properties)
        let featureProperties : string[] = Object.keys(props.feature.properties || {})
        for (const elem of featureProperties) {
            allProperties = allProperties.filter(prop => prop !== elem)
          }
        setPropertySuggestions(allProperties)
    }, [props.properties, props.feature])

    useEffect(() => {
        
    }, [propertySuggestions])

    useEffect(() => {
        
    }, [valueSuggestions])

    const onOk = () => {
        if (name.trim() === "") {
            setNameAlert({ message: 'Name cannot be empty', type: ActionTypes.ERROR } as IAlert)
            return
        }
        props.onAdd(name, value)
        setName('')
        setValue('')
        setProcessing(true)
    }

    const onNameChange = (value : string) => {
        setNameAlert(undefined)
        setName(value)
        if (props.properties.hasOwnProperty(value)) {
            setValueSuggestions(Array.from(props.properties[value]))
        }
    }

    const onValueChange = (value : string) => {
        
        setValue(value)
    }

    return (<>
        {!processing || <div className='box m-2 p-2 is-info has-background-light smallText'>
                            <div className='columns'>
                                <div className='column mt-3'>
                                    <span className=''>Processing...</span> 
                                </div>
                                <div className='column'>
                                    <div className="control is-loading"></div>
                                </div>
                            </div>
                        </div>
        }
        {processing || <div className='box m-0 p-0 is-info has-background-light'>
            <div className="field ml-1 mt-0 mb-0 p-0">
                <label className="label smallText mt-2">Name</label>
                <div className="control">
                    <Autocomplete value={name} suggestions={propertySuggestions} onChange={onNameChange}  showNoSuggestions={false} />
                </div>
            </div>
            <div className="field ml-1 mt-0 mb-2 p-0">
                <label className="label smallText">Value</label>
                <div className="control">
                    <Autocomplete value={value} suggestions={valueSuggestions} onChange={onValueChange} showNoSuggestions={false}/>
                </div>
            </div>
            <div className="is-centered ">
                <div className="button smallText is-centered" key='new prop' onClick={() => onOk()}>
                    Add New Property                    
                </div>
                <AlertMessage alert={nameAlert} style="smallText" />
            </div>
        </div>}
    </>
    )
}

export default NodePropertyNew;