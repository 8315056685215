import { Feature } from "standard/geojson/@types"
import { ColumnDrops, ColumnMappings, ConversionMappings, IMappingTemplate, RecordDeletes, RowMappings } from "./@types/IMappingTemplate"
import { cloneDeep } from "lodash"
import { Convert, DropColumns, MapColumn, MapLayer } from "./Mappers"

export const ApplyTransformation = (mappingTemplate: IMappingTemplate, features: Feature[]): Feature[] => {
  let clone : Feature[] = cloneDeep(features)
  if (mappingTemplate.recordDeletes){
    clone = ApplyDeleteRecords(mappingTemplate.recordDeletes, clone)   
  }  
  if (mappingTemplate.rowMappings) {
    clone = ApplyRowTransformations(mappingTemplate.rowMappings, clone)
  }
  if (mappingTemplate.conversionMappings) {
    clone = ApplyValueConversions(mappingTemplate.conversionMappings, clone)
  }
  if (mappingTemplate.columnMappings) {
    clone = ApplyColumnTransformations(mappingTemplate.columnMappings, clone)
  }
  if (mappingTemplate.columnDrops) {
    clone = ApplyColumnDrops(mappingTemplate.columnDrops, clone)
  }
  return clone
}

const ApplyDeleteRecords = (recordDeletes : RecordDeletes, features: Feature[]): Feature[] => {
  let recDels = Object.values(recordDeletes)

  for (let recDel of recDels) {
    if (recDel.value !== undefined && recDel!.values === undefined){
      recDel = {...recDel, ...{values : [recDel.value]}}
    }
    if (!recDel.values) continue
    for (let value of recDel.values){
      features = features.filter(f => { 
        if (!f.properties){
          return true
        }
        return f.properties?.[recDel.input] !== value
      })
    }
  } 

  return features
}

const ApplyRowTransformations = (rowMappings: RowMappings, features: Feature[]): Feature[] => {
  let mappings = Object.values(rowMappings)
  Object.values(features).forEach((f) => {
    let properties = {...f.properties}
    if (properties) {
      properties = MapLayer(mappings, properties)
      f.properties = properties
    }
  })
  return features
}

const ApplyColumnTransformations = (columnMappings: ColumnMappings, features: Feature[]): Feature[] => {
  let mappings = Object.values(columnMappings)
  Object.values(features).forEach((f) => {
    let properties = {...f.properties}
    if (properties) {
      properties = MapColumn(mappings, properties)
      f.properties = properties
    }
  })
  return features
}

const ApplyValueConversions = (conversionMappings: ConversionMappings, features: Feature[]): Feature[] => {
  let mappings = Object.values(conversionMappings)
  Object.values(features).forEach((f) => {
    let properties = {...f.properties}
    if (properties) {
      properties = Convert(mappings, properties)
      f.properties = properties
    }
  })

  return features
}

const ApplyColumnDrops = (columnDrops: ColumnDrops, features: Feature[]): Feature[] => {
  Object.values(features).forEach((f) => {
    let properties = {...f.properties}
    if (properties) {
      DropColumns(columnDrops, properties)
      f.properties = properties
    }
  })
  return features
}
