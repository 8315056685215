import { MapWrraper } from "./mapWrapper";
import { IS_ADD_ON_KEY, LAYER_NAME_KEY, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import L, { LayerEvent } from 'leaflet';
import { sha256 } from 'js-sha256';
import { LINE_STRING } from "standard/geojsonMap/constants";
import { v4 as uuidv4 } from 'uuid';
import { LayerEvents, Tools } from "./enums";

declare module './mapWrapper' {
	interface MapWrraper {
		onEachPointFeature(
			feature: any,
			layer: any,
			info: LayerInfo,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void,
		): void

		onClickLayer(
			layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void): void

		selectLayer(
			layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void): void

		setOverrideLayer(
			layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void

		deselectLayer(
			key: string,
			layerMetaData: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void

		unsetOverrideLayer(
			layerMetaData: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void

		deselectAllLayers(
			except: string[],
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void

	}
}

MapWrraper.prototype.onEachPointFeature = function (
	feature: any,
	layer: any,
	info: LayerInfo,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void,
) {
	const layerMetadata: LayerMetaData = {
		info: { ...info, ...{ [LAYER_UUID_KEY]: feature.uuid! } },
		layer: layer,
		feature: feature,
		showing: true,
		layerVersion: sha256(JSON.stringify(feature)),
		renderVersion: this.status.getRenderVersion()
	}

	this.status.featureLayers[feature.uuid!] = layerMetadata
	let selectedFeatures = Object.keys(this.status.selectedLayers)
	if (selectedFeatures) {
		if (selectedFeatures!.includes(feature.uuid!)) {
			this.onClickLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp);
		}
	}
	else {
		this.setDefaultLayerStyle(layerMetadata)
	}

	let resetSelectedTimer: any

	layer.on('click', function (e) {
		L.DomEvent.stopPropagation(e);
	});

	if (info[IS_ADD_ON_KEY] === false) {
		layer.on('mousedown', (e: any) => {
			this.map.dragging.disable();
			let meta: LayerMetaData = this.status.featureLayers[layer.feature.uuid]
			this.status.mouseEvents.setOnLayer(meta)
			L.DomEvent.stopPropagation(e);
			clearInterval(resetSelectedTimer);
			this.onClickLayer(meta, onLayerEvent, setContextMenuOptionsInstance, onMouseUp);
			return
		})
	} else {
		layer.on('mousedown', (e: any) => {
			this.map.dragging.disable();
			this.status.mouseEvents.setOnLayer(layerMetadata)
			L.DomEvent.stopPropagation(e);
			return
		})
	}

	layer.on('mouseover', (e: any) => {
		clearInterval(resetSelectedTimer);
		this.highlightLayer(layerMetadata, onLayerEvent)
		return
	})

	layer.on('mouseup', function (e: any) {
		onMouseUp()
	});

	layer.on('drag', function (e: any) {

	});
}

MapWrraper.prototype.onClickLayer = function (
	layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	if (this.status.hasSelection() && this.status.isSelected(layerMetadata.feature.uuid!) === false) {
		//If a layer has already been selected and the overried tool is selected
		if (this.status.activeTools.has(Tools.Override)) {
			if (this.status.overrideLayer?.feature.uuid == layerMetadata.feature.uuid) {
				this.unsetOverrideLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
			} else {
				this.setOverrideLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
			}
			return
		}
	}
	this.selectLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
}

MapWrraper.prototype.selectLayer = function (
	layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	let selectedFeatures = Object.keys(this.status.selectedLayers)

	if (selectedFeatures?.includes(layerMetadata.feature.uuid!)) {
		if (this.status.selectedLayers.hasOwnProperty(layerMetadata.feature.uuid!)) {
			this.deselectLayer(layerMetadata.feature.uuid!, layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
		}
		onLayerEvent(layerMetadata.feature.uuid!, LayerEvents.Deselected)
		return
	}

	if (this.status.activeTools.has(Tools.MutliSelect) === false) {
		this.deselectAllLayers([layerMetadata.feature.uuid!], onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
		this.status.selectedLayers = {}
	}
	this.unhighlightLayer(layerMetadata.feature.uuid!, layerMetadata, onLayerEvent)



	this.status.selectedLayers[layerMetadata.feature.uuid!] = layerMetadata 
	setContextMenuOptionsInstance(uuidv4())
	const hasFilters: boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetadata, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)
	onLayerEvent(layerMetadata.feature.uuid!, LayerEvents.Selected)
}

MapWrraper.prototype.setOverrideLayer = function (
	layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	if (this.status.overrideLayer) {
		if (this.status.overrideLayer.feature.uuid === layerMetadata.feature.uuid) {
			return
		}
		this.unsetOverrideLayer(this.status.overrideLayer, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
	}
	this.status.overrideLayer = layerMetadata
	onLayerEvent(layerMetadata.feature.uuid!, LayerEvents.OverrideSet)
	const hasFilters: boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetadata, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)


	// let selectedFeatures = Object.keys(this.status.selectedLayers)

	// if (selectedFeatures?.includes(layerMetadata.feature.uuid!)){
	// 	if (this.status.selectedLayers.hasOwnProperty(layerMetadata.feature.uuid!)) {
	// 		this.deselectLayer(layerMetadata.feature.uuid!, layerMetadata, onLayerEvent)
	// 	}
	// 	onLayerEvent(layerMetadata.feature.uuid!, LayerEvents.Deselected)
	// 	return
	// }

	// this.deselectAllLayers([layerMetadata.feature.uuid!], onLayerEvent)
	// this.unhighlightLayer(layerMetadata.feature.uuid!, layerMetadata, onLayerEvent)

	// if (this.status.isEditable) {
	// 	if (layerMetadata.feature?.geometry.type.toLowerCase() === LINE_STRING.toLowerCase()) {
	// 		this.addEdgePoints([layerMetadata.feature], layerMetadata.info[LAYER_NAME_KEY], onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
	// 	}
	// }

	// this.status.selectedLayers = { [layerMetadata.feature.uuid!]: layerMetadata }
	// setContextMenuOptionsInstance(uuidv4())	
	// this.setLayerStyle(layerMetadata, onLayerEvent)
	// onFeatureSelection(layerMetadata.feature.uuid!, true)
}

MapWrraper.prototype.deselectLayer = function (
	key: string, layerMetaData: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	// this.setDefaultLayerStyle(layerMetaData)
	this.removeLineDecorator(layerMetaData)
	let clonedSet = { ...this.status.selectedLayers }
	delete clonedSet[key]
	this.status.selectedLayers = clonedSet
	if (layerMetaData.feature.geometry.type === LINE_STRING) {
		this.removeEdgePoints(layerMetaData.feature.uuid!)
	}
	const hasFilters: boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetaData, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)
	onLayerEvent(layerMetaData.feature.uuid!, LayerEvents.Deselected)
}

MapWrraper.prototype.unsetOverrideLayer = function (
	layerMetaData: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	this.removeLineDecorator(layerMetaData)
	this.status.overrideLayer = undefined
	const hasFilters: boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetaData, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)
	onLayerEvent(layerMetaData.feature.uuid!, LayerEvents.OverrideUnset)
}

MapWrraper.prototype.deselectAllLayers = function (
	except: string[],
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	for (const [key, value] of Object.entries(this.status.selectedLayers)) {
		if (!except.includes(key)) {
			this.deselectLayer(key, value, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
		}
	}
}
