import React, { useRef, useEffect, useCallback, useState } from 'react';
import L from 'leaflet';
import { MapWrraper } from 'standard/leaflet';
import { MapViewLayer, MapViewLayers } from '../@types/mapViewerLayer';
import { Box } from 'components/digitalTwins/map/bbox';
import { LINE_STRING, POINT } from '../constants';
import { LayerEvents } from 'standard/leaflet/enums';
import { v4 as uuidv4 } from 'uuid';
import { MapFormattings } from './mapFormat';
import { printCallStack } from 'standard/utils/debubTools';
import { arraysAreEqual } from 'standard/types/extensions/arrays/arraysAreEqual';

function MapLayers(props: { 
    mapWrapper: React.MutableRefObject<MapWrraper | null>, 
    formattings: MapFormattings,
    layers: MapViewLayers, 
    filteredFeatures: string[],
    onLayerEvent : (key: string, event : LayerEvents) => void
    finishLineMouseMove : () => void
    finishMarkerMouseMove : () => void
}) {
    const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);
    let [contextMenuOptionsInstance, setContextMenuOptionsInstance] = useState<string>(uuidv4());

    useEffect(() => {


	}, []);

    useEffect(() => {

	}, [props.formattings]);

    const drawLayers = useCallback((layers: MapViewLayers) => {
        let currentCenter: L.LatLng | undefined
        let currentZoom: number | undefined
        
        if (featuresLoaded) {
            try {
                currentCenter = props.mapWrapper.current!.getCenter()
                currentZoom = props.mapWrapper.current!.getZoom()
            } catch (e) {

            }
        }

        let layersBox: any = new Box()
        let hasContent: boolean = false
        props.mapWrapper.current!.removeAllLayers()
        props.mapWrapper.current!.removeAllLayerGroups()
        props.mapWrapper.current!.status.filtered = [...props.filteredFeatures]
        props.mapWrapper.current!.status.formattings = {...props.formattings}
        Object.keys(layers).forEach((layerKey) => {
            let leafletLayer: L.FeatureGroup = new L.FeatureGroup()
            props.mapWrapper.current!.layers[layerKey] = leafletLayer
            props.mapWrapper.current!.controls!.addOverlay(leafletLayer, layerKey);
            props.mapWrapper.current!.layers[layerKey].setStyle({
                opacity: 1,
                fillColor: '#F0F0F0',
                fillOpacity: 1,
            })

            props.mapWrapper.current!.addLayer(leafletLayer)
            //leafletLayer.bringToBack()
            const layer: MapViewLayer = layers[layerKey]
            if (Object.keys(layer.types[LINE_STRING]).length > 0) {
                const geoJSONLayer = props.mapWrapper.current!.addEdges(Object.values(layer.types[LINE_STRING]), layer.layer, props.onLayerEvent, setContextMenuOptionsInstance, props.finishLineMouseMove)
                leafletLayer.addLayer(geoJSONLayer)
                layersBox.mergeBox(leafletLayer.getBounds())
                hasContent = true
            } else if (Object.keys(layer.types[POINT]).length > 0) {
                const geoJSONLayer = props.mapWrapper.current!.addNodes(Object.values(layer.types[POINT]), layer.layer, props.onLayerEvent, setContextMenuOptionsInstance, props.finishMarkerMouseMove)
                leafletLayer.addLayer(geoJSONLayer)
                layersBox.mergeBox(leafletLayer.getBounds())
                hasContent = true
            }
            // addLinePoints(tile)
        })

        setFeaturesLoaded(true)
        if (hasContent) {
            var z = currentZoom ? currentZoom : props.mapWrapper.current!.getBoundsZoom(layersBox.bounds, true);
            var center = currentCenter ? currentCenter : layersBox.bounds.getCenter()
            props.mapWrapper.current!.setView(center, z);
        }
        else {
            props.mapWrapper.current!.setView([49, 8], 16);
        }
    }, [props])

    useEffect(() => {
        
         if (props.mapWrapper.current === null) { 
            return
        }

        drawLayers(props.layers)
    }, [props.layers, props.mapWrapper.current?.status.selectedLayers,  props.formattings]);
        
    useEffect(() => {
    
    }, [props.mapWrapper]);

    useEffect(() => {
    
    }, [props.layers]);

    useEffect(() => {
        if (props.mapWrapper.current === null) { 
            return
        }

        if (arraysAreEqual(props.filteredFeatures, props.mapWrapper.current!.status.filtered)){
            return
        }



        drawLayers(props.layers)
    }, [props.filteredFeatures]);

    useEffect(() => {

    }, [props.formattings]);
    
    return null;
}


export { MapLayers };
