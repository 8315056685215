import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IAlert } from "standard/alert";
import { IDomainModel } from 'standard/ontology/interfaces'
import { ConversionMapping, IConversionMapping } from 'standard/datatools/@types';
import MappingEdit from './mappingedit'
import { Input } from 'standard/forms/inputs'
import { Upload_Meta } from '../../types/uploads';
import ModalDialog from 'standard/dialog/modaldialog';
import * as FormInputs from 'standard/forms/inputs'
import { Feature } from 'standard/geojson/@types';
import Tags from 'standard/tags/components/tags';

const Mapping = (props: {
    id: string
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    onEdit?: (id: string, inputs: { [key: string]: Input }) => void
    onDelete: (id: string) => void
}) => {
    const [editting, setEditting] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlert>();
    const [conversionMapping, setConversionMapping] = useState<ConversionMapping | undefined>();

    useEffect(() => {
        let m : ConversionMapping = new ConversionMapping(props.upload.mapping.template.conversionMappings![props.id] as IConversionMapping)
        setConversionMapping(m);
    }, [props.upload]);

    useEffect(() => {

    }, [conversionMapping]);


    const handleEditClick = (e: any) => {
        e.preventDefault();
        setEditting(true)
    }

    const handleDeleteClick = (e: any) => {
        e.preventDefault();
        props.onDelete(props.id)
    }

    const onEdit = (id: string, inputs: { [key: string]: FormInputs.Input }) => {
        setEditting(false)
        props.onEdit!(id, inputs)
    }

    const onCancelEdit = () => {

        completeEdit()
    }

    const completeEdit = () => {
        setAlert(undefined)
        setEditting(false)
    }

    const content = () => {
        return !editting || <MappingEdit
        title='Edit Value Mapping'
        upload={props.upload}
        features={props.features}
        targetModel={props.targetModel}
        key={conversionMapping!.input}
        mapping={conversionMapping}
        isEditMode={true}
        onEdit={onEdit}
        onCancel={onCancelEdit} />
      } 

    return (
        <>
            {(conversionMapping) ?
                editting === true ||
                <div className="columns mt-0 pt-1 mb-1 is-centered" key={conversionMapping.input}>
                    <div className="column is-2 m-0 p-0">
                        {conversionMapping.input}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        {conversionMapping.matchcriteria}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        <Tags tags={conversionMapping.values}/>                        
                    </div>                    
                    <div className="column is-2 m-0 p-0">
                        {conversionMapping.output}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        {conversionMapping.newproperty}
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faPencil} onClick={handleEditClick} />
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faTrash} onClick={event => handleDeleteClick(event)} />
                    </div>
                </div>
                : null
            }
            <hr className="navbar-divider p-0 m-0" />
            <ModalDialog title='Edit Value Mapping' display={editting}
                content={content()}
            />
        </>
    )
}

export default Mapping;