import { Feature } from "standard/geojson/@types"


export const LAYER_NAME_KEY = 'name'
export const IS_ADD_ON_KEY = 'isAddOn'

export type LayerInfo = { [key: string]: any }
export type LayerMetaData = { 
	info: LayerInfo,
	feature : Feature
	layer: any
	showing: boolean
	attachments: string[]
}