import { useEffect, useState } from "react";
import { useExport } from "standard/datatools/hooks/useExport";
import useNetwork from "standard/ontology/digitalTwin/hooks/useNetwork";
import { ActionTypes, IAlert } from "standard/alert";
import { Feature, Features } from "standard/geojson/@types";
import { useImportNetworkIndividuals } from "components/dataTools/dataimport/hooks/useImportNetworkIndividuals";
import { IDataImport } from "standard/datatools/@types/IDataImport";
import AlertMessage from "standard/alert/components/alertMessage";
import ImportInfo from "./importInfo";
import MessageWrapper from "../messageWrapper";
import useDomainModel from "standard/ontology/domainModel/hooks/useDomainModel";
import useDomainModelsImports from "standard/ontology/domainModel/hooks/useDomainModelImports";
import { ValidatedFeatures, validateFeatures } from "standard/ontology/digitalTwin/components/utils";
import useDomainModelTree from "standard/ontology/domainModel/hooks/useDomainModelTree";
import { DomainModels } from "standard/ontology/domainModel/domainmodels";
import Loader from "standard/loader/loader";

const Importer = (props: {
    networkId: string,
    features: Feature[]
    restart: () => void
    onComplete: () => void
    onRequestChangeNetwork: () => void
}) => {
    const [importStatus, setImportStatus] = useState<IDataImport>();
    const { network } = useNetwork({ OntUUID: props.networkId })
    const { domainModel } = useDomainModel({ URI: process.env.REACT_APP_NETWORKS_MODEL_FILTER, inScopeOnly: false, onlyMeta: false });
    const [alert, setAlert] = useState<IAlert>();
    const [alertError, setAlertError] = useState<IAlert>();
    const [startedImport, setStartedImport] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [cancelled, setCancelled] = useState<boolean>(false);
    const { importedModels } = useDomainModelsImports({ OntUUID: domainModel?.uuid! });
    const { domainModels} = useDomainModelTree({ domainModel: domainModel});
    const { importIndividuals, canImport } = useImportNetworkIndividuals({ domainModels: domainModels, network: network });
    const [asyncImporterGenerator, setAsyncImporterGenerator] = useState<AsyncGenerator<IAlert, void, void>>();
    const [validatedFeatures, setValidatedFeatures] = useState<Feature[]>();

    useEffect(() => {

    }, [network])

    useEffect(() => {

    }, [domainModel])

    useEffect(() => {

    }, [importedModels])

    useEffect(() => {

    }, [importStatus])

    useEffect(() => {

    }, [startedImport])

    useEffect(() => {

    }, [completed])

    useEffect(() => {
        if(cancelled && asyncImporterGenerator){
            asyncImporterGenerator!.return()
            setAlertError({
                message: `Import was interrupted`,
                type: ActionTypes.ERROR,
            } as IAlert)
            setCompleted(true)
            setStartedImport(false)
        }
    }, [cancelled])

    useEffect(() => {

    }, [importStatus?.status])

    useEffect(() => {

    }, [importStatus?.status.numberOfImports])

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (domainModels){
            validateFeatures(props.networkId, domainModels, props.features).then((result : ValidatedFeatures) =>{
                setValidatedFeatures(Object.values(result.validated).flatMap(feature => feature))
            })
        }
    }, [props.networkId, domainModels, props.features]);  

    useEffect(() => {

    }, [validatedFeatures]);  

    useEffect(() => {
        if(asyncImporterGenerator){
            runAsyncImporter(asyncImporterGenerator);
        }
    }, [asyncImporterGenerator])

    const doImport = (e: any, domainModels : DomainModels, features : Feature[]) => {
        setStartedImport(true)
        setAsyncImporterGenerator(importIndividuals(domainModels, features, 500));
    }

    const runAsyncImporter = (asyncImportIterator : AsyncGenerator<IAlert, void, void>) =>{        
        (async () => {            
            for await (const result of asyncImportIterator) {
                if (cancelled){
                    break
                }
                let alertMessage: IAlert = result
                if (alertMessage.type === ActionTypes.SUCCESS) {
                    let newImportStatus: IDataImport = alertMessage.data!.status
                    setImportStatus({ ...importStatus, ...newImportStatus })
                    setAlert(alertMessage)
                    if (!newImportStatus.status.running) {
                        props.onComplete()
                    }
                } else {
                    setAlert(alertMessage)                    
                    props.onComplete()
                    break
                }
            }
            setCompleted(true)
            setStartedImport(false)
        })();
    }

    const load = () => {
        if (!network){
            return <MessageWrapper>
                <h4 className="subtitle is-6">Network</h4>
                <span className='has-text-info '>Loading Network...Should not take long unless there is a problem.</span>
            </MessageWrapper>
        }

        if (!domainModel) {
            return <MessageWrapper>
                <h4 className="subtitle is-6">Domain Model</h4>
                <span className='has-text-info '>Loading Domain Model...Should not take long unless there is a problem.</span>
            </MessageWrapper>
        }
       
        return <>
            {   
                (validatedFeatures && domainModels) && 
                <> 
                    <ImportInfo network={network} features={validatedFeatures} importStatus={importStatus} onRequestChangeNetwork={props.onRequestChangeNetwork}/>
                    <div className="buttons">
                        {(startedImport === false && completed === false) && <button className="button is-primary" onClick={(e) => doImport(e, domainModels, validatedFeatures)}>Import</button>}    
                        {completed === true && <button className="button is-primary" onClick={props.restart}>New Import</button>}    
                        {(startedImport === true && cancelled === false) && <button className="button is-primary" onClick={() => setCancelled(true)}>Cancel</button>}               
                    </div>
                    {(startedImport === true && cancelled === false) && <div>
                        <Loader message="Importing..."/>
                    </div>}
                    {(startedImport === true && cancelled) &&  <div>                        
                        <Loader message="Stopping Import..."/>
                    </div>}
                    <AlertMessage alert={alert} />
                    <AlertMessage alert={alertError} />
                </>
            }
        </>        
    }

    return (
        <div className="columns ml-1 mr-1 mt-0 mb-0 pt-0 pb-0">
            <div className="column box">
                {load()}
            </div>
        </div>
    );
}


export default Importer;