import { useEffect } from "react";
import { IDigitalTwin } from "standard/ontology/interfaces";
import { Feature } from "standard/geojson/@types";
import Label from "standard/labelcomment/label";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

const ImportDetails = (props: {
    network: IDigitalTwin,
    features: Feature[]
    onRequestChangeNetwork: () => void
}) => {
    useEffect(() => {

    }, []);

    useEffect(() => {

    }, [props.network])

    useEffect(() => {

    }, [props.features])

    return (<>
        <div className="columns p-0 m-0 ml-2 mt-1 ">
            <div className="column is-half m-1 p-1">
                <Label label="Selected Network" value={`${props.network.labels.default}`} />
            </div>
            <div className="column is-one-fifth m-1 p-1 clickable">
                <FontAwesomeIcon icon={faPencil} onClick={props.onRequestChangeNetwork} />
            </div> 
        </div>
        <hr className="list-divider m-0 p-0" />
        <div className="columns p-0 m-0 ml-2 mt-1">
            <div className="column is-half m-1 p-1">
                <Label label="Records To Import" value={`${props.features.length}`} />                
            </div>
        </div>
        
    </>

    );
}

export default ImportDetails;