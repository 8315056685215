import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from 'hooks/redux';
import { IAlert } from "standard/alert";
import { IDomainModel, IDomainModelClass } from 'standard/ontology/interfaces'
import { IRowMapping } from 'standard/datatools/@types';
import MappingEdit from './mappingedit'
import { Input } from 'standard/forms/inputs'
import { Upload_Meta } from '../../types/uploads';
import ModalDialog from 'standard/dialog/modaldialog';
import * as FormInputs from 'standard/forms/inputs'
import { Feature } from 'standard/geojson/@types';
import TextSummary from 'standard/UI/components/textSummary';

const Mapping = (props: {
    id: string
    upload: Upload_Meta
    features: Feature[]
    targetModel: IDomainModel
    referencedClasses: IDomainModelClass[]
    onEdit?: (id: string, inputs: { [key: string]: Input }) => void
    onDelete: (id: string) => void
}) => {
    const [editting, setEditting] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlert>();
    const [mappedClass, setMappedClass] = useState<IDomainModelClass | undefined>();
    const [rowmapping, setRowMapping] = useState<IRowMapping | undefined>();

    useEffect(() => {
        let m : IRowMapping = props.upload.mapping.template.rowMappings![props.id]
        setRowMapping(m);
        setMappedClass(props.referencedClasses && props.referencedClasses.find((item : IDomainModelClass) => item.labels.default === m.mapping))
    }, [props.upload]);

    useEffect(() => {
        if (rowmapping){
            if (rowmapping.value !== undefined && rowmapping!.values === undefined){
                setRowMapping({...rowmapping, ...{values : [rowmapping.value]}})
            }
        }
    }, [rowmapping]);

    useEffect(() => {

    }, [mappedClass]);

    const handleEditClick = (e: any) => {
        e.preventDefault();
        setEditting(true)
    }

    const handleDeleteClick = (e: any) => {
        e.preventDefault();
        props.onDelete(props.id)
    }

    const onEdit = (id: string, inputs: { [key: string]: FormInputs.Input }) => {
        setEditting(false)
        props.onEdit!(id, inputs)
    }

    const onCancelEdit = () => {
        completeEdit()
    }

    const completeEdit = () => {
        setAlert(undefined)
        setEditting(false)
    }

    const content = () => {
        return !editting || <MappingEdit
        title='New Mappping'
        upload={props.upload}
        features={props.features}
        targetModel={props.targetModel}
        referencedClasses={props.referencedClasses}
        key={rowmapping!.input}
        mapping={rowmapping}
        isEditMode={true}
        onEdit={onEdit}
        onCancel={onCancelEdit} />
    }

    return (
        <>
            {(rowmapping && mappedClass) ?
                editting === true ||
                <div className="columns mt-0 pt-1 mb-1 is-centered" key={rowmapping.input}>
                    <div className="column is-2 m-0 p-0">
                        {rowmapping.input}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        {rowmapping.values && <TextSummary value={rowmapping.values.concat().join(', ')} prefixLength={20} suffixLength={5}/>}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        {mappedClass && (mappedClass.labels.default)}
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faPencil} onClick={handleEditClick} />
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faTrash} onClick={event => handleDeleteClick(event)} />
                    </div>
                </div>
                : null
            }
            <hr className="navbar-divider p-0 m-0" />
            <ModalDialog title='New Mappping' display={editting}
                content={content()}
            />
        </>
    )
}

export default Mapping;