import { useEffect, useState } from 'react';
import Properties from './properties';
import { useAppSelector } from 'hooks/redux';
import { Summaries, FlattenSummaries, SUMMARYTYPE, ISummary } from './@types/ISummary';
import SummaryList from 'standard/summaryList/summaryList';

const Summary = (props: {
    columns: string[]
    data: ({ [k: string]: any; } | undefined)[]
}
) => {
    const ui = useAppSelector(state => state.ui)
    const [data, setData] = useState<({ [k: string]: any; } | undefined)[]>();
    const [summaryRules, setSummaryRules] = useState<Record<string, Set<SUMMARYTYPE>>>({ 'layer' : new Set([SUMMARYTYPE.count])});
    const [summaries, setSummaries] = useState<(Summaries)>();
    const [flattened, setFlattened] = useState<(Record<string, ISummary>)[]>();

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    useEffect(() => {

    }, [data])

    useEffect(() => {
        if (summaries){
            setFlattened(FlattenSummaries(summaries))
        }else{
            setFlattened(undefined)
        }
    }, [summaries])

    useEffect(() => {
   
        
    }, [flattened])

    useEffect(() => {
        
    }, [summaryRules])

    useEffect(() => {

    
    }, [props.columns])

    const onNewSummary = (newSummaryRules : Record<string, Set<SUMMARYTYPE>>, newSummaries : Summaries) => {
        setSummaries(newSummaries)
        setSummaryRules(newSummaryRules)
    }
    
    return (
        <div className={`is-centered no-scroll ${ui.fullscreenenabled ? 'map-content-max' : 'map-content'}`}>
            <div className='columns no-scroll'>
                <div className={`column filter is-one-fifth`}>
                    <Properties data={props.data} columns={props.columns} onNewSummary={onNewSummary}/>
                </div>
                <div className='column no-scroll'>
                    {flattened && <SummaryList title='Summary' data={flattened}  />}
                    {/* {flattened && <DataList title='Results'  data={flattened} /> }                         */}
                </div>
            </div>
        </div>

    )
}

export default Summary;