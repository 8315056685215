import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FileUploader from 'components/standard/inputs/FileUploader';
import { useImport } from "../../dataimport/hooks/useImport";
import { Feature, Features } from "standard/geojson/@types";
import { ActionTypes, IAlert } from 'standard/alert';
import { Uploads_Meta, Upload_Meta } from '.././types/uploads';
import UploadItem from './uploadItem';
import AlertDialog from 'standard/dialog/alertDialog';
import { useExport } from 'standard/datatools/hooks/useExport';
import AlertMessage from 'standard/alert/components/alertMessage';



const UploadList = (props: {
                                uploads? : Uploads_Meta
                                loading : boolean
                                onNewUpload : (name: string, features : Feature[], onComplete : () => void) => void
                                onSelected: (key: string, context : string) => void
                                onDelete: (key: string) => void
                            }) => {
    const importController = useImport();
    const exportController = useExport();
    const [alert, setAlert] = useState<IAlert>();
    const [fileNameAlert, setFileNameAlert] = useState<IAlert>();
    let [getFileName, setGetFileName] = useState<boolean>(false);
    let [fileName, setFileName] = useState<string>("");
    const [uploadToExport, setUploadToExport] = useState<Upload_Meta>();
    
    
    
    useEffect(() => {
        
    }, [props.uploads])

    useEffect(() => {
        
    }, [props.loading])

    const onDataPreview = (file: any[]) => {
        readDataFile(file)
    }

    const readDataFile = (files: any[]) => {
        if (!files) {
            return
        }


        // reader(files)

        importController.getGeoFeatures("", files).then((importFeatures: Features) => {
            importFeatures.features = setUuids(importFeatures.features)
            props.onNewUpload(importFeatures.originName, importFeatures.features, onNewUploadComplete)
        }).catch((err: IAlert) => {
            setAlert(err)
        })        
    }

    const onNewUploadComplete = () => {
        
    }

    const setUuids = (features : Feature[]) : Feature[] => {
        features.forEach((feature) => {
            if (feature.uuid === undefined) {
                if (feature.uuid === undefined) {
                    feature.uuid = uuidv4()
                }
                feature.geometry.uuid = feature.uuid
                if (feature.properties === undefined){
                    feature.properties = {}
                }
                feature.properties.uuid = feature.uuid
            }
        })
        return features
    }
    

    // const onUploadChange = (newValue: OnChangeValue<any, false>) => {
    //     setFeatures(previewMetaData![newValue].features)
    //     setSelectedUpload(newValue)
    //   }
    
    useEffect(() => {    
        if (fileName === "") {
            return
        }   

        const exportFeatures = () => {
            getFeatures(uploadToExport!).then((alert : IAlert) => {
                exportController.exportFeatures(`${fileName}.geojson`, { originName: uploadToExport!.name, features: alert.data! })
                setFileName("")
                setUploadToExport(undefined)
            }).catch((error : IAlert) => {
    
            })
        }

        exportFeatures()        
    }, [getFileName])



    const onUploadSelected = (upload: Upload_Meta, context : string) => {
        
        selectUpload(upload.key, context)
    }

    const selectUpload = (key: string, context : string) => {
        props.onSelected(key, context)
    }

    const onUploadDelete = (upload: Upload_Meta) => {
        props.onDelete(upload.key)   
    }

    const onUploadExport = (upload: Upload_Meta) => {
        setUploadToExport(upload)        
    }

    useEffect(() => {
        if (uploadToExport !== undefined) {
            setGetFileName(true)
        }
    }, [uploadToExport])

    async function getFeatures(upload : Upload_Meta): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            // try {
            //     res({
            //         message: "Got features!",
            //         type: ActionTypes.SUCCESS,
            //         data: upload.features
            //     } as IAlert)
            // } catch (error: any) {
            //     rej({
            //         message: `Unable to get features`,
            //         type: ActionTypes.ERROR,
            //     } as IAlert)
            // }
        })
        return promise
    }

    const okFileName = () => {        
        if (fileName.trim() === ""){
            setFileNameAlert({message: 'Filename cannot be empty', type : ActionTypes.ERROR} as IAlert)
            return
        }
        setGetFileName(false)
    }

    const cancelFileName = () => {
        setUploadToExport(undefined)
        setGetFileName(false)
        setFileName("")
    }

    const onFileNameChange = (e: any) => {
        e.preventDefault()
        setFileNameAlert(undefined)
        setFileName(e.target.value)
    }

    const onAbortUpload = () => {

    }

    return <div className='card ml-5 mr-5 '>
        <div className="card-header">
            <div className="card-header-title">
                <div className='columns'>
                    <div className='column mt-2'>
                        Uploads
                    </div>
                </div>
            </div>
            <div className='buttons m-2'>
                <FileUploader className="card-header-icon button is-link" title='Upload Geojson File' isMultiple={true} handleFile={onDataPreview} handleStartingSelect={onAbortUpload} />
            </div>
        </div>
        <div>
            {(!props.loading && props.uploads) ?
                Object.keys(props.uploads!).map((k: string) => {
                    return <UploadItem
                        key={k}
                        item={props.uploads![k]}
                        onSelected={onUploadSelected}
                        onDelete={onUploadDelete}
                    />
                }) :
                'Loading...'}
        </div>
        { !getFileName  ||     
            <AlertDialog
                title='Please enter a file name'
                ok={okFileName}
                cancel={cancelFileName}
                okText='Ok'
                cancelText='Cancel'
                show={getFileName}
            >
            <input className="input" type="text" value={fileName} onChange={onFileNameChange} placeholder="Enter file name here" />
            <AlertMessage alert={fileNameAlert}/>
        </AlertDialog>}
    </div>

}

export default UploadList;

