import { useEffect } from 'react';
import { DomainModels } from '../domainmodels';

export const useGeoClass = (props: { domainModels: DomainModels | undefined}) => {
    const domainClass = props.domainModels ? props.domainModels.getClassByName(`${process.env.REACT_APP_GEOMETRY_CLASS_NAME}`) : undefined;

    useEffect(() => {
 
    }, [domainClass]);

    return { geoClass : domainClass };
}