const Label = 
    (props: {
        label : string
        value : string
    }) => {

    return (
        <div className='columns'>
            <div className='column label'>
                {props.label}
            </div>
            <div className='column ml-2'>
               {props.value}
            </div>
        </div>
        
    )
}

export default Label;