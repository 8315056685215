import { setIndividual, setResponseSummary } from "./shared"
import { IAlert, ActionTypes } from "../../alert";
import { IIndividual } from "../interfaces";
import { IIndividuals } from "../interfaces/IIndividuals";
import { getDigitalTwinService } from "../utils";

export interface ApiResponse {
    status: string
    response: any
}

function tryProcessResponse(response: any) {
    if (response.status === "200" || response.status === 200) {
        return undefined
    }

    if (response.status.toString() === "204") {
        return {
            message: "No data",
            type: ActionTypes.ERROR,
        } as IAlert
    }

    return {
        message: response.description || response.error,
        type: ActionTypes.ERROR,
    } as IAlert
}

export async function getIndividual(token: string, ontologyUuid: string, individualId: string): Promise<IIndividual> {
    const promise = new Promise<IIndividual>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${getDigitalTwinService()}/digitaltwin/${ontologyUuid}/individual/${individualId}`, requestOptions)
            .then(response => {
                return response.json()
            }
            )
            .then(result => {
                const response = tryProcessResponse(result);
                if (response !== undefined) {
                    rej(response);
                    return;
                }
                const item: IIndividual = {                    
                    uuid: result.response.uuid,
                    iri: result.response.iri,
                    labels: result.response.labels,
                    comments: result.response.comments,
                    types: result.response.types,
                    dataProperties: result.response.dataProperties,
                    objectProperties: result.response.objectProperties
                };
                res(item)
            })
            .catch(error => rej(error)
            );
    });
    return promise;
}

export async function getIndividuals(params : {token: string, summarize : boolean, ontUUID?: string, search? : string, page?: number, pageSize?: number, classUUID?: string}): Promise<IIndividuals> {
    const promise = new Promise<IIndividuals>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${params.token}`);

        var requestOptions: RequestInit = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };


        var url = new URL(`${getDigitalTwinService()}/digitaltwin/${params.ontUUID}/individual`);
        url.search = new URLSearchParams(
            {
                type: params.classUUID ? params.classUUID : "",
                summarize: params.summarize.toString(),
                search: params.search ? params.search : "",
                page: params.page ? params.page.toString() : "",
                pagesize: params.pageSize ? params.pageSize.toString() : ""
            }).toString();

        fetch(url.href, requestOptions)
            .then(response => {
                const responseProcessed = tryProcessResponse(response);
                if (responseProcessed !== undefined) {
                    rej(responseProcessed);
                    return;
                }
                return response.json()
            })
            .then(result => {
                const items = result.response.map((x: any) => {
                    return setIndividual(x)
                });
                res({ list: items, summary: setResponseSummary(result.summary) } as IIndividuals)
            })
            .catch(error => rej(error));
    });
    return promise;
}

export async function updateIndividuals(token: string, digitaltwinUUID: string, data: IIndividual[]): Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");
        
        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(`${getDigitalTwinService()}/digitaltwin/${digitaltwinUUID}/individual/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => {
                rej(error);
            })

    });
    return promise;
}

export async function deleteIndividuals(token: string, digitaltwinUUID: string, data: string[]): Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");

        const requestOptions: RequestInit = {
            method: 'DELETE',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(`${getDigitalTwinService()}/digitaltwin/${digitaltwinUUID}/individual/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => {
                rej(error);
            })

    });
    return promise;
}