export interface IPagination {
    pages : number[]
    currentPage : number
    display: string[]
}

export const getPagination = (pageCount : number, currentPage : number, visible : number) : IPagination => {
    let pages : number[] =  getVisiblePages(pageCount, currentPage, visible)
    return {pages : pages, currentPage : currentPage, display : getPagesDisplay(pages)} as IPagination
}

export const getPagesDisplay = (pages : number[]) : string[] => {
    let pagesDisplay : string[] = []
    for (let i = 0;i < pages.length; i++){
        if (i > 0){
            if (pages[i] - pages[i-1] > 1){
                pagesDisplay.push("")
            }
        }
        pagesDisplay.push(pages[i].toString())
    }
    return pagesDisplay
}

function addPage(pages : number[], page :  number) {
    if (!pages.includes(page)) {
        pages.push(page);
    }
}

const getVisiblePages = (pageCount : number, currentPage : number, visible : number) : number[] => {
    let pages : number[] = []

    for (let i = currentPage - 1; i < currentPage + 3; i++){
        if (i < 1) continue 
        if (i > pageCount) continue
        addPage(pages, i)
    }

    if (pageCount <= visible){
        for (let i = 0; i < pageCount; i++){
            addPage(pages, i + 1)
        }
        return pages
    }

    if (pageCount > visible){
        for (let i = 0; i < 5; i++){
            addPage(pages, pageCount - i)
            if (pages.length > visible - 1){
                break
            }
            addPage(pages,i + 1)
            if (pages.length > visible - 1){
                break
            }   
        }
        
        return pages.sort((a,b) => a - b)
    }

    return pages.sort(( a,b) => a - b)
}