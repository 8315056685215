import { useEffect, useState, useRef } from "react"
import { IAlert, ActionTypes } from "standard/alert";
import * as FormInputs from 'standard/forms/inputs'
import { IDomainModel } from "standard/ontology/interfaces";
import { Upload_Meta } from "../../types/uploads";
import { Inputs } from "./inputs";
import { Feature } from "standard/geojson/@types";
import { IRecordAdd } from "standard/datatools/@types";
import MappingEditor from "../mappingeditor";

const MappingEdit = (props: {
    title: string
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    mapping?: IRecordAdd
    onAdd?: (inputs: { [key: string]: FormInputs.Input }) => void
    onEdit?: (id: string, inputs: { [key: string]: FormInputs.Input }) => void
    onCancel: () => void
    isEditMode: boolean
    alert?: IAlert
}) => {
    const [inputs, setInputs] = useState<{ [key: string]: FormInputs.Input }>({});

    useEffect(() => {

    }, []);

    useEffect(() => {
        let newInputs = Object.assign({}, Inputs());
        if (props.mapping) {
            for (var i in newInputs) {
                let input: FormInputs.Input = newInputs[i];
                input.value = props.mapping![i as keyof IRecordAdd] || ''
            }
        }
        setInputs(newInputs)
    }, [props.mapping]);
   
    return (
        <MappingEditor
            title={props.title}
            id={props.mapping?.id}
            upload={props.upload}
            features={props.features}
            inputs={inputs}
            targetModel={props.targetModel}
            onAdd={props.onAdd}
            onEdit={props.onEdit} 
            onCancel={props.onCancel}
            isEditMode={props.isEditMode}
            alert={props.alert}
        />
    )
}


export default MappingEdit