import { useState, useEffect, useRef } from 'react';
import { Feature } from 'standard/geojson/@types';
import { useUndirectedGraph } from 'components/dataTools/datapreview/hooks/useUndirectedGraph';
import AlertMessage from 'standard/alert/components/alertMessage';
import { IAlert } from 'standard/alert';
import Card from 'standard/card/card';

const GraphCreator = (props: {
    features:  Feature[]
    onFeaturesChange: (features: Feature[]) => any
    isExpanded?:boolean
}) => {
    const [nameAlert, setNameAlert] = useState<IAlert>();
    const [radius, setRadius] = useState<number>(1);
    const [appliedRadius, setAppliedRadius] = useState<number>();
    const { undirectedGraph } = useUndirectedGraph({features : props.features, radius: appliedRadius})
    const [paramsChanged, setParamsChanged] = useState<boolean>(true);
    const [creatingGraph, setCreatingGraph] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const isExpanded = (props.isExpanded !== undefined ? props.isExpanded : false);
    
    useEffect(() => {           
        
    }, [ appliedRadius])

    useEffect(() => {           
        if(radius === appliedRadius){
            setParamsChanged(false)
        }else{
            setParamsChanged(true)
        }
    }, [radius, appliedRadius])

    useEffect(() => {
        
    }, [paramsChanged])

    useEffect(() => {     
        if (undirectedGraph && creatingGraph){
            props.onFeaturesChange(undirectedGraph)
            setCreatingGraph(false)
        }      
    }, [undirectedGraph])

    const onOk = () => {
        setCreatingGraph(true)
        setAppliedRadius(radius)
    }

    const onChange = (e: any) =>{
        if (isNaN(parseFloat( e.currentTarget.value))){
            setRadius(radius)
            inputRef.current!.value = radius.toString()
        }else{
            setRadius(parseFloat( e.currentTarget.value))
        }
    }
    
    return (
        <Card title='Graph Creator' isExpanded={isExpanded}>
            <div className="field">
                    <label className="label smallText">Radius (meters)</label>
                    <div className="control">
                        <input ref={inputRef} className="input smallText" type="number" value={radius} placeholder="Enter Radius here" onChange={onChange}/>
                    </div>
                </div>
                <div className="is-centered ">
                    {
                        <button className="button smallText is-centered" disabled={!paramsChanged} key='new prop' onClick={() => onOk()}>
                            Create Graph                    
                        </button> 
                    }
                    <AlertMessage alert={nameAlert} style="smallText" />
                </div>
        </Card>
    )
}
    

export default GraphCreator;