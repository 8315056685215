import { useState, useEffect } from 'react';
import MappingEdit from './mappingedit'
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'standard/forms/inputs'
import { IDomainModel, IDomainModelClass } from 'standard/ontology/interfaces';
import { IRowMapping } from 'standard/datatools/@types';
import { Upload_Meta } from '../../types/uploads';
import { RowMappings } from 'standard/datatools/@types/IMappingTemplate';
import { default as RowMapped } from './mapping';
import ModalDialog from 'standard/dialog/modaldialog';
import { Inputs } from './inputs';
import { Feature } from 'standard/geojson/@types';

const MapperList = (props: {
    upload: Upload_Meta
    features: Feature[]
    targetModel: IDomainModel
    referencedClasses: IDomainModelClass[]
    onMappingChange: (mappings: RowMappings) => void
}) => {
    const [mappingClass, setMappingClass] = useState<IDomainModelClass>();
    const [showAddNew, setShowAddNew] = useState<boolean>(false);

    useEffect(() => {

    }, [props.upload])

    useEffect(() => {

    }, [props.upload.mapping])

    useEffect(() => {

    }, [props.targetModel])


    // useEffect(() => {
    //     if (!mappingClassProperty) {
    //         return
    //     }

    //     if (!props.template.individuals) {
    //         return
    //     }
    //     let mappings: IRowMapping[] = []
    //     for (var i = 0; i < props.template.individuals.length; i++) {
    //         for (var j in props.template.individuals[i].dataProperties) {
    //             if (j !== mappingClassProperty!.uuid) continue
    //             let dataProp : IRowMapping = IRowMappingFromJson(props.template.individuals[i].dataProperties![j])
    //             mappings.push({ id: props.template.individuals[i].uuid, input: dataProp.input, value: dataProp.value, mapping: dataProp.mapping } as IRowMapping)
    //         }
    //     }
    //     setMappings(mappings)
    // }, [props.template, mappingClassProperty])

    // useEffect(() => {
    //     setMappingClass(undefined)
    //     setMappingClassProperty(undefined)

    //     if (!templateModel) {
    //         return
    //     }
    //     if (templateModel.classes) {
    //         setMappingClass(templateModel.classes.find(c => c.iri === `${process.env.REACT_APP_TEMPLATES_MODEL_FILTER}/class/mapping`))
    //     }
    //     if (templateModel.dataProps) {
    //         setMappingClassProperty(templateModel.dataProps.find(c => c.iri === `${process.env.REACT_APP_TEMPLATES_MODEL_FILTER}/dataprop/mapclass`))
    //     }

    // }, [templateModel])

    // useEffect(() => {
    //     setTargetModelProperty(undefined)
    //     if (!templateModel) {
    //         return
    //     }
    //     if (templateModel.dataProps) {
    //         setTargetModelProperty(templateModel.dataProps.find(c => c.iri === `${process.env.REACT_APP_TEMPLATES_MODEL_FILTER}/dataprop/target-model`))
    //     }
    // }, [templateModel])

    // useEffect(() => {
    //     if (props.template && targetModelProperty ){
    //         let dt = new DigitalTwin(props.template)
    //         let target = dt.getDataProperties(targetModelProperty!.uuid!)
    //         if (target.length > 0) {
    //             getDomainModelWithUri(authenticated.user!.token, target[0], true)
    //                 .then((item: IDomainModel) => {
    //                     setTargetModel(item);
    //                 })
    //                 .catch((err) => {

    //                 });
    //         }        
    //     }
    // }, [props.template, targetModelProperty]);


    useEffect(() => {

    }, [mappingClass])


    const onAddNew = () => {
        setShowAddNew(true)
    }

    const onCloseDialog = () => {
        setShowAddNew(false)
    }

    const handleAddNew = (newinputs: { [key: string]: Input }) => {
        let id: string = uuidv4()
        let rowMapping = {
            id: id,
            input: newinputs.input.value,
            values: newinputs.values.value,
            mapping: newinputs.mapping.value
        } as IRowMapping

        let rowMappings: RowMappings = {}
        if (props.upload.mapping.template && props.upload.mapping.template.rowMappings) {
            rowMappings = { ...props.upload.mapping.template.rowMappings }
        }

        rowMappings[id] = rowMapping
        props.onMappingChange(rowMappings)
        setShowAddNew(false)
    }

    const handleEdit = (id: string, newinputs: { [key: string]: Input }) => {
        let rowMapping = {
            id: id,
            input: newinputs.input.value,
            values: newinputs.values.value,
            mapping: newinputs.mapping.value
        } as IRowMapping

        let rowMappings: RowMappings = {}
        if (props.upload.mapping.template && props.upload.mapping.template.rowMappings) {
            rowMappings = { ...props.upload.mapping.template.rowMappings }
        }
        rowMappings[id] = rowMapping
        props.onMappingChange(rowMappings)
    }


    const onDelete = (id: string) => {
        if (props.upload.mapping.template && props.upload.mapping.template.rowMappings) {
            let rowMappings: RowMappings = {}
            rowMappings = { ...props.upload.mapping.template.rowMappings }
            delete rowMappings[id]
            props.onMappingChange(rowMappings)
        }
    }

    const createHeaders = () => {
        return Object.values(Inputs()).map((value) => {
            return <div key={value.name} className="column is-2 list-header-cell cell p-0 pl-1 pr-1 m-0">
                {value.label}
            </div>
        })
    }

    const content = () => {
        return !showAddNew || <MappingEdit 
            title='New Mappping'
            upload={props.upload} 
            features={props.features} 
            targetModel={props.targetModel} 
            referencedClasses={props.referencedClasses} 
            isEditMode={false} 
            onAdd={handleAddNew} 
            onCancel={onCloseDialog} 
            />
    }

    return (
        <>
            <div className='ml-5 mr-5  smallText is-centered'>
                <div className="columns list-header mt-1 mb-0 pb-0 has-text-weight-bold is-centered is-vcentered">
                    {createHeaders()}
                    <div className="column is-1 m-0 p-0 pb-1 clickable">
                        <button className="card-header-icon button is-primary" aria-label="more options" onClick={onAddNew}>
                            Create New
                        </button>
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                    </div>
                </div>
                <hr className="navbar-divider m-0 p-0" />
                {(props.upload.mapping.template && props.upload.mapping.template.rowMappings) && Object.keys(props.upload.mapping.template.rowMappings).map((key) => {
                    return <RowMapped upload={props.upload} 
                                      features={props.features} 
                                      targetModel={props.targetModel}
                                      referencedClasses={props.referencedClasses} 
                                      key={key} id={key} 
                                      onEdit={handleEdit} onDelete={onDelete} />
                })}

            </div>
            <ModalDialog title='New Mappping' display={showAddNew}
                content={content()} />
        </>
    )
}

export default MapperList;