import './styles.css'
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import RootMenuFlat from 'standard/navigation/menu/components/rootMenuFlat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react';
import { ContextMenuOptions } from './@types/contextMenuOptions';

const ContextMenu = (props: {
    version: string
    options: ContextMenuOptions
    action: (item: IMenuItem) => void
    onClose: () => void
}) => {
    const [isActive, setActive] = useState<boolean>(false);
    const [clientPosition, setClientPosition] = useState<{ x: number, y: number }>();

    useEffect(() => {
        if (props.options.clientPosition){
            var mapElement = document.getElementById("contextMenuWrapper")?.parentElement?.parentElement?.parentElement
            var mapRect = mapElement!.getBoundingClientRect();
            setClientPosition({ x: props.options.clientPosition.x - mapRect.left + document.documentElement.scrollLeft , y: props.options.clientPosition.y - mapRect.top + document.documentElement.scrollTop - 50 })
        }
    }, [props.version, props.options.clientPosition]);

    useEffect(() => {
        
    }, [clientPosition]);
					
    useEffect(() => {
        if (props.options.visible === false) {
            setActive(false)
            return
        }

        if (!props.options.clientPosition) {
            setActive(false)
            return
        }


        if (!props.options.menuItems) {
            setActive(false)
            return
        }


        if (!props.options.title) {
            setActive(false)
            return
        }

        setActive(true)
    }, [props.options.clientPosition, props.options.mapPostion, props.options.title, props.options.visible, props.options.menuItems]);

    useEffect(() => {
        
    }, [isActive]);

    return <div id='contextMenuWrapper'>
        {(isActive && clientPosition) ?
        <div id="contextmenu" className="card m-2 p-2" style={{ left: clientPosition!.x, top: clientPosition!.y }}>
            <header className="card-header">
                <p className="card-header-title contextmenu-title">
                    {props.options.title!}
                </p>
                <button className="card-header-icon" aria-label="more options" onClick={props.onClose}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </header>
            <div className="card-content m-0 p-0 pt-1">
                <RootMenuFlat links={props.options.menuItems!} action={props.action} />
            </div>
        </div> :
        <></>}
    </div> 
}

export default ContextMenu;