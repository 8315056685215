import { useEffect, useState } from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { summarizeText } from 'standard/utils/summarizeText';
import { IMenuItem } from './@types/IMenuItem';
import { CreateOrUpdateSummary, getSummaryType, ISummary, Summaries, SUMMARYTYPE } from './@types/ISummary';

const summaryMenu: Record<string, IMenuItem> = {
    // [SUMMARYTYPE.sum]: { name: SUMMARYTYPE.sum, label: 'sum', ordinality: 1, parent: '' },
    [SUMMARYTYPE.count]: { name: SUMMARYTYPE.count, label: 'count', ordinality: 2, parent: '' },
    // [SUMMARYTYPE.max]: { name: SUMMARYTYPE.max, label: 'min', ordinality: 3, parent: '' },
    // [SUMMARYTYPE.min]: { name: SUMMARYTYPE.min, label: 'max', ordinality: 4, parent: '' },
}

const Properties = (props: {
    columns: string[]
    data: ({ [k: string]: any; } | undefined)[]
    onNewSummary : (summaryRules : Record<string, Set<SUMMARYTYPE>>, summaries : Summaries) => void
}) => {
    const [columns, setColumns] = useState<string[]>();
    const [showPropertyMenu, setShowPropertyMenu] = useState<Record<string, boolean>>({});
    const [summaryRules, setSummaryRules] = useState<Record<string, Set<SUMMARYTYPE>>>({ 'layer' : new Set([SUMMARYTYPE.count])});

    useEffect(() => {
        setColumns(props.columns)
    }, [props.columns])

    useEffect(() => {

    }, [props.data])

    useEffect(() => {

    }, [columns])

    useEffect(() => {
        aggregate(props.data, summaryRules)
    }, [summaryRules])

    useEffect(() => {

    }, [showPropertyMenu])

    const buildSummary = (summaries : Summaries | undefined, summary: ISummary | undefined, row: { [k: string]: any; } | undefined, rules: Record<string, Set<SUMMARYTYPE>>, pendingRules : string[]) : ISummary | undefined => {
        const nextRuleKey : string | undefined = pendingRules.shift()
        
        if (nextRuleKey !== undefined){
            const propertyValue : any  = row && row[nextRuleKey]
            if (summary) {                
                if (!summary!.children) {
                    summary!.children = {}
                }
                let thisSummary = CreateOrUpdateSummary(summary.key, nextRuleKey, propertyValue, summary.children,rules) 
                buildSummary(thisSummary.children, thisSummary, row, rules, [...pendingRules])
                summary!.children[thisSummary.key] = thisSummary
            }else{
                summary =  CreateOrUpdateSummary("", nextRuleKey, propertyValue, summaries,rules) 
                buildSummary(summaries, summary, row, rules, [...pendingRules])
            }  
        }
        return summary
    }

    const aggregate = (dataToAggregate: ({ [k: string]: any; } | undefined)[], rules: Record<string, Set<SUMMARYTYPE>>) => {
        let summaries: Summaries = {}
        let properties = Object.keys(rules)

        Object.values(dataToAggregate).forEach((val) => {    
            const summary : ISummary | undefined = buildSummary(summaries, undefined, val, rules, [...properties])
            if (summary){
                summaries[summary.key] = summary! 
            }
        })
        
        props.onNewSummary(rules, summaries)
    }

    const summarize = (column: string, type: SUMMARYTYPE, selected: boolean) => {
        if (!selected) {
            if (summaryRules.hasOwnProperty(column)) {
                const summarySet = summaryRules[column];
                summarySet.delete(type)
                if (summarySet.size > 0){
                    setSummaryRules({ ...summaryRules, ...{ [column]: summarySet } })
                }else{
                    let clone = {...summaryRules};
                    delete clone[column];
                    setSummaryRules({ ...clone})
                }
                
            }
        }
        else {
            let columnDictionary : Set<SUMMARYTYPE> = summaryRules[column] ? { ...summaryRules[column] } : new Set();
            columnDictionary.add(type)
            setSummaryRules({ ...summaryRules, ...{ [column]: columnDictionary } })
        }
    }

    const getSummaryMenu = (property: string, menu: Record<string, IMenuItem>) => {
        return <div className='m-0 p-0'>{Object.values(menu).map((value, idx) => {
            let isSelected: boolean = (summaryRules[property] !== undefined && summaryRules[property].has(getSummaryType(value.name))) ? true : false            
            return <div key={value.name} className={`columns p-0 m-0 ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`}>
                <div className="column m-0 p-0" >
                    <div className='columns m-0 p-0'>
                        <div className='column m-0 p-0 ml-5 is-four-fifths filter-hidden'>
                            <label className="checkbox smallText">
                                <input type="checkbox" checked={isSelected} onChange={(e) => summarize(property, getSummaryType(value.name), e.target.checked)} />
                                <span className='ml-1'>{value.label}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        })}
        </div>
    }

    const displayProperties = (headers: string[]) => {
        return headers.map((value, idx) => {
            let hasFilter = -1
            return <div key={value} className={`box columns p-1 m-1 mb-1 ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`}>
                <div className="column m-0 p-0 " >
                    <div className='columns m-0 p-0'>
                        <div className='column pl-2 ml-2 mt-1 mb-1 m-0 p-0 is-four-fifths filter-hidden'>
                            {summarizeText(value, 15, 3)}
                        </div>
                        <div className='column m-0 mt-1 mb-1 p-0 is-pulled-right'>
                            <FontAwesomeIcon className={`ml-1 button headericon is-inverted ${hasFilter !== -1 ? 'is-danger' : ''}`} icon={faAngleDown} onClick={() => onShowPropertyMenu(value)} />
                        </div>
                    </div>
                    {
                        showPropertyMenu[value] && getSummaryMenu(value, summaryMenu)
                    }
                </div>
            </div>
        })
    }

    const onShowPropertyMenu = (column: string) => {
        if (showPropertyMenu.hasOwnProperty(column)) {
            const { [column]: deletedKey, ...newDictionary } = showPropertyMenu;
            setShowPropertyMenu(newDictionary);
            return
        }
        setShowPropertyMenu({ ...showPropertyMenu, [column]: true })
    }

    return (<>
        {columns && displayProperties(columns)}
    </>
    )
}

export default Properties;