import {
	control,
	Control,
	Layer,
	Map,
	LatLng,
	LatLngExpression,
	LatLngBoundsExpression,
	tileLayer,
	Point,
	ZoomPanOptions
} from "leaflet";
import { IS_ADD_ON_TO, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import { MapRefStatus } from "standard/geojsonMap/@types/mapStatus";
import { LayerEvents } from "./enums";
import { MapFormattingKeys, MapFormattings } from "standard/geojsonMap/map/mapFormat";
export class MapWrraper {
	public map: Map
	public status: MapRefStatus = new MapRefStatus()
	public layers: { [key: string]: L.FeatureGroup } = {}
	public controls: L.Control.Layers | undefined
	constructor(map: Map, isEditable: boolean, formattings : MapFormattings) {
		this.map = map
		this.status.isEditable = isEditable
		this.status.formattings = formattings
	}

	addControl(control: Control) {
		this.map.addControl(control)
	}

	addLayer(layer: Layer) {
		this.map.addLayer(layer)
	}

	eachLayer(fn: (layer: Layer) => void, context?: any) {
		return this.map.eachLayer(fn, context)
	}

	getZoom(): number {
		return this.map.getZoom()
	}

	getCenter(): LatLng {
		return this.map.getCenter()
	}

	getBoundsZoom(bounds: LatLngBoundsExpression, inside?: boolean, padding?: Point): number {
		return this.map.getBoundsZoom(bounds, inside, padding)
	}

	setView(center: LatLngExpression, zoom?: number, options?: ZoomPanOptions) {
		this.map.setView(center, zoom, options)
	}

	/**
	 * Removes all listeners to all events on the object.
	 */
	off() {
		this.map.off()
	}

	remove() {
		this.map.remove()
	}

	removeAllLayers() {
		this.map.eachLayer((layer) => {
			if (layer instanceof Layer) {
				this.map.removeLayer(layer);
			}
		});
	}

	removeAllLayerGroups() {
		for (let group of Object.keys(this.layers)) {
			this.removeLayerGroup(group)
		}
	}

	removeLayerGroup(layerKey: string) {
		if (!this.layers.hasOwnProperty(layerKey)) {
			return
		}

		if (this.controls) {
			this.controls.removeLayer(this.layers[layerKey])
		}

		this.map.removeLayer(this.layers[layerKey]);
		delete this.layers[layerKey]
	}

	removeLayer(
		uuid: string,
	) {
		let layerMetaData: LayerMetaData = this.status.featureLayers[uuid]
		if (layerMetaData) {
			layerMetaData.layer.remove()
			// mapRef.current!.removeLayerGroup(layerMetaData.layer)
		}
		delete this.status.featureLayers[uuid]
		delete this.status.highlightedLayers[uuid]
		delete this.status.selectedLayers[uuid]
	}

	removeEdgePoints(
		edgeUUID: string
	) {
		let edgePoints: LayerInfo[] = this.status.edgePoints.filter((e: LayerInfo) => e[IS_ADD_ON_TO] === edgeUUID);
		edgePoints.forEach((e: LayerInfo) => {
			this.removeLayer(e[LAYER_UUID_KEY])
		})
		this.status.edgePoints = this.status.edgePoints.filter((e: LayerInfo) => e[IS_ADD_ON_TO] !== edgeUUID);
	}

	render(overlayLayers: any) {
		const openStreetMapTileLayer = tileLayer(
			'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
			{
				attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
				maxZoom: 50,
				maxNativeZoom: 18
			});

		const arcgisOnlineMapTileLayer = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			{
				attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
				maxZoom: 50,
				maxNativeZoom: 18
			});

		const anonymousMapLayer = tileLayer('');


		let baseMaps = {
			"Anonymous Mode": anonymousMapLayer,
			"Open Street Map": openStreetMapTileLayer,
			"Arcgis Online Map": arcgisOnlineMapTileLayer
		}

		this.controls = control.layers(baseMaps, overlayLayers).addTo(this.map);
	}

	redraw(
		onLayerEvent : (key: string, event : LayerEvents) => void,
		setContextMenuOptionsInstance: (key: string) => void,
		onMouseUp: () => void
	) {
		let currentCenter: L.LatLng | undefined
		let currentZoom: number | undefined

		try {
			currentCenter = this.getCenter()
			currentZoom = this.getZoom()
		} catch (e) {

		}

		Object.keys(this.layers).forEach((layerKey) => {
			let leafletLayer: L.FeatureGroup = this.layers[layerKey]
			this.addLayer(leafletLayer)
		})

		this.curate(onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
	}
}