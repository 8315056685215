import { useEffect, useState,  useCallback } from 'react';
import { IOptions } from 'standard/forms/interfaces/IOption';
import './styles.css'
import StandardList from './list';


const RangeList = (props: {
    title: string,
    data: string[]
    onValueSelected: (value: string) => void
    options?: IOptions
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [data, setData] = useState<string[]>();
    const [doingSearch, setDoingSearch] = useState<boolean>(false);
    const [search, setSearch] = useState<string>();

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        setDoingSearch(false)
    }, [data])

    useEffect(() => {
        doSearch(search)
    }, [search])


    const onSearchChange = () => {
        setDoingSearch(true)
    }


    useEffect(() => {

    }, [doingSearch])



    const onPageSelect = (page: number) => {
        setCurrentPage(page)
    }

    const doSearch = (newSearch?: string) => {
        if (!data) {
            setDoingSearch(false)
            return
        }
        let splitSearch = newSearch?.toLowerCase().split(' ')
        if (splitSearch) {
            splitSearch = splitSearch!.length === 0 ? undefined : splitSearch
        }

        if (!splitSearch) {
            setData(props.data)
            return
        }

        const search = (searches: string[], value: string) => {
            if (value === null || value === undefined) {
                return false
            }
            let valueSearch: boolean = true
            for (const searchString of Object.values(searches)) {
                if (value.toString().toLowerCase().indexOf(searchString) === -1) {
                    valueSearch = false
                    break
                }
            }

            if (valueSearch) {
                return true
            }
            return false
        }

        const searchResult = props.data!.filter((d) => {
            let found: boolean = true
            if (splitSearch && d) {
                if (!search(splitSearch, d)) {
                    found = false
                }
            }

            return found

        })
        setData(searchResult)
    }

    const onValueClick = (e: any, value: string) => {
        props.onValueSelected(value)
    }

    const displayContent = (from: number, to: number) => {
        if (!data) {
            return <></>
        }
        return <div className='columns is-multiline'>
            {
                data.slice(from, to).map((row, idx) => {
                    let valueToDisplay: string = ''
                    let tooltip: string = ''
                    let value: string = ''
                    if (row) {
                        if (row!.length > 30) {
                            const l = row!.length
                            valueToDisplay = (row!).substring(0, 20) + '...' + row!.substring(l - 5, l)
                            tooltip = row!
                        } else {
                            valueToDisplay = row!
                        }
                        value = row!
                    }
                    return <div key={value} className={`column item ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`} title={tooltip} onClick={(e) => onValueClick(e, value)}>
                            <span >{valueToDisplay}</span>
                        </div>
                })
            }
        </div>
    }

    return (<>
        <StandardList
            title={props.title}
            onPageSelect={onPageSelect}
            currentPage={currentPage}
            itemCount={data ? data.length : 0}
            pageSize={pageSize}
            onSearch={setSearch}
            onSearchChange={onSearchChange}
            options={props.options}
        >
            {doingSearch === true ?
                'Searching...'
                : <div className='is-centered rangelist-content is-8' >
                    {displayContent((currentPage - 1) * pageSize, ((currentPage - 1) * pageSize) + pageSize)}
                </div>
            }
        </StandardList>
    </>

    )
}

export default RangeList;