import { IDomainModel, IDomainModelClass, IDomainModelProperty } from "../interfaces";
import { getClassProperties } from "./Classes/utils";
import { DomainModel } from "./domainmodel";
import { ClassProperties } from "./types/classProperties";

export class DomainModels extends Array<DomainModel> {
    public classes: Record<string, IDomainModelClass> = {}
    public dataProps: Record<string,IDomainModelProperty> = {}
    public objProps: Record<string,IDomainModelProperty> = {}

    constructor(items: IDomainModel[]) {
        super()
        items.forEach(item => this.add(item))
    }

    add(model: IDomainModel) {
        this.push(new DomainModel(model));
        this.update(model);
    }

    private update(model: IDomainModel){        
        if (model.classes){
            let classDict : Record<string, IDomainModelClass> = model.classes.reduce((acc, curr, index) => {
                acc[curr.uuid!] = curr;
                return acc;
            }, {});

            this.classes = {...this.classes, ...classDict}
            this.updateClasses(model.classes)
        }

        if (model.dataProps){
            let dPs : IDomainModelProperty[] = model.dataProps!.map((d) => {return {...d, Ontology: model} as IDomainModelProperty} )
            let dPsDict : Record<string, IDomainModelProperty> = dPs.reduce((acc, curr, index) => {
                acc[curr.uuid!] = curr;
                return acc;
            }, {});
            this.dataProps = {...this.dataProps, ...dPsDict}
        }

        if (model.objProps){
            let oPs : IDomainModelProperty[] = model.objProps!.map((d) => {return {...d, Ontology: model} as IDomainModelProperty} )
            let oPsDict : Record<string, IDomainModelProperty> = oPs.reduce((acc, curr, index) => {
                acc[curr.uuid!] = curr;
                return acc;
            }, {});
            this.objProps = {...this.objProps, ...oPsDict}
        }                
    }

    private updateClasses(classes: IDomainModelClass[]){
        classes.forEach((item : IDomainModelClass) => {            
            let props : ClassProperties = getClassProperties(item, this)
            item.dataProps = props.dataProps
            item.objProps = props.objProps
        })
    }

    getClassByName(label: string): IDomainModelClass | undefined {
        return Object.values(this.classes).find((c : IDomainModelClass) => c.labels.default === label)
    }

    getClassById(uuid: string): IDomainModelClass | undefined {
        return Object.values(this.classes).find((c : IDomainModelClass) => c.uuid === uuid)
    }

    getDataProperty(label: string): IDomainModelProperty | undefined {
        for (let m of this){
            let dataProps = m.getDataProperty(label)
            if (dataProps) {
                return dataProps
            }
        }
        return undefined
    }
}