import { useEffect, useCallback, useState } from 'react';
import { Feature} from 'standard/geojson/@types';

import {sha256} from 'js-sha256'
import { ConnectedTo, IConnectedTo } from 'standard/ontology/map/features/connectedTo';
import { CONNECTIONS_KEY } from 'components/utils/constants';

export const useUndirectedGraph = (props : {
        features? : Feature[], 
        radius? : number
    }) => {
    let [undirectedGraph, setUndirectedGraph] = useState<Feature[]>();

    const getConnections = useCallback(() => {
        if (!props.features){
            setUndirectedGraph(undefined);
            return
        }
        if (!props.radius){
            setUndirectedGraph(undefined);
            return
        }

        function convertDegToRad( degrees : number ) {
			return degrees * Math.PI / 180;
		};

		function getDistanceFromLatLonInKm(point1 : number[], point2 : number[]) : number | undefined {
            if (!Array.isArray(point1)) return undefined
			if (!Array.isArray(point2)) return undefined
			if (point1.length !== 2) return undefined
			if (point2.length !== 2) return undefined
			
            const [lat1, lon1] = point1;
			const [lat2, lon2] = point2;
			const earthRadius = 6371;
			const dLat = convertDegToRad(lat2 - lat1);
			const dLon = convertDegToRad(lon2 - lon1);
			const squarehalfChordLength =
			  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			  Math.cos(convertDegToRad(lat1)) * Math.cos(convertDegToRad(lat2)) *
			  Math.sin(dLon / 2) * Math.sin(dLon / 2);
		  
			const angularDistance = 2 * Math.atan2(Math.sqrt(squarehalfChordLength), Math.sqrt(1 - squarehalfChordLength));
			const distance = earthRadius * angularDistance;
			return distance;
		  
		  }

        function calculateRadius (from : number[], to : number[]) : number | undefined  {
            let dKm : number | undefined = getDistanceFromLatLonInKm(from, to)
            if (!dKm) return undefined;
            let r = dKm * 1000
            r = r < 0.5 ? 0.5 : r
            return r
        }

        let newFeatures : Feature[] = [...props.features!];
        newFeatures.forEach((feature1 : Feature) => {
            newFeatures.forEach((feature2) => {
                if (feature1.uuid !== feature2.uuid) {
                    let feature1Ends = firstAndLast(feature1.geometry.coordinates)
                    let feature2Ends = firstAndLast(feature2.geometry.coordinates)
                    feature1Ends.forEach((c1) => {
                        feature2Ends.forEach((c2) => {
                            let radius : number | undefined = calculateRadius(c1, c2)
                            if (radius && (radius < props.radius!)) {
                                if (!feature1.properties!.hasOwnProperty(CONNECTIONS_KEY)){
                                    feature1.properties![CONNECTIONS_KEY] = {}
                                }
                                let connection : ConnectedTo = new ConnectedTo(feature1.uuid!, {fromUuid: feature1.uuid, toUuid : feature2.uuid, atPoint: c1, toPoint: c2, radius: radius} as IConnectedTo)

                                let key : string = sha256(JSON.stringify(connection))
                                feature1.properties![CONNECTIONS_KEY][key] = connection
                            }
                        })
                    })
                }
            })
        })
       
        setUndirectedGraph(newFeatures)
    }, [props.features, props.radius])

    function firstAndLast(array : any[]) : any[] {
        var firstItem = array[0];
        var lastItem = array[array.length-1];
        return [firstItem, lastItem];
    }
    useEffect(() => {
        getConnections()
    }, [props.features, getConnections])

    
    const reload = useCallback(() => {
        getConnections()
    }, [getConnections])

    
    return { undirectedGraph, reload };
}
