import { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

export enum MapFormattingKeys {
    filteredOpacity = "filteredOpacity"
}

export type MapFormatting = {
    key: MapFormattingKeys
    value: any
}

export type MapFormattings = Record<string, MapFormatting>

const marks = (min : number, max : number, step : number) => {
    const dictionary = {};
    // Use a loop to generate values from min to max with the specified step
    for (let i = min; i <= max; i += step) {
        const key = i.toFixed(1); // Convert the value to a string with one decimal place
        dictionary[key] = key; // Assign the key-value pair to the dictionary
    }
    return dictionary;
}

export const MapFormat = (props: {
    formattings: MapFormattings
    onFormatChange: (formattings: MapFormattings) => void
}) => {
    const [formattings, setFormattings] = useState<MapFormattings>(props.formattings);

    useEffect(() => {

    }, [])

    useEffect(() => {
        props.onFormatChange(formattings);
    }, [formattings])

    const handleSliderChange = (key: MapFormattingKeys, value: any[]) => {        
        setFormattings({ ...formattings, ...{[key]: {key : key, value : value[0]} }});
    };

    return (<div className='columns'>
        <div className='column field p-1 ml-5 mr-5'>
            <label className='label'>Filter Opacity</label>
            <div className="control">
                <Slider
                    range
                    min={0}
                    max={1}
                    step={0.1}
                    marks={marks(0, 1, 0.2)}
                    defaultValue={formattings[MapFormattingKeys.filteredOpacity]?.value ? formattings[MapFormattingKeys.filteredOpacity]?.value : 0}
                    onChange={(value: any) => handleSliderChange(MapFormattingKeys.filteredOpacity, value)}
                />  
            </div>    
        </div>
    </div>
    )
}

