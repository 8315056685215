import { useEffect } from "react";
import {IDigitalTwin} from "standard/ontology/interfaces";
import { IDataImport } from "standard/datatools/@types/IDataImport";
import ImportDetails from "./importDetails";
import ImportSummary from "./importSummary";
import { Feature } from "standard/geojson/@types";

const ImportInfo = (props: {
    network: IDigitalTwin,
    features: Feature[],
    importStatus?: IDataImport
    onRequestChangeNetwork: () => void
}) => {
    useEffect(() => {

    }, [props.network])

    useEffect(() => {

    }, [props.importStatus])

    useEffect(() => {

    }, [props.importStatus?.status])

    useEffect(() => {

    }, [props.importStatus?.status.numberOfImports])

    useEffect(() => {

    }, []);
    
    return (
        <>            
            <ImportDetails network={props.network} features={props.features} onRequestChangeNetwork={props.onRequestChangeNetwork} />
            {props.importStatus && <ImportSummary importStatus={props.importStatus} />}                       
        </>
    );
}

export default ImportInfo;