import { IOptions  } from 'standard/forms/interfaces/IOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { uiActions } from 'standard/UI/actions';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';

const Wrapper = (props: {
    title: string
    itemCount: number
    menuLinks?: IMenuItem[]
    hide?: boolean
    options? :  IOptions
    children?: any
}
) => {
    const dispatch = useAppDispatch()
    const ui = useAppSelector(state => state.ui)

    const onMinimize = () =>{
        dispatch(uiActions.disableFullscreen())
    }

    const onMaximize = () =>{
        dispatch(uiActions.enableFullscreen())
    }

    return (
        <div className={`card is-fullwidth  m-0 p-0 pl-2  ${(props.hide !== undefined && props.hide) && "is-hidden"}`}>
            <div className="card-header ">
                <div className='columns wrapper-menu m-0 p-0'>
                    <div className={`column mt-2 ml-2 p-0 is-one-quarter `}>
                        <span className='wrapper-title'>{props.title}</span>
                    </div>
                    {/* {(props.menuLinks) &&
                        <div className='column m-0 p-0'>
                            <RootMenu links={props.menuLinks} />
                        </div>
                    } */}
                </div>
                {/* {(props.options) && 
                    <div className="card-header-icon">
                        <Dropdown title='Actions' options={props.options}/>
                    </div>
                } */}                
                <div className="card-header-icon">
                    {ui.fullscreenenabled ?
                    <FontAwesomeIcon icon={faMinimize} size="1x" onClick={onMinimize}/>    
                    : 
                    <FontAwesomeIcon icon={faMaximize} size="1x" onClick={onMaximize}/>
                    }
                </div>
            </div>            
            {props.children}
        </div>
    )
}


export default Wrapper;