import React from 'react';
import ReactDOM from "react-dom/client";
import L from 'leaflet';
import Avatar from 'standard/geojsonMap/utils/avartar';
import { sha256 } from 'js-sha256';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export const getMarkerIcon = (layer: string, size: number, style: string, iconDefinition? : IconDefinition  ): L.DivIcon => {
	const iconNode = document.createElement('span');
	let icon : L.DivIcon

	 if (iconDefinition){		
		ReactDOM.createRoot(iconNode).render(			
			<FontAwesomeIcon icon={iconDefinition} color="#8B0000" size={`${size}` as SizeProp}/>
		)
		icon = L.divIcon({
			className: 'mapMarker',
			html: iconNode,
			// iconSize: [rect.width, rect.height],
			// iconAnchor: [0, 0]
		});
	 }
     else{
	 	ReactDOM.createRoot(iconNode).render(<Avatar hashCode={sha256(layer || '')} size={size} style={style} />)
		icon = L.divIcon({
			className: 'mapMarker',
			html: iconNode,
			iconSize: [size, size],
			// iconAnchor: [0, 0]
		});
	 }

	return icon;
}

