import { useEffect, useState } from "react";
import CenteredScreen from "components/standard/centeredScreen/centeredScreen";
import { Feature } from "standard/geojson/@types";

const OverwriteCurrent = (props: {
  key: string
  features: Feature[]
  onUpdateFeatures: (key: string, features: Feature[], onComplete: () => void) => void
}) => {
  const [cloningComplete, setCloningComplete] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Export : Overwrite Current';
  }, [])

  useEffect(() => {
    if (isSaving) {
      props.onUpdateFeatures(props.key, props.features, onNewUploadComplete) 
    }
  }, [isSaving])

  useEffect(() => {

  }, [cloningComplete])

  const overwrite = () => {
    setIsSaving(true)
  }

  const onNewUploadComplete = () => {
    setIsSaving(false)
    setCloningComplete(true)
  }

  return (<CenteredScreen>
    {(isSaving || cloningComplete) ||
      (<><h3 className="subtitle is-6">Overwrite the upload with current results.</h3>
      <button className="button is-info is-pulled-left" onClick={overwrite}>
        Overwrite
      </button></>)
    }
    {!isSaving || <><div className="control is-loading"></div><h3 className="subtitle is-6">Overwriting the current upload, please wait...</h3></>}
    {!cloningComplete || (<h3 className="subtitle is-6">Overwriting the current upload was successfully created.</h3>)}
  </CenteredScreen>);
}
export default OverwriteCurrent;