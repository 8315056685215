import { useState, useEffect, useRef } from "react"
import { IAlert } from "standard/alert";
import { Multiselect, Text } from "components/standard";
import Autocomplete from 'standard/autocomplete/autocomplete';
import * as FormInputs from 'standard/forms/inputs'
import SelectBox from "standard/selectbox/SelectBox";
import { InputType } from "standard/forms/enums";
import { OnChangeValue } from 'react-select';
import { IOption, Options } from "standard/multiselect/@types/IOption";
import { IInput } from "./interfaces";
import TaggingControl from "standard/tags/components/taggingControl";

const FormBuilder = (props: {
    inputs: { [key: string]: FormInputs.Input }
    options: { [key: string]: IOption[] }
    alerts: { [key: string]: IAlert }
    onValueChanged: (input: IInput, value: any) => void
}) => {

    const [inputs, setInputs] = useState<{ [key: string]: FormInputs.Input }>({});
    const [alerts, setAlerts] = useState<{ [key: string]: IAlert }>({});
    const [selection, setSelection] = useState<{ [key: string]: { value: string; label: string; } }>({});

    useEffect(() => {
        setInputs(props.inputs)
    }, [props.inputs]);

    useEffect(() => {

    }, [props.options]);

    useEffect(() => {
        setAlerts(props.alerts)
    }, [props.alerts]);

    const onChangeInput = (input: FormInputs.Input, value: string) => {
        let inputClone = { ...input }
        inputClone.value = value
        setInputs(prevState => ({
            ...prevState,
            [inputClone.name]: inputClone
        }));
        props.onValueChanged(input, value);
        if (value.trim() !== '') removeAlert(inputClone.name)
    }

    const removeAlert = (name: string) => {
        let clone = Object.assign({}, alerts);
        delete clone[name];
        setAlerts(clone);
    };

    const onSelectionChange = (input: FormInputs.Input, newValue: OnChangeValue<any, false>) => {
        let inputClone = { ...input }
        inputClone.value = newValue && newValue.value
        if (inputClone.value === inputs[inputClone.name].value) {
            return
        }
        setSelection({ ...selection, [inputClone.name]: newValue })

        let newInputs = { ...inputs, [inputClone.name]: inputClone }
        newInputs = FormInputs.clearChildren(newInputs, inputClone.name)
        setInputs(newInputs);
        props.onValueChanged(input, newValue ? newValue.value : undefined);
        if (newValue) removeAlert(inputClone.name)
    }

    const onTagsChange = (input: FormInputs.Input, tags: string[]) => {

        let inputClone = { ...input }
        let newInputs = { ...inputs, [inputClone.name]: inputClone }

        setInputs(newInputs);
        props.onValueChanged(input, tags ? tags : []);
        if (tags.length > 0) removeAlert(inputClone.name)
    }

    const onSelection = (input: FormInputs.Input, selections: Options) => {
        let inputClone = { ...input }
        inputClone.value = selections.map(v => v.value)
        setInputs(prevState => ({
            ...prevState,
            [inputClone.name]: inputClone
        }));
        props.onValueChanged(input, inputClone.value);
        if (selections.length !== 0) removeAlert(inputClone.name)
    }

    const renderControlWrapper = (input: FormInputs.Input, idx: number) => {
        return <div className={`field mt-1 p-1 m-0  alternate`} key={input.name}>
            <label className="label smallText mt-1">{input.label}{!input.required ? ' (optional)' : ''}</label>
            <div className="control"> {renderControl(input, idx)}</div>
            {/* <hr className="navbar-divider" /> */}
        </div>
    }

    const renderControl = (input: FormInputs.Input, idx : number) => {
        
        switch (input.type) {
            case InputType.STRING:
                return <Text
                    id={input.name}
                    label={input.label}
                    inputOnly={true}
                    value={input.value}
                    onChange={(_, value) => onChangeInput(input, value)}
                    alert={alerts[input.name]}
                    style={{ text: 'smallText' }}
                />;
            case InputType.OPTION:
                return <SelectBox
                    options={input.options || props.options[input.name]}
                    onChange={(newValue) => onSelectionChange(input, newValue)}
                    isMulti={false}
                    selected={input.value}
                    alert={alerts[input.name]}
                    style={{ text: 'smallText' }}
                />
            case InputType.TAGGING:
                let tagSuggestions = props.options[input.name] && Object.values(props.options[input.name]).map(option => { return option.value })
                return <TaggingControl
                    tags={input.value}
                    placeholder={input.prompt}
                    suggestions={tagSuggestions}
                    alert={alerts[input.name]}
                    onChange={(newValue) => onTagsChange(input, newValue)}
                />
            case InputType.AUTOCOMPLETE:
                let suggestions = props.options[input.name] && Object.values(props.options[input.name]).map(option => { return option.value })
                return <Autocomplete
                    value={input.value}
                    suggestions={suggestions}
                    onChange={(newValue) => onChangeInput(input, newValue)}
                    showNoSuggestions={false}
                    alert={alerts[input.name]}
                    style={{ text: 'smallText' }}
                />
            case InputType.MULTISELECT:
                let multiSelectSuggestions = props.options[input.name] && Object.values(props.options[input.name]).map(option => { return { value: option.value, label: option.label } })
                return <Multiselect
                    id={input.name}
                    label=''
                    options={multiSelectSuggestions}
                    selected={input.value.map(value => { return { value: value, label: value } })}
                    onSelectionChanged={(options) => onSelection(input, options)}
                    alert={alerts[input.name]}
                />
            default:
                return 'Unhandled type' + input.type;
        }
    };

    return (
        <>
            {
                inputs && Object.values(inputs).map(
                    (i: FormInputs.Input, idx: number) => {
                        return renderControlWrapper(i, idx)
                    }
                )
            }
        </>
    )
}


export default FormBuilder