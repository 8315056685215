import { useEffect, useMemo } from 'react';
import { ISummary } from 'standard/summary/@types/ISummary';
import Cell from './cell';
import { v4 as uuidv4 } from 'uuid';
import TextSummary from 'standard/UI/components/textSummary';

const Row = (props: {
    headers: string[]
    idx: number
    rowData: { [k: string]: ISummary; }
    prevRowData?: { [k: string]: ISummary; }
    style?: string
    onValueClick: (e: any, key: string, value: any) => void
    onValueDoubleClick: (e: any, key: string, value: any) => void

}) => {
    const key = useMemo(
        () => uuidv4(),
        []);

    useEffect(() => {

    }, [])
    
    const displayContent = () => {        
        return <div key={key} className={`columns mt-0 mb-0 pb-0 ml-1`}>
            {props.headers.map((header) => {
                return <div className='card ml-0 mt-2 mb-0 p-1'> 
                <header className="card-header" >
                    <span className="card-header-title smallText m-1 p-1">
                        {header} : <TextSummary value={props.rowData[header].value ? props.rowData[header].value : '-'} prefixLength={20} suffixLength={5}/>
                    </span>
                </header>
                <div className="card-content p-1 m-1">
                    <Cell key={`${key + header}`} rowKey={key} propertyName={header} value={props.rowData[header]} prevValue={props.prevRowData && props.prevRowData[header]} onValueClick={props.onValueClick} onValueDoubleClick={props.onValueDoubleClick} />
                </div>
            </div>
                
            })}
        </div>               
    }

    return  displayContent()
}

export default Row;