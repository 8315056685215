export enum FilterType {
  ISEMPTY = "isempty",
	STRINGCONTAINS = "stringcontains",
  STRINGEXACT = "stringexact",
  STRINGEXCLUDE = "stringexclude",
	NUMERICRANGE = "numericrange",
  DATERANGE = "daterange"
}

export interface IEmptyFilter {
  id: string
  type: FilterType
  property: string
}

export interface IStringFilter {
    id: string
    type: FilterType
    property: string
    value: string
}

export interface IRangeFilter {
    id : string
    type: FilterType
    property: string
    fromValue: any
    toValue: any
}

export class EmptyFilter  implements IEmptyFilter {
  public id: string
  public type: FilterType = FilterType.ISEMPTY
  public property: string
  
  constructor(i : IEmptyFilter) {
    this.id = i.id;   
    this.property = i.property;
  }
}

export class StringFilter  implements IStringFilter {
    public id: string
    public type: FilterType
    public property: string
    public value: string

    constructor(i : IStringFilter) {
      this.id = i.id;   
      this.type = i.type;
      this.property = i.property;
      this.value = i.value;
    }
  }
  
  export class RangeFilter  implements IRangeFilter {
    public id : string
    public type: FilterType
    public property: string
    public fromValue: string
    public toValue: string

    constructor(i : IRangeFilter) {
      this.id = i.id
      this.type = i.type
      this.property = i.property
      this.fromValue = i.fromValue
      this.toValue = i.toValue
    }
  }
  
