import { Feature } from "standard/geojson/@types";
import { MapWrraper } from "./mapWrapper";
import { POINT } from "standard/geojsonMap/constants";
import { GEOMETRY_INDEX, IS_ADD_ON_KEY, IS_ADD_ON_TO, LAYER_ICON, LAYER_ICON_SIZE, LAYER_NAME_KEY, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import { IconDefinition, faCircleDot, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import L from 'leaflet';
import { IMenuItem } from "standard/navigation/interfaces/IMenuItem";
import { DELETE_LINE_POINT } from "standard/geojsonMap/@types/actions";
import { LayerEvents } from "./enums";

declare module './mapWrapper' {
	interface MapWrraper {
		addEdgePoints(
			features: any,
			layerKey: string,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void

		addLayerContextMenuForEdgePoint(layerMetaData: LayerMetaData, setContextMenuOptionsInstance: (key: string) => void): void

		addLineDecorator(layerMetaData: LayerMetaData): void

		removeLineDecorator(layerMetaData: LayerMetaData): void
	}
}

MapWrraper.prototype.addEdgePoints = function (
	features: any,
	layerKey: string,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void,
) {
	let typedFeatures: Feature[] = features
	typedFeatures.forEach((f: Feature) => {
		let coordinates: any[] = f.geometry.coordinates
		coordinates.forEach((c: number[], idx: number) => {
			const id = `${f.uuid}_${idx}`
			let feature: Feature = {
				id: id,
				uuid: id,
				type: 'Feature',
				properties: {},
				geometry: {
					uuid: id,
					coordinates: c,
					type: POINT
				}
			}

			let info: LayerInfo = {
				[LAYER_UUID_KEY]: id,
				[LAYER_NAME_KEY]: layerKey,
				[IS_ADD_ON_KEY]: true,
				[GEOMETRY_INDEX]: idx,
				[IS_ADD_ON_TO]: f.uuid
			}

			this.status.edgePoints.push(info);
			let iconDef: IconDefinition = ((idx === 0 || idx === f.geometry.coordinates.length - 1) ? faXmarkSquare : faCircleDot);
			let iconSize: string = ((idx === 0 || idx === f.geometry.coordinates.length - 1) ? "lg" : "sm");
			info[LAYER_ICON] = iconDef
			info[LAYER_ICON_SIZE] = iconSize

			L.geoJSON([feature as any], {
				pointToLayer: (feature: any, latlng: any) => {
					return this.createPoint(layerKey, iconSize, feature, latlng, iconDef);
				},
				onEachFeature: ((feature: any, layer: any) => this.onEachPointFeature(
					feature,
					layer,
					info,
					onLayerEvent,
					setContextMenuOptionsInstance,
					onMouseUp))
			})
			let layerMetaData: LayerMetaData = this.status.featureLayers[id]
			this.addLayerContextMenuForEdgePoint(layerMetaData, setContextMenuOptionsInstance)
		})
	})
}

let edgePointEditMenuItems: IMenuItem[] = [
	{ name: DELETE_LINE_POINT, label: "Delete Point", ordinality: 1, parent: undefined },
]

MapWrraper.prototype.addLayerContextMenuForEdgePoint = function (layerMetaData: LayerMetaData, setContextMenuOptionsInstance: (key: string) => void) {
	this.addLayerContextMenu(layerMetaData.layer, 'Edit Edge Point', edgePointEditMenuItems, setContextMenuOptionsInstance);
}

MapWrraper.prototype.addLineDecorator = function (layerMetadata: LayerMetaData) {
	let leafletLayer: L.FeatureGroup = this.layers[layerMetadata.info[LAYER_NAME_KEY]];
	const polylineDecorator = L.polylineDecorator(layerMetadata.layer, {
		patterns: [
			{
				offset: 10,
				repeat: 20,
				symbol: L.Symbol.dash({ pixelSize: 5, pathOptions: { color: "red", weight: 4 } }),
			},
		],
	})

	leafletLayer.addLayer(polylineDecorator);
	layerMetadata.decorator = polylineDecorator
}

MapWrraper.prototype.removeLineDecorator = function (layerMetadata: LayerMetaData) {
	if (!layerMetadata.decorator) {
		return
	}
    
	this.map.removeLayer(layerMetadata.decorator!)
	layerMetadata.decorator!.remove()
	layerMetadata.decorator = undefined
}