import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux'
import './styles.css'
import { Feature } from 'standard/geojson/@types';
import Property from './property';
import { column, sortColumns } from 'standard/geojson/utils';
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from 'standard/ontology/interfaces';
import { IOption } from 'standard/forms/interfaces/IOption';
import { getClassByDefaultName, getEdgeClasses, getNodeClasses, getRootClassByName } from 'standard/ontology/domainModel/domainmodelclass';
import { LINE_STRING, POINT } from './constants';
import { ClassProperties } from 'standard/ontology/domainModel/types/classProperties';

const Properties = (props: {
    targetModel: IDomainModel
    referencedClasses : IDomainModelClass[]
    isEdittable: boolean
    classProperties: ClassProperties | undefined
    disabledProperties?: string[]
    canFilter?: boolean
    canSelect?: boolean
    selectedFeature?: Feature
    selectedProperties?: string[]
    defaultClass?: string
    onFilter: (filter: any) => void
    onDeleteFilter: (id: string) => void
    onDeletePropertyFilters: (property: string) => void
    onFeatureChange: (feature : Feature | undefined) => void
    onSetFilterProperty?: (property : string) => void
    filters?: Record<string, any>
    onPropertyChange?: (uuid: string, key: string, value: any) => void
    onSelection?: (selection : string[]) => void
}) => {
    const [columns, setColumns] = useState<column[]>();
    const [options, setOptions] = useState<{ [key: string]: IOption[] }>({});
    const [defaultFeature, setDefaultFeature] = useState<Feature >();
    const [className, setClassName] = useState<string>();
    const [propertyClasses, setPropertyClasses] = useState<IDomainModelClass[]>();
    let [selected, setSelected] = useState<string[]>(props.selectedProperties || []);
    
    useEffect(() => {
        let feature : Feature | undefined

        if (props.selectedFeature) {
            feature = props.selectedFeature
        }else if (defaultFeature) {
            feature = defaultFeature;
        }

        if (!feature){
            return 
        }

        let keys: Record<string, column> = {}
        if (feature){
            if (feature.properties) {
                Object.keys(feature.properties).forEach((key: string) => {
                    keys[key] = ({name : key, inDomain : false})
                });
            }
        }
        
        if (props.classProperties){
            props.classProperties.dataProps.forEach((prop: IDomainModelProperty) => {
                keys[prop.labels.default] = ({name : prop.labels.default, inDomain : true})
            });  
        }
       
        setColumns(Object.values(keys))
        
    }, [props.selectedFeature, defaultFeature, props.classProperties])
    

    useEffect(() => {

    }, [columns])

    useEffect(() => {

    }, [props.classProperties])

    useEffect(() => {

    }, [props.referencedClasses])

    useEffect(() => {

    }, [selected])

    useEffect(() => {

    }, [props.targetModel])

    useEffect(() => {
        setClassName(props.defaultClass)
        if (props.defaultClass){
            let rootCls = getRootClassByName(props.referencedClasses, props.defaultClass)
            if (rootCls){
                if (rootCls!.labels.default.toLocaleLowerCase() === 'edge'){                    
                    setDefaultFeature({properties: {layer:props.defaultClass}, geometry: {type: LINE_STRING, coordinates : []}} as Feature)
                }else if (rootCls!.labels.default.toLocaleLowerCase() === 'node'){
                    setDefaultFeature({properties: {layer:props.defaultClass}, geometry: {type: POINT, coordinates : []}} as Feature)
                }
            }
        }
    }, [props.defaultClass])

    useEffect(() => {
        props.onFeatureChange(defaultFeature)
    }, [defaultFeature])

    useEffect(() => {
 
    }, [props.selectedFeature])

    useEffect(() => {
        if (className){
            setPropertyClasses(getPropertyClassHierachy(className, []))
        }
    }, [className])

    const getPropertyClassHierachy = (clsName: string, hierachy : IDomainModelClass[]) : IDomainModelClass[] => {
        let cls : IDomainModelClass | undefined = getClassByDefaultName(props.referencedClasses, clsName);
        if (cls !== undefined){
            hierachy.push(cls)
            if (cls.isSubClassOf !== undefined) {
                hierachy = getPropertyClassHierachyById(cls.isSubClassOf, hierachy)
            }
        }
        return hierachy
    }

    const getPropertyClassHierachyById = (uuid: string, hierachy : IDomainModelClass[]) : IDomainModelClass[] => {
        let cls : IDomainModelClass | undefined = props.targetModel!.classes!.find((f : IDomainModelClass) => f.uuid === uuid)
        if (cls !== undefined){
            hierachy.push(cls)
            if (cls.isSubClassOf !== undefined) {
                hierachy = getPropertyClassHierachyById(cls.isSubClassOf, hierachy)
            }
        }
        return hierachy
    }

    useEffect(() => {

    }, [options])

    useEffect(() => {
        if (props.onSelection){
            props.onSelection(selected) 
        }
    }, [selected])
 
    useEffect(() => {
        if (!props.targetModel) {
            return
        }
        let newOpts = {}

        if (props.targetModel.classes) {
            newOpts["layer"] = props.targetModel!.classes!.map((item) => {
                return {
                    value: item.labels.default, label: item.labels.default
                }
            }
            );
        }
       
        let feature : Feature | undefined

        if (props.selectedFeature) {
            feature = props.selectedFeature
        }else if (defaultFeature) {
            feature = defaultFeature;
        }

        if (feature) {

            let refs = [...props.referencedClasses]
            refs = refs.sort((a, b) => {                
                if (a.Ontology.uuid! === props.targetModel.uuid! && b.Ontology.uuid! !== props.targetModel.uuid!) return -1;
                if (b.Ontology.uuid! === props.targetModel.uuid! && a.Ontology.uuid! !== props.targetModel.uuid!) return 1;
                if (a.Ontology.uuid! !== b.Ontology.uuid!){
                    return a.Ontology.labels.default.localeCompare(b.Ontology.labels.default)
                }
                return a.labels.default.localeCompare(b.labels.default)
            });
            newOpts["layer"] = refs.map((item) => { return { value: item.labels.default, label: `${item.labels.default} (${item.Ontology.labels.default})` } });
            
            if (feature.properties) {
                setClassName(feature.properties["layer"])
            }
        }

        setOptions({ ...options, ...newOpts })
    
    }, [props.targetModel,props.selectedFeature, defaultFeature]);
       
    const onPropertyChange = (uuid: string | undefined, key: string, value: any) => {
        if (uuid){
            props.onPropertyChange!(uuid, key, value)
        }else{
            let prev : Feature = {...defaultFeature!}
            prev.properties![key] = value
            setDefaultFeature(prev)
            props.onFeatureChange(prev)
        }

    }

    const onSelected = (item: string, value: boolean) => {
        if (value) {
            if (selected.includes(item)) {
                return
            }
            setSelected([...selected, ...[item]])
        } else {
            setSelected(selected.filter((v) => v !== item))
        }
    }

    const getFeature = () : Feature | undefined=> {
        let feature : Feature | undefined

        if (props.selectedFeature) {
            feature = props.selectedFeature
        }else if (defaultFeature) {
            feature = defaultFeature;
        }

        return feature
    }

    const displayProperties = (headers: column[]) => {
        let feature = getFeature()
        return headers.sort(sortColumns).map((column, idx) => {
            let value: string | undefined = undefined;
            if (feature) {
                if (feature.properties) {
                    value = feature.properties[column.name]
                }
            }
            return <Property
                key={column.name}
                isDomainProperty={column.inDomain}
                isEdittable={props.isEdittable}
                selected={selected.includes(column.name)}
                canFilter={props.canFilter === undefined ? true : props.canFilter}
                canSelect={props.canSelect === undefined ? false : props.canSelect}
                disabled={props.disabledProperties === undefined ? false : props.disabledProperties.includes(column.name)}
                onSelected={onSelected}
                options={options}                
                feature={feature}
                idx={idx}
                propertyName={column.name}
                value={value}
                onFilter={props.onFilter}
                onSetFilterProperty={props.onSetFilterProperty}
                onDeleteFilter={props.onDeleteFilter}
                onDeletePropertyFilters={props.onDeletePropertyFilters}
                filters={props.filters}
                onValueChange={onPropertyChange}
            />
        })

    }

    return (<div className=''>
            <div className=''>
        {columns && (displayProperties(columns))}
        </div>
    </div>
    )
}

export default Properties;