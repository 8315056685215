import { useEffect, useCallback, useState } from 'react';
import localforage from 'localforage';
import { Upload, Uploads } from '../types/uploads';
import { UPLOAD_META_LOCAL_STORAGE_KEY } from 'components/utils/constants';
import { Feature } from 'standard/geojson/@types';
import { getFeatures, updateFeatures } from './utils';
import { POINT } from 'standard/geojsonMap/constants';

export const useUpload = (props: { uploadKey?: string }) => {
    let [upload, setUpload] = useState<Upload>();
    let [features, setFeatures] = useState<Feature[]>();
    let [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

    }, [upload])
  
    useEffect(() => {
        
    }, [features])


    const loadData = useCallback(() => {
        if (loading) {
            if (!props.uploadKey) {
                setLoading(false)
                setUpload(undefined)
                return
            }

            const getFeatures = (key : string) => {
                localforage.getItem(key, function (err, value) {
                    if (value) {
                        let features = (value as Feature[])
                        setFeatures(features)
                    } else {
                        setFeatures(undefined)
                    }
                    setLoading(false)
                });
            }

            localforage.getItem(UPLOAD_META_LOCAL_STORAGE_KEY, function (err, value) {
                if (value) {
                    let uploads = (value as Uploads)
                    setUpload(uploads[props.uploadKey!])
                } else {
                    setUpload(undefined)
                }
                getFeatures(props.uploadKey!)
            });
        }
    }, [props.uploadKey, loading])

    useEffect(() => {
        loadData()
    }, [loading, loadData])

    useEffect(() => {
        setLoading(true)
    }, [props.uploadKey])

    const reload = useCallback(() => {
        setLoading(true)
    }, [])

    const onPropertyChanged = (uuid : string, properties : Record<string, any>) => {
        getFeatures(props.uploadKey!).then((currentFeatures) => {   
            if (currentFeatures){
                let feature = currentFeatures!.find(f => f.uuid === uuid)
                if (feature) {
                    if (!feature.properties) {
                        feature.properties = {}
                    }
                    Object.keys(properties).forEach(key => {
                        feature!.properties![key] = properties[key] 
                    })
                }
                updateFeatures(props.uploadKey!, currentFeatures!, reload)
            }
        })
    }

    const onGeometryChanged = (uuid : string, index: number, value : number[]) => {
        getFeatures(props.uploadKey!).then((currentFeatures) => {   
            if (currentFeatures){
                let feature = currentFeatures!.find(f => f.uuid === uuid)
                if (feature) {
                    if (feature.geometry.type === POINT){
                        feature.geometry.coordinates = value 
                    }
                    else{
                        if (value){
                            feature.geometry.coordinates[index] = value 
                        }else{   
                            feature.geometry.coordinates.splice(index, 1); 
                        }
                    }
                }
                updateFeatures(props.uploadKey!, currentFeatures!, reload)
            }
        })
    }

    const onGeometryRemoved = (uuid : string, index: number) => {
        getFeatures(props.uploadKey!).then((currentFeatures) => {   
            if (currentFeatures){
                let feature = currentFeatures!.find(f => f.uuid === uuid)
                if (feature) {
                    feature.geometry.coordinates.splice(index, 1); 
                }
                updateFeatures(props.uploadKey!, currentFeatures!, reload)
            }
        })
    }

    const onFeatureAdd = (feature : Feature ) => {      
        getFeatures(props.uploadKey!).then((currentFeatures) => {   
            if (currentFeatures){
                currentFeatures = [...currentFeatures, ...[feature]]; 
                updateFeatures(props.uploadKey!, currentFeatures , reload)
            }   
        })
    }
        
    const onFeaturesDelete = (uuids : string[] ) => {   
        getFeatures(props.uploadKey!).then((currentFeatures) => {   
            if (currentFeatures){     
                        
                updateFeatures(props.uploadKey!, currentFeatures!.filter((f : Feature) => uuids.includes(f.uuid!) === false)  , reload)
            }
        })
    }

    return { upload, features, reload, loading, onFeatureAdd, onFeaturesDelete, onPropertyChanged, onGeometryChanged };
}