import { filterContainsString, filterEmptyString, filterExactString, filterNumericRange, filterOutString } from 'standard/filter/utils';
import { FilterType } from '../../filter/@types/filter';

export const filter = (filters: Record<string, any>, row: { [key: string]: any }) => {
    if (filters.length === 0) {
        return true
    }

    let columnsFiltered: Record<string, number> = {}
    for (const eachFilter of Object.values(filters)) {
        columnsFiltered[eachFilter.property] = (columnsFiltered[eachFilter.property] || 0) + 1;
    }

    for (const eachFilter of Object.values(filters)) {
        if (eachFilter.type === FilterType.ISEMPTY) {
            if (!filterEmptyString(eachFilter, row, false)) {
                if (columnsFiltered[eachFilter.property] === 1) {
                    return false
                }
                columnsFiltered[eachFilter.property] -= 1
            }
        } else {
            const value = row![eachFilter.property]

            if (value === null || value === undefined) {
                return false
            }

            if (eachFilter.type === FilterType.STRINGEXACT) {
                if (!filterExactString(eachFilter, row, false)) {
                    if (columnsFiltered[eachFilter.property] === 1) {
                        return false
                    }
                    columnsFiltered[eachFilter.property] -= 1
                }
            } else if (eachFilter.type === FilterType.STRINGCONTAINS) {
                if (!filterContainsString(eachFilter, row, false)) {
                    if (columnsFiltered[eachFilter.property] === 1) {
                        return false
                    }
                    columnsFiltered[eachFilter.property] -= 1
                }
            } else if (eachFilter.type === FilterType.STRINGEXCLUDE) {
                if (!filterOutString(eachFilter, row, false)) {
                    if (columnsFiltered[eachFilter.property] === 1) {
                        return false
                    }
                    columnsFiltered[eachFilter.property] -= 1
                }
            } else if (eachFilter.type === FilterType.NUMERICRANGE) {
                if (!filterNumericRange(eachFilter, row)) {
                    if (columnsFiltered[eachFilter.property] === 1) {
                        return false
                    }
                    columnsFiltered[eachFilter.property] -= 1
                }
            }
        }
    }
    return true
}