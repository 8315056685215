export const EnumToOptions = <T extends Record<keyof T, string | number>>(enumObj: T): IOption[] => {
    return Object.keys(enumObj).map((key) => {
        return {value : key, label : enumObj[key as keyof T]}   as IOption    
      });
  };


export interface IOption {
    value : string
    label : string
}

export type Options = IOption[]