import { Feature, Features } from "standard/geojson/@types";
import { readKMLfile } from "../../helpers/fileReaders/kml";

export const importKmlFiles = (props : {
    features : Features,
    res: (value: Features | PromiseLike<Features>) => void,
    rej: (reason?: any) => void,
    fileContent: string,
    setDataKML: (kml : Document) => void,
    setEditingMetadata: (val: boolean) => void,
    setCurrentGeo: (val: Feature | undefined) => void
}) => {
    let features = props.features;
    const parser = new DOMParser();
    const kml = parser.parseFromString(props.fileContent, 'text/xml');
    let schema = Array.from(kml.getElementsByTagName("Schema"));
    if (schema.length > 0) {
        props.setDataKML(kml);
    } else {
        features.features = readKMLfile(kml)
        props.setEditingMetadata(true);
        props.setCurrentGeo(undefined);
        props.res(features);
    }
}