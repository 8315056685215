import Dropdown from 'standard/dropdown/v2/Dropdown';
import { IOptions  } from 'standard/forms/interfaces/IOption';
import { IStyling } from 'standard/styling/IStyling';
import Paginator from 'standard/pagination/paginator';
import Search from '../search/search';

const List = (props: {
    title: string
    onPageSelect: (page: number) => void
    itemCount: number
    pageSize: number
    currentPage: number
    hide?: boolean
    options? :  IOptions
    onSearch: (search: string) => void
    onSearchChange?: () => void
    onEdit?: () => any,
    children?: any
}
) => {
    return (
        <div className={`card is-fullwidth  m-0 p-0 pl-2  ${(props.hide !== undefined && props.hide) && "is-hidden"}`}>
            <div className="card-header ">
                <p className="card-header-title">
                    {props.title}
                </p>
                {props.onEdit && 
                    <button className="card-header-icon" aria-label="more options" onClick={props.onEdit}>
                        Edit
                    </button>
                }
                {(props.options) && 
                    <div className="card-header-icon">
                        <Dropdown title='Actions' options={props.options}/>
                    </div>
                }
            </div>
            <div className="columns m-0 is-centered">
                <div className="column m-1 p-0 is-4">
                    <Search onSearch={props.onSearch} onSearchChange={props.onSearchChange} style={searchStyles} />
                </div>
            </div>
            <Paginator itemCount={props.itemCount} pageSize={props.pageSize} currentPage={props.currentPage} onPageSelect={props.onPageSelect} />
            <hr className='m-2 p-0'/>
            {props.children}
        </div>
    )
}

const searchStyles : IStyling = {
    container: 'search',
    text : 'smallText'
}
export default List;