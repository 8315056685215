import { IDomainModel } from "../interfaces"

export const domainmodelImports = () : (Record<string,any>) => {
    return {
    'dom-cd4bf1f6-4507-4924-9e0f-ec4def624672' : [
      {
        "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
        "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
        "labels": {
          "default": "Data Types",
          "en": "Data Types"
        },
        "comments": {},
        "imports": [],
        "type": 1,
        "isprivate": false,
        "classes": [
          {
            "uuid": "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5",
            "labels": {
              "default": "text",
              "en": "text"
            },
            "comments": {},
            "CreatedAt": 1677583351,
            "UpdatedAt": 1677583351,
            "deletedAt": 0,
            "name": "text",
            "Iri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types/class/text",
            "OntologyUuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
            "Ontology": {
              "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
              "labels": {
                "default": "Data Types",
                "en": "Data Types"
              },
              "comments": {},
              "CreatedAt": 1677583326,
              "UpdatedAt": 1677583326,
              "deletedAt": 0,
              "name": "Data Types",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-9ea3da31-052d-4d59-9135-41304ff1805e",
            "labels": {
              "default": "number",
              "en": "number"
            },
            "comments": {},
            "CreatedAt": 1677583363,
            "UpdatedAt": 1677583363,
            "deletedAt": 0,
            "name": "number",
            "Iri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types/class/number",
            "OntologyUuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
            "Ontology": {
              "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
              "labels": {
                "default": "Data Types",
                "en": "Data Types"
              },
              "comments": {},
              "CreatedAt": 1677583326,
              "UpdatedAt": 1677583326,
              "deletedAt": 0,
              "name": "Data Types",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-8eedd597-8759-4f1f-babf-24d1aae6caf1",
            "labels": {
              "default": "date",
              "en": "date"
            },
            "comments": {},
            "CreatedAt": 1677583370,
            "UpdatedAt": 1677583370,
            "deletedAt": 0,
            "name": "date",
            "Iri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types/class/date",
            "OntologyUuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
            "Ontology": {
              "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
              "labels": {
                "default": "Data Types",
                "en": "Data Types"
              },
              "comments": {},
              "CreatedAt": 1677583326,
              "UpdatedAt": 1677583326,
              "deletedAt": 0,
              "name": "Data Types",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c752fe01-3977-4d36-a7de-0087d4b4809b",
            "labels": {
              "default": "integer",
              "en": "integer"
            },
            "comments": {},
            "CreatedAt": 1687427811,
            "UpdatedAt": 1687427811,
            "deletedAt": 0,
            "name": "integer",
            "Iri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types/class/integer",
            "OntologyUuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
            "Ontology": {
              "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
              "labels": {
                "default": "Data Types",
                "en": "Data Types"
              },
              "comments": {},
              "CreatedAt": 1677583326,
              "UpdatedAt": 1677583326,
              "deletedAt": 0,
              "name": "Data Types",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-9ea3da31-052d-4d59-9135-41304ff1805e",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-25d67d94-152e-4a6f-b2de-e703dab42eb7",
            "labels": {
              "default": "double",
              "en": "double"
            },
            "comments": {},
            "CreatedAt": 1687427821,
            "UpdatedAt": 1687427821,
            "deletedAt": 0,
            "name": "double",
            "Iri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types/class/double",
            "OntologyUuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
            "Ontology": {
              "uuid": "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
              "labels": {
                "default": "Data Types",
                "en": "Data Types"
              },
              "comments": {},
              "CreatedAt": 1677583326,
              "UpdatedAt": 1677583326,
              "deletedAt": 0,
              "name": "Data Types",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/data-types",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-9ea3da31-052d-4d59-9135-41304ff1805e",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          }
        ],
        "objProps": [],
        "dataProps": [],
        "individuals": [],
        "scopes": {
          "institution": "institution_iJQMqWOspA"
        }
      },
      {
        "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
        "uri": "staging.pipepredict.com/iiotsystem",
        "labels": {
          "default": "IIOT System",
          "en": "IIOT System"
        },
        "comments": {
          "en": "this model defines fundamental classes and relations serving for general iiot system and predictive maintenance system"
        },
        "imports": [
          "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
        ],
        "type": 1,
        "isprivate": false,
        "classes": [
          {
            "uuid": "onc-424d8867-2c4c-42c9-9cfb-fc014af1e422",
            "labels": {
              "default": "PressureMeasurement",
              "en": "PressureMeasurement"
            },
            "comments": {},
            "CreatedAt": 1687423730,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "PressureMeasurement",
            "Iri": "pressuremeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423267,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-0ea0fdcf-90a9-4338-8145-df13d9daf25a",
            "labels": {
              "default": "ConnectorApp",
              "en": "ConnectorApp"
            },
            "comments": {},
            "CreatedAt": 1687269470,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "ConnectorApp",
            "Iri": "connectorapp",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "Predictive Maintenance",
                "en": "Predictive Maintenance"
              },
              "comments": {},
              "CreatedAt": 1687269328,
              "UpdatedAt": 1687269328,
              "deletedAt": 0,
              "name": "Predictive Maintenance",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/predictive-maintenance",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-977406a4-f106-4f4b-989a-b7dc3104cf1e",
            "labels": {
              "default": "AcousticSensor",
              "en": "AcousticSensor"
            },
            "comments": {},
            "CreatedAt": 1687424051,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AcousticSensor",
            "Iri": "acousticsensor",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423971,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-0fb5deb1-2859-4f16-9d2e-3f4101f441a2",
            "labels": {
              "default": "Frequency",
              "en": "Frequency"
            },
            "comments": {},
            "CreatedAt": 1687424868,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Frequency",
            "Iri": "frequency",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687424768,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-ff15e997-8f0a-4338-bd73-4c0ee52edf57",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0",
            "labels": {
              "default": "Sensor",
              "en": "Sensor"
            },
            "comments": {},
            "CreatedAt": 1687419992,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Sensor",
            "Iri": "sensor",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-39022d7b-3fcd-4030-802a-98fcdc01e6af",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c81110b8-9169-4925-a1e5-d2e129cf697f",
            "labels": {
              "default": "Pressure",
              "en": "Pressure"
            },
            "comments": {},
            "CreatedAt": 1687423748,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Pressure",
            "Iri": "pressure",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423267,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-424d8867-2c4c-42c9-9cfb-fc014af1e422",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-f68c9dba-d048-4ae7-891d-bcd65bc83367",
            "labels": {
              "default": "Amplitude",
              "en": "Amplitude"
            },
            "comments": {},
            "CreatedAt": 1687424852,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Amplitude",
            "Iri": "amplitude",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687424768,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-ff15e997-8f0a-4338-bd73-4c0ee52edf57",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-a9f29e54-2b0c-43c3-b938-4e8566fd15f0",
            "labels": {
              "default": "AssetSystem",
              "en": "AssetSystem"
            },
            "comments": {},
            "CreatedAt": 1687274113,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AssetSystem",
            "Iri": "assetsystem",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687273695,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-6253eaee-2037-41af-8f05-af35f81d94fa",
            "labels": {
              "default": "FlowSensor",
              "en": "FlowSensor"
            },
            "comments": {},
            "CreatedAt": 1687424136,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "FlowSensor",
            "Iri": "flowsensor",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423971,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-4ca7416b-d93a-4691-9af0-1ac37a2a3228",
            "labels": {
              "default": "FlowRate",
              "en": "FlowRate"
            },
            "comments": {},
            "CreatedAt": 1687424905,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "FlowRate",
            "Iri": "flowrate",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687424768,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-778c5d81-901c-4349-b33c-b21ae6ed40df",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-4bfeb9a5-942e-40a6-aaf9-2b7fb513f8e2",
            "labels": {
              "default": "AssetData",
              "en": "AssetData"
            },
            "comments": {
              "en": "Object holds metadata"
            },
            "CreatedAt": 1687420473,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AssetData",
            "Iri": "assetdata",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687420376,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-a9f29e54-2b0c-43c3-b938-4e8566fd15f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b",
            "labels": {
              "default": "App",
              "en": "App"
            },
            "comments": {
              "en": "Application that consumes and publishes data to message broker"
            },
            "CreatedAt": 1687269417,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "App",
            "Iri": "app",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "Predictive Maintenance",
                "en": "Predictive Maintenance"
              },
              "comments": {},
              "CreatedAt": 1687269328,
              "UpdatedAt": 1687269328,
              "deletedAt": 0,
              "name": "Predictive Maintenance",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/predictive-maintenance",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-4b3c5b17-c9fd-46d2-b269-c5cb4cf4f25c",
            "labels": {
              "default": "TemperatureSensor",
              "en": "TemperatureSensor"
            },
            "comments": {},
            "CreatedAt": 1687424112,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "TemperatureSensor",
            "Iri": "temperaturesensor",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423971,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-6980f15e-d82f-42cf-9977-ecf150fa3c69",
            "labels": {
              "default": "Temperature",
              "en": "Temperature"
            },
            "comments": {},
            "CreatedAt": 1687424976,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Temperature",
            "Iri": "temperature",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687424768,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c6690512-068c-4c65-bff5-c7f646854f66",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-072b8a9d-0584-4b8b-b5f1-5f4008f4f3e8",
            "labels": {
              "default": "ObservedEvent",
              "en": "ObservedEvent"
            },
            "comments": {
              "en": "Event recorded from reality"
            },
            "CreatedAt": 1687419069,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "ObservedEvent",
            "Iri": "observedevent",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-f189e6c0-22f0-42b2-bf12-db4bc7ed4627",
            "labels": {
              "default": "DataTransmitter",
              "en": "DataTransmitter"
            },
            "comments": {},
            "CreatedAt": 1687423397,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "DataTransmitter",
            "Iri": "datatransmitter",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423267,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-39022d7b-3fcd-4030-802a-98fcdc01e6af",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-7e62bf40-3aa3-42ef-9eac-66af6fd2d6d3",
            "labels": {
              "default": "AnalyticApp",
              "en": "AnalyticApp"
            },
            "comments": {},
            "CreatedAt": 1687269485,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AnalyticApp",
            "Iri": "analyticapp",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "Predictive Maintenance",
                "en": "Predictive Maintenance"
              },
              "comments": {},
              "CreatedAt": 1687269328,
              "UpdatedAt": 1687269328,
              "deletedAt": 0,
              "name": "Predictive Maintenance",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/predictive-maintenance",
              "isprivate": false,
              "type": 1,
              "imports": [],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-39022d7b-3fcd-4030-802a-98fcdc01e6af",
            "labels": {
              "default": "Device",
              "en": "Device"
            },
            "comments": {},
            "CreatedAt": 1687419971,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Device",
            "Iri": "device",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf",
            "labels": {
              "default": "UnitSystem",
              "en": "UnitSystem"
            },
            "comments": {},
            "CreatedAt": 1687274130,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "UnitSystem",
            "Iri": "unitsystem",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687273695,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-fe27de45-cfa7-4431-94a6-bebb8b4025c6",
            "labels": {
              "default": "NotifierApp",
              "en": "NotifierApp"
            },
            "comments": {},
            "CreatedAt": 1687271644,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "NotifierApp",
            "Iri": "notifierapp",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {
                "en": "this model defines fundamental classes and relations serving for general iiot system and predictive maintenance system"
              },
              "CreatedAt": 0,
              "UpdatedAt": 1687433133,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc",
            "labels": {
              "default": "MeasurementSystem",
              "en": "MeasurementSystem"
            },
            "comments": {
              "en": "Defines different types of measurement (quantifiable) such as temperature, voltage, amplitude, magnitude, frequency, pressure, flow, etc."
            },
            "CreatedAt": 1687418756,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "MeasurementSystem",
            "Iri": "measurementsystem",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": null,
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-67fc2c6d-9c99-4076-80f0-0349403fb422",
            "labels": {
              "default": "AnalyticEvent",
              "en": "AnalyticEvent"
            },
            "comments": {
              "en": "Event predicted by Analytic applications. Event values are discrete"
            },
            "CreatedAt": 1687419370,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AnalyticEvent",
            "Iri": "analyticevent",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-b3701967-3eff-4080-96c0-dd5dff5e1c27",
            "labels": {
              "default": "PhysicalEntity",
              "en": "PhysicalEntity"
            },
            "comments": {},
            "CreatedAt": 1687419981,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "PhysicalEntity",
            "Iri": "physicalentity",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6",
            "labels": {
              "default": "AssetEntity",
              "en": "AssetEntity"
            },
            "comments": {},
            "CreatedAt": 1687419952,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AssetEntity",
            "Iri": "assetentity",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-a9f29e54-2b0c-43c3-b938-4e8566fd15f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-befa7d83-383c-4824-85bc-26c8f61ee401",
            "labels": {
              "default": "Magnitude",
              "en": "Magnitude"
            },
            "comments": {},
            "CreatedAt": 1687424886,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "Magnitude",
            "Iri": "magnitude",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687424768,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-ff15e997-8f0a-4338-bd73-4c0ee52edf57",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-4446c514-72ca-4fe6-8585-c4ed17067dc5",
            "labels": {
              "default": "PressureSensor",
              "en": "PressureSensor"
            },
            "comments": {},
            "CreatedAt": 1687424078,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "PressureSensor",
            "Iri": "pressuresensor",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423971,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-957af764-edaf-45c0-9642-6520249cbfa1",
            "labels": {
              "default": "RegressiveMeasurement",
              "en": "RegressiveMeasurement"
            },
            "comments": {
              "en": "Measure estimated by regressive methods (such as ARIMA, ARMA, or any regressive algorithm)"
            },
            "CreatedAt": 1687419516,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "RegressiveMeasurement",
            "Iri": "regressivemeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
            "labels": {
              "default": "SensorMeasurement",
              "en": "SensorMeasurement"
            },
            "comments": {
              "en": "Those that sensors measure"
            },
            "CreatedAt": 1687419020,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "SensorMeasurement",
            "Iri": "sensormeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687350542,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-ff15e997-8f0a-4338-bd73-4c0ee52edf57",
            "labels": {
              "default": "AcousticMeasurement",
              "en": "AcousticMeasurement"
            },
            "comments": {},
            "CreatedAt": 1687423686,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "AcousticMeasurement",
            "Iri": "acousticmeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423267,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-c6690512-068c-4c65-bff5-c7f646854f66",
            "labels": {
              "default": "TemperatureMeasurement",
              "en": "TemperatureMeasurement"
            },
            "comments": {},
            "CreatedAt": 1687424170,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "TemperatureMeasurement",
            "Iri": "temperaturemeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423971,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          },
          {
            "uuid": "onc-778c5d81-901c-4349-b33c-b21ae6ed40df",
            "labels": {
              "default": "FlowMeasurement",
              "en": "FlowMeasurement"
            },
            "comments": {},
            "CreatedAt": 1687423703,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "FlowMeasurement",
            "Iri": "flowmeasurement",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": {
              "uuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
              "labels": {
                "default": "IIOT System",
                "en": "IIOT System"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687423267,
              "deletedAt": 0,
              "name": "IIOT System",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/iiot-system",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": null,
              "classes": null,
              "objProps": null,
              "dataProps": null
            },
            "isSubClassOf": "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
            "IsSubClassOfLabel": "",
            "ParentClass": null
          }
        ],
        "objProps": [
          {
            "uuid": "obj-e429228a-fdc1-4462-9b31-35f538a842ab",
            "labels": {
              "default": "measures",
              "en": "measures"
            },
            "comments": {
              "en": ""
            },
            "CreatedAt": 1687420090,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "measures",
            "iri": "measures",
            "domain": [
              "onc-c7deaa37-d253-4fbf-9d30-23ad586dc7f0"
            ],
            "range": [
              "onc-c52da970-d532-45b5-8a1e-3cb8adb58933"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-0189f93a-fa4b-4df2-8b3f-d03170ac9326",
            "labels": {
              "default": "acousticMeasures",
              "en": "acousticMeasures"
            },
            "comments": {},
            "CreatedAt": 1687423971,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "acousticMeasures",
            "iri": "acousticmeasures",
            "domain": [
              "onc-977406a4-f106-4f4b-989a-b7dc3104cf1e"
            ],
            "range": [
              "onc-ff15e997-8f0a-4338-bd73-4c0ee52edf57"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-e429228a-fdc1-4462-9b31-35f538a842ab",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-71faaff7-bf4c-4f80-b3a8-1cafc42daabb",
            "labels": {
              "default": "isComponentOf",
              "en": "isComponentOf"
            },
            "comments": {},
            "CreatedAt": 1687424360,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "isComponentOf",
            "iri": "iscomponentof",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-289d59f4-30c4-41cd-9f22-24b478fc553e",
            "labels": {
              "default": "isDeviceOf",
              "en": "isDeviceOf"
            },
            "comments": {},
            "CreatedAt": 1687424507,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "isDeviceOf",
            "iri": "isdeviceof",
            "domain": [
              "onc-39022d7b-3fcd-4030-802a-98fcdc01e6af"
            ],
            "range": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-71faaff7-bf4c-4f80-b3a8-1cafc42daabb",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-dcd6b2aa-8e12-48eb-b56a-fcb1611c2263",
            "labels": {
              "default": "pressureMeasures",
              "en": "pressureMeasures"
            },
            "comments": {},
            "CreatedAt": 1687424578,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "pressureMeasures",
            "iri": "pressuremeasures",
            "domain": [
              "onc-4446c514-72ca-4fe6-8585-c4ed17067dc5"
            ],
            "range": [
              "onc-424d8867-2c4c-42c9-9cfb-fc014af1e422"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-e429228a-fdc1-4462-9b31-35f538a842ab",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-abb1b626-6de8-40a4-8d35-f266e1674cb8",
            "labels": {
              "default": "flowMeasures",
              "en": "flowMeasures"
            },
            "comments": {},
            "CreatedAt": 1687424705,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "flowMeasures",
            "iri": "flowmeasures",
            "domain": [
              "onc-6253eaee-2037-41af-8f05-af35f81d94fa"
            ],
            "range": [
              "onc-778c5d81-901c-4349-b33c-b21ae6ed40df"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-e429228a-fdc1-4462-9b31-35f538a842ab",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-aa807c48-08bf-4420-8966-d3612af77b1a",
            "labels": {
              "default": "thermalMeasures",
              "en": "thermalMeasures"
            },
            "comments": {},
            "CreatedAt": 1687424748,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "thermalMeasures",
            "iri": "thermalmeasures",
            "domain": [
              "onc-4b3c5b17-c9fd-46d2-b269-c5cb4cf4f25c"
            ],
            "range": [
              "onc-c6690512-068c-4c65-bff5-c7f646854f66"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-e429228a-fdc1-4462-9b31-35f538a842ab",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-cfed71df-97ae-449e-b994-a5f65944f7a3",
            "labels": {
              "default": "hasUnit",
              "en": "hasUnit"
            },
            "comments": {},
            "CreatedAt": 1687425356,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "hasUnit",
            "iri": "hasunit",
            "domain": [
              "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc"
            ],
            "range": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-d6cd4098-cc99-4124-b2fd-6d89380b1fbf",
            "labels": {
              "default": "hasInputVariable",
              "en": "hasInputVariable"
            },
            "comments": {},
            "CreatedAt": 1687432936,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "hasInputVariable",
            "iri": "hasinputvariable",
            "domain": [
              "onc-7e62bf40-3aa3-42ef-9eac-66af6fd2d6d3",
              "onc-fe27de45-cfa7-4431-94a6-bebb8b4025c6"
            ],
            "range": [
              "onc-67fc2c6d-9c99-4076-80f0-0349403fb422",
              "onc-072b8a9d-0584-4b8b-b5f1-5f4008f4f3e8",
              "onc-957af764-edaf-45c0-9642-6520249cbfa1",
              "onc-c52da970-d532-45b5-8a1e-3cb8adb58933"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-6c9bb88b-c304-4a4e-a694-dddbeaec9811",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-6c9bb88b-c304-4a4e-a694-dddbeaec9811",
            "labels": {
              "default": "hasAnalyticProperty",
              "en": "hasAnalyticProperty"
            },
            "comments": {},
            "CreatedAt": 1687428106,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "hasAnalyticProperty",
            "iri": "hasanalyticproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-a372e9b4-8981-4190-ac53-a07aa80050b3",
            "labels": {
              "default": "hasTargetVariable",
              "en": "hasTargetVariable"
            },
            "comments": {},
            "CreatedAt": 1687432958,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "hasTargetVariable",
            "iri": "hastargetvariable",
            "domain": [
              "onc-7e62bf40-3aa3-42ef-9eac-66af6fd2d6d3"
            ],
            "range": [
              "onc-072b8a9d-0584-4b8b-b5f1-5f4008f4f3e8",
              "onc-c52da970-d532-45b5-8a1e-3cb8adb58933"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-6c9bb88b-c304-4a4e-a694-dddbeaec9811",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-ac750d9c-8238-4a0d-9d69-b1d0ed1bd98c",
            "labels": {
              "default": "predicts",
              "en": "predicts"
            },
            "comments": {},
            "CreatedAt": 1687432916,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "predicts",
            "iri": "predicts",
            "domain": [
              "onc-7e62bf40-3aa3-42ef-9eac-66af6fd2d6d3"
            ],
            "range": [
              "onc-67fc2c6d-9c99-4076-80f0-0349403fb422",
              "onc-957af764-edaf-45c0-9642-6520249cbfa1"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "obj-6c9bb88b-c304-4a4e-a694-dddbeaec9811",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "isInverseFunctional": false
          }
        ],
        "dataProps": [
          {
            "uuid": "dat-95a4042b-7187-4d58-9f92-3f522d33a7bf",
            "labels": {
              "default": "configFile",
              "en": "configFile"
            },
            "comments": {
              "en": "configFile in json"
            },
            "CreatedAt": 1688327976,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "configFile",
            "iri": "configfile",
            "domain": [
              "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-fdfee37d-742b-4097-a3b1-10452bbeacc8",
            "labels": {
              "default": "installationDate",
              "en": "installationDate"
            },
            "comments": {},
            "CreatedAt": 1687421080,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "installationDate",
            "iri": "installationdate",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [
              "onc-8eedd597-8759-4f1f-babf-24d1aae6caf1"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-4836ece9-7d4f-455b-b7d0-df781f13bfef",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-8356a671-d288-4141-8b0a-652f4b7cc7fb",
            "labels": {
              "default": "unitSystemProperty",
              "en": "unitSystemProperty"
            },
            "comments": {},
            "CreatedAt": 1687422326,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unitSystemProperty",
            "iri": "unitsystemproperty",
            "domain": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-d4b6b95f-ca7e-43c9-9a4a-aaea683d3123",
            "labels": {
              "default": "sourceFile",
              "en": "sourceFile"
            },
            "comments": {
              "en": "source code of the app"
            },
            "CreatedAt": 1688328084,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "sourceFile",
            "iri": "sourcefile",
            "domain": [
              "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-5a53161b-3678-4416-b04c-d5027a265768",
            "labels": {
              "default": "eventValue",
              "en": "eventValue"
            },
            "comments": {},
            "CreatedAt": 1687423109,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "eventValue",
            "iri": "eventvalue",
            "domain": [
              "onc-67fc2c6d-9c99-4076-80f0-0349403fb422",
              "onc-072b8a9d-0584-4b8b-b5f1-5f4008f4f3e8"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": true,
            "isSubPropertyOf": "dat-b5372b32-f50f-439c-a54f-ec2ba4054bcf",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-e9659397-acb1-4cc2-95d9-c088359a4c43",
            "labels": {
              "default": "connectionTimestampVariable",
              "en": "connectionTimestampVariable"
            },
            "comments": {
              "en": "connection string to extract timestamp"
            },
            "CreatedAt": 1688328282,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "connectionTimestampVariable",
            "iri": "connectiontimestampvariable",
            "domain": [
              "onc-0ea0fdcf-90a9-4338-8145-df13d9daf25a"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-e4226291-5a89-47b5-95a4-3d86b9b91891",
            "labels": {
              "default": "connectionDataVariable",
              "en": "connectionDataVariable"
            },
            "comments": {
              "en": "connection string to extract measurement value"
            },
            "CreatedAt": 1688328245,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "connectionDataVariable",
            "iri": "connectiondatavariable",
            "domain": [
              "onc-0ea0fdcf-90a9-4338-8145-df13d9daf25a"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "labels": {
              "default": "appSystemProperty",
              "en": "appSystemProperty"
            },
            "comments": {
              "en": ""
            },
            "CreatedAt": 1687271425,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "appSystemProperty",
            "iri": "appsystemproperty",
            "domain": [
              "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b"
            ],
            "range": [],
            "isFunctional": true,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-b5372b32-f50f-439c-a54f-ec2ba4054bcf",
            "labels": {
              "default": "measurementProperty",
              "en": "measurementProperty"
            },
            "comments": {},
            "CreatedAt": 1687420592,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "measurementProperty",
            "iri": "measurementproperty",
            "domain": [
              "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc"
            ],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-3693cfef-f4c6-47a9-b2e1-211114bb5de9",
            "labels": {
              "default": "notifierProperty",
              "en": "notifierProperty"
            },
            "comments": {},
            "CreatedAt": 1687434212,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "notifierProperty",
            "iri": "notifierproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-6f2d9509-217a-478c-9edc-a4c9bda1045b",
            "labels": {
              "default": "recipients",
              "en": "recipients"
            },
            "comments": {},
            "CreatedAt": 1687434279,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "recipients",
            "iri": "recipients",
            "domain": [
              "onc-fe27de45-cfa7-4431-94a6-bebb8b4025c6"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-3693cfef-f4c6-47a9-b2e1-211114bb5de9",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-2513e2b3-b0d0-4ffa-b9a7-4666bd5cf2e7",
            "labels": {
              "default": "pressure",
              "en": "pressure"
            },
            "comments": {},
            "CreatedAt": 1687423811,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "pressure",
            "iri": "pressure",
            "domain": [
              "onc-424d8867-2c4c-42c9-9cfb-fc014af1e422"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-2df59679-fdad-4f3c-a6cb-5a5729866ecd",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-1988789e-1c0e-4011-9101-6c71a0214540",
            "labels": {
              "default": "assetDataProperty",
              "en": "assetDataProperty"
            },
            "comments": {},
            "CreatedAt": 1688328629,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "assetDataProperty",
            "iri": "assetdataproperty",
            "domain": [
              "onc-4bfeb9a5-942e-40a6-aaf9-2b7fb513f8e2"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-10394d93-3628-45ec-9ba9-88b947520f03",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-0a7ae190-04a6-40e3-8cdd-efcdb8a70d53",
            "labels": {
              "default": "manufactoredDate",
              "en": "manufactoredDate"
            },
            "comments": {},
            "CreatedAt": 1687421068,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "manufactoredDate",
            "iri": "manufactoreddate",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [
              "onc-8eedd597-8759-4f1f-babf-24d1aae6caf1"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-4836ece9-7d4f-455b-b7d0-df781f13bfef",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923",
            "labels": {
              "default": "unitSymbol",
              "en": "unitSymbol"
            },
            "comments": {},
            "CreatedAt": 1687422748,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unitSymbol",
            "iri": "unitsymbol",
            "domain": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": true,
            "isSubPropertyOf": "dat-8356a671-d288-4141-8b0a-652f4b7cc7fb",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-10394d93-3628-45ec-9ba9-88b947520f03",
            "labels": {
              "default": "assetSystemProperty",
              "en": "assetSystemProperty"
            },
            "comments": {},
            "CreatedAt": 1687420621,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "assetSystemProperty",
            "iri": "assetsystemproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-37bfc075-f562-4616-b9cb-ab549e1f1efe",
            "labels": {
              "default": "identification",
              "en": "identification"
            },
            "comments": {
              "en": "a string encodes the id or serial number or whatever is used to identify the individual"
            },
            "CreatedAt": 1687421895,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "identification",
            "iri": "identification",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-4836ece9-7d4f-455b-b7d0-df781f13bfef",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-2edbc88d-0a26-471b-87d4-95c4c99bcd43",
            "labels": {
              "default": "dataValue",
              "en": "dataValue"
            },
            "comments": {},
            "CreatedAt": 1688328641,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "dataValue",
            "iri": "datavalue",
            "domain": [
              "onc-4bfeb9a5-942e-40a6-aaf9-2b7fb513f8e2"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1988789e-1c0e-4011-9101-6c71a0214540",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-4a38c3eb-1512-4fd8-b35a-b89d3724d0b0",
            "labels": {
              "default": "state",
              "en": "state"
            },
            "comments": {
              "en": "state of the app"
            },
            "CreatedAt": 1687422502,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "state",
            "iri": "state",
            "domain": [
              "onc-7015c698-27a9-4c85-a566-0b52f59bbc9b"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-ea4ecab2-e1f8-4b64-a461-74307d143f3a",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-43f1a084-6d12-4f9e-9b41-695aa5d9eeec",
            "labels": {
              "default": "activeUntil",
              "en": "activeUntil"
            },
            "comments": {
              "en": "the moment it was replaced by another entity"
            },
            "CreatedAt": 1687421163,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "activeUntil",
            "iri": "activeuntil",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [
              "onc-8eedd597-8759-4f1f-babf-24d1aae6caf1"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-4836ece9-7d4f-455b-b7d0-df781f13bfef",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-78470883-43f8-46a0-afea-76dcf9126e28",
            "labels": {
              "default": "unitName",
              "en": "unitName"
            },
            "comments": {
              "en": "name of the unit"
            },
            "CreatedAt": 1687422792,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unitName",
            "iri": "unitname",
            "domain": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-8356a671-d288-4141-8b0a-652f4b7cc7fb",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-4836ece9-7d4f-455b-b7d0-df781f13bfef",
            "labels": {
              "default": "assetEntityProperty",
              "en": "assetEntityProperty"
            },
            "comments": {},
            "CreatedAt": 1687420843,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "assetEntityProperty",
            "iri": "assetentityproperty",
            "domain": [
              "onc-305ac61e-2524-4022-8890-0d9bb6ba72f6"
            ],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-10394d93-3628-45ec-9ba9-88b947520f03",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-6f1b74b7-db56-4daa-bf6f-e01004ccdb1a",
            "labels": {
              "default": "icon",
              "en": "icon"
            },
            "comments": {},
            "CreatedAt": 1688328690,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "icon",
            "iri": "icon",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1988789e-1c0e-4011-9101-6c71a0214540",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-906993c5-9adc-4ceb-8119-83fb5a2ac512",
            "labels": {
              "default": "timestamp",
              "en": "timestamp"
            },
            "comments": {},
            "CreatedAt": 1687422876,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "timestamp",
            "iri": "timestamp",
            "domain": [
              "onc-8016da1f-49f5-4076-9b3f-bc0702b125bc"
            ],
            "range": [
              "onc-8eedd597-8759-4f1f-babf-24d1aae6caf1"
            ],
            "isFunctional": true,
            "isSubPropertyOf": "dat-b5372b32-f50f-439c-a54f-ec2ba4054bcf",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-2af5302e-1ab4-465b-b21d-cce8e7dba822",
            "labels": {
              "default": "eventDuration",
              "en": "eventDuration"
            },
            "comments": {
              "en": "duration in minutes when the event happened (starting from the timestamp of the recorded event)"
            },
            "CreatedAt": 1687423165,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "eventDuration",
            "iri": "eventduration",
            "domain": [
              "onc-67fc2c6d-9c99-4076-80f0-0349403fb422",
              "onc-072b8a9d-0584-4b8b-b5f1-5f4008f4f3e8"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": true,
            "isSubPropertyOf": "dat-b5372b32-f50f-439c-a54f-ec2ba4054bcf",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          },
          {
            "uuid": "dat-2df59679-fdad-4f3c-a6cb-5a5729866ecd",
            "labels": {
              "default": "measurementValue",
              "en": "measurementValue"
            },
            "comments": {},
            "CreatedAt": 1687423024,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "measurementValue",
            "iri": "measurementvalue",
            "domain": [
              "onc-c52da970-d532-45b5-8a1e-3cb8adb58933",
              "onc-957af764-edaf-45c0-9642-6520249cbfa1"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": true,
            "isSubPropertyOf": "dat-b5372b32-f50f-439c-a54f-ec2ba4054bcf",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null
          }
        ],
        "individuals": [
          {
            "uuid": "ind-9fa8e146-df31-439f-a491-514657b5d519",
            "labels": {
              "default": "unit-celsius-degree",
              "en": "unit-celsius-degree"
            },
            "comments": {
              "en": "Celsius degree / centigrade degree"
            },
            "CreatedAt": 1687952771,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unit-celsius-degree",
            "iri": "unitcelsiusdegree",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "types": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "objectProperties": {},
            "dataProperties": {
              "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923": "°C",
              "dat-78470883-43f8-46a0-afea-76dcf9126e28": "Celsius degree"
            }
          },
          {
            "uuid": "ind-f3ec86ae-b182-4605-9ef8-1e699682e784",
            "labels": {
              "default": "unit-pressure-bar",
              "en": "unit-pressure-bar"
            },
            "comments": {
              "en": "Bar"
            },
            "CreatedAt": 1687952894,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unit-pressure-bar",
            "iri": "unitpressurebar",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "types": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "objectProperties": {},
            "dataProperties": {
              "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923": "bar",
              "dat-78470883-43f8-46a0-afea-76dcf9126e28": "bar"
            }
          },
          {
            "uuid": "ind-12d9e8a5-dd42-4809-aa20-fae16da022e9",
            "labels": {
              "default": "unit-pressure-pascal",
              "en": "unit-pressure-pascal"
            },
            "comments": {
              "en": "Pascal"
            },
            "CreatedAt": 1687952948,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unit-pressure-pascal",
            "iri": "unitpressurepascal",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "types": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "objectProperties": {},
            "dataProperties": {
              "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923": "Pa",
              "dat-78470883-43f8-46a0-afea-76dcf9126e28": "Pascal"
            }
          },
          {
            "uuid": "ind-19fe4af5-4d06-4972-a50b-b4efff3cc0cf",
            "labels": {
              "default": "unit-frequency-hertz",
              "en": "unit-frequency-hertz"
            },
            "comments": {},
            "CreatedAt": 1687953079,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unit-frequency-hertz",
            "iri": "unitfrequencyhertz",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "types": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "objectProperties": {},
            "dataProperties": {
              "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923": "Hz",
              "dat-78470883-43f8-46a0-afea-76dcf9126e28": "Hertz"
            }
          },
          {
            "uuid": "ind-f05c55e0-bc02-47fa-b266-e3328388a77e",
            "labels": {
              "default": "unit-flowrate-si",
              "en": "unit-flowrate-si"
            },
            "comments": {
              "en": "flowrate"
            },
            "CreatedAt": 1687953453,
            "UpdatedAt": 1688328705,
            "deletedAt": 0,
            "name": "unit-flowrate-si",
            "iri": "unitflowratesi",
            "OntologyUuid": "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69",
            "Ontology": null,
            "types": [
              "onc-c7df426f-df22-49ac-87ee-7b2d18a700cf"
            ],
            "objectProperties": {},
            "dataProperties": {
              "dat-4794a749-dfc2-43ba-a0c2-0a6d7f23d923": "m3/s",
              "dat-78470883-43f8-46a0-afea-76dcf9126e28": "cubic meter per second"
            }
          }
        ],
        "scopes": {
          "institution": "institution_iJQMqWOspA"
        }
      }
    ]
    
  } 
}