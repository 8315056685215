import { useRef, useEffect, useState, useCallback } from 'react';
import { LatLng } from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'
import { Feature, Geometry } from 'standard/geojson/@types';
import { LINE_STRING, POINT } from './constants';
import "./mapViewer.css";
import 'standard/leaflet/mapWrapperNodes';
import { MapViewLayers, MapViewLayer } from './@types/mapViewerLayer'
import { IAlert } from 'standard/alert';
import { IS_ADD_ON_KEY, LayerInfo, LayerMetaData, GEOMETRY_INDEX, IS_ADD_ON_TO, LAYER_UUID_KEY, LAYER_UUID_KEYS } from './@types/layerInfo';
import { Box } from './bbox';
import { getSingleTile } from './utils/tiles';
import ContextMenu from './contextMenu';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import { v4 as uuidv4 } from 'uuid';
import { getMarkerFontAwesome } from './mapViewerElements';
import { DELETE_FEATURE } from './@types/actions';
import { DrawStatus } from './@types/drawing';
import { contextMenuDefault } from './@types/contextMenuOptions';
import { faMapPin, faArrowsLeftRight, faLayerGroup, faTrash, faFilter } from '@fortawesome/free-solid-svg-icons'
import { MapWrraper } from 'standard/leaflet';
import CustomToolBox from './customToolBox';
import { LayerEvents, Tools } from 'standard/leaflet/enums';
import { MapLayers } from './map/mapLayers';
import { MapFormattings } from './map/mapFormat';

declare const L: any;

enum MapStatus {
	Prerender = 1,
	RenderComplete,
	NotLoaded,
	UnLoaded,
}

const getDelta = (pos1: { x: number, y: number }, pos2: { x: number, y: number }): { x: number, y: number } => {
	return { x: pos1.x - pos2.x, y: pos1.y - pos2.y }
}



const MapViewer = (props: {
	features: Feature[]
	filteredFeatures?: string[]
	onLayerEvent: (key: string, event: LayerEvents) => void,
	onClearSelection: () => void
	newItemsMenu: IMenuItem[]
	isEditable: boolean
	formattings: MapFormattings
	selectedFeatures?: string[]
	highlightedFeatures?: string[]
	drawFeature?: Feature
	updates?: Feature[]
	center?: LatLng
	onDrawStart: (status: DrawStatus) => void
	onDrawStop: (status: DrawStatus) => void
	onMapAction: (item: IMenuItem, latlng?: LatLng) => void
	onFeatureAdd: (feature: Feature) => void
	onActiveToolsChange : (tools: Set<string>) => void
	onGeometryChange?: (uuid: string, index: number, value: number[]) => void
	onGeometryRemove?: (uuid: string, index: number) => void
	onError?: (error: IAlert) => void
}) => {
	const mapWrapperRef = useRef<MapWrraper | null>(null);
	const mapRef = useRef<HTMLDivElement>(null);
	const [layers, setLayers] = useState<MapViewLayers | undefined>(undefined);
	const [mapStatus, setMapStatus] = useState<MapStatus>(MapStatus.NotLoaded);
	const [activeTools, setActiveTools] = useState<Set<string>>(new Set());
	const [isDrawn, setIsDrawn] = useState<boolean>(false);
	const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);
	let [layeredFeatures, setLayeredFeatures] = useState<{ [key: string]: Feature[] }>({});

	let [contextMenuOptionsInstance, setContextMenuOptionsInstance] = useState<string>(uuidv4());

	useEffect(() => {
		setMapStatus(MapStatus.Prerender)
		return () => {
			if (mapWrapperRef!.current !== null) {
				mapWrapperRef.current.map.off();
				mapWrapperRef.current.map.remove();
			}
		}
	}, []);

	useEffect(() => {

	}, [mapWrapperRef.current?.status.highlightedLayers]);

	useEffect(() => {

	}, [mapWrapperRef.current?.status.highlightIntervalId]);

	useEffect(() => {

	}, [contextMenuOptionsInstance]);

	useEffect(() => {
		if (!activeTools.has(Tools.Override)) {
			props.onLayerEvent("", LayerEvents.OverrideUnset)

		} else if (!activeTools.has(Tools.MutliSelect)) {
			props.onLayerEvent("", LayerEvents.MutliSelectUnset)
		}
		props.onActiveToolsChange(activeTools)
	}, [activeTools]);

	useEffect(() => {

	}, [props.formattings]);

	useEffect(() => {
		if (mapWrapperRef.current === null) return
		if (props.selectedFeatures === undefined) {
			mapWrapperRef.current!.deselectAllLayers([], onLayerEvent, setContextMenuOptionsInstance, finishMarkerMouseMove)
		}
	}, [props.selectedFeatures]);

	useEffect(() => {
		if (layers) {
			// curateLayers(layers!);
		}
	}, [props.highlightedFeatures]);

	useEffect(() => {

	}, [props.isEditable]);

	useEffect(() => {


	}, [props.filteredFeatures]);

	useEffect(() => {
		if (mapWrapperRef.current) {
			mapWrapperRef.current!.status.drawFeature = props.drawFeature
		}
	}, [props.drawFeature]);

	useEffect(() => {

	}, [featuresLoaded]);

	useEffect(() => {

	}, [layeredFeatures]);

	useEffect(() => {

	}, [props.features]);

	const createLayers = useCallback((unlayeredFeatures: Feature[]): { [key: string]: Feature[] } => {
		let newFeatures: { [key: string]: any[] } = {}
		unlayeredFeatures.forEach((f: Feature) => {
			if (!newFeatures.hasOwnProperty(f.properties!.layer)) {
				newFeatures[f.properties!.layer] = []
			}
			// Only feature with geometry get mapped
			if (f.geometry) {
				if (!f.properties) {
					f.properties = { uuid: f.uuid }
				}

				if (!f.properties!.layer) {
					f.properties!.layer = 'Unknown'
				}

				if (!newFeatures.hasOwnProperty(f.properties!.layer)) {
					newFeatures[f.properties!.layer] = []
				}

				newFeatures[f.properties!.layer].push(f)
			}
		})
		return newFeatures
	}, [])

	useEffect(() => {
		if (props.features) {
			mapWrapperRef.current?.status.updateRenderVersion()
			setLayeredFeatures(createLayers(props.features!));
		}
	}, [props.features, setLayeredFeatures]);

	useEffect(() => {
		if (!props.center) {
			return
		}

		mapWrapperRef.current?.map.flyTo(props.center, 15)
	}, [props.center]);

	useEffect(() => {
		if (!layeredFeatures) {
			setLayers(undefined);
			return
		}

		let ls: MapViewLayers = {};
		Object.keys(layeredFeatures).forEach((layerKey: string) => {
			ls[layerKey] = getSingleTile(layerKey, layeredFeatures![layerKey])
		})

		setIsDrawn(false)

		setLayers(ls)
	}, [layeredFeatures]);

	const handleContextMenu = (event: any) => {
		event.preventDefault();
		// var bodyRect = document.body.getBoundingClientRect()
		// var mapElement = document.getElementById("pp-map")?.parentElement?.parentElement
		// var mapRect = mapElement!.getBoundingClientRect();

		// setcontextMenuOptions({
		// 	...contextMenuOptions, 
		// 	...{
		// 		visible : true,
		// 		clientPosition :  { x: mapRect.left, y: mapRect.top - bodyRect.top },
		// 		// clientPosition :  { x: event.pageX - mapRect.left + window.scrollX, y: event.pageY - mapRect.top + window.scrollY }
		// 	}
		// })
	};

	const onContextMenu = (e: any) => {
		// //  if (Object.keys(highlightedLayers!).length > 0) {
		// // 	setContextMenuTitle('Edit Element')
		// // } else{
		// // 	setContextMenuTitle('Add Element 2')
		// // }
		// mapWrapperRef.current!.status.contextMenuOptions = {
		// 	...mapWrapperRef.current!.status.contextMenuOptions,
		// 	...{
		// 		visible: true,
		// 		title: 'Add Element',
		// 		mapPostion: e.latlng,
		// 		clientPosition: { x: e.originalEvent.clientX, y: e.originalEvent.clientY },
		// 		menuItems: props.newItemsMenu
		// 	}
		// };

		// setContextMenuOptionsInstance(uuidv4())
	};

	const onLayerEvent = (key: string, event: LayerEvents) => {
		// switch (event) {
		// 	case (LayerEvents.Highlighted):
		// 		break
		// 	case (LayerEvents.Selected):
		// 		onFeatureSelection(key)
		// 		break

		// }
		props.onLayerEvent(key, event)
	}

	const handleCloseContextMenu = () => {
		mapWrapperRef.current!.status.contextMenuOptions = contextMenuDefault
		setContextMenuOptionsInstance(uuidv4())
	};

	const handleRemoveEdgePoint = (info: LayerInfo) => {
		let layerOnFocus: LayerMetaData = mapWrapperRef.current!.status.featureLayers[info[LAYER_UUID_KEY]]
		if (layerOnFocus && props.onGeometryRemove) {
			const geometryIndex: number = info[GEOMETRY_INDEX]
			let uuid: string = layerOnFocus.info[IS_ADD_ON_TO]
			props.onGeometryRemove(uuid, geometryIndex)
		}
	};

	const onMapAction = (item: IMenuItem) => {
		if (item.name === DELETE_FEATURE) {
			props.onMapAction(item, mapWrapperRef.current!.status.contextMenuOptions.mapPostion!)

		} else {
			handleRemoveEdgePoint(item.data!)
		}

		mapWrapperRef.current!.status.contextMenuOptions = contextMenuDefault
		setContextMenuOptionsInstance(uuidv4())
	}


	const addDrawControl = () => {
		var drawnItems = new L.FeatureGroup();
		var markerIcon = getMarkerFontAwesome('2x', '#fff', faMapPin)
		mapWrapperRef.current!.addLayer(drawnItems);
		var drawControl = new L.Control.Draw({
			draw: {
				marker: {
					icon: markerIcon
				},
				polygon: false,
				rectangle: false,
				circle: false,
				circlemarker: false
			},
			edit: false,
			delete: false
			// edit: {
			// 	featureGroup: drawnItems
			// }
		});

		mapWrapperRef.current!.map.on('draw:drawstart', (event: any) => {
			mapWrapperRef.current!.clearSelectionsAndHighlights(onLayerEvent, setContextMenuOptionsInstance, finishMarkerMouseMove)
			props.onDrawStart({ type: event.layerType })
		});

		mapWrapperRef.current!.map.on('draw:drawstop', (event: any) => {
			props.onDrawStop({ type: '' })
		});

		//Create A New Graph Element Manually
		mapWrapperRef.current!.map.on('draw:created', (event: any) => {
			if (event.layerType === 'polyline') {
				let latlngs: any[] = event.layer.getLatLngs()
				let newFeature: Feature = { ...mapWrapperRef.current!.status.drawFeature! }
				let properties: { [k: string]: any } = {}
				properties.layer = "Edge"
				newFeature.properties = properties
				let geometry: Geometry = { type: LINE_STRING, coordinates: latlngs.map((c) => { return [c.lng, c.lat] }) }
				newFeature.geometry = geometry
				props.onFeatureAdd(newFeature)
			} else if (event.layerType === 'marker') {
				let latlng = event.layer.getLatLng()
				let newFeature: Feature = { ...mapWrapperRef.current!.status.drawFeature! }
				let properties: { [k: string]: any } = {}
				properties.layer = "Node"
				newFeature.properties = properties
				let geometry: Geometry = { type: POINT, coordinates: [latlng.lng, latlng.lat] }
				newFeature.geometry = geometry
				props.onFeatureAdd(newFeature)
			}
		});

		mapWrapperRef.current!.addControl(drawControl);
	}

	const loadMap = useCallback(() => {
		let overlayLayers = {};

		const anonymousMapLayer = L.tileLayer('');

		mapWrapperRef.current = new MapWrraper(L.map("pp-map", {
			maxZoom: 30,
			attributionControl: false,
			zoomControl: false,
			layers: [anonymousMapLayer],
			renderer: L.canvas(),
		}), props.isEditable, props.formattings);


		if (props.isEditable) {
			addDrawControl()
		}

		mapWrapperRef.current!.map.doubleClickZoom.disable();
		// mapRef.current.on('mousedown', onMouseDown)				
		mapWrapperRef.current!.map.on('mousedown', onMouseDown)

		document.addEventListener('keydown', onKeyDown)

		if (props.isEditable) {
			mapWrapperRef.current!.map.on('dragstart', function (event: L.LeafletEvent) {
				mapWrapperRef.current!.status.mouseEvents.dragStart()
			});

			mapWrapperRef.current!.map.on('mousemove', function (event: L.LeafletMouseEvent) {
				mapWrapperRef.current!.status.mouseEvents.mouseMove(({ x: event.originalEvent.clientX, y: event.originalEvent.clientY }))
				let layerOnFocus = mapWrapperRef.current!.status.mouseEvents.getOnLayer()
				if (layerOnFocus) {
					if (layerOnFocus.info[IS_ADD_ON_KEY] === true) {
						const geometryIndex: number = layerOnFocus.info[GEOMETRY_INDEX]
						let parentUUID: string = layerOnFocus.info[IS_ADD_ON_TO]
						let parentLayerMetadata = mapWrapperRef.current!.status.featureLayers[parentUUID]
						let latlngs = parentLayerMetadata.layer.getLatLngs()
						latlngs[geometryIndex] = event.latlng
						parentLayerMetadata.layer.setLatLngs(latlngs)
					} else {
						layerOnFocus!.layer.setLatLng(event.latlng)
					}
					mapWrapperRef.current!.status.mouseEvents.layerMoved = true
				}
			});

			mapWrapperRef.current!.map.on('mouseup', function (event: L.LeafletMouseEvent) {
				finishMarkerMouseMove()
			});

			mapWrapperRef.current!.map.on('drag', function (event: any) {
				if (mapWrapperRef.current!.status.contextMenuOptions.clientPosition) {
					const newPos = { x: event.originalEvent.clientX, y: event.originalEvent.clientY }
					const startPos = mapWrapperRef.current!.status.mouseEvents.getDown()
					const delta = getDelta(newPos, startPos!)
					mapWrapperRef.current!.status.contextMenuOptions = {
						...mapWrapperRef.current!.status.contextMenuOptions,
						...{
							clientPosition: {
								x: mapWrapperRef.current!.status.contextMenuOptions.clientPosition.x + delta.x,
								y: mapWrapperRef.current!.status.contextMenuOptions.clientPosition.y + delta.y
							},
						}
					};
					setContextMenuOptionsInstance(uuidv4())
				}
			});

			mapWrapperRef.current!.map.on('dragend', function (event: L.LeafletEvent) {
				mapWrapperRef.current!.status.mouseEvents.dragEnd()
			});
		}

		// mapRef.current.on('contextmenu', onContextMenu);

		// addContextMenu()

		L.control.zoom({ position: 'topright' }).addTo(mapWrapperRef.current.map);

		mapWrapperRef.current!.render(overlayLayers);

		setMapStatus(MapStatus.RenderComplete)
	}, [])

	const finishMarkerMouseMove = () => {
		let layerOnFocus = mapWrapperRef.current!.status.mouseEvents.getOnLayer()
		if (layerOnFocus && props.onGeometryChange) {
			if (mapWrapperRef.current!.status.mouseEvents.layerMoved === true) {
				const geometryIndex: number = layerOnFocus.info[GEOMETRY_INDEX]
				let uuid: string
				if (layerOnFocus.info[IS_ADD_ON_KEY] === true) {
					uuid = layerOnFocus.info[IS_ADD_ON_TO]
				} else {
					uuid = layerOnFocus.feature.uuid!
				}
				let latlng = layerOnFocus.layer.getLatLng()
				props.onGeometryChange(uuid, geometryIndex, [latlng.lng, latlng.lat])
			}
		}
		mapWrapperRef.current!.status.mouseEvents.setOnLayer(undefined)
		mapWrapperRef.current!.map.dragging.enable();
	}

	const finishLineMouseMove = () => {

	}

	const onMouseDown = (e: L.LeafletMouseEvent) => {
		mapWrapperRef.current!.status.mouseEvents.mouseDown({ x: e.originalEvent.clientX, y: e.originalEvent.clientY })
		// deselectAllLayers()
	}

	const onKeyDown = (e: KeyboardEvent) => {	
		if (e.key === 'Backspace') {
			deleteSelected()
		}		
	}

	const deleteSelected = () => {
		const activeElement : Element | undefined = document.activeElement || undefined

		if (activeElement === undefined) {
			return
		}

		let canDelete : boolean = false

		if (activeElement!.id === 'pp-map'){
			canDelete = true
		} 
		
		if (activeElement!.localName === 'body') {
			canDelete = true
		}

		if (canDelete === false){
			return
		}

		const data = {[LAYER_UUID_KEYS] : mapWrapperRef.current?.status.selected()}
		props.onMapAction({label : 'delete features',  name : Tools.Delete, data : data, ordinality: 0} as IMenuItem)

	}

	const onMapDrag = (e: L.LeafletEvent) => {

	}

	useEffect(() => {
		if (mapStatus === MapStatus.Prerender) {
			loadMap()
		}
	}, [mapStatus, loadMap]);

	useEffect(() => {
		if (isDrawn) {
			return
		}

		if (mapStatus !== MapStatus.RenderComplete) {
			return
		}

		if (!layers) {
			return
		}

		setIsDrawn(true)

		mapWrapperRef.current!.status.edgePoints = []
		// if (featuresLoaded) {
		// 	mapWrapperRef.current!.redraw(onLayerEvent, setContextMenuOptionsInstance, finishMarkerMouseMove)
		// }
		// else {
		// 	drawLayers(layers)
		// }
	}, [isDrawn, layers, mapStatus]);

	const onToolActivated = (tool: Tools) => {

	}

	const onToolDeactivated = (tool: Tools) => {
		switch (tool) {
			case Tools.Override:
				if (mapWrapperRef.current!.status.overrideLayer) {
					mapWrapperRef.current!.unsetOverrideLayer(mapWrapperRef.current!.status.overrideLayer, onLayerEvent, setContextMenuOptionsInstance, finishMarkerMouseMove)
				}
				break;
			case Tools.MutliSelect:
				if (mapWrapperRef.current!.status.hasSelection()) {
					mapWrapperRef.current!.deselectAllLayers([], onLayerEvent, setContextMenuOptionsInstance, finishMarkerMouseMove)
				}
				break;
			default:
				break;
		}
	}

	const onToolboxDelete = (item: IMenuItem) => {
		item.data = {[LAYER_UUID_KEYS] : Object.keys(mapWrapperRef.current!.status.selectedLayers)}
		props.onMapAction(item)
	}

	const toolBoxOptions: Array<IMenuItem> = [
		{ name: Tools.Override, "label": Tools.Override, "icon": faArrowsLeftRight, ordinality: 1, visible: true},
		{ name: Tools.MutliSelect, "label": Tools.MutliSelect, "icon": faLayerGroup, ordinality: 2, visible: true },
		{ name: Tools.Delete, "label": Tools.Delete, "icon": faTrash, ordinality: 3, action: onToolboxDelete, visible: mapWrapperRef.current?.status.hasSelection() || false},
		// { name: Tools.Filter, "label": Tools.Filter, "icon": faFilter, ordinality: 4, action: onToolboxDelete, visible: props.filteredFeatures ? props.filteredFeatures.length > 0 : false},
	];

	const onCustomToolBox = (item: IMenuItem) => {
		if (item.action) {
			item.action(item)
			return
		}
		setActiveTools(mapWrapperRef.current!.toggleTool(item.name as Tools, [onToolActivated], [onToolDeactivated]));
	}

	return <div>
		<div id="pp-map" className="pp-map-viewer-container darkmode" onContextMenu={handleContextMenu} ref={mapRef}>
			{(layers && mapWrapperRef.current) &&
				<MapLayers
					mapWrapper={mapWrapperRef}
					formattings={props.formattings}
					layers={layers}
					filteredFeatures={props.filteredFeatures || []}
					onLayerEvent={onLayerEvent}
					finishLineMouseMove={finishLineMouseMove}
					finishMarkerMouseMove={finishMarkerMouseMove}
				/>
			}
			{props.isEditable &&
				<div id="right-toolbar">
					<CustomToolBox menuItems={toolBoxOptions} onMenuItemClick={onCustomToolBox} active={activeTools}></CustomToolBox>
				</div>
			}
		</div>
		{mapWrapperRef.current &&
			<>
				<ContextMenu
					version={contextMenuOptionsInstance}
					options={mapWrapperRef.current!.status.contextMenuOptions}
					onClose={handleCloseContextMenu}
					action={onMapAction}
				/>
			</>
		}
	</div>
}

export default MapViewer;

