import { Fragment, useEffect, useState } from 'react';
import '../style.css';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons'

const HMenuRoot = (props: {
    item: IMenuItem
    children: IMenuItem[]
    active: string
    onselected: (item: IMenuItem) => void
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {

    })

    const toggle = () =>{
        setExpanded(!expanded);
    }

    return <Fragment key={props.item.name} >
        <div className='columns m-0 p-0 pl-1 pt-1 pb-1 pr-1 hmenu-root clickable' onClick={toggle}>
            <div className='column m-0 p-0'>
                <p key={props.item.name} className="hmenu-label">
                    {props.item.label}
                </p>
            </div>
            <div className='column m-0 p-0 pr-1 pt-1'>
                <FontAwesomeIcon className='is-pulled-right' icon={faAngleRight} />
            </div>
        </div>
        
        {expanded && <ul className="hmenu-list">
            {props.children && props.children.map((child: IMenuItem) =>
                <li key={child.name} className={`hmenu-item clickable ${props.active === child.name ? 'is-active' : ''}`} onClick={() => props.onselected(child)}>
                    <div className='columns is-1 m-0 p-0'>
                        <div className='column hmenu-item-itemized m-0 p-0'>
                            <span className='ml-3'></span>      
                        </div>
                        <div className='column is-11 m-0 p-0'>
                            {child.label}
                            <span className='ml-3'>{child.icon && <FontAwesomeIcon icon={child.icon} />}</span>                    
                        </div>
                    </div>
                </li>
            )}
        </ul>
        }
        <hr className="navbar-divider m-0 p-0" />
    </Fragment>
};

export default HMenuRoot;