import { setDigitalTwin } from "./shared"
import { IDigitalTwin } from "../interfaces";
import { getDigitalTwinService } from "../utils";


export async function getNetworks(token: string, inScopeOnly : boolean, onlyMeta : boolean): Promise<Array<IDigitalTwin>> {
    const promise = new Promise<Array<IDigitalTwin>>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        
        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${getDigitalTwinService()}/digitaltwin?importsuri=${process.env.REACT_APP_NETWORKS_MODEL_FILTER}&inscopeonly=${inScopeOnly}&onlymeta=${onlyMeta}`, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(result => {
                const items = result.response.map((x: any) => {
                    return setDigitalTwin(x)
                });
                res(items)
            })
            .catch(error => console.log('error', error));
    });
    return promise;
}
