import { useEffect } from 'react';
import { Feature } from 'standard/geojson/@types';
import { useAppSelector } from 'hooks/redux';
import { MENUS, menus } from '../menus';
import MessageWrapper from '../messageWrapper';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import ImportData from './importdata';

const Import = (props: {
    currentMenu: string,
    features?: Feature[]
}) => {
    const ui = useAppSelector(state => state.ui)

    useEffect(() => {

    }, [ui.fullscreenenabled])

    useEffect(() => {

    }, [props.currentMenu])

    useEffect(() => {

    }, [props.features])


    const load = () => {
        let menu: IMenuItem = menus[props.currentMenu]
        if (!menu) {
            return <></>
        }
        if (!menu.parent) {
            return <></>
        }
        if (menu.parent !== 'import') {
            return <></>
        }
        if (props.features === undefined) {
            return <MessageWrapper>
                <h4 className="subtitle is-6">Import</h4>
                <span className='has-text-info '>No transformations available</span>
            </MessageWrapper>
        }
        if (props.currentMenu === MENUS.updatenetwork) {
            return <div>
                <div className={`box is-fullwidth  m-0 p-0 pl-2`}>
                    <p className="card-header-title">
                        Update Network
                    </p>
                </div>
                <div className='mt-2'>
                    <ImportData features={props.features!} />
                </div>
            </div>
        }
        return <></>
    }

    return load()
}

export default Import;