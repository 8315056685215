import { Feature, Features, Geometry } from "standard/geojson/@types";
import { readGeoFiles, reProjection } from "../../helpers/parsingDataFile";
import { IAlert, ActionTypes } from "standard/alert";
import { finishPolygon } from "../../helpers/util";
import { POLYGON } from "../../constants/variableName";
import shp from "shpjs";

export const importShpFiles = (props : 
    {
        typeId : string, 
        res: (value: Features | PromiseLike<Features>) => void, 
        rej: (reason?: any) => void, 
        files: any, 
        setEditingMetadata: (val: boolean) => void,
        setCurrentGeo: (val: Feature | undefined) => void
        zip?: boolean,
    }) => {
    readGeoFiles(props.files, props.zip).then(({ shpBuffer, prjBuffer, dbfBuffer, shpFileName }) => {
        if (shpBuffer) {
            let importedFeatures: Features = {originName : shpFileName, features :[]};
            
            let projection: string = "EPSG:3857";
            if (shpBuffer && prjBuffer && dbfBuffer) {
                let importedGeo = shp.combine([shp.parseShp(shpBuffer as Buffer, prjBuffer as string), shp.parseDbf(dbfBuffer as Buffer, dbfBuffer as Buffer)]);
                // console.log("importedGeo===>>>>>", importedGeo);
                importedFeatures.features = importedGeo.features as Feature[];
            } else if (prjBuffer) {
                let importedGeo = shp.parseShp(shpBuffer as Buffer, prjBuffer as string);
                for (let i = 0; i < importedGeo.length; i += 1) {
                    let geo = importedGeo[i];
                    importedFeatures.features.push({ type: props.typeId, geometry: geo as Geometry, originOrder: i })
                }
            } else {
                let importedGeo = shp.parseShp(shpBuffer as Buffer, projection);

                let dataProps: any[];
                if (dbfBuffer) {
                    dataProps = shp.parseDbf(dbfBuffer as Buffer, dbfBuffer as Buffer);
                }
                importedGeo.map((geo, index) => {
                    if (dataProps && dataProps.length === importedGeo.length) {
                        importedFeatures.features.push({ type: props.typeId, geometry: geo as Geometry, properties: dataProps[index] })
                    } else {
                        importedFeatures.features.push({ type: props.typeId, geometry: geo as Geometry })
                    }
                    return null;
                });
            }
            for (let feature of importedFeatures.features) {
                reProjection(feature);
            };
            props.setEditingMetadata(true);
            props.setCurrentGeo(undefined);
            importedFeatures.features.map((f: Feature) => {
                if (f.geometry.type === POLYGON) {
                    f.geometry = finishPolygon(f.geometry);
                }
                return f;
            })
            props.res(importedFeatures )
        }
        else {
            props.rej({
                message: "Missing shape file",
                type: ActionTypes.ERROR,
            } as IAlert)
        }
    });
}