import { v4 as uuidv4 } from 'uuid';
import { ContextMenuOptions, contextMenuDefault } from "./contextMenuOptions";
import { LayerInfo, LayerMetaData, LayerStyling } from "./layerInfo";
import { MapMouseEvents } from "./mapMouseEvents";
import { Feature } from 'standard/geojson/@types';
import { Tools } from 'standard/leaflet/enums';
import { MapFormatting, MapFormattings } from '../map/mapFormat';

export class MapRefStatus {
	public contextMenuOptions: ContextMenuOptions = contextMenuDefault;
	public lineStyling: { [key: string]: LayerStyling } = ({});
	public formattings: Record<string, MapFormatting> = ({});
	public featureLayers: { [key: string]: LayerMetaData } = ({});
	public mouseEvents: MapMouseEvents = (new MapMouseEvents());
	public highlightedLayers: Record<string, LayerMetaData> = ({});
	public highlightIntervalId: any | undefined = (undefined);
	public highlightVisible: boolean = (true);
	public selectedLayers: Record<string, LayerMetaData> = ({});
	public overrideLayer: LayerMetaData | undefined = (undefined);
	public filtered: string[]  = ([]);
	public edgePoints: LayerInfo[] = ([]);
	public iconSize: number = 1
	public markerSize: number = 20
	public drawFeature?: Feature
	public isEditable: boolean = true
	public activeTools: Set<Tools> = new Set();
	private renderVersion: string = uuidv4();

	hasSelection() : boolean { 
		return Object.keys(this.selectedLayers).length > 0;
	}

	isSelected(uuid : string) : boolean { 
		return Object.keys(this.selectedLayers).includes(uuid);
	}

	selected() : string[] { 
		return Object.keys(this.selectedLayers);
	}

	updateRenderVersion() {
		this.renderVersion = uuidv4()
	}

	getRenderVersion(): string {
		return this.renderVersion
	}
}
