import { Feature, Geometry } from "standard/geojson/@types";
import { POLYGON, LINE_STRING, POINT } from "../constants/variableName"
import { IDxf } from "dxf-parser";
import { distance2Line } from "./util";
import proj4 from "proj4";
import { readBinaryFile } from "./fileReaders/bin";
import { readTextFile } from "./fileReaders/txt";

proj4.defs("EPSG:31467","+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +nadgrids=BETA2007.gsb +units=m +no_defs +type=crs");
// var fs = require('fs');
// const buffer = fs.readFileSync( "../../../importTemplates/datapreview/BETA2007.gsb").buffer
// proj4.nadgrid('BETA2007', buffer);
        

export const readGeoFiles = async (files: any, zip?: boolean) => {
    let shpBuffer, prjBuffer, dbfBuffer;
    let shpFileName, prjFileName, dbfFileName;

    for (let file of files) {
        let fileType = file.name.split('.').pop().toLowerCase();
        if (fileType === "shp") {
            shpFileName = file.name.split('.').slice(0, -1).join('.')
            if (zip === true) {
                shpBuffer = await file.async('uint8array');
            } else {
                shpBuffer = await readBinaryFile(file);
            }

        }
        else if (fileType === "prj") {
            prjFileName = file.name.split('.').slice(0, -1).join('.')
            if (zip === true) {
                prjBuffer = await file.async('text');
            } else {
                prjBuffer = await readTextFile(file);
            }
        }
        else if (fileType === "dbf") {
            dbfFileName = file.name.split('.').slice(0, -1).join('.')
            if (zip === true) {
                dbfBuffer = await file.async('uint8array');
            } else {
                dbfBuffer = await readBinaryFile(file);
            }
        }
    }
    return { shpBuffer, prjBuffer, dbfBuffer, shpFileName, prjFileName, dbfFileName };
}

export const reProjection = (feature: Feature, from?: string, to?: string) => {
    if (feature.geometry.type === POINT) {
        if (from && to) {
            feature.geometry.coordinates = proj4(from, to).forward([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
        } else {
            feature.geometry.coordinates = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
        }
    } else if (feature.geometry.type === LINE_STRING) {
        let newCoords : any[] = [];
        for (let point of feature.geometry.coordinates) {
            if (from && to) {
                newCoords.push(proj4(from, to).forward([point[0], point[1]]));
            } else {
                newCoords.push([point[1], point[0]]);
            }
        }
        feature.geometry.coordinates = newCoords;
    } else if (feature.geometry.type === POLYGON) {
        let newPolygon : any[] = [];
        for (let polygon of feature.geometry.coordinates) {
            let newPolyCoords : any[] = []
            for (let point of polygon) {
                if (from && to) {
                    newPolyCoords.push(proj4(from, to).forward([point[0], point[1]]));
                } else {
                    newPolyCoords.push([point[1], point[0]]);
                }
            }
            newPolygon.push(newPolyCoords);
        };
        feature.geometry.coordinates = newPolygon;
    }
    return feature;
}

export const readDxfFile = (dxf : IDxf, loadLayers: string[]) => {
    let importingFeatures : Feature[] = [];
    if(dxf){
        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxX = Number.MIN_SAFE_INTEGER;
        let maxY = Number.MIN_SAFE_INTEGER;

        let entities = dxf.entities.filter((entity:any)=>(loadLayers.indexOf(entity.layer.trim()) > -1 ));


        entities.map((entity:any)=>{
            let entityType = entity.type.toUpperCase();
            if(entityType === "LINE" || entityType === "LWPOLYLINE"){
                entity.vertices.map((coord:any)=>{
                    minY = (coord.y < minY ? coord.y : minY)
                    minX = (coord.x < minX ? coord.x : minX)
                    maxX = (coord.x > maxX ? coord.x : maxX)
                    maxY = (coord.y > maxY ? coord.y : maxY)
                })
            }else if (entityType==="POINT"){
                minY = (entity.position.y < minY ? entity.position.y : minY)
                minX = (entity.position.x < minX ? entity.position.x : minX)
                maxX = (entity.position.x > maxX ? entity.position.x : maxX)
                maxY = (entity.position.y > maxY ? entity.position.y : maxY)
            }else if(entityType === "TEXT"){
                minY = (entity.startPoint.y < minY ? entity.startPoint.y : minY)
                minX = (entity.startPoint.x < minX ? entity.startPoint.x : minX)
                maxX = (entity.startPoint.x > maxX ? entity.startPoint.x : maxX)
                maxY = (entity.startPoint.y > maxY ? entity.startPoint.y : maxY)
            }else if(entityType === "MTEXT"){
                minY = (entity.position.y < minY ? entity.position.y : minY)
                minX = (entity.position.x < minX ? entity.position.x : minX)
                maxX = (entity.position.x > maxX ? entity.position.x : maxX)
                maxY = (entity.position.y > maxY ? entity.position.y : maxY)
            }
        });

        entities.map((entity:any, index: number)=>{            
            let entityType = entity.type.toUpperCase();
            if((entityType === "LINE" || entityType === "LWPOLYLINE")){
                let geo: Geometry = {
                    type: LINE_STRING,
                    coordinates: []
                };
                entity.vertices.map((coord:any)=>{
                    geo.coordinates.push([(coord.x - minX)/(maxX-minX), (coord.y - minY)/(maxY-minY)]);
                    // geo.coordinates.push([coord.x, coord.y])
                })
                importingFeatures.push({type: "Feature", geometry: geo})
            }else if (entityType==="POINT"){
                let geo: Geometry = {
                    type: POINT,
                    coordinates: []
                };
                      geo.coordinates = [(entity.position.x - minX)/(maxX - minX), (entity.position.y - minY)/(maxY-minY)];
                // geo.coordinates= [entity.position.x, entity.position.y]
                importingFeatures.push({type: "Feature", geometry: geo})
            }
 
        });

        entities.map((entity:any, index: number)=>{
            let entityType  = entity.type.toUpperCase();
            if(entityType === "TEXT" || entityType === "MTEXT"){  
                let minDistance = Number.MAX_SAFE_INTEGER;
                let featureIndex = -1;
                for(let i=0; i<importingFeatures.length; i++){
                    let f = importingFeatures[i];
                    if(f.geometry.type === LINE_STRING){
                        let dis;
                        if(entityType === "TEXT"){
                            dis = distance2Line(f.geometry.coordinates, [(entity.startPoint.x-minX)/(maxX-minX), (entity.startPoint.y-minY)/(maxY-minY)]);
                            // dis = distance2Line(f.geometry.coordinates, [entity.startPoint.x, entity.startPoint.y]);
                        }else{
                            dis = distance2Line(f.geometry.coordinates, [(entity.position.x-minX)/(maxX-minX), (entity.position.y-minY)/(maxY-minY)]);
                            // dis = distance2Line(f.geometry.coordinates, [entity.position.x, entity.position.y]);
                        }
                        if( dis < minDistance){
                            minDistance = dis;
                            featureIndex = i;
                        }
                    }
                }
                if(featureIndex > -1){
                    let propKey = `${entityType}_${index}`;
                    importingFeatures[featureIndex].properties = {};
                    importingFeatures[featureIndex].properties![propKey] = entity.text;    
                }
                    
                // }
            }
            // }
        })
    }
    return importingFeatures;
}







