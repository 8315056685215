import { IDomainModel } from "../interfaces"

export const domainmodels = () : any[] => {
    return [
    {
        "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
        "uri": "http://localhost:86/ontology/network",
        "labels": {
          "default": "Network",
          "en": "Network"
        },
        "comments": {},
        "imports": [
          "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
        ],
        "type": 1,
        "isprivate": false,
        "classes": [
          {
            "uuid": "onc-3434ba19-2ab3-4421-846c-cc3445ca92c3",
            "labels": {
              "default": "Bend",
              "en": "Bend"
            },
            "comments": {},
            "CreatedAt": 1687330472,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Bend",
            "Iri": "bend",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-7f329c21-0e3c-4f3d-bc90-5f7e3af18d7f",
            "labels": {
              "default": "Barb",
              "en": "Barb"
            },
            "comments": {},
            "CreatedAt": 1687330446,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Barb",
            "Iri": "barb",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-ee9dde7d-d79c-4eae-b3ab-08276f3bce2d",
            "labels": {
              "default": "Pump",
              "en": "Pump"
            },
            "comments": {},
            "CreatedAt": 1687269308,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Pump",
            "Iri": "pump",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-ee3171e7-b6d6-46f8-9485-aae9861a867a",
            "labels": {
              "default": "Wye",
              "en": "Wye"
            },
            "comments": {},
            "CreatedAt": 1687330652,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Wye",
            "Iri": "wye",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-4ebcda85-1364-474a-8b50-4072c3a624f5",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "labels": {
              "default": "Junction",
              "en": "Junction"
            },
            "comments": {},
            "CreatedAt": 1687269386,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Junction",
            "Iri": "junction",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-8fe4f0ae-8ee6-4571-9597-dc260cf5661c",
            "labels": {
              "default": "Plug",
              "en": "Plug"
            },
            "comments": {},
            "CreatedAt": 1687330039,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Plug",
            "Iri": "plug",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b7699434-e0c1-4327-b20c-fd978b43f35f",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-6e14698d-0387-45ac-90e9-9f88832dada6",
            "labels": {
              "default": "Source",
              "en": "Source"
            },
            "comments": {},
            "CreatedAt": 1677585313,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Source",
            "Iri": "source",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1677583433,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-0ea50263-f4d9-45c2-9052-017ec6bf908b",
            "labels": {
              "default": "Union",
              "en": "Union"
            },
            "comments": {},
            "CreatedAt": 1687330375,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Union",
            "Iri": "union",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-59d3a4d2-0086-46b5-b0dc-3a19f7f369fd",
            "labels": {
              "default": "Latrolet",
              "en": "Latrolet"
            },
            "comments": {},
            "CreatedAt": 1687330547,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Latrolet",
            "Iri": "latrolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-052668be-8278-4753-a315-3015cbd32947",
            "labels": {
              "default": "Pressure Reductor",
              "en": "Pressure Reductor"
            },
            "comments": {},
            "CreatedAt": 1687269330,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Pressure Reductor",
            "Iri": "pressurereductor",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-8f070452-6565-467b-ba50-7d7e724e6028",
            "labels": {
              "default": "Outlet",
              "en": "Outlet"
            },
            "comments": {},
            "CreatedAt": 1687330809,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Outlet",
            "Iri": "outlet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-0e4f6818-bc42-4d1b-b14f-6cc2afc42fed",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "labels": {
              "default": "Node",
              "en": "Node"
            },
            "comments": {},
            "CreatedAt": 1677585266,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Node",
            "Iri": "node",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1688968471,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "staging.pipepredict.com/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-eaeb2dd9-0169-4c18-a6b1-262074f3c0e9",
            "labels": {
              "default": "Coupling",
              "en": "Coupling"
            },
            "comments": {},
            "CreatedAt": 1687330362,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Coupling",
            "Iri": "coupling",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-c861f924-96c1-46cc-b9c9-a568bd49c91c",
            "labels": {
              "default": "Inlet",
              "en": "Inlet"
            },
            "comments": {},
            "CreatedAt": 1687273942,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Inlet",
            "Iri": "inlet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-f64cda98-15e7-41e1-9121-8fe7baa64d80",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-b7699434-e0c1-4327-b20c-fd978b43f35f",
            "labels": {
              "default": "End",
              "en": "End"
            },
            "comments": {},
            "CreatedAt": 1687269344,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "End",
            "Iri": "end",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-21dd0d13-08a4-4eae-aff6-0cbb12640ac1",
            "labels": {
              "default": "Sweepolet",
              "en": "Sweepolet"
            },
            "comments": {},
            "CreatedAt": 1687330578,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Sweepolet",
            "Iri": "sweepolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-a9c0a0ab-751c-4206-bf85-2a5d21dedb86",
            "labels": {
              "default": "Reservoir",
              "en": "Reservoir"
            },
            "comments": {},
            "CreatedAt": 1687274095,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Reservoir",
            "Iri": "reservoir",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-137f1e23-bbdf-489b-b8a4-8bac219b7d02",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-6d33a42b-fb33-4eb8-b873-14438f755f8e",
            "labels": {
              "default": "Nipolet",
              "en": "Nipolet"
            },
            "comments": {},
            "CreatedAt": 1687330566,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Nipolet",
            "Iri": "nipolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-d287f2fd-539e-490e-8186-140074c7abbe",
            "labels": {
              "default": "Diverter Tee",
              "en": "Diverter Tee"
            },
            "comments": {},
            "CreatedAt": 1687330702,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Diverter Tee",
            "Iri": "divertertee",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-4ebcda85-1364-474a-8b50-4072c3a624f5",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-97c5acdb-c1e3-4b18-851c-a39f36e6c5fc",
            "labels": {
              "default": "Cap and Plug",
              "en": "Cap and Plug"
            },
            "comments": {},
            "CreatedAt": 1687330423,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Cap and Plug",
            "Iri": "capandplug",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-2df5b4aa-b2e0-4fc4-a501-beb5119390a8",
            "labels": {
              "default": "Geometry",
              "en": "Geometry"
            },
            "comments": {
              "en": "Geometry Data"
            },
            "CreatedAt": 1688992008,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Geometry",
            "Iri": "geometry",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1688968471,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "staging.pipepredict.com/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-4bfeb9a5-942e-40a6-aaf9-2b7fb513f8e2",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-cb56939e-5607-4cbf-a7d0-779ef382fea0",
            "labels": {
              "default": "Heat Transfer Station",
              "en": "Heat Transfer Station"
            },
            "comments": {},
            "CreatedAt": 1687330120,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Heat Transfer Station",
            "Iri": "heattransferstation",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-2b9dd8c6-eb44-4cff-80e7-52fce67573cc",
            "labels": {
              "default": "Nipple",
              "en": "Nipple"
            },
            "comments": {},
            "CreatedAt": 1687330410,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Nipple",
            "Iri": "nipple",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-bfa1795b-32ec-4060-8dd7-750fe0b5f0e5",
            "labels": {
              "default": "Drain",
              "en": "Drain"
            },
            "comments": {},
            "CreatedAt": 1687330834,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Drain",
            "Iri": "drain",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-6cf40637-c991-4d33-b93b-bb75482c02c0",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-4ebcda85-1364-474a-8b50-4072c3a624f5",
            "labels": {
              "default": "Intersection",
              "en": "Intersection"
            },
            "comments": {},
            "CreatedAt": 1687269419,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Intersection",
            "Iri": "intersection",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-0547763c-8852-43fc-ac1c-fd0771aba86a",
            "labels": {
              "default": "Butterfly Valve",
              "en": "Butterfly Valve"
            },
            "comments": {},
            "CreatedAt": 1687274488,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Butterfly Valve",
            "Iri": "butterflyvalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-2e7437e0-c636-40fb-827d-badd9e7534a7",
            "labels": {
              "default": "Sockolet",
              "en": "Sockolet"
            },
            "comments": {},
            "CreatedAt": 1687330534,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Sockolet",
            "Iri": "sockolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-47797af6-7ff4-4634-8f8e-83075088f148",
            "labels": {
              "default": "Flange",
              "en": "Flange"
            },
            "comments": {},
            "CreatedAt": 1687330400,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Flange",
            "Iri": "flange",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-6e98bda3-bfee-49e0-b7bd-708ee02e414a",
            "labels": {
              "default": "Pipe",
              "en": "Pipe"
            },
            "comments": {},
            "CreatedAt": 1677585375,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Pipe",
            "Iri": "pipe",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1677583433,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f",
            "labels": {
              "default": "NetworkEntity",
              "en": "NetworkEntity"
            },
            "comments": {},
            "CreatedAt": 1688969912,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "NetworkEntity",
            "Iri": "networkentity",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1688968471,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "staging.pipepredict.com/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3701967-3eff-4080-96c0-dd5dff5e1c27",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-833dd1fe-ceb1-40cb-b80c-a2a92cf47cc5",
            "labels": {
              "default": "Plug Valve",
              "en": "Plug Valve"
            },
            "comments": {},
            "CreatedAt": 1687274500,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Plug Valve",
            "Iri": "plugvalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-f64cda98-15e7-41e1-9121-8fe7baa64d80",
            "labels": {
              "default": "Swell",
              "en": "Swell"
            },
            "comments": {},
            "CreatedAt": 1687269456,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Swell",
            "Iri": "swell",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-682ec6f8-1fe6-4cda-a8d5-62443598e68d",
            "labels": {
              "default": "Tee",
              "en": "Tee"
            },
            "comments": {},
            "CreatedAt": 1687330635,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Tee",
            "Iri": "tee",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-4ebcda85-1364-474a-8b50-4072c3a624f5",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-99972b6d-4055-42b1-a0af-792bf4895d2c",
            "labels": {
              "default": "Sink",
              "en": "Sink"
            },
            "comments": {},
            "CreatedAt": 1677585327,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Sink",
            "Iri": "sink",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1677583433,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-aac4babb-758f-459f-98cd-e3666b24e80a",
            "labels": {
              "default": "Adapter",
              "en": "Adapter"
            },
            "comments": {},
            "CreatedAt": 1687330385,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Adapter",
            "Iri": "adapter",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-3f47c607-16fd-4b45-a6e0-dd15e2901135",
            "labels": {
              "default": "Vent",
              "en": "Vent"
            },
            "comments": {},
            "CreatedAt": 1687330844,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Vent",
            "Iri": "vent",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-6cf40637-c991-4d33-b93b-bb75482c02c0",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-4d3ff54b-b6af-45bb-bf30-9b0117d2daa3",
            "labels": {
              "default": "Return",
              "en": "Return"
            },
            "comments": {},
            "CreatedAt": 1677585399,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Return",
            "Iri": "return",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1677583433,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-6e98bda3-bfee-49e0-b7bd-708ee02e414a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-0e4f6818-bc42-4d1b-b14f-6cc2afc42fed",
            "labels": {
              "default": "Consumers",
              "en": "Consumers"
            },
            "comments": {},
            "CreatedAt": 1687330719,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Consumers",
            "Iri": "consumers",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-99972b6d-4055-42b1-a0af-792bf4895d2c",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-afdb041b-b0ea-4401-bb69-c0a1954d1ad9",
            "labels": {
              "default": "Fredolet",
              "en": "Fredolet"
            },
            "comments": {},
            "CreatedAt": 1687330517,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Fredolet",
            "Iri": "fredolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-d460c6e1-75c2-4fac-b258-87c688d50818",
            "labels": {
              "default": "Check Valve",
              "en": "Check Valve"
            },
            "comments": {},
            "CreatedAt": 1687274519,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Check Valve",
            "Iri": "checkvalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-97cdda0b-ab1c-475a-b2b7-1e67def9fcab",
            "labels": {
              "default": "Cement",
              "en": "Cement"
            },
            "comments": {},
            "CreatedAt": 1687330048,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Cement",
            "Iri": "cement",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b7699434-e0c1-4327-b20c-fd978b43f35f",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-f0ddb2d3-8263-4256-8009-476151e6d5a2",
            "labels": {
              "default": "Gate Valve",
              "en": "Gate Valve"
            },
            "comments": {},
            "CreatedAt": 1688369092,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Gate Valve",
            "Iri": "gatevalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687437244,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-e175a22c-0df5-4313-b73a-ac7f31f7a1f0",
            "labels": {
              "default": "Elbow",
              "en": "Elbow"
            },
            "comments": {},
            "CreatedAt": 1687330353,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Elbow",
            "Iri": "elbow",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-43990a0b-c2e9-4da6-9155-bf49a4d43111",
            "labels": {
              "default": "Shut-off Valve",
              "en": "Shut-off Valve"
            },
            "comments": {},
            "CreatedAt": 1688369109,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Shut-off Valve",
            "Iri": "shutoffvalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1687437244,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-a19cfa45-d0e3-4b31-a8c4-7f8b2862db92",
            "labels": {
              "default": "End Client",
              "en": "End Client"
            },
            "comments": {},
            "CreatedAt": 1687330795,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "End Client",
            "Iri": "endclient",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-0e4f6818-bc42-4d1b-b14f-6cc2afc42fed",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-e87f0cee-9df4-46ae-b313-27d06b7f7bb5",
            "labels": {
              "default": "Hydrant",
              "en": "Hydrant"
            },
            "comments": {},
            "CreatedAt": 1687330854,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Hydrant",
            "Iri": "hydrant",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-6cf40637-c991-4d33-b93b-bb75482c02c0",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-ebb50ce1-27d2-4f6f-9732-32517e0e56ee",
            "labels": {
              "default": "Cross",
              "en": "Cross"
            },
            "comments": {},
            "CreatedAt": 1687330644,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Cross",
            "Iri": "cross",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-4ebcda85-1364-474a-8b50-4072c3a624f5",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-06550758-1734-40e1-814d-6a7e7f9a50c7",
            "labels": {
              "default": "Elevated Tank",
              "en": "Elevated Tank"
            },
            "comments": {},
            "CreatedAt": 1687274012,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Elevated Tank",
            "Iri": "elevatedtank",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-137f1e23-bbdf-489b-b8a4-8bac219b7d02",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-6cf40637-c991-4d33-b93b-bb75482c02c0",
            "labels": {
              "default": "Extraction Point",
              "en": "Extraction Point"
            },
            "comments": {},
            "CreatedAt": 1687330779,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Extraction Point",
            "Iri": "extractionpoint",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-99972b6d-4055-42b1-a0af-792bf4895d2c",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-37225a9b-6f40-4e87-b411-ccae654afc4a",
            "labels": {
              "default": "Weldolet",
              "en": "Weldolet"
            },
            "comments": {},
            "CreatedAt": 1687330507,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Weldolet",
            "Iri": "weldolet",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "labels": {
              "default": "Valve",
              "en": "Valve"
            },
            "comments": {},
            "CreatedAt": 1687269293,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Valve",
            "Iri": "valve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b3782556-4e09-482a-a30b-584018d45ed1",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
            "labels": {
              "default": "Edge",
              "en": "Edge"
            },
            "comments": {},
            "CreatedAt": 1677585256,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Edge",
            "Iri": "edge",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1688968471,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "staging.pipepredict.com/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662",
                "dom-7a7de324-e48b-4f68-bb16-beba7a8c0c69"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-bb42fca3-afc5-42db-aef2-633d17cdf01a",
            "labels": {
              "default": "Ball Valve",
              "en": "Ball Valve"
            },
            "comments": {},
            "CreatedAt": 1687274134,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Ball Valve",
            "Iri": "ballvalve",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-b8461d43-64e2-4bf8-9d09-7484ece058fa",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-95f6f655-f2cf-4c96-b50a-49e7d1cd4930",
            "labels": {
              "default": "Direct Client",
              "en": "Direct Client"
            },
            "comments": {},
            "CreatedAt": 1687330823,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Direct Client",
            "Iri": "directclient",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-0e4f6818-bc42-4d1b-b14f-6cc2afc42fed",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-cc10cb78-1bd0-45f2-9e3d-7c82edc8a520",
            "labels": {
              "default": "Junction 45 Degree",
              "en": "Junction 45 Degree"
            },
            "comments": {},
            "CreatedAt": 1687330606,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Junction 45 Degree",
            "Iri": "junction45degree",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-d904afaa-714a-4dd4-bfe6-dc20e53f2f60",
            "labels": {
              "default": "Bushing",
              "en": "Bushing"
            },
            "comments": {},
            "CreatedAt": 1687330436,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Bushing",
            "Iri": "bushing",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-6cebd2f0-19ad-4058-ae83-b46b87dba97c",
            "labels": {
              "default": "Supply",
              "en": "Supply"
            },
            "comments": {},
            "CreatedAt": 1677585386,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Supply",
            "Iri": "supply",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1677583433,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-6e98bda3-bfee-49e0-b7bd-708ee02e414a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-7e3ea8f3-0994-40c7-85aa-a94b6cdf883e",
            "labels": {
              "default": "Water Tower",
              "en": "Water Tower"
            },
            "comments": {},
            "CreatedAt": 1687274053,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Water Tower",
            "Iri": "watertower",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-137f1e23-bbdf-489b-b8a4-8bac219b7d02",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-137f1e23-bbdf-489b-b8a4-8bac219b7d02",
            "labels": {
              "default": "Tank",
              "en": "Tank"
            },
            "comments": {},
            "CreatedAt": 1687273960,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Tank",
            "Iri": "tank",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-f64cda98-15e7-41e1-9121-8fe7baa64d80",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          },
          {
            "uuid": "onc-35a66ac4-245e-4adb-bc66-0beab256be6b",
            "labels": {
              "default": "Reducer",
              "en": "Reducer"
            },
            "comments": {},
            "CreatedAt": 1687330459,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "Reducer",
            "Iri": "reducer",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": {
              "uuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
              "labels": {
                "default": "Network",
                "en": "Network"
              },
              "comments": {},
              "CreatedAt": 0,
              "UpdatedAt": 1682017080,
              "deletedAt": 0,
              "name": "Network",
              "scopes": {
                "institution": "institution_iJQMqWOspA"
              },
              "uri": "http://pipepredict.com/ontology/institution_ijqmqwospa/network",
              "isprivate": false,
              "type": 1,
              "imports": [
                "dom-533430c1-3cc7-453f-ac95-5627e7f58662"
              ],
              "individuals": undefined,
              "classes": undefined,
              "objProps": undefined,
              "dataProps": undefined
            },
            "isSubClassOf": "onc-c684958c-204e-4cf1-9ba8-e760d30c5a6a",
            "IsSubClassOfLabel": "",
            "ParentClass": undefined
          }
        ],
        "objProps": [
          {
            "uuid": "obj-50ca1f84-b0bd-46f2-b4bd-ea1bed562295",
            "labels": {
              "default": "hasIncomingConnection",
              "en": "hasIncomingConnection"
            },
            "comments": {},
            "CreatedAt": 1688968451,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "hasIncomingConnection",
            "iri": "hasincomingconnection",
            "domain": [
              "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f"
            ],
            "range": [
              "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-35ff05f8-59b1-4120-9c9a-2e758a6faaca",
            "labels": {
              "default": "hasOutgoingConnection",
              "en": "hasOutgoingConnection"
            },
            "comments": {},
            "CreatedAt": 1688968471,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "hasOutgoingConnection",
            "iri": "hasoutgoingconnection",
            "domain": [
              "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f"
            ],
            "range": [
              "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined,
            "isInverseFunctional": false
          },
          {
            "uuid": "obj-b3a032fa-17f8-4065-929b-9a84860b0892",
            "labels": {
              "default": "hasGeometryProperty",
              "en": "hasGeometryProperty"
            },
            "comments": {},
            "CreatedAt": 1688993294,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "hasGeometryProperty",
            "iri": "hasgeometryproperty",
            "domain": [
              "onc-80dd3a1a-91f3-4210-8a82-d98e9c79558f"
            ],
            "range": [
              "onc-2df5b4aa-b2e0-4fc4-a501-beb5119390a8"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined,
            "isInverseFunctional": false
          }
        ],
        "dataProps": [
          {
            "uuid": "dat-336fd7a8-f46b-421d-bc74-f6a1a51eda1f",
            "labels": {
              "default": "houseNumber",
              "en": "houseNumber"
            },
            "comments": {},
            "CreatedAt": 1677589465,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "houseNumber",
            "iri": "housenumber",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-d5c58242-6d8d-43d2-8133-757f172136e6",
            "labels": {
              "default": "heightDelta",
              "en": "heightDelta"
            },
            "comments": {
              "en": "height of one end - height of other end in meters"
            },
            "CreatedAt": 1682016576,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "heightDelta",
            "iri": "heightdelta",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "labels": {
              "default": "networkComponentProperty",
              "en": "networkComponentProperty"
            },
            "comments": {},
            "CreatedAt": 1688963649,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "networkComponentProperty",
            "iri": "networkcomponentproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-ea55b08c-bdca-4d57-9f6c-e0f424278be3",
            "labels": {
              "default": "diameter",
              "en": "diameter"
            },
            "comments": {},
            "CreatedAt": 1677586254,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "diameter",
            "iri": "diameter",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-c159defa-92e0-4263-8eee-2e1be1e503bd",
            "labels": {
              "default": "nominalOutflow",
              "en": "nominalOutflow"
            },
            "comments": {},
            "CreatedAt": 1677589679,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nominalOutflow",
            "iri": "nominaloutflow",
            "domain": [
              "onc-99972b6d-4055-42b1-a0af-792bf4895d2c"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-07410f43-a5cf-41cb-a20c-cde227b42c7a",
            "labels": {
              "default": "coordinates",
              "en": "coordinates"
            },
            "comments": {},
            "CreatedAt": 1688992142,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "coordinates",
            "iri": "coordinates",
            "domain": [
              "onc-2df5b4aa-b2e0-4fc4-a501-beb5119390a8"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-52a81815-91b2-4ca3-9daa-f8b9c2d1b537",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-5ca400ca-35c6-4678-abe1-fe628f67cf92",
            "labels": {
              "default": "length",
              "en": "length"
            },
            "comments": {},
            "CreatedAt": 1677586029,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "length",
            "iri": "length",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-dbdaa783-f579-412a-b9f3-41ee0cf84563",
            "labels": {
              "default": "nominalPressure",
              "en": "nominalPressure"
            },
            "comments": {},
            "CreatedAt": 1677589133,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nominalPressure",
            "iri": "nominalpressure",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-a7dc9f7a-86c8-40ca-8464-e8f9fc5ce46e",
            "labels": {
              "default": "nominalPressureHead",
              "en": "nominalPressureHead"
            },
            "comments": {},
            "CreatedAt": 1677589348,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nominalPressureHead",
            "iri": "nominalpressurehead",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-3ac33c95-4ae4-47da-8308-8dd0b19d905c",
            "labels": {
              "default": "nominalResistance",
              "en": "nominalResistance"
            },
            "comments": {},
            "CreatedAt": 1677589184,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nominalResistance",
            "iri": "nominalresistance",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
              "onc-99972b6d-4055-42b1-a0af-792bf4895d2c"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c159defa-92e0-4263-8eee-2e1be1e503bd",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-257f6241-1f11-446c-894e-9cd0861a575e",
            "labels": {
              "default": "material",
              "en": "material"
            },
            "comments": {},
            "CreatedAt": 1677585965,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "material",
            "iri": "material",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-dee23b3b-c51e-4d16-aee8-7e3578cf9b13",
            "labels": {
              "default": "minorHeadResistance",
              "en": "minorHeadResistance"
            },
            "comments": {},
            "CreatedAt": 1677589515,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "minorHeadResistance",
            "iri": "minorheadresistance",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-5e027630-697f-490e-8103-5dae633cd67c",
            "labels": {
              "default": "wallThickness",
              "en": "wallThickness"
            },
            "comments": {},
            "CreatedAt": 1677586728,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "wallThickness",
            "iri": "wallthickness",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-61d63efa-0ee7-43b6-9545-9e25a48f7dc9",
            "labels": {
              "default": "id",
              "en": "id"
            },
            "comments": {},
            "CreatedAt": 1677585876,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "id",
            "iri": "id",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
              "onc-b3782556-4e09-482a-a30b-584018d45ed1"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-69bfd223-b729-4026-b849-416ca80fdb3e",
            "labels": {
              "default": "height",
              "en": "height"
            },
            "comments": {
              "en": "over NN in meters"
            },
            "CreatedAt": 1682016955,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "height",
            "iri": "height",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-81189e58-d21e-435d-a966-477b4cfc154e",
            "labels": {
              "default": "element_type",
              "en": "element_type"
            },
            "comments": {
              "en": "Supply or Return"
            },
            "CreatedAt": 1682016886,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "element_type",
            "iri": "elementtype",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "labels": {
              "default": "nodeProperty",
              "en": "nodeProperty"
            },
            "comments": {},
            "CreatedAt": 1688963769,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nodeProperty",
            "iri": "nodeproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-49c3a926-c8c2-4fe5-992a-5742fdd6f5ea",
            "labels": {
              "default": "nominalFlow",
              "en": "nominalFlow"
            },
            "comments": {},
            "CreatedAt": 1677589162,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "nominalFlow",
            "iri": "nominalflow",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-4eb25212-4e6b-4114-b63f-3ed80a6382e0",
            "labels": {
              "default": "model",
              "en": "model"
            },
            "comments": {},
            "CreatedAt": 1677589410,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "model",
            "iri": "model",
            "domain": [
              "onc-b3782556-4e09-482a-a30b-584018d45ed1"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-a17fa432-f75e-4650-8c72-8fcdaab1a5f1",
            "labels": {
              "default": "minorAbsorptionCoef",
              "en": "minorAbsorptionCoef"
            },
            "comments": {},
            "CreatedAt": 1677589557,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "minorAbsorptionCoef",
            "iri": "minorabsorptioncoef",
            "domain": [
              "onc-6e14698d-0387-45ac-90e9-9f88832dada6"
            ],
            "range": [
              "onc-9ea3da31-052d-4d59-9135-41304ff1805e"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-d6dbf859-eb7b-4176-8f05-ea2d4c43ef2c",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-c13b6ebe-9c77-4ea3-b96c-b5419b915b0a",
            "labels": {
              "default": "edgeProperty",
              "en": "edgeProperty"
            },
            "comments": {},
            "CreatedAt": 1688963732,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "edgeProperty",
            "iri": "edgeproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-52a81815-91b2-4ca3-9daa-f8b9c2d1b537",
            "labels": {
              "default": "geometryProperty",
              "en": "geometryProperty"
            },
            "comments": {},
            "CreatedAt": 1688992088,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "geometryProperty",
            "iri": "geometryproperty",
            "domain": [],
            "range": [],
            "isFunctional": false,
            "isSubPropertyOf": "dat-10394d93-3628-45ec-9ba9-88b947520f03",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-43e9a129-2157-4d0c-801d-b9dad955528b",
            "labels": {
              "default": "type",
              "en": "type"
            },
            "comments": {},
            "CreatedAt": 1688992127,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "type",
            "iri": "type",
            "domain": [
              "onc-2df5b4aa-b2e0-4fc4-a501-beb5119390a8"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-52a81815-91b2-4ca3-9daa-f8b9c2d1b537",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          },
          {
            "uuid": "dat-6b8dae8b-6faf-4fda-97d5-135c1e7708e0",
            "labels": {
              "default": "belongsToDMA",
              "en": "belongsToDMA"
            },
            "comments": {},
            "CreatedAt": 1677586477,
            "UpdatedAt": 1691491633,
            "deletedAt": 0,
            "name": "belongsToDMA",
            "iri": "belongstodma",
            "domain": [
              "onc-a15aade0-43a9-4dba-8abc-4b5ba51a15a9",
              "onc-b3782556-4e09-482a-a30b-584018d45ed1"
            ],
            "range": [
              "onc-a0ab09d8-6fcb-46c1-8738-bfed6fe4a9e5"
            ],
            "isFunctional": false,
            "isSubPropertyOf": "dat-1c19ded7-e232-47a4-8722-eddfd5ad42d9",
            "OntologyUuid": "dom-cd4bf1f6-4507-4924-9e0f-ec4def624672",
            "Ontology": undefined
          }
        ],
        "individuals": [],
        "scopes": {
          "institution": "institution_iJQMqWOspA"
        }
      }
    
]}