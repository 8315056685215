import { Fragment, useState, useEffect, useCallback } from 'react';
import { ILayer } from './interfaces/ILayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff, faTrash } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from 'standard/autocomplete/autocomplete';
import { fitText } from 'components/utils/string';

const editTime : number = 10000

const LayerItem = (props: {
        item : ILayer,
        selected : boolean
        valueSuggestions: string[]
        onSelected: (item: ILayer, value: boolean) => any
        onNameChange: (item: ILayer, newName: string) => any
        onDeleted: (item : ILayer) => void
    }) => {
        
        
    let [selected, setSelected] = useState<boolean>(false);    
    let [isEditting, setIsEditting] = useState<boolean>(false); 
    let [name, setName] = useState<string>(props.item.name); 
    let [resetTimeout, setResetTimeout] = useState<any>(); 
        

    useEffect(() => {
   
    }, [props.item])

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    useEffect(() => {      

    }, [selected])


    useEffect(() => {      
        clearTimeout(resetTimeout);
        setResetTimeout(setTimeout(((e) => editCompleteRequest(e)), editTime))
    }, [name])

    const editCompleteRequest = useCallback((e: any) => {
        clearTimeout(resetTimeout);
        setIsEditting(false)
        if (props.item.name !== name){
            props.onNameChange(props.item, name)
        }
    }, [resetTimeout])

    useEffect(() => {      
        
    }, [isEditting, editCompleteRequest])

    const onCheck = (e: any) => {
        setSelected(true)
        props.onSelected(props.item, true)
    }  
    
    const onUncheck = (e: any) => {
        setSelected(false)
        props.onSelected(props.item, false)
    }  

    const editRequest = (e: any) => {
        if (!isEditting){
            setResetTimeout(setTimeout(((e) => editCompleteRequest(e)), editTime))
            setIsEditting(true)
        }
    }

    const onChange = (value : string) => {
        setName(value)
        clearTimeout(resetTimeout);
        setResetTimeout(setTimeout(editCompleteRequest, editTime))      
    }

    return (
        <Fragment>            
            <div className="columns mt-0 mb-0 pt-0 pl-1 smallText" >
                <div className={`column is-1`}>
                    {!selected || <FontAwesomeIcon className='checkbox has-text-success' icon={faToggleOn} onClick={(e : any) => onUncheck(e)}  />}
                    {selected || <FontAwesomeIcon className='checkbox has-text-dark' icon={faToggleOff} onClick={(e : any) => onCheck(e)}  />}    
                </div>
                <div className="column is-success edittable" >
                    { isEditting ||<span onClick={(e : any) => editRequest(e)} title={name}>{fitText(name, 12)} ({props.item.count})</span>}
                    {!isEditting || <Autocomplete value={name} suggestions={props.valueSuggestions} onChange={onChange} onBlur={editCompleteRequest} showNoSuggestions={false}/>}
                </div> 
                <div className="column clickable is-2 has-text-danger mb-0 pb-1 mt-0 pt-0">
                    <span className='is-pulled-right'><FontAwesomeIcon  icon={faTrash} onClick={() => props.onDeleted(props.item)} /></span>
                </div>
            </div>
            <hr className="m-0 p-0"/>
        </Fragment>
    )
}

export default LayerItem;