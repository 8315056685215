import { IAlert, ActionTypes } from "standard/alert";
import { Mapping } from "components/dataTools/datapreview/types/uploads";

export const useExportTemplate = () => {
    async function exportTemplate(fileName : string, mapping : Mapping): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                    JSON.stringify(mapping)
                )}`;
                const link = document.createElement("a");
                link.href = jsonString;
                link.download = fileName;
                link.click();
                res({
                    message: "Template exported to file",
                    type: ActionTypes.SUCCESS,
                } as IAlert)
            } catch (error: any) {
                rej({
                    message: `Unable to export geo data to file ${error}`,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })
        return promise
    }

    return { exportTemplate};
}