import { useState, useEffect } from 'react';
import MappingEdit from './mappingedit'
import '../dataList.css'
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'standard/forms/inputs'
import { IDomainModel } from 'standard/ontology/interfaces';
import { ConversionMapping } from 'standard/datatools/@types';
import { Upload_Meta } from '../../types/uploads';
import {  ConversionMappings } from 'standard/datatools/@types/IMappingTemplate';
import { default as ValueConverted } from './mapping';
import ModalDialog from 'standard/dialog/modaldialog';
import { Inputs } from './inputs';
import { Feature } from 'standard/geojson/@types';

const MapperList = (props: {
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    onMappingChange: (mappings: ConversionMappings) => void
}) => {
    const [showAddNew, setShowAddNew] = useState<boolean>(false);

    useEffect(() => {
        
    }, [props.upload])

    useEffect(() => {

    }, [props.upload.mapping])

    useEffect(() => {

    }, [props.targetModel])


    const onAddNew = () => {
        setShowAddNew(true)
    }

    const onCloseDialog = () => {
        setShowAddNew(false)
    }

    const handleAddNew = (newinputs: { [key: string]: Input }) => {      
        let conversionMapping : ConversionMapping = Object.entries(newinputs).reduce((obj, [key, value]) => {
              obj[key] = value.value;
            return obj;
        }, {} as ConversionMapping);

        conversionMapping.id = uuidv4()

        let conversionMappings: ConversionMappings = {}
        if (props.upload.mapping.template && props.upload.mapping.template.conversionMappings) {
            conversionMappings = {...props.upload.mapping.template.conversionMappings}
        }

        conversionMappings[conversionMapping.id] = conversionMapping
        props.onMappingChange(conversionMappings)
        setShowAddNew(false)
    }

    const handleEdit = (id : string, newinputs: { [key: string]: Input }) => {
        let conversionMapping : ConversionMapping = Object.entries(newinputs).reduce((obj, [key, value]) => {
            obj[key] = value.value;
          return obj;
        }, {} as ConversionMapping);

        conversionMapping.id = id

        let conversionMappings: ConversionMappings = {}
        if (props.upload.mapping.template && props.upload.mapping.template.conversionMappings) {
            conversionMappings = {...props.upload.mapping.template.conversionMappings}
        }
        conversionMappings[id] = conversionMapping
        props.onMappingChange(conversionMappings)        
    }

    const onDelete = (id: string) => {
        if (props.upload.mapping.template && props.upload.mapping.template.conversionMappings) {
            let conversionMappings: ConversionMappings = {}
            conversionMappings = {...props.upload.mapping.template.conversionMappings}
            delete conversionMappings[id]
            props.onMappingChange(conversionMappings)
        }
    }

    const createHeaders = () => {
        return Object.values(Inputs()).map((value) => {return <div key={value.name} className="column is-2 list-header-cell cell p-0 pl-1 pr-1 m-0">
            {value.label}
        </div>})
      }
  
      const content = () => {
        return !showAddNew || <MappingEdit 
        title='New Value Mapping'                            
        upload={props.upload} features={props.features} targetModel={props.targetModel} isEditMode={false} onAdd={handleAddNew} onCancel={onCloseDialog} />
      } 

    return (
        <>
            <div className='ml-5 mr-5  smallText is-centered'>
                <div className="columns list-header mt-1 mb-0 pb-0 has-text-weight-bold is-centered is-vcentered">
                    {createHeaders()}                    
                    <div className="column is-1 m-0 p-0 pb-1 clickable">
                        <button className="card-header-icon button is-primary" aria-label="more options" onClick={onAddNew}>
                            Create New
                        </button>
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                    </div>
                </div>
                <hr className="navbar-divider m-0 p-0" />
                {(props.upload.mapping.template && props.upload.mapping.template.conversionMappings) && Object.keys(props.upload.mapping.template.conversionMappings).map((key) => {
                    return <ValueConverted upload={props.upload} features={props.features} targetModel={props.targetModel} key={key} id={key} onEdit={handleEdit} onDelete={onDelete} />
                })}

            </div>
            <ModalDialog title='New Mappping' display={showAddNew}
                content={content()}
            />
        </>
    )
}

export default MapperList;