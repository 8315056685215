import { useEffect, useState } from "react"
import { getKeyValues } from "./utils";
import Filter from "standard/filter/filter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import RangeList from "standard/rangelist/rangeList";
import { TokenizedMessage } from "standard/utils/tokenizedMessage";
import { FilterType, IEmptyFilter, IRangeFilter, IStringFilter } from "standard/filter/@types/filter";

const PropertyFilter = (props: {
    data: ({ [k: string]: any; } | undefined)[]
    property: string,
    onFilter: (filter: any) => void
    onDeleteFilter: (id: string) => void
    onDeletePropertyFilters: (property: string) => void
    onCancel: () => void
    filters?: Record<string, any>
}) => {
    const [propertyValues, setPropertyValues] = useState<string[]>();
    const [filter, setFilter] = useState<any>();
    const [filters, setFilters] = useState<any[]>();
    const [editFilter, setEditFilter] = useState<any>();
    const [filterId, setFilterId] = useState<string>(uuidv4());
    const [input, setInput] = useState<TokenizedMessage>();

    useEffect(() => {
        setPropertyValues(getKeyValues(props.data, props.property))
    }, [props.data, props.property]);

    useEffect(() => {

    }, [propertyValues]);

    useEffect(() => {

    }, [editFilter]);

    useEffect(() => {

    }, [input]);

    useEffect(() => {

    }, [filterId]);

    useEffect(() => {
        if(props.filters){
            let f : any | undefined = Object.values(props.filters).filter((f: any) => { return f.property === props.property })
            if (f.length === 0){
                f = undefined;
            }
            setFilters(f)
        }else{
            setFilters(undefined)
        }
    }, [props.filters, props.property]);

    useEffect(() => {
        // setFilter(filter)
        // if (filter) {            
        //     if (filter.type === FilterType.STRING){ 
        //     }else if (filter.type === FilterType.RANGE){ 
        //     }
        // }
        
    }, [filter]);

    const doFilter = () => {        
        if (!filter) return
        props.onFilter(filter);
        setFilterId(uuidv4())
        setEditFilter(undefined)
    }

    const deleteFilter = (id: string) => {
        props.onDeleteFilter(id)
    }

    const clearFilters = () => {
        props.onDeletePropertyFilters(props.property)
    }

    const clear = () => {
        setEditFilter(undefined)
    }

    const showEmptyFilter = (row: IEmptyFilter) => {
        return <>Empty</>
    }

    const showStringExactFilter = (row: IStringFilter) => {
        return <>Exact '{row.value}'</>
    }

    const showStringContainsFilter = (row: IStringFilter) => {
        return <>Contains '{row.value}'</>
    }

    const showStringExcludeFilter = (row: IStringFilter) => {
        return <>Exclude '{row.value}'</>
    }

    const showNumericRangeFilter = (row: IRangeFilter) => {
        return <> Include `{row.fromValue} to {row.toValue}`</>
    }

    const onValueSelected = (value: string) => {
        setInput(new TokenizedMessage(value))
    }


    const showCurrentFilters = () => {
        if (!filters) {
            return <></>
        }
        return <div className="card m-1 p-1">
            <header className="card-header mt-0 pt-0 mb-0 pb-0">
                <p className="card-header-title">
                    Existing filter
                </p>
                <button className="card-header-icon p-0" aria-label="more options">
                    <span className='button is-danger is-inverted m-0 pr-5 pl-5' title={`Clear all ${props.property} filters`}><FontAwesomeIcon icon={faTrash} onClick={event => clearFilters()} /></span>
                </button>
            </header>
            <div className="card-content p-0 m-0">
                {filters && filters.map((row, idx) => {                    
                    return <div key={idx} className={`columns mt-1 m-0 p-0 ml-1 is-vcentered ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`}>
                        <div className="column is-three-quarters p-0 m-0 ml-2" onClick={() => setEditFilter(row)}>                            
                            {!(row.type === FilterType.STRINGCONTAINS) || showStringContainsFilter(row)}
                            {!(row.type === FilterType.STRINGEXACT) || showStringExactFilter(row)}
                            {!(row.type === FilterType.STRINGEXCLUDE) || showStringExcludeFilter(row)}
                            {!(row.type === FilterType.NUMERICRANGE) || showNumericRangeFilter(row)}
                            {!(row.type === FilterType.ISEMPTY) || showEmptyFilter(row)}
                        </div>
                        <div className="column  p-0 m-0" onClick={event => deleteFilter(row.id)}>
                            <span className='button is-danger is-inverted m-0 pl-5 pr-5' ><FontAwesomeIcon icon={faTrash}  /></span>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    return (
        <div className="card m-0 p-0 no-border">
            <header className="card-header mt-0 pt-0 mb-0 pb-0">
                <p className="card-header-title">
                    Property : {props.property}
                </p>
            </header>
            <div className="card-content p-1 m-0">
                <div className="columns smallText m-0 p-0">
                    <div className="column">
                        <div className="columns mt-1 mb-0 pb-0 is-centered ">
                            <div className="column m-0 p-0 mb-1">
                                <Filter property={props.property} onFilter={setFilter} filterId={filterId} filter={editFilter} input={input}/>
                            </div>
                        </div>
                        <div className="columns is-centered m-0 p-0">
                            <div className="column is-4 m-0 p-0">
                                <div className="columns mt-1 mb-0 pb-0 is-centered">
                                    <div className="column m-0 p-0 mr-2">
                                        <button className="button is-primary" aria-label="more options" onClick={doFilter}>
                                            Add Filter
                                        </button>
                                    </div>
                                    {!editFilter || <div className="column m-0 p-0 mr-2">
                                        <button className="button is-primary" aria-label="more options" onClick={clear}>
                                            Clear
                                        </button>
                                    </div>}
                                    <div className="column m-0 p-0 mr-2">
                                        <button className="button is-primary" aria-label="more options" onClick={props.onCancel}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {filters &&
                        <div className="column ml-5 is-half">
                            {showCurrentFilters()}
                        </div>
                    }
                </div>
                <div className="columns is-centered">
                    <div className="column">
                        {
                            propertyValues && <RangeList title='Range Values' data={propertyValues} onValueSelected={onValueSelected}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PropertyFilter