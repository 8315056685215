import { useEffect, useState, useRef } from "react"
import { useAppSelector } from 'hooks/redux'
import { IAlert, ActionTypes } from "standard/alert";
import * as FormInputs from 'standard/forms/inputs'
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from "standard/ontology/interfaces";
import { IColumnMapping } from "standard/datatools/@types";
import { InputType } from "standard/forms/enums";
import { Upload_Meta } from "../../types/uploads";
import { getPropertyKeys, getPropertyValues } from "standard/geojson/utils";
import { Inputs } from "./inputs";
import * as ontologyUtils from "standard/ontology/domainModel/utils/display"
import { Feature } from "standard/geojson/@types";
import FormBuilder from "standard/forms/formBuilder";
import { IInput } from "standard/forms/interfaces";

const MappingEdit = (props: {
    title: string
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    referencedClasses: IDomainModelClass[]
    referencedDataProps: IDomainModelProperty[]    
    mapping?: IColumnMapping
    onAdd?: (inputs: { [key: string]: FormInputs.Input }) => void
    onEdit?: (id: string, inputs: { [key: string]: FormInputs.Input }) => void
    onCancel: () => void
    isEditMode: boolean
    alert?: IAlert
}) => {
    const [inputs, setInputs] = useState<{ [key: string]: FormInputs.Input }>({});
    const [alerts, setAlerts] = useState<{ [key: string]: IAlert }>({});
    const [selection, setSelection] = useState<{ [key: string]: { value: string; label: string; } }>({});
    const [options, setOptions] = useState<{ [key: string]: { value: string; label: string; }[] }>({});
    const valueParentValue = useRef<string>()

    useEffect(() => {

    }, []);

    useEffect(() => {
        let newInputs = Object.assign({}, Inputs());
        if (props.mapping) {
            for (var i in newInputs) {
                let input: FormInputs.Input = newInputs[i];
                input.value = props.mapping![i as keyof IColumnMapping]
            }
        }

        setInputs(newInputs)
    }, [props.mapping]);

    useEffect(() => {
        let updatedSelection = { ...selection }
        let updated: boolean = false
        for (var i in inputs) {
            let input: FormInputs.Input = inputs[i];
            if ((input.type === InputType.AUTOCOMPLETE || input.type === InputType.OPTION) && options.hasOwnProperty(input.name)) {
                const inputSelection = options[input.name].find((o: { value: string; label: string; }) => o.value === inputs[input.name].value)
                if (inputSelection) {
                    updatedSelection = { ...updatedSelection, [input.name]: inputSelection }
                    updated = true

                    if (input.name === 'input' && valueParentValue.current !== input.value) {
                        valueParentValue.current = input.value
                        setValueOptions(input, input.value)
                    }
                }
            }
        }
        if (updated) {
            setSelection(updatedSelection)
        }
    }, [options, inputs]);

    useEffect(() => {
        if (!props.upload) {
            return
        }
        if (!props.targetModel) {
            return
        }
        let newOpts = {}

        if (props.features) {
            let propertyKeys: string[] = getPropertyKeys(props.features)
            newOpts["input"] = propertyKeys.map((item) => { return { value: item, label: item } });
        }

        if (props.referencedDataProps) {
            let refs = [...props.referencedDataProps]
            refs = refs.sort((a, b) => {                
                if (a.Ontology.uuid! === props.targetModel.uuid! && b.Ontology.uuid! !== props.targetModel.uuid!) return -1;
                if (b.Ontology.uuid! === props.targetModel.uuid! && a.Ontology.uuid! !== props.targetModel.uuid!) return 1;
                if (a.Ontology.uuid! !== b.Ontology.uuid!){
                    return a.Ontology.labels.default.localeCompare(b.Ontology.labels.default)
                }
                return a.labels.default.localeCompare(b.labels.default)
            });
            newOpts["mapping"] = refs.map((item) => { return { value: item.labels.default, label: `${item.labels.default} ${ontologyUtils.labels(props.referencedClasses, item.domain)} - ${item.Ontology.labels.default}` } });
        }
        setOptions({ ...options, ...newOpts })
    }, [props.targetModel, props.upload]);

    useEffect(() => {

    }, [inputs]);

    useEffect(() => {

    }, [alerts]);

    useEffect(() => {


    }, [selection]);


    const addEdit = () => {
        let isValid = validate()
        if (!isValid) return
        if (!props.isEditMode) {
            props.onAdd!(inputs)
        }
        else {
            props.onEdit!(props.mapping!.id, inputs)
        }
    }

    const removeAlert = (name: string) => {
        let clone = Object.assign({}, alerts);
        delete clone[name];
        setAlerts(clone);
    };


    const setValueOptions = (input: FormInputs.Input, property: string) => {
        let newOpts = {}
        let propertyKeys: string[] = getPropertyValues(props.features, property)
        Object.values(inputs).forEach(i => {
            if (i.parent && i.parent === input.name) {
                newOpts[i.name] = propertyKeys.map((item) => { return { value: item, label: item } });
            }
        })
        setOptions({ ...options, ...newOpts })
    };

    const isValid = (input: IInput): boolean => {
        if (!input.required) return true
        if (input !== undefined) {
            if (Array.isArray(input.value)) {
                if (input.value.length > 0) {
                    return true
                }
            } else if (input.value.trim() !== '') return true
        }

        return false
    }

    const validate = (): boolean => {
        let newAlerts: { [key: string]: IAlert } = {}
        let isValid = true
        let toValidate: IInput[] = Object.values(inputs)

        for (var thisInput of toValidate) {
            if (!thisInput.required) continue
            if (thisInput !== undefined) {
                if (Array.isArray(thisInput.value)) {
                    if (thisInput.value.length > 0) {
                        continue
                    }
                } else if (thisInput.value.trim() !== '') continue
            }
            newAlerts[thisInput.name] = {
                message: "missing value!",
                type: ActionTypes.ERROR,
            } as IAlert

            isValid = false
        }

        setAlerts(newAlerts)

        return isValid

    }

    const onValueChanged = (input: FormInputs.Input, value: any) => {
        let inputClone = { ...input }
        inputClone.value = value
        let newInputs = { ...inputs, [inputClone.name]: inputClone }
        newInputs = FormInputs.clearChildren(newInputs, inputClone.name)
        setInputs(newInputs);
        if (inputClone.name === 'input') {
            setValueOptions(input, inputClone.value)
        }

        if (alerts[inputClone.name]) {
            if (isValid(inputClone)) {
                removeAlert(inputClone.name)
            }
        }
    }

    return (
        <>
            <div className='columns dialog-header '>
                <div className='column mt-3'>
                    <p className="header-label">{props.title}</p>
                </div>
                <div className='column'>
                    <div className="dialog-buttons">
                        <div className="dialog-button-right">
                            <button className=" button is-primary ml-1 mr-1" aria-label="more options" onClick={addEdit}>
                                {props.isEditMode === false ? 'Add' : 'Update'}
                            </button>
                            <button className="button is-primary ml-1 mr-1" aria-label="more options" onClick={props.onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-scroll">
                <FormBuilder inputs={inputs} options={options} alerts={alerts} onValueChanged={onValueChanged} />
            </div>
        </>
    )
}

export default MappingEdit