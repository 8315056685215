import BaseStructure from "./basestructure"
import { IDomainModel, IDomainModelClass, IIndividual, IDomainModelProperty } from "../interfaces"
import { ILabelComment } from '../localization/interfaces';

export function newDomainModel(uri : string): DomainModel {
  let iDM: IDomainModel = {
    uri: uri,
    uuid: undefined,
    labels: {},
    comments: {},
    imports: [],
    type: 1,
    isprivate: false,
    scopes: {},
  }

  return new DomainModel(iDM)
}

export class DomainModel extends BaseStructure implements IDomainModel, ILabelComment {
  public uri: string
  public isprivate: boolean
  public type: number
  public imports: string[] | undefined
  public classes?: IDomainModelClass[]   
  public dataProps?: IDomainModelProperty[]
  public objProps?: IDomainModelProperty[]
  public individuals?: IIndividual[] 
  public scopes: { [key: string]: string }

  constructor(i : IDomainModel) {
    super( i.labels, i.comments, i.uuid);
    this.uri = i.uri
    this.isprivate = i.isprivate
    this.type = i.type
    this.imports = i.imports
    this.classes = i.classes
    this.dataProps = i.dataProps
    this.objProps = i.objProps
    this.individuals = i.individuals
    this.scopes = i.scopes
  }

  getClass(label : string) : IDomainModelClass | undefined {
    return this.classes &&  this.classes!.find(item => {
      return item.labels.default === label
    })
  }

  getDataProperty(label : string) : IDomainModelProperty | undefined {    
    return this.dataProps && this.dataProps!.find(item => {
      return item.labels.default === label
    })
  }

  updateClass(withClass : IDomainModelClass){
    let updatingClasses: IDomainModelClass[];
    if (this.classes) {
        if (withClass.uuid !== undefined){
            this.classes = this.classes.filter(prop => prop.uuid === withClass.uuid!)
        }
        updatingClasses = [...this.classes, withClass]
    } else {
        updatingClasses = [withClass];
    }
    this.classes = updatingClasses 
  }

  updateDataProperty(withDataProperty : IDomainModelProperty){
    let update: IDomainModelProperty[];
    if (this.dataProps) {
        if (withDataProperty.uuid !== undefined){
            this.dataProps = this.dataProps.filter(prop => prop.uuid === withDataProperty.uuid!)
        }
        update = [...this.dataProps, withDataProperty]
    } else {
        update = [withDataProperty];
    }
    this.dataProps = update 
  }

  updateObjectProperty(withObjectProperty : IDomainModelProperty){
    let update: IDomainModelProperty[];
    if (this.objProps) {
        if (withObjectProperty.uuid !== undefined){
            this.objProps = this.objProps.filter(prop => prop.uuid === withObjectProperty.uuid!)
        }
        update = [...this.objProps, withObjectProperty]
    } else {
        update = [withObjectProperty];
    }
    this.objProps = update 
  }

  updateIndividual(withIndividual : IIndividual){
    let update: IIndividual[];
    if (this.individuals) {
        if (withIndividual.uuid !== undefined){
            this.individuals = this.individuals.filter(prop => prop.uuid === withIndividual.uuid!)
        }
        update = [...this.individuals, withIndividual]
    } else {
        update = [withIndividual];
    }
    this.individuals = update 
  }

}

export const removeClass = (domainModel : IDomainModel,  id : string) : IDomainModel =>{
  if (domainModel.classes) {
    domainModel.classes = domainModel.classes.filter(prop => prop.uuid !== id)
  }
  return domainModel
}

export const addIndividuals = (domainModel : DomainModel,  withIndividual : IIndividual) : DomainModel =>{
  let update: IIndividual[];
  if (domainModel.individuals) {
      if (withIndividual.uuid !== undefined){
        domainModel.individuals = domainModel.individuals.filter(prop => prop.uuid !== withIndividual.uuid!)
      }
      update = [...domainModel.individuals, withIndividual]
  } else {
      update = [withIndividual];
  }
  domainModel.individuals = update 
  
  return domainModel
}

export const removeIndividual = (domainModel : IDomainModel,  id : string) : IDomainModel =>{
  if (domainModel.individuals) {
    domainModel.individuals = domainModel.individuals.filter(prop => prop.uuid !== id)
  }
  return domainModel
}

export const addObjectProperty = (domainModel : IDomainModel,  withObjectProperty : IDomainModelProperty) : IDomainModel =>{
  let update: IDomainModelProperty[];
  if (domainModel.objProps) {
      if (withObjectProperty.uuid){
        domainModel.objProps = domainModel.objProps.filter(prop => prop.uuid !== withObjectProperty.uuid!)
      }
      update = [...domainModel.objProps, withObjectProperty as IDomainModelProperty]
  } else {
      update = [withObjectProperty];
  }
  domainModel.objProps = update 
  
  return domainModel
}

export const removeObjectProperty = (domainModel : IDomainModel,  id : string) : IDomainModel =>{
  if (domainModel.objProps) {
    domainModel.objProps = domainModel.objProps.filter(prop => prop.uuid !== id)
  }
  return domainModel
}

export const addDataProperty = (domainModel : IDomainModel,  withDataProperty : IDomainModelProperty) : IDomainModel => {
  let update: IDomainModelProperty[];
  if (domainModel.dataProps) {
      if (withDataProperty.uuid){
        domainModel.dataProps = domainModel.dataProps.filter(prop => prop.uuid !== withDataProperty.uuid!)
      }
      update = [...domainModel.dataProps, withDataProperty]
  } else {
      update = [withDataProperty];
  }
  domainModel.dataProps = update 

  return domainModel
}

export const removeDataProperty = (domainModel : IDomainModel,  id : string) : IDomainModel =>{
  if (domainModel.dataProps) {
    domainModel.dataProps = domainModel.dataProps.filter(prop => prop.uuid !== id)
  }
  return domainModel
}