import { IDomainModel } from "standard/ontology/interfaces";
import { Feature, Geometry } from "standard/geojson/@types";
import { POLYGON, LINE_STRING, POINT } from "../constants/variableName"

export const castValue = (type: string, value: string) :any => {
    if (type === "string") {
        return String(value);
    } else if (type === "float") {
        return parseFloat(value);
    }
}

export const mergeTwoObject = (from: { [k: string]: any } | undefined, to: { [k: string]: any } | undefined) => {

    let mergedObj: { [k: string]: any } = {};
    if (from && to) {
        for (let p of Object.keys(from)) {
            if (p in to) {
                mergedObj[p] = from[p];
            }
        }
        for (let pp of Object.keys(to)) {
            if (!(pp in mergedObj)) {
                mergedObj[pp] = "";
            }
        }
    }
    return mergedObj;
}

export const getLastFeatureCoordinate = (feature: Feature) => {
    let coord: number[] = [0, 0];
    if (feature.geometry.type === POINT) {
        coord = feature.geometry.coordinates;
    } else if (feature.geometry.type === LINE_STRING) {
        coord = feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
    } else if (feature.geometry.type === POLYGON) {
        let lastPolygon = feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
        coord = lastPolygon[lastPolygon.length - 1];
    }
    return coord;
}

//Add first point as last point complete polygon points
export const finishPolygon = (polygon: Geometry) : Geometry => {
    let nbPolygon = polygon.coordinates.length;
    let previousPolygon = polygon.coordinates.slice(0, nbPolygon - 1);
    let lastPolygon: number[] = [];
    let checkClosed = distance(polygon.coordinates[nbPolygon-1][0], polygon.coordinates[nbPolygon-1][polygon.coordinates[nbPolygon-1].length-1]);
    if (polygon.coordinates[nbPolygon - 1].length >= 3) {
        if(checkClosed > 0.0000000001){
            lastPolygon = [...polygon.coordinates[nbPolygon - 1], polygon.coordinates[nbPolygon - 1][0]];
        }else{
            lastPolygon =  [...polygon.coordinates[nbPolygon - 1]];
        }
    }
    polygon = {
        ...polygon,
        coordinates: [...previousPolygon, lastPolygon],
    }
    return polygon;
}

export const isOverlap = (from: number[], to: number[], radius: number) => {
    let diff = Math.sqrt(Math.pow((from[0]-to[0]),2)+Math.pow((from[1]-to[1]), 2));
    return diff < radius;
}

export const distance = (coord1: number[], coord2: number[]) => {
    let distance = Math.sqrt(Math.pow((coord1[0] - coord2[0]),2) + Math.pow((coord1[1] - coord2[1]),2));
    return distance
}

export const getMiddlePoint = (coord1: number[], coord2: number[]) => {
    let mid0 = coord1[0]>coord2[0] ? (coord2[0] + (coord1[0]-coord2[0])/2) : (coord1[0] + (coord2[0]-coord1[0])/2);
    let mid1 = coord1[1]>coord2[1] ? (coord2[1] + (coord1[1]-coord2[1])/2) : (coord1[1] + (coord2[1]-coord1[1])/2);

    return [mid0, mid1];
}

export const distance2Line = (line: number[][], point: number[]) => {
    let [x1, y1] = line[0];
    let [x2, y2] = line[line.length-1];
    let [x0, y0] = point;
    return Math.abs((x2-x1)*(y1-y0) - (x1-x0)*(y2-y1))/Math.sqrt(Math.pow(x2-x1, 2) + Math.pow(y2-y1, 2))
}

export const getUUIDGeoFields = (items: IDomainModel[]) => {
    let geoPropsUUID: string | undefined;
    let geoCoordPropsUUID: string | undefined;
    let geoTypePropsUUID: string | undefined;
    for(let item of items){
        if(item.classes){
            for(let c of item.classes){
                if(c.labels.default === "GeometryProperty"){
                    geoPropsUUID = c.uuid;
                }
            }
        }
        if(item.dataProps){
            for(let d of item.dataProps){
                if(d.labels.default === "coordinates"){
                    geoCoordPropsUUID = d.uuid;
                }else if(d.labels.default === "type"){
                    geoTypePropsUUID = d.uuid;
                }
            }
        }
    }
    return {geoPropsUUID, geoCoordPropsUUID, geoTypePropsUUID}
}