import { actionTypes } from './actionsTypes';
import { IAuthentication } from './interfaces';
import { IAuthenticatedUser } from './interfaces/IAuthenticatedUser'
import { removeUserState, setUserState } from './userState/userState';

let user: IAuthenticatedUser | undefined

if (localStorage.getItem('user') !== null) {
  user = JSON.parse(localStorage.getItem('user')!);
}

const emptyState: IAuthentication = { loggedIn: false, loggedOut: false, loggingIn: true, logginfailed: false, refreshingtoken: false, refreshingtokenfailed: false, user: undefined, timedOut: false};
const loggedOutState: IAuthentication = { loggedIn: false, loggedOut: true, loggingIn: false, logginfailed: false, refreshingtoken: false, refreshingtokenfailed: false, user: undefined, timedOut:false };
const initialState: IAuthentication = user ? { loggedIn: true, loggedOut: false, loggingIn: false, logginfailed: false, refreshingtoken: false, refreshingtokenfailed: false, user : user, timedOut:false} : emptyState
const timedOutState: IAuthentication = {...loggedOutState, ...{timedOut : true} };

export function authentication(state = initialState, action: any): IAuthentication {
  const nextState = { ...state }
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.user))
      return {
        loggingIn: false,
        loggedOut: false,
        loggedIn: true,
        logginfailed: false,
        user: action.user,
        refreshingtoken: false,
        refreshingtokenfailed: false,
        timedOut: false
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedOut: false,
        loggedIn: false,
        logginfailed: true,
        user: undefined,
        refreshingtoken: false,
        refreshingtokenfailed: false,
        timedOut:false
      };
    case actionTypes.REFRESH_TOKEN_REQUEST:
      nextState.refreshingtoken = true
      return nextState
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.user))
      nextState.user = action.user
      nextState.refreshingtoken = false
      return nextState
    case actionTypes.REFRESH_TOKEN_FAILURE:
      nextState.refreshingtoken = false
      nextState.refreshingtokenfailed = true
      return nextState
    case actionTypes.LOGOUT:
      removeUserState(action.userId)
      localStorage.removeItem('user')
      return loggedOutState;
    case actionTypes.TIMEOUT:
      setUserState(action.userState)
      localStorage.removeItem('user')
      return timedOutState;
    default:
      return state
  }
}