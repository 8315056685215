import { CONNECTIONS_KEY, CONNECTION_KEY } from "components/utils/constants"
import { sha256 } from "js-sha256"
import { Feature } from "standard/geojson/@types"
import { createAddOnFeature, IAddOn, IAddOnInfo } from "./addOn"
import { IAddOns } from "./addOns"

export interface IConnectedTo {
    atPoint: number[]
    toPoint: number[]
    toUuid: string
    fromUuid: string
    radius: number //Pre-computed value for computation sake!
}

export class ConnectedTo implements IAddOn, IConnectedTo {
    public type: string
    public attachedTo: string
    public atPoint: number[]
    public toPoint: number[]
    public toUuid: string
    public fromUuid: string
    public radius: number
    constructor(attachedTo: string, c: IConnectedTo
    ) {
        this.attachedTo = attachedTo
        this.type = CONNECTION_KEY
        this.atPoint = c.atPoint
        this.toPoint = c.toPoint
        this.toUuid = c.toUuid
        this.fromUuid = c.fromUuid
        this.radius = c.radius
    }
}

const style : {[key : string] : any} = {
    color: '#fcd12a',
    // fillColor: '#4c5270',
    fillOpacity: 0,
    radius: 0.25,
    weight: 2
}

const selectedStyle : {[key : string] : any} = {
    color: '#676f98',
    // fillColor: '#676f98',
    fillOpacity: 0,
    radius: 0.55,
    weight: 3
}

export const createFeatures = (features: Feature[]): Feature[] => {
    let featureConnections: any[] = features.filter((f: Feature) => { return f.properties !== undefined && f.properties![CONNECTIONS_KEY] !== undefined }).map((f: Feature) => { return f.properties![CONNECTIONS_KEY] })
    let addOnFeatures: Feature[] = []
    let dict: { [key: string]: ConnectedTo } = {}
    for (var conns of featureConnections) {
        // for (const [key, value] of Object.entries(conns)) {
        //     let conn : ConnectedTo = value as ConnectedTo
        //     connectionFeatures.push(createFeature(key , CONNECTION_KEY, conn.atPoint, conn))
        // }
        for (const value of Object.values(conns)) {
            let conn: IConnectedTo = value as IConnectedTo
            let cInverted: ConnectedTo =  { fromUuid: conn.toUuid, toUuid: conn.fromUuid, atPoint: conn.toPoint, toPoint: conn.atPoint } as ConnectedTo
            let hashed: string = sha256(JSON.stringify(conn))
            let hashedInverted: string = sha256(JSON.stringify(cInverted))
            if (!dict.hasOwnProperty(hashed) && !dict.hasOwnProperty(hashedInverted)) {
                dict[hashed] = new ConnectedTo(conn.fromUuid, conn)
            }
        }
    }

    let info: IAddOnInfo = {
        // style: {
        //     color: '#000',
        //     fillColor: '#4c5270',
        //     fillOpacity: 0.7,
        //     radius: 0.5,
        //     weight: 2
        // },
        // selectedStyle: {
        //     color: '#808080',
        //     fillColor: '#676f98',
        //     fillOpacity: 1,
        //     radius: 1,
        //     weight: 3
        // }
    }

    addOnFeatures = Object.values(dict).map((c: ConnectedTo) => { return createAddOnFeature(sha256(JSON.stringify(c)), c.atPoint, c, info) })
    return addOnFeatures
}

export const createAddOns = (features: Feature[]): IAddOns => {
    return {features : createFeatures(features), style : style, selectedStyle : selectedStyle}
}
