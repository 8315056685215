import { actionTypes } from './actionsTypes';

export const configActions = {
    configCheckSuccess,
    configCheckFailure,
    configCheckRequest,
};

function configCheckSuccess() {
    return { type: actionTypes.CONFIG_CHECK_SUCCESS};
}

function configCheckFailure() {
    return { type: actionTypes.CONFIG_CHECK_FAILURE};
}

function configCheckRequest() {
    return { type: actionTypes.CONFIG_CHECK_REQUEST};
}
