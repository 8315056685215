import { useEffect, useState } from "react";
import Message from "./message";
import { ActionTypes } from "standard/alert/actionsTypes";
import { IAlert } from "../types";

const AlertMessage = (props: {
    alert? : IAlert,
    style? : string,
     actiontitle?: string,
     onAction?: () => any,}
     ) => {
    const [msg, setMsg] = useState<string>("");
    const [style, setStyle] = useState("");
    
    
    useEffect(() => {        
        if (props.alert !== undefined && props.alert.type !== undefined) {
            let propStyle = props.style || ''
            switch (props.alert.type) {
                case ActionTypes.SUCCESS:
                    setStyle(`${propStyle} is-primary`)
                    setMsg(props.alert.message)
                    break
                case ActionTypes.ERROR:
                    setMsg(props.alert.message)
                    setStyle(`${propStyle} is-danger`)
                    break
                case ActionTypes.ERROR_SERVER:
                    setMsg(props.alert.message)
                    setStyle(`${propStyle} is-danger`)
                    break
            }                    
        } else {
            setStyle("")
            setMsg("")
        }
    }, [props])

    useEffect(() => {

    }, [msg])
    
    return <>
        {
            props.alert && 
            <Message 
                title={props.alert.title} 
                message={msg} 
                style={style} 
                actiontitle={props.actiontitle}
                onAction={props.onAction}
            />
        }
    </>
    
    
    // 
};

export default AlertMessage;
