export const readBinaryFile = (file: any) => {
    return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            var arrayBuffer: ArrayBuffer = fileReader.result as ArrayBuffer;
            if (arrayBuffer) {
                resolve(Buffer.from(arrayBuffer));
            }
        }
        fileReader.readAsArrayBuffer(file);
    });
}