import { useEffect, useState, useRef } from "react"
import { useAppSelector } from 'hooks/redux'
import { IAlert, ActionTypes } from "standard/alert";
import { Multiselect, Text } from "components/standard";
import Autocomplete from 'standard/autocomplete/autocomplete';
import * as FormInputs from 'standard/forms/inputs'
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from "standard/ontology/interfaces";
import { IRowMapping } from "standard/datatools/@types";
import SelectBox from "standard/selectbox/SelectBox";
import { InputType } from "standard/forms/enums";
import { OnChangeValue } from 'react-select';
import { Upload_Meta } from "../../types/uploads";
import { getPropertyKeys, getPropertyValues } from "standard/geojson/utils";
import { Inputs } from "./inputs";
import { IOption, Options } from "standard/multiselect/@types/IOption";
import { Feature } from "standard/geojson/@types";
import { IInput } from "standard/forms/interfaces";

const MappingEdit = (props: {
    title: string
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    referencedClasses: IDomainModelClass[]
    mapping?: IRowMapping
    onAdd?: (inputs: { [key: string]: FormInputs.Input }) => void
    onEdit?: (id: string, inputs: { [key: string]: FormInputs.Input }) => void
    onCancel: () => void
    isEditMode: boolean
    alert?: IAlert
}) => {
    const [inputs, setInputs] = useState<{ [key: string]: FormInputs.Input }>({});
    const [alerts, setAlerts] = useState<{ [key: string]: IAlert }>({});
    const [alert, setAlert] = useState<IAlert>();
    const [selection, setSelection] = useState<{ [key: string]: { value: string; label: string; } }>({});
    const [targetModelProperty, setTargetModelProperty] = useState<IDomainModelProperty>();
    // const optionsRef = useRef<{ [key: string]: { value: string; label: string; }[] }>({});
    const [options, setOptions] = useState<{ [key: string]: IOption[] }>({});
    const valueParentValue = useRef<string>()

    useEffect(() => {

    }, []);

    useEffect(() => {
        let newInputs = Object.assign({}, Inputs());
        if (props.mapping) {
            for (var i in newInputs) {
                let input: FormInputs.Input = newInputs[i];
                input.value = props.mapping![i as keyof IRowMapping]
                // switch (input.type) {
                //     case InputType.MULTISELECT:
                //         input.value = {value : input.value, label : input.value}
                //         break;            
                //     default:
                //         break;
                // }   
            }
        }

        setInputs(newInputs)
    }, [props.mapping]);

    useEffect(() => {
        let updatedSelection = { ...selection }
        let updated: boolean = false
        for (var i in inputs) {
            let input: FormInputs.Input = inputs[i];
            switch (input.type) {
                case InputType.AUTOCOMPLETE:
                case InputType.OPTION:
                case InputType.MULTISELECT:
                    if (options.hasOwnProperty(input.name)) {
                        const inputSelection = options[input.name].find((o: { value: string; label: string; }) => o.value === inputs[input.name].value)
                        if (inputSelection) {
                            updatedSelection = { ...updatedSelection, [input.name]: inputSelection }
                            updated = true
        
                            if (input.name === 'input' && valueParentValue.current !== input.value) {
                                valueParentValue.current = input.value
                                setValueOptions(input.value)
                            }
                        }
                    }
                    break;            
                default:
                    break;
            }            
        }
        if (updated) {
            setSelection(updatedSelection)
        }
     }, [options, inputs]);


    useEffect(() => {
        if (targetModelProperty) {
            // let dt = new DigitalTwin(props.template)
            // let target = dt.getDataProperties(targetModelProperty!.uuid!)
            // if (target.length > 0) {
            //     getDomainModelWithUri(authenticated.user!.token, target[0], true)
            //         .then((item: IDomainModel) => {
            //             setTargetModel(item);
            //         })
            //         .catch((err) => {

            //         });
            // }        
        }
    }, [targetModelProperty]);

    // const updateOptions = (opts: { [key: string]: { value: string; label: string; }[] }) => {
    //     setOptions({ ...options, ...opts })
    //     //setInputs(setOptions(inputs, optionsRef.current))
    // }

    useEffect(() => {
        if (!props.upload) {
            return
        }
        if (!props.targetModel) {
            return
        }
        let newOpts = {}

        if (props.features) {
            let propertyKeys: string[] = getPropertyKeys(props.features)
            newOpts["input"] = propertyKeys.map((item) => { return { value: item, label: item } });
        }
        if (props.referencedClasses){
            let refs = [...props.referencedClasses]
            refs = refs.sort((a, b) => {                
                if (a.Ontology.uuid! === props.targetModel.uuid! && b.Ontology.uuid! !== props.targetModel.uuid!) return -1;
                if (b.Ontology.uuid! === props.targetModel.uuid! && a.Ontology.uuid! !== props.targetModel.uuid!) return 1;
                if (a.Ontology.uuid! !== b.Ontology.uuid!){
                    return a.Ontology.labels.default.localeCompare(b.Ontology.labels.default)
                }
                return a.labels.default.localeCompare(b.labels.default)
            });
            newOpts["mapping"] = refs.map((item) => { return { value: item.labels.default, label: `${item.labels.default} (${item.Ontology.labels.default})` } });
        }
        
        setOptions({ ...options, ...newOpts })
    }, [props.targetModel, props.upload]);

    useEffect(() => {
        setAlert(props.alert)
    }, [props.alert]);

    useEffect(() => {
        // if (!inputs) return
        // if (!inputs["mapping"]) return
        // if (!inputs["mapping"].value) return
        // if (!optionsRef.current.mapping) return
        // setSelection(optionsRef.current.mapping.find((o : { value: string; label: string; }) => o.value === inputs["mapping"].value))     
    }, [inputs]);

    useEffect(() => {


    }, [alerts]);

    useEffect(() => {

    }, [selection]);

    const isValid = (input: IInput): boolean => {
        if (!input.required) return true
        if (input !== undefined) {
            if (Array.isArray(input.value)) {
                if (input.value.length > 0) {
                    return true
                }
            } else if (input.value.trim() !== '') return true
        }

        return false
    }

    const validate = (): boolean => {
        let newAlerts: { [key: string]: IAlert } = {}
        let isValid = true
        let toValidate: IInput[] = Object.values(inputs)
        

        for (var thisInput of toValidate) {
            if (!thisInput.required) continue
            if (thisInput !== undefined) {
                if (Array.isArray(thisInput.value)) {
                    if (thisInput.value.length > 0) {
                        continue
                    }
                } else if (thisInput.value.trim() !== '') continue
            }
            newAlerts[thisInput.name] = {
                message: "missing value!",
                type: ActionTypes.ERROR,
            } as IAlert

            isValid = false
        }

        setAlerts(newAlerts)

        return isValid

    }

    const addEdit = () => {
        let isValid = validate()
        if (!isValid) return
        if (!props.isEditMode) {
            props.onAdd!(inputs)
        }
        else {
            props.onEdit!(props.mapping!.id, inputs)
        }
    }

    const onChangeInput = (input: FormInputs.Input, value: string) => {
        let inputClone = { ...input }
        inputClone.value = value
        setInputs(prevState => ({
            ...prevState,
            [inputClone.name]: inputClone
        }));
        if (value.trim() !== '') removeAlert(inputClone.name)
    }

    const onSelection = (input: FormInputs.Input, selections: Options) => {
        let inputClone = { ...input }
        inputClone.value = selections.map(v => v.value)
        setInputs(prevState => ({
            ...prevState,
            [inputClone.name]: inputClone
        }));
       
        if (selections.length !== 0) removeAlert(inputClone.name)
    }

    const removeAlert = (name: string) => {
        let clone = Object.assign({}, alerts);
        delete clone[name];
        setAlerts(clone);
    };

    const setValueOptions = (property: string) => {
        let newOpts = {}
        let propertyKeys: string[] = getPropertyValues(props.features, property)
        newOpts["value"] = propertyKeys.map((item) => { return { value: item, label: item } });
        newOpts["values"] = propertyKeys.map((item) => { return { value: item, label: item } });
        setOptions({ ...options, ...newOpts })
    };

    const onSelectionChange = (input: FormInputs.Input, newValue: OnChangeValue<any, false>) => {
        let inputClone = { ...input }
        inputClone.value = newValue && newValue.value
        if (inputClone.value === inputs[inputClone.name].value) {
            return
        }
        setSelection({ ...selection, [inputClone.name]: newValue })

        let newInputs = { ...inputs, [inputClone.name]: inputClone }
        newInputs = FormInputs.clearChildren(newInputs, inputClone.name)
        setInputs(newInputs);

        if (inputClone.name === 'input') {
            setValueOptions(inputClone.value)
        }
        if (newValue) removeAlert(inputClone.name)
    }

    const renderControlWrapper = (input: FormInputs.Input, idx: number) => {
        return <div className={`field ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`} key={input.name}>
            <label className="label smallText">{input.label}</label>
            <div className="control"> {renderControl(input)}</div>
            <hr className="navbar-divider" />
        </div>
    }

    const renderControl = (input: FormInputs.Input) => {
        switch (input.type) {
            case InputType.STRING:
                return <Text
                    id={input.name}
                    label={input.label}
                    inputOnly={true}
                    value={input.value}
                    onChange={(newValue) => onChangeInput(input, newValue)}
                    alert={alerts[input.name]}
                />;
            case InputType.OPTION:
                return <SelectBox
                    options={options[input.name]}
                    onChange={(newValue) => onSelectionChange(input, newValue)}
                    isMulti={false}
                    selected={input.value}
                    alert={alerts[input.name]}
                    style={{ text: 'smallText' }}
                />
            case InputType.AUTOCOMPLETE:
                let suggestions = options[input.name] && Object.values(options[input.name]).map(option => { return option.value })
                return <Autocomplete
                    value={input.value}
                    suggestions={suggestions}
                    onChange={(newValue) => onChangeInput(input, newValue)}
                    showNoSuggestions={false}
                    alert={alerts[input.name]}
                    style={{ text: 'smallText' }}
                />
            case InputType.MULTISELECT:
                let multiSelectSuggestions = options[input.name] && Object.values(options[input.name]).map(option => { return  {value :  option.value, label : option.label}})
                return  <Multiselect
                id={input.name}
                label=''
                options={multiSelectSuggestions} 
                alert={alerts[input.name]}
                selected={input.value.map(value => { return  {value :  value, label : value}})}
                onSelectionChanged={(options) => onSelection(input, options)}
                />

                
                // <Autocomplete
                //     value={input.value}
                //     suggestions={suggestions}
                //     onChange={(newValue) => onChangeInput(input, newValue)}
                //     showNoSuggestions={false}
                //     alert={alerts[input.name]}
                //     style={{ text: 'smallText' }}
                // />
            default:
                return 'Unhandled type' + input.type;
        }
    };

    return (
        <>  
            <div className='columns dialog-header '>
                <div className='column mt-3'>
                    <p className="header-label">{props.title}</p>
                </div>
                <div className='column'>
                    <div className="dialog-buttons">
                        <div className="dialog-button-right">
                            <button className=" button is-primary ml-1 mr-1" aria-label="more options" onClick={addEdit}>
                                {props.isEditMode === false ? 'Add' : 'Update'}
                            </button>
                            <button className="button is-primary ml-1 mr-1" aria-label="more options" onClick={props.onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-scroll">
                {
                    inputs && Object.values(inputs).map(
                        (i: FormInputs.Input, idx: number) => {
                            return renderControlWrapper(i, idx)

                        })
                }
            </div>        
        </>
    )
}


export default MappingEdit