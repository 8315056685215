import 'bulma/css/bulma.min.css';
import { useEffect } from 'react';

const Message = (props: {
  title? : string,
  message : string, 
  style? : string,
  actiontitle? : string,
  onAction?: () => any,
}) => {
  
  useEffect(() => {
   
  }, [props.message])

  const handleOnAction = (e: any) => {
    e.preventDefault();
    if (props.onAction){
      props.onAction()
    }
  } 

  return <>{props.message !== "" ? <article className={`message ${props.style}`}>
    {props.title && <div className="message-header">
      <p>{props.title}</p>
      <button className="delete" aria-label="delete"></button>
    </div>}
    <div className="message-body  mt-2 mb-1 pt-1 pb-1 pl-2"> 
      <div className='columns'>
        <div className='column'>
          {props.message}
        </div>
        {props.actiontitle && 
          <div className='column'>
            <button className="button is-ghost is-pulled-right is-small" onClick={(e) => handleOnAction(e)} >
              {props.actiontitle}
            </button> 
          </div>
        }
      </div>
    </div>    
  </article> : ""}
  </>
};

export default Message;
