import React from 'react';
import ReactDOM from "react-dom/client";
import L from 'leaflet';
import { LAYER_NAME_KEY, IS_ADD_ON_KEY, LayerInfo, GEOMETRY_INDEX } from './@types/layerInfo';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { DELETE_FEATURE } from './@types/actions';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { MapWrraper } from 'standard/leaflet/mapWrapper';


export const getMarkerFontAwesome = (size: SizeProp, color: string, iconDefinition : IconDefinition  ): L.DivIcon => {
	const iconNode = document.createElement('span');
	ReactDOM.createRoot(iconNode).render(
			<FontAwesomeIcon icon={iconDefinition} color={color} size={size}/>)
	
	const icon = L.divIcon({
		className: 'mapMarker',
		html: iconNode,
	});

	return icon;
}






