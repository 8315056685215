import { MapWrraper } from "./mapWrapper";
import { GEOMETRY_INDEX, IS_ADD_ON_KEY, LAYER_NAME_KEY, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import L from 'leaflet';
import { IMenuItem } from "standard/navigation/interfaces/IMenuItem";
import { DELETE_FEATURE } from "standard/geojsonMap/@types/actions";
import { LayerEvents } from "./enums";

declare module './mapWrapper' {
	interface MapWrraper {
		addNodes (
			features: any,
			layerKey: string,
			onLayerEvent : (key: string, event : LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void,
		): L.GeoJSON 

		addLayerContextMenuForNode (layer: L.Layer, setContextMenuOptionsInstance: (key: string) => void) : void	
	}
}

MapWrraper.prototype.addNodes = function (
	features: any,
	layerKey: string,
	onLayerEvent : (key: string, event : LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void,
): L.GeoJSON  {
	let info: LayerInfo = {
		[LAYER_NAME_KEY]: layerKey,
		[IS_ADD_ON_KEY]: false,
		[GEOMETRY_INDEX]: 0
	}
	const geoJSONLayer = L.geoJSON(features, {
		pointToLayer: (feature: any, latlng: any) => {
			return this.createPoint(layerKey, this.status.markerSize, feature, latlng);
		},
		onEachFeature: ((feature: any, layer: any) => this.onEachPointFeature( feature, layer, info, onLayerEvent, setContextMenuOptionsInstance, onMouseUp))
	})
	this.addLayerContextMenuForNode(geoJSONLayer, setContextMenuOptionsInstance)
	return geoJSONLayer
}

let nodeEditMenuItems: IMenuItem[] = [
	{ name: DELETE_FEATURE, label: "Delete Node", ordinality: 1, parent: undefined },
]

MapWrraper.prototype.addLayerContextMenuForNode = function (layer: L.Layer, setContextMenuOptionsInstance: (key: string) => void) {
	this.addLayerContextMenu(layer, 'Edit Element', nodeEditMenuItems, setContextMenuOptionsInstance);
}

export {}