import * as XLSX from "xlsx";

export const readXlsxFile = (file: any) => {
    return new Promise((resolve)=>{
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target?.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            resolve(wb);
        }
        reader.readAsBinaryString(file);
    })
}