import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import "./styles.css";

const RootMenuFlat = (props: {
    links : IMenuItem[]
    action : (item : IMenuItem) => void
}) => {
    const { links  } = props;
    const rootNodes = links && links.filter((node: IMenuItem) => node.parent === undefined)

    const node = (item: IMenuItem) => {
        const children = links.filter((node: IMenuItem) => node.parent === item.name)
        return  <li key={item.name} className='menu-item'>
                    <div className="menu-item-label" onClick={() => props.action(item)}>{item.label}</div>
                    <ul className='childrenMenu'>
                        {children && children.map((child: IMenuItem) =>
                            node(child)
                        )}  
                    </ul>             
                </li>
    }

    return <div id="menu" className="" >
            {rootNodes && rootNodes.map((item: IMenuItem) => 
                <ul key={item.name}>
                    {node(item)} 
                </ul>   
            )}        
    </div>
};

export default RootMenuFlat;