import { IDataImport } from "./IDataImport";
import { IDataImportStatus } from "./IDataImportStatus";

export function newDataImport(totalRecords : number): DataImport {
  let dataImport: IDataImport = {
    totalRecords : totalRecords,
    status : {
        numberOfImports : 0,
        mapped: 0, 
        recordsProcessed : 0,
        running : false,
        unmapped : []},
  }

  return new DataImport(dataImport)
}

export class DataImport implements IDataImport {
  public totalRecords : number
  public maxErrors? : number
  public status: IDataImportStatus
  public errors?: string[]

  constructor(i : IDataImport) {
    this.totalRecords = i.totalRecords
    this.maxErrors = i.maxErrors
    this.errors = i.errors
    this.status = i.status
  }

  // updateIndividual(withIndividual : IIndividual){
  //   let update: IIndividual[];
  //   if (this.individuals) {
  //       if (withIndividual.uuid !== undefined){
  //           this.individuals = this.individuals.filter(prop => prop.uuid === withIndividual.uuid!)
  //       }
  //       update = [...this.individuals, withIndividual]
  //   } else {
  //       update = [withIndividual];
  //   }
  //   this.individuals = update 
  // }

}

