import { useState, useEffect } from 'react';
import DataList from 'standard/list/dataList';
import { Feature } from 'standard/geojson/@types';
import { useAppSelector } from 'hooks/redux';
import { IOptions } from 'standard/forms/interfaces/IOption';

const FeaturesList = (props: {
    title : string
    features: Feature[] | undefined
    options?: IOptions
    columnSort? : (a : string, b :string) => number
}) => {
    const ui = useAppSelector(state => state.ui)
    const [featureProperties, setFeatureProperties] = useState<({ [k: string]: any; } | undefined)[]>();
    const [featuresColumns, setFeatureColumns] = useState<string[]>();

    useEffect(() => {
        if (featureProperties) { 
            let keys: Set<string> = new Set()
            featureProperties.forEach((item: ({ [k: string]: any } | undefined)) => {
                Object.keys(item!).forEach((key: string) => {
                    keys.add(key)
                });
            });
            setFeatureColumns(Array.from(keys).sort(props.columnSort ||  function (a, b) {                
                const x = a.toLowerCase();
                const y = b.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            }))
        }        
    }, [featureProperties])

    useEffect(() => {
        if (props.features) { 
            setFeatureProperties(Object.values(props.features).map((f) => { return f.properties }))
        }else {
            setFeatureProperties(undefined)
            setFeatureColumns(undefined)
        }
    }, [props.features])

    useEffect(() => {

    }, [ui.fullscreenenabled])

    return  <DataList
                title={props.title}
                isEdittable={false}
                columns={featuresColumns}
                data={featureProperties}
                options={props.options}
            />
}

export default FeaturesList;