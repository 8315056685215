import { useEffect, useState } from "react";
import { IPagination, getPagination } from "./pagination";

const Paginator = (props: { itemCount: number, pageSize: number, currentPage: number, onPageSelect: (page: number) => void }) => {
    const [currentPage, setCurrentPage] = useState<number>(props.currentPage);
    const [pageCount, setPageCount] = useState<number>();
    const [pagination, setPagination] = useState<IPagination>();

    useEffect(() => {
        if (pageCount) {
            setPagination(getPagination(pageCount, props.currentPage, 7))
        } else {
            setPageCount(undefined)
        }
    }, [props.currentPage, pageCount])

    useEffect(() => {

    }, [pagination])

    useEffect(() => {
        if (!(props.itemCount > 0)) {
            return
        }
        if (!(props.pageSize > 0)) {
            return
        }
        setPageCount(Math.ceil(props.itemCount / props.pageSize));
    }, [props.pageSize, props.itemCount])

    useEffect(() => {
        if (pageCount === undefined) {
            return
        }

        if (currentPage > pageCount) {
            setCurrentPage(pageCount)
            return
        }
        if (currentPage < 1) {
            setCurrentPage(1)
            return
        }
        props.onPageSelect(currentPage)
    }, [currentPage, pageCount])

    const onPreviousPage = () => {
        if (currentPage === 1) {
            return
        }
        setCurrentPage(currentPage - 1);
    }

    const onNextPage = () => {
        if (pageCount === undefined) {
            return
        }
        if (currentPage >= pageCount) {
            return
        }
        setCurrentPage(currentPage + 1);
    }

    const onGoToPage = (page : number) => {
        setCurrentPage(page);
    }

    const onLastPage = () => {
        if (pageCount === undefined) {
            return
        }
        setCurrentPage(pageCount);
    }

    return ((pageCount && pageCount > 1) ? <nav className="pagination is-small mt-2 mb-0" role="navigation" aria-label="pagination">
        <div className="columns">
            <div className="column is-7">
                <ul className="pagination-list">
                    {
                        pagination && pagination.display.map(
                            (item, idx) => {
                                if (item === ""){
                                    return <li key={idx}><span className="pagination-ellipsis">&hellip;</span></li>
                                }
                                if (item === currentPage.toString()){
                                    return <li key={idx}><div className={`pagination-link ${currentPage.toString() !== item || "is-current"}`}>{item}</div></li>
                                }

                                return <li key={idx}><div className={`pagination-link`} aria-label={`Goto page ${item}`} onClick={() => onGoToPage(parseInt(item))}>{item}</div></li>
                            }
                        )
                    }
                </ul>
            </div>
            <div className="column">
                <div className="columns">
                    <div className="column is-3">
                        <button className="button pagination-previous" onClick={onPreviousPage} disabled={currentPage === 1}>Previous</button>
                    </div>
                    <div className="column is-3">
                        <button className="button pagination-next" onClick={onNextPage} disabled={currentPage === pageCount}>Next page</button>
                    </div>
                    <div className="column is-5 mt-1 ml-2">
                        <span>{props.itemCount} record(s)</span>
                    </div>
                </div>

            </div>

        </div>

    </nav> : <></>
    );
}

export default Paginator;





       // <li><a className={`pagination-link ${currentPage !== 1 || "is-current"}`} aria-label="Goto page 1" onClick={onFirstPage}>1</a></li>
                    // {currentPage <= 3 || <li><span className="pagination-ellipsis">&hellip;</span></li>}
                    // {currentPage < 3 || <li><a className="pagination-link" aria-label={`Goto page ${currentPage - 1}`} onClick={onNextPage}>{currentPage - 1}</a></li>}
                    // {(currentPage === 1 || currentPage === pageCount) || <li><a className={`pagination-link is-current`} aria-label={`Page ${currentPage}`} aria-current="page" onClick={onNextPage}>{currentPage}</a></li>}
                    // {currentPage >= pageCount - 1 || <li><a className="pagination-link" aria-label={`Goto page ${currentPage + 1}`} onClick={onNextPage}>{currentPage + 1}</a></li>}
                    // {currentPage >= pageCount - 2 || <li><span className="pagination-ellipsis">&hellip;</span></li>}
                    // {pageCount === 1 || <li><a className={`pagination-link ${currentPage !== pageCount || "is-current"}`} aria-label={`Goto page ${pageCount}`} onClick={onLastPage}>{pageCount}</a></li>}
