import { useRef } from "react"

const FileUploader = (props: {
    className: string,
    title: string,
    isMultiple: boolean,
    handleFile: any,
    handleStartingSelect : () => void,
}) => {
    const hiddenFileInput = useRef<any>(null);

    const handleClick = (e: any) =>{
        if(hiddenFileInput && hiddenFileInput.current){
            hiddenFileInput.current.click();
        }
    }

    const handleChange = (e: any) => {
        const filesUploaded = e.target.files;
        props.handleFile(filesUploaded);
    }

    const handleStartingSelect = (e: any) => {
        props.handleStartingSelect();
    }


    return (
        <>
            <button className={props.className} onClick={handleClick}>{props.title}</button>
            <input type="file" multiple={props.isMultiple} ref={hiddenFileInput} onChange={handleChange} onAbort={handleStartingSelect} onClick={handleStartingSelect} style={{display: 'none'}}/>
        </>
    )
}

export default FileUploader;