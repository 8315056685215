import { MapWrraper } from "./mapWrapper";
import { LINE_STRING, POINT } from "standard/geojsonMap/constants";
import { IS_ADD_ON_KEY, LAYER_ICON, LAYER_ICON_SIZE, LAYER_NAME_KEY, LayerMetaData, LayerStyling } from "standard/geojsonMap/@types/layerInfo";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { getMarkerIcon } from "./utils";
import { GenerateLayerColor } from "standard/geojsonMap/utils/utils";
import { LayerEvents } from "./enums";
import  "leaflet-polylinedecorator";
import L from 'leaflet';
import { MapFormattingKeys } from "standard/geojsonMap/map/mapFormat";

declare module './mapWrapper' {
	interface MapWrraper {
		setDefaultLayerStyle(layerMetadata: LayerMetaData): void
		setSeletedLayerStyle(layerMetadata: LayerMetaData): void
		setFilteredOutLayerStyle(layerMetadata: LayerMetaData): void
		setOverrideLayerStyle(layerMetadata: LayerMetaData): void
		unhighlightLayer(
			key: string, layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
		): void
		highlightLayer(
			layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void
		): void
		startBlinkingHighlighted(): void
		setLayerStyle(
			layerMetadata: LayerMetaData,
			onLayerEvent: (key: string, event: LayerEvents) => void,
			hasFilters: boolean,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void
		blinkHighlighted(): void
		unhighlightAllLayers(onLayerEvent: (key: string, event: LayerEvents) => void): void
		getDefaultLineStyle(layer: string): LayerStyling
		setDefaultLineStyle(layer: string): void
		getFilteredOutLineStyle(layer: string): LayerStyling
		setFilteredOutLineStyle(layer: string): void
		curate(
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void
		): void
		clearSelectionsAndHighlights(
			onLayerEvent: (key: string, event: LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void): void
	}
}

MapWrraper.prototype.setDefaultLayerStyle = function (layerMetadata: LayerMetaData) {
	if (layerMetadata.feature?.geometry.type.toLowerCase() === POINT.toLowerCase()) {
		if (layerMetadata.info[IS_ADD_ON_KEY] === true) {
			layerMetadata.layer.setIcon(getMarkerIcon(layerMetadata.feature?.properties?.layer, layerMetadata.info[LAYER_ICON_SIZE] || this.status.iconSize, '', layerMetadata.info[LAYER_ICON] || faCircleDot))
		} else {
			layerMetadata.layer.setIcon(getMarkerIcon(layerMetadata.feature?.properties?.layer, this.status.markerSize, ''))
		}
	} else {
		layerMetadata.layer.setStyle(this.getDefaultLineStyle(layerMetadata.feature?.properties?.layer));
	}
}

MapWrraper.prototype.setSeletedLayerStyle = function (layerMetadata: LayerMetaData) {
	if (layerMetadata.feature?.geometry.type.toLowerCase() === POINT.toLowerCase()) {
		layerMetadata.layer.setIcon(getMarkerIcon(layerMetadata.feature?.properties?.layer, 30, 'mapMarkerIconSelected'))
	} else {
		layerMetadata.layer.setStyle({
			"color": "#ffffff",
			"weight": 8,
			"opacity": 1,
		});
	}
}


MapWrraper.prototype.setFilteredOutLayerStyle = function (layerMetadata: LayerMetaData) {
	if (layerMetadata.feature?.geometry.type.toLowerCase() === POINT.toLowerCase()) {
		if (layerMetadata.info[IS_ADD_ON_KEY] === true) {
			layerMetadata.layer.setIcon(getMarkerIcon(layerMetadata.feature?.properties?.layer, layerMetadata.info[LAYER_ICON_SIZE] || this.status.iconSize, '', layerMetadata.info[LAYER_ICON] || faCircleDot))
		} else {
			layerMetadata.layer.setIcon(getMarkerIcon(layerMetadata.feature?.properties?.layer, this.status.markerSize, ''))
		}
		layerMetadata.layer.setOpacity(this.status.formattings[MapFormattingKeys.filteredOpacity]!.value === undefined ? 0.1 : this.status.formattings[MapFormattingKeys.filteredOpacity]!.value)
		
	} else {
		layerMetadata.layer.setStyle(this.getFilteredOutLineStyle(layerMetadata.feature?.properties?.layer));
	}
}

MapWrraper.prototype.setOverrideLayerStyle = function (layerMetadata: LayerMetaData) {
	(layerMetadata.layer as L.Polyline).setStyle({
		"color": "#ffc845",
		"weight": 8,
		"opacity": 1,
	});

	let leafletLayer: L.FeatureGroup = this.layers[layerMetadata.info[LAYER_NAME_KEY]];
	const polylineDecorator = L.polylineDecorator(layerMetadata.layer, {
		patterns: [
			{
				offset: 10,
				repeat: 20,
				symbol: L.Symbol.dash({ pixelSize: 5, pathOptions: { color: "#2a2a2a", weight: 4 } }),
			},
		],
	}).addTo(this.map);

	leafletLayer.addLayer(polylineDecorator);
	layerMetadata.decorator = polylineDecorator
}

MapWrraper.prototype.highlightLayer = function (
	layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void
) {
	if (Object.keys(this.status.selectedLayers).length > 0) {
		return
	}

	if (this.status.highlightedLayers.hasOwnProperty(layerMetadata.feature.uuid!)) {
		return
	}

	this.unhighlightAllLayers(onLayerEvent)
	// layer.setStyle(hoverStyle);
	this.status.highlightedLayers = { [layerMetadata.feature.uuid!]: layerMetadata }
	this.startBlinkingHighlighted()
	onLayerEvent(layerMetadata.feature.uuid!, LayerEvents.Highlighted)
}

MapWrraper.prototype.startBlinkingHighlighted = function () {
	let id = setInterval(this.blinkHighlighted.bind(this), 500)
	this.status!.highlightIntervalId = id
}

MapWrraper.prototype.blinkHighlighted = function () {
	this.status.highlightVisible = !this.status.highlightVisible
	for (const value of Object.values(this.status.highlightedLayers)) {
		if (value.feature?.geometry.type.toLowerCase() === POINT.toLowerCase()) {
			if (this.status.highlightVisible) {
				value.layer.setIcon(getMarkerIcon(value.feature?.properties?.layer, 25, ''))
			} else {
				value.layer.setIcon(getMarkerIcon(value.feature?.properties?.layer, this.status.markerSize, ''))
			}
		} else {
			let style: LayerStyling = { ...this.getDefaultLineStyle(value.feature?.properties?.layer) }
			if (this.status.highlightVisible) {
				style.weight = 8
			} else {
				style.weight = 5
			}
			value.layer.setStyle(style);
		}
	}
}

MapWrraper.prototype.unhighlightLayer = function (
	key: string, layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void) {
	this.setDefaultLayerStyle(layerMetadata)
	let clonedSet = { ...this.status.highlightedLayers }
	delete clonedSet[key]
	this.status.highlightedLayers = clonedSet
	if (Object.keys(this.status.highlightedLayers).length === 0) {
		clearInterval(this.status.highlightIntervalId);
	}
	onLayerEvent(key, LayerEvents.Dehighlighted)
}

MapWrraper.prototype.unhighlightAllLayers = function (
	onLayerEvent: (key: string, event: LayerEvents) => void
) {
	for (const [key, value] of Object.entries(this.status.highlightedLayers)) {
		return this.unhighlightLayer(key, value, onLayerEvent)
	}
}

MapWrraper.prototype.getDefaultLineStyle = function (layer: string): LayerStyling {
	if (this.status.lineStyling[layer] === undefined) {
		this.setDefaultLineStyle(layer)
	}
	return this.status.lineStyling[layer]
}

MapWrraper.prototype.setDefaultLineStyle = function (layer: string) {
	this.status.lineStyling[layer] = {
		color: GenerateLayerColor(layer, "#192434"),
		weight: 5,
		opacity: 1
	};
}

MapWrraper.prototype.getFilteredOutLineStyle = function (layer: string): LayerStyling {	
	return {
		color: GenerateLayerColor(layer, "#192434"),
		weight: 5,
		opacity: this.status.formattings[MapFormattingKeys.filteredOpacity]!.value === undefined ? 0.1 : this.status.formattings[MapFormattingKeys.filteredOpacity]!.value
	}
}

MapWrraper.prototype.setLayerStyle = function (
	layerMetadata: LayerMetaData,
	onLayerEvent: (key: string, event: LayerEvents) => void,
	hasFilters: boolean,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void) {
		
		
	if (this.status.selectedLayers.hasOwnProperty(layerMetadata.feature.uuid!)) {
		if (this.status.isEditable) {
			if (layerMetadata.feature?.geometry.type.toLowerCase() === LINE_STRING.toLowerCase()) {
				this.addEdgePoints([layerMetadata.feature], layerMetadata.info[LAYER_NAME_KEY], onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
				this.addLineDecorator(layerMetadata)
			}
		}
		this.setSeletedLayerStyle(layerMetadata)
		return
	} else if (this.status.overrideLayer?.feature.uuid! === layerMetadata.feature.uuid) {
		this.setOverrideLayerStyle(layerMetadata)
		return
	}
	else if (this.status.highlightedLayers.hasOwnProperty(layerMetadata.feature.uuid!)) {
		this.highlightLayer(layerMetadata, onLayerEvent)
		return
	}

	if (hasFilters) {
		if (this.status.filtered.includes(layerMetadata.feature.uuid!)) {
			this.setDefaultLayerStyle(layerMetadata)			
			return
		} else{
			this.setFilteredOutLayerStyle(layerMetadata)
			return
		}
	}
	
	this.setDefaultLayerStyle(layerMetadata)

}

//In case the map is reloaded, this function can be used to return the map to its previous visualization
MapWrraper.prototype.curate = function (
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {

	const hasFilters : boolean = this.status.filtered.length > 0
	Object.values(this.status.selectedLayers).forEach((layerMetadata) => {
		if (this.status.isEditable) {
			if (layerMetadata.feature?.geometry.type.toLowerCase() === LINE_STRING.toLowerCase()) {
				this.addEdgePoints([layerMetadata.feature], layerMetadata.info[LAYER_NAME_KEY], onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
				this.addLineDecorator(layerMetadata)
			}
		}
		this.setLayerStyle(layerMetadata, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)
	})

	const removeLayer = (layer: any) => {
		let layerMetadata: LayerMetaData = this.status.featureLayers[layer.feature.uuid!]
		if (layerMetadata) {
			if (layerMetadata.renderVersion !== renderVersion) {
				// clearSelectionsAndHighlights(onLayerEvent)
				this.removeLayer(layer.feature.uuid!)
			}
		}
	}
	//Remove deleted features
	let renderVersion = this.status.getRenderVersion();
	this.eachLayer(function (layer: any) {
		if (layer.feature) {
			removeLayer(layer)
		}
	});
}

MapWrraper.prototype.clearSelectionsAndHighlights = function (
	onLayerEvent: (key: string, event: LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void
) {
	this.unhighlightAllLayers(onLayerEvent)
	this.deselectAllLayers([], onLayerEvent, setContextMenuOptionsInstance, onMouseUp);
}