import { useEffect, useState } from 'react';
import { IDomainModel } from '../../interfaces';
import { DomainModels } from '../domainmodels';
import useDomainModelsImports from './useDomainModelImports';

const useDomainModelTree = (props: { domainModel: IDomainModel | undefined }) => {
    const [domainModels, setDomainModels] = useState<DomainModels>();
    const { importedModels } = useDomainModelsImports({ OntUUID: props.domainModel?.uuid! });

    useEffect(() => {
        if (importedModels && props.domainModel) {
            setDomainModels(new DomainModels([...[props.domainModel], ...importedModels]))
        }else{
            setDomainModels(undefined);
        }
    }, [props.domainModel, importedModels]);

    return { domainModels, importedModels };
}

export default useDomainModelTree