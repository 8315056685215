import { actionTypes } from './actionsTypes';
import { IAppContext } from '../interfaces/IAppContext';
import { IInstitution } from '../interfaces';

export const contextActions = {
    setInstitution,
    clearContext,
};

function clearContext() {
    return actionTypes.CLEAR_CONTEXT;
}

function setInstitution(institution : IInstitution | undefined) {
    return (dispatch : any) => {
        dispatch(request());
        dispatch(success({institution : institution} as IAppContext));         
    };

    function request() { return { type: actionTypes.SET_INSTITUTION_REQUEST } }
    function success(context : IAppContext) { return { type: actionTypes.SET_INSTITUTION_SUCCESS, context  } }
}
