import { useState, useEffect } from "react";
import { BehaviorSubject } from "rxjs";
import { DRAWED_GEO } from "./sharedStateName";

/**
 * Manage all shared state between components using rxjs
 */

//Store all shared states observables
const sharedStateObs: { [key: string]: any } = {};

function getNewSharedState(initValue: any): BehaviorSubject<any> {
    return new BehaviorSubject(initValue);
}

export const getSharedStateValue = (stateName: string) => {
    if (!sharedStateObs[stateName]) {
        return undefined;
    }
    return sharedStateObs[stateName].value;
}

export const useSharedState = (stateName: string, initVal: any) => {
    const [state, setState] = useState(initVal);

    if (!sharedStateObs[stateName]) {
        sharedStateObs[stateName] = getNewSharedState(initVal);
    }

    const stateObs = sharedStateObs[stateName];

    useEffect(() => {
        const subscription = stateObs.subscribe((message: typeof initVal) => {
            setState(message);
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, [stateObs]);

    function pushNewStateVal(val: typeof initVal): void {
        stateObs.next(val);
        // if(stateName === DRAWED_GEO){
        //     console.log("sharedState ===>>>> stateName ===>>>>>", stateName, stateObs, val);
        // } 
    }

    return [state, pushNewStateVal];
}
