import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Upload_Meta } from '../types/uploads';

const UploadItem = (props: {
    item: Upload_Meta
    onSelected: (item: Upload_Meta, context : string) => any
    onDelete: (item: Upload_Meta) => any
}) => {

    return <><div className="columns mt-0 mb-0 pt-0 pl-2 "  >
        <div className="column is-5">
            {props.item.name}
        </div>
        <div className="column is-3">
            {props.item.timestamps && props.item.timestamps.created.toLocaleString()}
        </div>
        <div className="column is-1 clickable" onClick={() => props.onSelected(props.item, 'details')}>
            <FontAwesomeIcon icon={faPencil} />
        </div>
        {/* <div className="column is-1 clickable" onClick={() => props.onSelected(props.item, 'map')}>
            <FontAwesomeIcon icon={faGlobe} />
        </div> */}
        {/* {props.exporting || <div className="column is-1 clickable" onClick={() => props.onDownload(props.item)}>
            <FontAwesomeIcon icon={faDownload} />
        </div>} */}
        <div className="column is-1 clickable m-0 p-1" onClick={() => props.onDelete(props.item)}>
            <span className='button is-danger is-inverted m-0 p-0' ><FontAwesomeIcon icon={faTrash} /></span>
        </div>
    </div>
    <hr className='m-0 p-0'/></>
}

export default UploadItem;