import { useEffect, useState } from 'react';
import { getDomainModelImports  } from '../service';
import { useAppSelector } from 'hooks/redux'
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from '../../interfaces';
import { IAlert, ActionTypes } from "../../../alert";

const useDomainModelsImports = (props : {OntUUID?: string }) => {
    const [importedModels, setImportedDomainModels] = useState<Array<IDomainModel>>();
    const [importedClasses, setImportedClasses] = useState<Array<IDomainModelClass>>();
    const [importedDataProps, setImportedDataProps] = useState<Array<IDomainModelProperty>>();
    const [importedObjectProps, setImportedObjectProps] = useState<Array<IDomainModelProperty>>();
    
    const authenticated = useAppSelector(state => state.authentication) 
    const [domainModelsAlert, setDomainModelsAlert] = useState<IAlert>();

    useEffect(()=>{
        load()
    },[props.OntUUID]);

    useEffect(()=>{

    },[importedModels, importedClasses, importedDataProps, importedObjectProps]);


    const load = () => {
        if (!props.OntUUID){
            setImportedDomainModels(undefined)
            return
        } 
        
        var classes : IDomainModelClass[] = []
        var dataProps : IDomainModelProperty[] = []
        var objProps : IDomainModelProperty[] = []
        
        getDomainModelImports(props.OntUUID!)
        .then((items : IDomainModel[]) =>{            
            setImportedDomainModels(items);
            items.forEach((item : IDomainModel) => {   
                // let domainClasses : IDomainModelClass[] = item.classes || [];
                // for (let cls of domainClasses) {
                //     cls.ontology = item
                // }
                if (item.classes){
                    classes = [...classes, ...item.classes]
                }
                if (item.dataProps){
                    let dPs : IDomainModelProperty[] = item.dataProps?.map((d) => {return {...d, Ontology: item} as IDomainModelProperty} )
                    dataProps = [...dataProps, ...dPs || []]
                }
                if (item.objProps){
                    let oPs : IDomainModelProperty[] = item.objProps?.map((d) => {return {...d, Ontology: item} as IDomainModelProperty} )
                    objProps = [...objProps, ...oPs || []]
                }
            })
            setImportedClasses(classes)
            setImportedDataProps(dataProps)
            setImportedObjectProps(objProps)
        })
        .catch((err)=>{
            setImportedDomainModels(undefined);
            setDomainModelsAlert({
                message: err.message,
                type: ActionTypes.ERROR,
            } as IAlert)
        });        
    }

    const reloadImports = () => {
        load()
    }
    
    return {importedModels, importedClasses, importedDataProps, importedObjectProps, reloadImports, domainModelsAlert};
}


export default useDomainModelsImports

