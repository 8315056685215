import { cloneDeep } from "lodash";
import { Feature, featureHash, geometryHash } from "standard/geojson/@types";
import { DomainModels } from "standard/ontology/domainModel/domainmodels";
import { ClassProperties } from "standard/ontology/domainModel/types/classProperties";
import { IDigitalTwin, IDomainModelClass, IDomainModelProperty, IIndividual } from "standard/ontology/interfaces";
import { newIndividual } from 'standard/ontology/ontologyindividual';

export type FeaturesToIndividuals = {
    individuals: IIndividual[]
    unmapped: Feature[]
}

export type ValidatedFeatures = {
    validated: Record<string, Feature[]>
    unknownclass: Feature[]
}

export async function validateFeatures(
    networkId: string,
    domainModels: DomainModels,
    features: Feature[]):
    Promise<ValidatedFeatures> {
    const promise = new Promise<ValidatedFeatures>((res, rej) => {
        let validation: ValidatedFeatures = {validated : {}, unknownclass: []};
        for (let feature of features) {
            if (feature.properties! && feature.properties.layer) {
                let cls: IDomainModelClass | undefined = domainModels!.getClassByName(feature.properties!.layer)
                if (!cls) {
                    validation.unknownclass.push(feature)
                    continue
                }
                
                let geometryProp = cls!.objProps!.find((p) => p.labels.default === process.env.REACT_APP_HAS_GEOMETRY_PROPERTY_NAME)  
                if (!geometryProp) {
                    validation.unknownclass.push(feature)
                    continue
                }
                
                let cloned : Feature = cloneDeep(feature)

                if (cloned.uuid) {
                    cloned.uuid = featureHash(networkId, cloned.uuid)
                    cloned.properties!['uuid'] = cloned.uuid
                }

                cloned.type = "Feature"
                cloned.class = cls.uuid
                let dataPropLabels : string[] = cls!.dataProps!.map(prop => prop.labels.default)
                Object.keys(cloned.properties!).forEach(key => {
                    if (!dataPropLabels.includes(key)) {
                        delete cloned.properties![key]
                    }   
                })
                cls!.dataProps!.forEach(prop => {
                    if (cloned.properties![prop.labels.default] === undefined) {
                        cloned.properties![prop.labels.default] = undefined;
                    }
                })
                if (!validation.validated[cls.labels.default]) {
                    validation.validated[cls.labels.default] = []
                }
                validation.validated[cls.labels.default].push(cloned)                
            }
            else{
                validation.unknownclass.push(feature)
            }
        }
        res(validation)
    });

    return promise
}

export async function groupFeatures(
    features: Feature[]):
    Promise<Record<string, Feature[]>> {
    const promise = new Promise<Record<string, Feature[]>>((res, rej) => {
        const grouped : Record<string, Feature[]> = features.reduce((groupedDict, obj) => {
            const category = obj.properties!.layer;
            if (!groupedDict[category]) {
              groupedDict[category] = [];
            }
            groupedDict[category].push(obj);
            return groupedDict;
          }, {});
        res(grouped)
    });

    return promise
}

export async function featuresToIndividuals(network: IDigitalTwin,
    domainModels: DomainModels,
    features: Feature[],
    geoClass: IDomainModelClass,
    geoClassProperties: ClassProperties):
    Promise<FeaturesToIndividuals> {
    const promise = new Promise<FeaturesToIndividuals>((res, rej) => {
        let conversion: FeaturesToIndividuals = {individuals : [], unmapped: []};        

        for (let feature of features) {
            if (feature.properties! && feature.properties.layer) {
                let cls: IDomainModelClass | undefined = domainModels!.getClassByName(feature.properties.layer)
                if (cls) {
                    let dataProps: IDomainModelProperty[] = domainModels.classes[cls!.uuid!].dataProps!
                    let objProps: IDomainModelProperty[] = domainModels.classes[cls!.uuid!].objProps!
                    let individual = newIndividual(cls!.uuid!)

                    individual.uuid = feature.uuid!
                    individual.labels.default = individual.uuid! || geometryHash(network.uuid!, cls!.uuid!, feature.geometry)

                    individual.dataProperties = {}
                    individual.objectProperties = {}
                    Object.keys(feature.properties).forEach(key => {
                        let dataProp: IDomainModelProperty | undefined = dataProps!.find(property => property.uuid! === key)
                        if (dataProp) {
                            individual.dataProperties![dataProp.uuid!] = feature.properties![key]
                        }
                    }) 

                    let geoIndividual = createGeoIndividual(network.uuid!, feature, geoClass)
                    let objPropsWithGeometryRange = objProps.filter(p => p.range.includes(geoClass!.uuid!))
                    for (let objProp of objPropsWithGeometryRange) {
                        individual.objectProperties[objProp.uuid!] = geoIndividual.iri
                    }
                    conversion.individuals.push(geoIndividual)
                    conversion.individuals.push(individual)
                } else {
                    conversion.unmapped.push(feature)
                }
            }
        }
        res(conversion)
    });

    return promise
}

export function createGeoIndividual(networID: string, feature: Feature, geoClass: IDomainModelClass): IIndividual {
    let individual = newIndividual(geoClass!.uuid!)
    let id = geometryHash(networID, geoClass!.uuid!, feature.geometry)
    individual.uuid = `geo-${feature.uuid}`
    individual.iri = id
    individual.labels.default = id
    individual.dataProperties = {}

    let typeProp: IDomainModelProperty | undefined = geoClass!.dataProps!.find(property => property.labels.default === 'type')
    if (typeProp) {
        individual.dataProperties[typeProp.uuid!] = feature.geometry.type
    }
    let coordinatesProp: IDomainModelProperty | undefined = geoClass!.dataProps!.find(property => property.labels.default === 'coordinates')
    if (coordinatesProp) {
        individual.dataProperties[coordinatesProp.uuid!] = feature.geometry.coordinates
    }

    return individual
}
