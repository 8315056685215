import { useState, useEffect } from 'react';
import { Feature } from 'standard/geojson/@types';
import { IDomainModel } from 'standard/ontology/interfaces';
import useDomainModelTree from 'standard/ontology/domainModel/hooks/useDomainModelTree';
import { ValidatedFeatures, validateFeatures } from '../utils';
import FeaturesList from '../list';

const Unmapped = (props: {
    title : string
    domainModel: IDomainModel,
    features: Feature[]
}) => {
    const { domainModels} = useDomainModelTree({ domainModel: props.domainModel});
    const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>();

    useEffect(() => {

    }, [domainModels]);

    useEffect(() => {
        if (domainModels){
            validateFeatures("", domainModels, props.features).then((result : ValidatedFeatures) =>{
                setSelectedFeatures(result.unknownclass);
            })
        }
    }, [domainModels, props.features]);   

    const columnSort = (a : string, b : string) =>{
        if (a === 'layer') {
            return -1
        }
        if (b === 'layer') {
            return 1
        }
        const x = a.toLowerCase();
        const y = b.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    }

    return  <FeaturesList
                title={props.title}
                features={selectedFeatures}
                columnSort={columnSort}
            />
}

export default Unmapped;