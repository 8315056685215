import { useEffect, useState, useCallback } from 'react';
import { IOption, IOptions } from 'standard/forms/interfaces/IOption';
import { TokenizedMessage } from 'standard/utils/tokenizedMessage';
import { EmptyFilter, FilterType, IEmptyFilter, IRangeFilter, IStringFilter, RangeFilter, StringFilter } from './@types/filter';


export enum FocusID {
    TEXTFILTER = 'TEXT_FILTER',
    NUMERICFROMFILTER = 'NUMERIC_FROM_FILTER',
    NUMERICTOFILTER = 'NUMERIC_TO_FILTER'
};


const resultsActions: IOption[] = 
[
    { value: FilterType.ISEMPTY, label: "Is Empty" },
    { value: FilterType.STRINGCONTAINS, label: "Contains Text" },
    { value: FilterType.STRINGEXACT, label: "Exact Text" },
    { value: FilterType.STRINGEXCLUDE, label: "Filter Out Text" },
    { value: FilterType.NUMERICRANGE, label: "Filter Numbers" },

    // { value: FilterType.DATERANGE, label: "Filter Dates" }
]

const Filter = (props: {    
    property : string
    filterId : string
    filter? : any
    input? : TokenizedMessage
    onFilter: (filter: any) => void

}) => {
    const [options, setOptions] = useState<IOptions>();
    const [filterType, setFilterType] = useState<FilterType>(FilterType.STRINGCONTAINS);
    var [textFilterPhrase, setTextFilterPhrase] = useState<string>("")

    var [numericFromFilterPhrase, setNumericFromFilterPhrase] = useState<string>("")
    var [numericToFilterPhrase, setNumericToFilterPhrase] = useState<string>("")
    
    var [dateFromFilterPhrase, setDateFromFilterPhrase] = useState<string>("")
    var [dateToFilterPhrase, setDateToFilterPhrase] = useState<string>("")

    var [currentFocus, setCurrentFocus] = useState<string>(FocusID.TEXTFILTER)

    var [id, setId] = useState<string>("")
    
    const {property, filter, onFilter} = props

    const clearForm = useCallback(() => {
        setTextFilterPhrase("")
        setNumericFromFilterPhrase("")
        setNumericToFilterPhrase("")
        setDateFromFilterPhrase("")
        setDateToFilterPhrase("")
        
	}, [])

    useEffect(() => {
        if (!props.filter) {
            setId(props.filterId)
            clearForm()
            return
        }
        setId(props.filter.id)
        if (props.filter instanceof StringFilter) {
            setTextFilterPhrase(props.filter.value)
        }
        if (props.filter instanceof RangeFilter) {
            if (props.filter.type === FilterType.NUMERICRANGE){
                setFilterType(FilterType.NUMERICRANGE)
                setNumericFromFilterPhrase(props.filter.fromValue)
                setNumericToFilterPhrase(props.filter.toValue)
            }else if (props.filter.type === FilterType.DATERANGE){
                setFilterType(FilterType.DATERANGE)
                setDateFromFilterPhrase(props.filter.fromValue)
                setDateToFilterPhrase(props.filter.toValue)
            }         
        }
    }, [props.filter, props.filterId, clearForm]);

    useEffect(() => {

        
    }, [currentFocus]);

    useEffect(() => {

    }, [options]);

    useEffect(() => {
        if (!props.input){
            return
        }
        switch(currentFocus) {
            case FocusID.TEXTFILTER:
                setTextFilterPhrase(props.input.message)
                break
            case FocusID.NUMERICFROMFILTER:
                setNumericFromFilterPhrase(props.input.message)
                break
            case FocusID.NUMERICTOFILTER:
                setNumericToFilterPhrase(props.input.message)
                break
        }
    }, [props.input]);

    useEffect(() => {
        clearForm()
        switch(filterType) {
            case FilterType.STRINGCONTAINS:
            case FilterType.STRINGEXACT:
            case FilterType.STRINGEXCLUDE:
                setCurrentFocus(FocusID.TEXTFILTER)
                break
            case FilterType.NUMERICRANGE:
                setCurrentFocus(FocusID.NUMERICFROMFILTER)
                break
        }
    }, [filterType, clearForm]);

    const resultsOptions = useCallback((): IOptions => {
        return { options: resultsActions, onSelect: onResultAction }
    }, [])

    useEffect(() => {
        setOptions(resultsOptions())
    }, [resultsOptions]);



    const doSearch = useCallback((filter : any) => {
        //clearForm()
        onFilter(filter)
	}, [onFilter, clearForm])

    useEffect(() => {
        if (filterType === FilterType.ISEMPTY ){
            doSearch(new EmptyFilter({id : id,  property : property} as IEmptyFilter))
        }
    }, [property, filterType, id, doSearch]);

    useEffect(() => {
        if (textFilterPhrase && textFilterPhrase.toString().trim() !== '' ){
            doSearch(new StringFilter({id : id, type : filterType, property : property, value : textFilterPhrase} as IStringFilter))
        }
    }, [textFilterPhrase, property, filterType, id, doSearch]);

    useEffect(() => {
        if (numericFromFilterPhrase.toString().trim() !== '' || numericToFilterPhrase.toString().trim() !== ''){
            doSearch(new RangeFilter({id : id, type : FilterType.NUMERICRANGE, property : property, fromValue : numericFromFilterPhrase, toValue : numericToFilterPhrase} as IRangeFilter))
        }
    }, [numericFromFilterPhrase, numericToFilterPhrase, property, id, doSearch]);

    useEffect(() => {
        if (dateFromFilterPhrase.toString().trim() !== '' || dateToFilterPhrase.toString().trim() !== ''){
            doSearch(new RangeFilter({id : id , type : FilterType.NUMERICRANGE, property : property, fromValue : dateFromFilterPhrase, toValue : dateToFilterPhrase} as IRangeFilter))
        }
    }, [dateFromFilterPhrase, dateToFilterPhrase, property, id, doSearch]);

    const onTextFilterPhraseChange = (e: any) => {
        setTextFilterPhrase(e.target.value)
    }

    const onNumericFromFilterPhraseChange = (e: any) => {
        setNumericFromFilterPhrase(e.target.value.replace(/[^0-9.]/g, ''))
    }

    const onNumericToFilterPhraseChange = (e: any) => {
        setNumericToFilterPhrase(e.target.value.replace(/[^0-9.]/g, ''))
    }

    const onDateFromFilterPhraseChange = (e: any) => {
        setDateFromFilterPhrase(e.target.value)
    }

    const onDateToFilterPhraseChange = (e: any) => {
        setDateToFilterPhrase(e.target.value)
    }

    const onResultAction = (option: IOption) => {
        // if (option.value === 'saveclone'){
        //     props.onNewUpload(props.upload.name, FeatureTransformer.ApplyTransformation(mapping.template, props.features), onNewUploadComplete)
        // }else if (option.value === 'saveoverwrite'){
        //     props.onUpdateFeatures(props.upload.key, FeatureTransformer.ApplyTransformation(mapping.template, props.features), onUpdateFeaturesComplete)
        // }
    }

    const textFilter = () => {
       return <input className="input" type="text" placeholder="Text input" value={textFilterPhrase} onChange={onTextFilterPhraseChange} onFocus={() => setCurrentFocus(FocusID.TEXTFILTER)}/>
    }

    const numericFilter = () => {
        return  <div className="columns ">
                    <div className="column">
                        <input className="input" type="text" placeholder="From..." value={numericFromFilterPhrase} onChange={onNumericFromFilterPhraseChange} onFocus={() => setCurrentFocus(FocusID.NUMERICFROMFILTER)}/>
                    </div>
                    <div className="column">
                        <input className="input" type="text" placeholder="To..." value={numericToFilterPhrase} onChange={onNumericToFilterPhraseChange} onFocus={() => setCurrentFocus(FocusID.NUMERICTOFILTER)}/>
                    </div>
                </div>
    }

    const dateFilter = () => {
        return <div className="columns">
                    <div className="column">
                        <input className="input" type="text" placeholder="From..." value={dateFromFilterPhrase} onChange={onDateFromFilterPhraseChange}/>
                    </div>
                    <div className="column">
                        <input className="input" type="text" placeholder="To..." value={dateToFilterPhrase} onChange={onDateToFilterPhraseChange}/>
                    </div>
                </div>
    }

    return (<>
        <div className="columns is-centered">
            <div className={`column mr-0 pr-0 ${filterType === FilterType.ISEMPTY ? 'is-4' : 'is-two-fifths'}`}>
                {options && <div className={`select ${filterType === FilterType.ISEMPTY ? 'is-centered' : 'is-pulled-right'}`}>
                    <select onChange={(e) => setFilterType(e.target.value as FilterType)} value={filterType}>
                        {options.options.map((value: IOption) => {
                            return <option value={value.value} key={value.value}>{value.label}</option>
                        })}
                    </select>
                </div>
                }
            </div>
            {filterType === FilterType.ISEMPTY || <div className="column ml-1 pl-0 is-three-fifths">
                {!(filterType === FilterType.STRINGCONTAINS) || textFilter()}
                {!(filterType === FilterType.STRINGEXACT) || textFilter()}
                {!(filterType === FilterType.STRINGEXCLUDE) || textFilter()}
                {!(filterType === FilterType.NUMERICRANGE) || numericFilter()}
                {!(filterType === FilterType.DATERANGE) || dateFilter()}
            </div>}
        </div>
    </>
    )
}

export default Filter;