import { useEffect, useState } from "react";
import CenteredScreen from "components/standard/centeredScreen/centeredScreen";
import { Feature } from "standard/geojson/@types";
import Text from "standard/forms/text/text"
import { useExport } from "standard/datatools/hooks/useExport";

const ExportToFile = (props: {
  name: string
  features: Feature[]  
}) => {
  const [uploadName, setUploadName] = useState<string>(props.name);
  const [cloningComplete, setCloningComplete] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const exportController = useExport();

  useEffect(() => {
    document.title = 'Export : To File';
  }, [])

  useEffect(() => {
   
  }, [uploadName])

  useEffect(() => {
    if (isSaving) {
      exportController.exportFeatures(`${uploadName}.geojson`, { originName: props.name, features: props.features }).then((results) => {
        onNewUploadComplete()
      })
    }
  }, [isSaving])

  useEffect(() => {

  }, [cloningComplete])

  const onNameChange = (id : string, value : string) => {
    setUploadName(value)
  }

  const exportToFile = () => {
    setIsSaving(true)
  }

  const onNewUploadComplete = () => {
    setIsSaving(false)
    setCloningComplete(true)
  }

  return (<CenteredScreen>
    {(isSaving || cloningComplete) ||
      (<><h3 className="subtitle is-6">Export to file.</h3>
      <Text id='fileName' label='Name of File' value={uploadName} onChange={onNameChange} />
      <button className="button is-info is-pulled-left" onClick={exportToFile}>
        Export
      </button></>)
    }
    {!isSaving || <><div className="control is-loading"></div><h3 className="subtitle is-6">Exporting to file, please wait...</h3></>}
    {!cloningComplete || (<h3 className="subtitle is-6">Exporting ({`${uploadName}.geojson`}) was successfully created.</h3>)}
  </CenteredScreen>);
}
export default ExportToFile;