import BaseStructure from "./basestructure"
import {  IDomainModel, IDomainModelClass } from "../interfaces"
import { ILabelComment } from '../localization/interfaces';

export function newDomainModelClass(domainModel : IDomainModel, parentUUID: string | undefined) {
  let c: IDomainModelClass = {
      Ontology : domainModel,
      uuid: undefined,
      labels: {},
      comments: {},
      Iri: '',
      isSubClassOf: parentUUID,
      domain: [],
      range: [],
  }

  return new DomainModelClass(
    c.Ontology,
    c.labels,
    c.comments,
    c.Iri,
    c.isSubClassOf,
    c.Domain,
    c.Range,
    c.uuid,
  )
}

export class DomainModelClass extends BaseStructure implements IDomainModelClass, ILabelComment {
  constructor(
    public Ontology: IDomainModel,
    public labels: { [key: string]: string },
    public comments: { [key: string]: string },
    public Iri: string,
    public isSubClassOf: string | undefined,
    public domain: [],
    public range: [],
    public uuid?: string,
) {
    super( labels, comments, uuid);
  }
}

export function getClass(classes : IDomainModelClass[], classUUID : string) : IDomainModelClass | undefined {
  return classes.find((c : IDomainModelClass) => c.uuid! === classUUID)
} 

export function getRootClassByName(classes : IDomainModelClass[], name : string) : IDomainModelClass | undefined {
  let cls : IDomainModelClass | undefined = getClassByDefaultName(classes, name);
  if (cls)
  {
    return getRootClass(classes, cls, [])
  }  
  return undefined;
} 

export function getRootClass(classes : IDomainModelClass[], domainModelClass : IDomainModelClass, path : string[]) : IDomainModelClass | undefined {
  if (domainModelClass.isSubClassOf){
    let parent = classes.find((c : IDomainModelClass) => c.uuid === domainModelClass.isSubClassOf)
    if (parent){
      if (parent.uuid === domainModelClass.uuid){
        return parent
      }
      parent = getRootClass(classes, parent, path) 
    }
    
    return parent
  }
  return domainModelClass;
} 

export  function getClassByDefaultName(classes : IDomainModelClass[], name : string) : IDomainModelClass | undefined {  
  let cls = classes.find((c : IDomainModelClass) => c.labels.default.toLowerCase() === name.toLowerCase() )
  return cls
}

export function getChildren(classes : IDomainModelClass[], domainModelClass : IDomainModelClass, children : IDomainModelClass[]) : IDomainModelClass[] {  
  let currentChildren : IDomainModelClass[] | undefined = classes.filter((c : IDomainModelClass) => c.isSubClassOf === domainModelClass.uuid!)
  if (currentChildren){
    children = [...children, ...currentChildren]
    currentChildren.forEach((c : IDomainModelClass) => {
      children = getChildren(classes, c, children)
    })
  }
  return children;
} 

export  function getChildrenByDefaultName(classes : IDomainModelClass[], name : string) : IDomainModelClass[] | undefined {  
  let cls = classes!.find((c : IDomainModelClass) => c.labels.default.toLowerCase() === name.toLowerCase() )
  if (cls){
    return getChildren(classes, cls, []);
  }
  
  return undefined;
}

export function getClassHierarchy(classes : IDomainModelClass[], domainModelClass : IDomainModelClass, hierachy : Record<number,IDomainModelClass>) : Record<number,IDomainModelClass> {
  hierachy[Object.keys(hierachy).length] = domainModelClass
  if (domainModelClass.isSubClassOf === undefined) return hierachy
  let parent = classes.find((c) => c.uuid === domainModelClass.isSubClassOf)
  if (!parent) return hierachy
  return getClassHierarchy(classes, parent, hierachy)
} 

export function getClassHierarchy2(classes : IDomainModelClass[], cls : IDomainModelClass) : IDomainModelClass[] | undefined {    
  let c : IDomainModelClass[] = []
  let root : IDomainModelClass | undefined= getRootClass(classes, cls, [])
  if (root){
    c.push(root)  
  }
  let children = getChildren(classes, cls, [])
  if (children){
    c = [...c, ...children]
  }
  return c
} 

export function getClassHierarchyByName(classes : IDomainModelClass[], name : string) : IDomainModelClass[] | undefined {    
  let c : IDomainModelClass[] = []
  let root : IDomainModelClass | undefined= getRootClassByName(classes, name)
  if (root){
    c.push(root)  
  }
  let children = getChildrenByDefaultName(classes, name)
  if (children){
    c = [...c, ...children]
  }
  return c
} 

export function getNodeClasses(classes : IDomainModelClass[]) : IDomainModelClass[] | undefined {    
  return getClassHierarchyByName(classes, 'node')
} 

export function getEdgeClasses(classes : IDomainModelClass[],) : IDomainModelClass[] | undefined {  
  return getClassHierarchyByName(classes, 'edge') 
}