import { useEffect } from "react";
import { IDataImport } from "standard/datatools/@types/IDataImport";
import ImportStatus from "components/dataTools/dataimport/importStatus";
import Label from "standard/labelcomment/label";

const ImportSummary = (props: {
    importStatus: IDataImport
}) => {

    useEffect(() => {

    }, []);

    const downLoadUnmmaped = (e: any) => {

    }

    return (
        <div className="card no-border">
            <header className="card-header">
                <p className="card-header-title">
                    Import Summary
                </p>
            </header>
            <div className="columns ml-5 mt-0 mb-0 pt-0 pb-0">
                <div className="column is-half">
                    {props.importStatus && <Label label={'Number of Records'} value={`${props.importStatus!.totalRecords}`} />}
                    {
                        props.importStatus.status &&
                        <>
                            <Label label={'Processed'} value={`${props.importStatus?.status && props.importStatus!.status.recordsProcessed}`} />
                            {/* <Label label={'Imported'} value={`${props.importStatus?.status && props.importStatus!.status.numberOfImports}`} /> */}
                            <Label label={'Unmapped'} value={`${props.importStatus?.status && props.importStatus!.totalRecords - props.importStatus!.status.mapped}`} />
                        </>
                    }
                </div>
            </div >
        </div>
    );
}

export default ImportSummary;