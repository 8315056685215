import { useState } from 'react';
import Card from 'standard/card/card';
import { ConnectionsFilter } from '../@types/graphFilters';

const GraphFilter = (props: {
    onConnectionsFilterChanged: (filter : ConnectionsFilter) => any
    isExpanded?:boolean
}) => {
    const isExpanded = (props.isExpanded !== undefined ? props.isExpanded : false);
    const [connectionsFilter, setConnectionsFilter] = useState<string>(ConnectionsFilter.ALL);

    const onConnectionsFilterChanged = (e : any) => {
        setConnectionsFilter(e.target.value)
        props.onConnectionsFilterChanged(e.target.value)
    }
    
    return (
        <Card title='Graph Filter' isExpanded={isExpanded}>            
            <label className='label smallText'>Connections</label>
            <div className="control">
                <div className="select is-small">
                    <select name="connections"  className='smallText is-small' onChange={onConnectionsFilterChanged} value={connectionsFilter}>
                        <option value={ConnectionsFilter.ALL}>All </option>
                        <option value={ConnectionsFilter.FULLYCONNECTED}>Fully connected</option>
                        <option value={ConnectionsFilter.PARTIALLYCONNECTED}>Partially connected</option>
                        <option value={ConnectionsFilter.NOTCONNECTED}>Not connected</option>
                    </select>
                </div>
            </div>            
        </Card>
    )
}    

export default GraphFilter;