import { ADDON_KEY } from "components/utils/constants";
import { Feature } from "standard/geojson/@types"
export interface IAddOn {
    type : string
    attachedTo : string
}

export interface IAddOnInfo{
    // style : { [key: string]:  any} 
    // selectedStyle : { [key: string]:  any}     
}

export const createAddOnFeature = (uuid : string, point: number[], addOnObject?: any, info? : IAddOnInfo): Feature => {
    return {
        uuid: uuid,
        type: "Feature",
        properties: {
            [ADDON_KEY]: addOnObject,
            ...info
        },
        geometry: {
            type: "Point",
            coordinates: point
        },
    } as Feature;
}
