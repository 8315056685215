import L from 'leaflet';

export interface RectangleOptions{
    selected : boolean
    highlightDuration? : number
    defaultStyle? : L.PolylineOptions
    hoverStyle? : L.PolylineOptions
}
export class Rectangle<P = any> extends L.Rectangle<P> {
    public customOptions : RectangleOptions 
    private styleResetTime? : any

    constructor(latLngBounds: L.LatLngBoundsExpression, customOptions : RectangleOptions){
        super(latLngBounds, customOptions.defaultStyle)
        this.customOptions = customOptions
        this.config()
    }

    private config(){
        if(this.customOptions.hoverStyle){
            this.on('mouseover', (e) => {
                if (this.styleResetTime){
                    clearTimeout(this.styleResetTime);
                }
                this.setStyle(this.customOptions.hoverStyle!);
            })
        }
        if(this.customOptions.defaultStyle){
            this.on('mouseout', (e) => {
                this.startResetClass(this)
            })
        }
    }

    private startResetClass(ref : Rectangle) {
        if(!ref.customOptions.defaultStyle){
            return
        }
        if(!ref.customOptions.highlightDuration){
            ref.resetClass(ref)
        }else{
            ref.styleResetTime = setTimeout(() => ref.resetClass(ref), 5000);
        }
    }

    private resetClass(ref : Rectangle) {
        if(!ref.customOptions.defaultStyle){
            return
        }
        ref.setStyle(ref.customOptions.defaultStyle)
    }

}