import { useEffect, useState, useCallback } from 'react';
import { IOptions } from 'standard/forms/interfaces/IOption';
import '../styles.css'
import Wrapper from '../wrapper';
import MapViewer from '../mapViewer';
import { Feature, Geometry } from 'standard/geojson/@types';
import { useAppSelector } from 'hooks/redux';
import Properties from '../properties';
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from 'standard/ontology/interfaces';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import { getClass, getRootClass } from 'standard/ontology/domainModel/domainmodelclass';
import { LatLng } from 'leaflet';
import { geometryFactory, featureFactory, getGeoValidFeatures } from 'standard/geojson/utils';
import { POINT } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { DrawStatus } from '../@types/drawing';
import { LAYER_UUID_KEY, LAYER_UUID_KEYS } from '../@types/layerInfo';
import { DELETE_FEATURE } from '../@types/actions';
import { filter } from './search';
import PropertiesBar from './propertiesBar';
import { LayerEvents, Tools } from 'standard/leaflet/enums';
import { RenderVersion } from 'standard/types/renderVersion/renderVersion';
import PropertyFilter from 'standard/list/propertyFilter';
import ModalDialog from 'standard/dialog/modaldialog';
import { MapFormatting, MapFormattingKeys, MapFormattings } from './mapFormat';
import useDomainModelTree from 'standard/ontology/domainModel/hooks/useDomainModelTree';
import { getClassProperties } from 'standard/ontology/domainModel/Classes/utils';
import { ClassProperties } from 'standard/ontology/domainModel/types/classProperties';

const Map = (props: {
    dataSetId: string
    title: string
    features: Feature[]
    targetModel: IDomainModel
    referencedClasses: IDomainModelClass[]
    isEdittable: boolean
    options?: IOptions
    onValueAction?: (action: string, key: string, value: string) => void
    onPropertyChange?: (uuid: string, properties : Record<string, any>) => void
    onFeatureAdd: (feature: Feature) => void
    onFeaturesDelete: (uuids: string[]) => void
    onGeometryChange?: (uuid: string, index: number, value: number[]) => void
}) => {
    const ui = useAppSelector(state => state.ui)
    const [columns, setColumns] = useState<string[]>();
    const [data, setData] = useState<Feature[]>();
    const [filteredData, setFilteredData] = useState<string[]>();
    const [doingSearch, setDoingSearch] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>();
    const [showMenu, setShowMenu] = useState(false);
    const [selectedFeatureUuid, setSelectedFeatureUuid] = useState<string>();
    const [selectedFeature, setSelectedFeature] = useState<Feature>();
    const [selectedClassProperties, setSelectedClassProperties] = useState<ClassProperties>();
    const [overrideFeatureUuid, setOverrideFeatureUuid] = useState<string>();
    const [overrideFeature, setOverrideFeature] = useState<Feature>();
    const [defaultFeature, setDefaultFeature] = useState<Feature>();
    const [newItemsMenu, setNewItemsMenu] = useState<IMenuItem[]>();
    const [defaultClass, setDefaultClass] = useState<string>();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showFilterProperty, setShowFilterProperty] = useState<string>();
    const [formattings, setFormattings] = useState<MapFormattings>({[MapFormattingKeys.filteredOpacity] : {key : MapFormattingKeys.filteredOpacity,  value : 0.1}});
    const [activeTools, setActiveTools] = useState<Set<string>>(new Set());
    const { domainModels } = useDomainModelTree({ domainModel: props.targetModel });

    useEffect(() => {

    }, [])

    useEffect(() => {

    }, [domainModels])

    useEffect(() => {
        setData(getGeoValidFeatures(props.features))
    }, [props.features])

    useEffect(() => {
        if (data) {
            let keys: Set<string> = new Set()
            data.forEach((item: ({ [k: string]: any } | undefined)) => {
                if (item!.properties) {
                    Object.keys(item!.properties).forEach((key: string) => {
                        keys.add(key)
                    });
                }
            });
            setColumns(Array.from(keys))
        } else {
            setFilteredData(undefined)
            setColumns(undefined)
        }
    }, [data])

    useEffect(() => {
        setDoingSearch(false)
    }, [filteredData])

    useEffect(() => {
        let domainMenuItems: IMenuItem[] = props.targetModel!.classes!.map((f: IDomainModelClass, idx: number) => {
            return {
                name: f.uuid!,
                label: `${f.labels.default}`,
                ordinality: idx,
                parent: f.isSubClassOf ? f.isSubClassOf : undefined,
            }
        })
        setNewItemsMenu(domainMenuItems)
    }, [props.targetModel])

    useEffect(() => {

    }, [newItemsMenu])

    const closeMenu = useCallback(() => {
        setShowMenu(false)
    }, [])

    useEffect(() => {
        closeMenu()
    }, [doingSearch, closeMenu])

    useEffect(() => {

    }, [columns])

    useEffect(() => {

    }, [showMenu])

    useEffect(() => {

    }, [showFilter])

    useEffect(() => {


    }, [formattings])

    useEffect(() => {
        if (data) {
            if (selectedFeatureUuid) {
                setSelectedFeature(data.find(d => d.uuid === selectedFeatureUuid))
            } else {
                setSelectedFeature(undefined)
            }
        } else {
            setSelectedFeature(undefined)
        }

    }, [selectedFeatureUuid, data])

    useEffect(() => {
        if (selectedFeature && domainModels) {
            let cls : IDomainModelClass | undefined = props.referencedClasses.find((c)  => c.labels?.default === selectedFeature.properties?.layer)
            if (cls){
               setSelectedClassProperties(getClassProperties(cls, domainModels))
            }            
        }
    }, [selectedFeature, domainModels])

    useEffect(() => {
        if (data) {
            if (overrideFeatureUuid) {
                setOverrideFeature(data.find(d => d.uuid === overrideFeatureUuid))
            } else {
                setOverrideFeature(undefined)
            }
        } else {
            setOverrideFeature(undefined)
        }

    }, [overrideFeatureUuid, data])

    useEffect(() => {

    }, [overrideFeature])

    useEffect(() => {
        doSearch(filters)
    }, [filters])

    const doSearch = (filters?: Record<string, any>) => {
        if (!data) {
            setDoingSearch(false)
            return
        }
        if (!filters) {
            setDoingSearch(false)
            setFilteredData(undefined)
            return
        }

        if (Object.keys(filters).length === 0) {
            setDoingSearch(false)
            setFilteredData(undefined)
            return
        }

        const searchResult = data!.filter((d) => {
            let found: boolean = true
            if (found) {
                if (filters && d.properties) {
                    if (!filter(filters, d.properties)) {
                        found = false
                    }
                }
            }
            return found
        })
        setFilteredData(searchResult.map((f) => f.uuid!))
    }

    const onDeleteFilter = (id: string) => {
        let newFilters = { ...filters }
        delete newFilters[id]
        setFilters(newFilters)
    }

    const onDeletePropertyFilters = (property: string) => {
        let newFilters = {}
        Object.values(filters!).forEach((value, key) => {
            if (value.property !== property) {
                newFilters[key] = value;
            }
        });
        setFilters(newFilters)
    }

    const onFilter = (filter: any) => {
        setDoingSearch(true)
        const currentFilters = filters ? filters : {}
        setFilters({ ...currentFilters, ...{ [filter.id]: filter } })
        // setShowFilter(false)
    }

    const onLayerEvent = (key: string, event : LayerEvents) => {
        switch (event) {
            case LayerEvents.Selected:
                onSelectFeature(key)
                break;
            case LayerEvents.Deselected:
                onDeselectFeature()
                break;
            case LayerEvents.OverrideSet:
                onOverrideSet(key)
                break;
            case LayerEvents.OverrideUnset:
                onOverrideUnset()
                break;
            default:
                break;
        }
  
    }

    const onSelectFeature = (uuid: string) => {     
        setSelectedFeatureUuid(uuid)         
    }

    const onDeselectFeature = () => {     
        setSelectedFeatureUuid(undefined)        
    }

    const onOverrideSet = (uuid: string | undefined) => {   
        setOverrideFeatureUuid(uuid)
    }

    const onOverrideUnset = () => {     
        setOverrideFeatureUuid(undefined)        
    }

    const onNoFeatureDetails = () => {
        setSelectedFeatureUuid(undefined)
        setSelectedFeature(undefined)
    }

    const onMapAction = (item: IMenuItem, latlng?: LatLng) => {
        if (item.name === Tools.Delete) {
            props.onFeaturesDelete(item.data![LAYER_UUID_KEYS])
            onNoFeatureDetails()
        } 
        if (item.name === DELETE_FEATURE) {
            let uuid = item.data![LAYER_UUID_KEY]
            if (uuid) {
                props.onFeaturesDelete([uuid])
            }
        } else {
            if (latlng){
                let cls: IDomainModelClass | undefined = getClass(props.referencedClasses, item.name)
                if (cls) {
                    let rootCls: IDomainModelClass | undefined = getRootClass(props.referencedClasses, cls, [])
                    if (rootCls?.labels.default === 'Node') {
                        const properties: { [k: string]: any } = { layer: cls.labels.default }
                        const id: string = uuidv4()
                        const geo: Geometry = geometryFactory(id, POINT, [latlng.lng, latlng.lat])
                        const feature: Feature = featureFactory(id, 'Feature', geo, properties)
                        props.onFeatureAdd!(feature)
                        // props.onUpdateFeatures!(props.uploadKey, [...props.features, ...[feature]], onUpdateComplete)
                    }
                }
            }
        }
    }

    const onFeatureAdd = (feature: Feature) => {
        const id: string = uuidv4()
        feature.geometry.uuid = id
        feature = featureFactory(id, 'Feature', feature.geometry, feature.properties || {})
        props.onFeatureAdd!(feature)
    }

    const onDrawStart = (status: DrawStatus) => {
        setSelectedFeature(undefined)
        if (status.type === 'polyline') {
            setDefaultClass('Edge')
        } else if (status.type === 'marker') {
            setDefaultClass('Node')
        }
    }

    const onDrawStop = (status: DrawStatus) => {
        setDefaultClass(undefined)
    }

    const onPropertyChange = (uuid: string, properties : Record<string, any>) => {
        props.onPropertyChange!(uuid, properties)
    }

    const onDefaultFeatureChange = (feature: Feature | undefined) => {
        setDefaultFeature(feature)
    }

    const onCloseFilter = () => {
        setShowFilterProperty(undefined)
        setShowFilter(false)
    }

    const content = () => {
        return <PropertyFilter
                data={Object.values(props.features!).map((f) => { return f.properties })}
                property={showFilterProperty!}
                onCancel={onCloseFilter}
                onFilter={onFilter}
                onDeleteFilter={onDeleteFilter}
                onDeletePropertyFilters={onDeletePropertyFilters}
                filters={filters}
            />
    }

    const onSetFilterProperty = (property : string) => {
        setShowFilterProperty(property)
        setShowFilter(true)
    }

    const onFormatChange = (formattings: MapFormattings) => {
        setFormattings(formattings)
    }

    const onActiveToolsChange = (tools: Set<string>) => {
		setActiveTools(tools);
	}

    const showPropertiesBar = () : boolean => {
		if (selectedFeature === undefined) return false;
        if (activeTools.has(Tools.MutliSelect)) return false;
        
        return true;
	}

    return (<>
        {data ?
            <Wrapper
                title={props.title}
                itemCount={filteredData ? filteredData.length : 0}
                menuLinks={newItemsMenu}
                options={props.options}
            >
                {doingSearch === true ?
                    'Searching...'
                    : <div className={`is-centered no-scroll ${ui.fullscreenenabled ? 'map-content-max' : 'map-content'}`}>
                        <div className='columns no-scroll'>
                            <div className={`column filter ${showPropertiesBar() ? 'is-one-quarter' : 'is-none'} `}>
                                {showPropertiesBar() && 
                                    <PropertiesBar
                                        feature={selectedFeature}
                                        override={overrideFeature}
                                        targetModel={props.targetModel}
                                        models={domainModels}
                                        classProperties={selectedClassProperties}
                                        formattings={formattings}
                                        referencedClasses={props.referencedClasses}
                                        isEdittable={props.isEdittable}
                                        onFilter={onFilter}
                                        onSetFilterProperty={onSetFilterProperty}
                                        defaultClass={defaultClass}
                                        onPropertyChanges={onPropertyChange}
                                        onFormatChange={onFormatChange}
                                    />
                                }
                            </div>
                            <div className='column map'>
                                {(data && newItemsMenu) && <MapViewer
                                    newItemsMenu={newItemsMenu}
                                    features={data}
                                    filteredFeatures={filteredData!}
                                    isEditable={props.isEdittable}
                                    formattings={formattings}
                                    onLayerEvent={onLayerEvent}
                                    onClearSelection={onNoFeatureDetails}
                                    selectedFeatures={selectedFeatureUuid ? [selectedFeatureUuid] : undefined}
                                    onMapAction={onMapAction}
                                    onGeometryChange={props.onGeometryChange}
                                    onDrawStart={onDrawStart}
                                    onDrawStop={onDrawStop}
                                    onFeatureAdd={onFeatureAdd}
                                    onActiveToolsChange={onActiveToolsChange}
                                    drawFeature={defaultFeature}
                                />
                                }
                            </div>
                        </div>
                    </div>
                }
            </Wrapper> : <></>}
            {(showFilterProperty) &&
            <ModalDialog title={`Filter ${showFilterProperty}`} display={showFilter}
                content={content()}
            />
        }
    </>
    )
}

export default Map;





// return (<>
//     {(feature ? displayPropertyWithValues() : displayProperties())}
//     {(showFilterProperty) &&
//         <ModalDialog title={`Filter ${showFilterProperty}`} display={showFilter}
//             content={content()}
//         />
//     }
// </>