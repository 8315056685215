import AlertMessage from "standard/alert/components/alertMessage";
import { IAlert } from "standard/alert";

const Loading = (props: {loading : boolean, loadingFailed? : boolean, error? : IAlert}) => {
  return props.loading === true || props.loadingFailed === true ? <div className="main">
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="box p-0">
                <div className="columns m-0 p-0">
                  <div className="column">
                    {props.loading && <>
                      <h1 className="title">
                        <div className="loader"></div>
                      </h1>
                      <h2 className="subtitle is-small">Loading, please wait....</h2>
                    </>}
                    {props.loadingFailed && <>                      
                      <h2 className="subtitle is-small">Unable to load.</h2>
                      {props.error && <AlertMessage alert={props.error} />}
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  </div >: <></>
};

export default Loading;
