import { Feature } from "standard/geojson/@types"
import { ColumnDrops, ColumnMappings, ConversionMappings, IMappingTemplate, RecordDeletes, RowMappings } from "./@types/IMappingTemplate"
import { Convert, DropColumns, MapColumn, MapLayer } from "./Mappers"
import { cloneDeep } from "lodash"

export const ApplyTransformation = (mappingTemplate  : IMappingTemplate, features : Feature[]) : Feature[] => {
  // let clone = [...features].slice(0,1)
  // let clone = [...input]
  let clone : Feature[] = cloneDeep(features)
  if (mappingTemplate.recordDeletes){
    clone = ApplyDeleteRecords(mappingTemplate.recordDeletes, clone)   
  }  
  if (mappingTemplate.rowMappings){
    clone = ApplyRowTransformations(mappingTemplate.rowMappings, clone)   
  }  
  if (mappingTemplate.conversionMappings){
    clone = ApplyValueConversions(mappingTemplate.conversionMappings, clone)
  } 
  if (mappingTemplate.columnMappings){
    clone = ApplyColumnTransformations(mappingTemplate.columnMappings, clone)
  }
  if (mappingTemplate.columnDrops){
    clone = ApplyColumnDrops(mappingTemplate.columnDrops, clone)
  }  
  return clone
}



const ApplyDeleteRecords = (recordDeletes : RecordDeletes, input : Feature[]) : Feature[] => {
  if (input.length === 0) return input
  
  let recDels = Object.values(recordDeletes)
    
  for (let recDel of recDels) {
    if (recDel.value !== undefined && recDel!.values === undefined){
        recDel = {...recDel, ...{values : [recDel.value]}}
    }
    if (!recDel.values) continue
    for (let value of recDel.values){
      input = input.filter(record => { 
        if (!record.properties){
          return true
        }
        return record.properties![recDel.input] !== value
      })
    }
  } 

  return input
}

const ApplyRowTransformations = (rowMappings : RowMappings, input : Feature[]) : Feature[] => {
  if (input.length === 0) return input
  let mappings = Object.values(rowMappings)
  for (var record of input) {
    if (!record.properties){
      continue
    }
    record.properties = MapLayer(mappings, record.properties)
  } 

  return input
}

const ApplyColumnTransformations = (columnMappings : ColumnMappings, input : Feature[]) : Feature[] => {
  if (input.length === 0) return input
  let mappings = Object.values(columnMappings)
  
  for (var record of input) {
    if (!record.properties){
      continue
    }
    record.properties = MapColumn(mappings, record.properties)
  } 

  return input
}

const ApplyValueConversions = (conversionMappings : ConversionMappings, input : Feature[]) : Feature[] => {
  if (input.length === 0) return input
  let mappings = Object.values(conversionMappings)
  for (var record of input) {
    if (!record.properties){
      continue
    }
    record.properties = Convert(mappings, record.properties)
  } 
  return input
}

const ApplyColumnDrops = (columnDrops : ColumnDrops, input : Feature[]) : Feature[] => {
  if (input.length === 0) return input
  for (var record of input) {
    if (!record.properties){
      continue
    }
    DropColumns(columnDrops, record.properties)
  } 
  return input
}
