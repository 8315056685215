import { RangeFilter, StringFilter } from "standard/filter/@types/filter"
import { ISummary } from "standard/summary/@types/ISummary";


export const getKeyValues = (data: ({ [k: string]: ISummary; } | undefined)[], property : string): string[] => {
    let values: Set<string> = new Set()
    if (data) {
        data.forEach(item => {
            if (item && item[property])  {              
                values.add(item![property].value)
            }
        })
    }
    return Array.from(values).sort();
}

export function getInterfaceName<T>(iface: { new(): T }): string {
    return (iface as any).name;
}



export const filterExactString = (filter: StringFilter, row: { [key: string]: ISummary }, split: boolean) => {
    const value = row![filter.property].value

    if (value === null || value === undefined) {
        return false
    }

    let valueSearch: boolean = true

    if (value.toString().toLowerCase() !== filter.value.toLowerCase()) {
        valueSearch = false
    }        

    if (valueSearch) {
        return true
    }

    return false
}


export const filterContainsString = (filter: StringFilter, row: { [key: string]: ISummary }, split: boolean) => {
    const value = row![filter.property].value

    if (value === null || value === undefined) {
        return false
    }

    let filterSearch: string[] | undefined = split ? `${filter.value}`.toLowerCase().split(' ') : [`${filter.value}`.toLowerCase()]
    filterSearch = filterSearch!.length === 0 ? undefined : filterSearch

    if (filterSearch === undefined) {
        return false
    }

    let valueSearch: boolean = true

    for (const searchString of Object.values(filterSearch)) {      
        if (value.toString().toLowerCase().indexOf(searchString) === -1) {
            valueSearch = false
            break
        }        
    }

    if (valueSearch) {
        return true
    }

    return false
}

export const filterOutString = (filter: StringFilter, row: { [key: string]: ISummary }, split : boolean) => {
    const value = row![filter.property].value

    if (value === null || value === undefined) {
        return false
    }

    let filterSearch: string[] | undefined = split ? `${filter.value}`.toLowerCase().split(' ') : [`${filter.value}`.toLowerCase()]
    filterSearch = filterSearch!.length === 0 ? undefined : filterSearch

    if (filterSearch === undefined) {
        return false
    }

    let valueSearch: boolean = true

    for (const searchString of Object.values(filterSearch)) {
        if (value.toString().toLowerCase().indexOf(searchString) !== -1) {
            valueSearch = false
            break
        }
    }

    if (valueSearch) {
        return true
    }

    return false
}


export const filterNumericRange = (filter: RangeFilter, row: { [key: string]: ISummary }) => {
    const value = row![filter.property].value

    if (value === null || value === undefined) {
        return false
    }

    if (filter.fromValue !== undefined){
        if (filter.fromValue !== ''){
            if (parseFloat(value) < parseFloat(filter.fromValue)) {
                return false
            }
        }
    }

    if (filter.toValue !== undefined){
        if (filter.toValue !== ''){
            if (value > filter.toValue) {
                return false
            }
        }
    }

    return true
}