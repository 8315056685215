import L from 'leaflet';
import { boxWithOffset } from '../bbox';
import { Feature, Geometry } from 'standard/geojson/@types';
import { Tiles } from '../@types/tiles';
import { Tile } from '../interfaces/tile';
import { LINE_STRING, POINT, POLYGON } from '../constants';
import { Rectangle, RectangleOptions } from '../@types/rectangle';
import { MapViewLayer } from '../interfaces/mapViewerLayer';

export const tileFactory = (bounds : L.LatLngBounds) : L.Rectangle => {
    var tileStyle = { color: '#293241', weight: 0.5, opacity: 0.5, fillOpacity: 0}
    var tileMouseOverStyle = { color: '#293241', weight: 1, opacity: 0.7, fillOpacity: 0.05 }

    var rectangle = new Rectangle(L.latLngBounds(bounds.getSouthEast(), bounds.getNorthWest()), 
                            {
                                selected : false, 
                                defaultStyle: tileStyle,
                                hoverStyle: tileMouseOverStyle,
                                highlightDuration:5
                                
                            } as RectangleOptions);

                                
    return rectangle
}

export const getSingleTile = (features: Feature[]): MapViewLayer => {
    const layer: MapViewLayer = { types: {} }
    layer.types[LINE_STRING] = []
    layer.types[POINT] = []
    layer.types[POLYGON] = []    
    features.forEach((f) => {
        if (f.geometry.coordinates){
           if (f.geometry.type === LINE_STRING) {
                layer.types[LINE_STRING][f.uuid!] = f
            } else if (f.geometry.type === POINT) {
                layer.types[POINT][f.uuid!] = f
            } else if (f.geometry.type === POLYGON) {
                layer.types[POLYGON][f.uuid!] = f
            }
        }
    })
    return layer
}

export const getTiles = (layerKey: string, geometries: Geometry[], rounding: number): Tiles => {
    const myTiles: Tiles = {}
    geometries.forEach((f) => {
        f.coordinates && f.coordinates.forEach((c) => {
            const valy = Math.round(c[0] * (1 / rounding)) / (1 / rounding)
            const valx = Math.round(c[1] * (1 / rounding)) / (1 / rounding)
            const key = `${valx}-${valy}`
            if (!myTiles.hasOwnProperty(key)) {
                myTiles[key] = {
                    key: key,
                    row: valx,
                    col: valy,
                    bounds: boxWithOffset(new L.Point(valx, valy), rounding / 2)
                } as Tile

                myTiles[key].layers = {}
            }
            if (!myTiles[key].layers.hasOwnProperty(layerKey)) {
                myTiles[key].layers[layerKey] = { types: {} }
                myTiles[key].layers[layerKey].types[LINE_STRING] = []
                myTiles[key].layers[layerKey].types[POINT] = []
                myTiles[key].layers[layerKey].types[POLYGON] = []
            }
            if (f.type === LINE_STRING) {
                myTiles[key].layers[layerKey].types[LINE_STRING][f.uuid!] = f
            } else if (f.type === POINT) {
                myTiles[key].layers[layerKey].types[POINT][f.uuid!] = f
            } else if (f.type === POLYGON) {
                myTiles[key].layers[layerKey].types[POLYGON][f.uuid!] = f
            }
        })
    })
    return myTiles
}

export const splitTiles = (tiles: Tiles, rounding: number): Tiles => {
    let myTiles: Tiles = {}
    let geometries: { [key: string]: Geometry[] } = {}

    Object.values(tiles).forEach((tile: Tile) => {
        Object.keys(tile.layers).forEach((layerKey: string) => {
            if (!geometries.hasOwnProperty(layerKey)) {
                geometries[layerKey] = []
            }
            const layer = tile.layers[layerKey]
            Object.values(layer.types).forEach((instance) => {
                geometries[layerKey] = [...geometries[layerKey], ...Object.values(instance)]
            })
        })
    })

    Object.keys(geometries).forEach((layerKey: string) => {
        myTiles = { ...myTiles, ...getTiles(layerKey, geometries[layerKey], rounding) }
    })
    return myTiles
}


// export const getLinePoints = (tile: Tile, mouseEventCallBack : (e : L.LeafletMouseEvent) => void ) : DraggablePoint[] => {
//     let points : DraggablePoint[] = []
//     Object.keys(tile.layers).forEach((mainLayersKey: string) => {
//         Object.keys(tile.layers[mainLayersKey].types).forEach((subLayerKey: string) => {
//             Object.values(tile.layers[mainLayersKey].types[subLayerKey]).forEach((obj: any) => {
//                 if (obj.type === LINE_STRING){
//                     points = [...points, ...pointsFactory(obj.coordinates, mouseEventCallBack, 0.00001, obj.uuid)]
//                 }
//             })           
//         })
//         // newLayers.layers[layerKey] = { instances: {} }
//         // Object.keys(layers!.layers[layerKey].instances).map((instancesKey: string) => {
//         // 	newLayers.layers[layerKey].instances[instancesKey] = layers.layers[layerKey].instances[instancesKey].filter((l) => inBounds(selection.getNorthEast(), selection.getSouthWest(), l.coordinates))
//         // })
//     })

    
//     return points
// }
    
