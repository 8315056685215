import './App.css';
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAppSelector } from './hooks/redux'
import * as paths from "./configuration/paths/paths"
import DataPreview from 'components/dataTools/datapreview/transformAndLoad/dataPreview';
// import LoggedOut from 'standard/iam/screens/LoggedOut';
import NoPage from 'standard/iam/screens/NoPage';
// import ChangePassword from 'standard/iam/screens/ChangePassword';
// import ProtectedRoute from 'components/routes/ProtectedRoute';
import useEnvironment from 'components/configuration/useEnvironment';

function App() {
  const configuration = useAppSelector(state => state.configuration)
  const { configs } = useEnvironment()

  useEffect(() => {

  }, []);
  
  useEffect(() => {

  }, [configs]);

  useEffect(() => {

  }, [configuration]);

  return ((configuration.isLoaded) ? <BrowserRouter  basename="/">
    <Routes>
      {/* <Route path={paths.loggedout.path} element={<LoggedOut />} /> */}
      <Route path={paths.editor.path} element={<DataPreview />} />
          {/* <Route path={paths.changePassword.path} element={
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        } /> */}
      <Route path="*" element={<NoPage />} />
    </Routes>
  </BrowserRouter> : null
  );
}

export default App;
