import { CONNECTION_KEY } from "components/utils/constants"
import { sha256 } from "js-sha256"
import { Feature } from "standard/geojson/@types"
import { createAddOnFeature, IAddOn, IAddOnInfo } from "./addOn"
import { IAddOns } from "./addOns"

export interface INode {
    point: number[]
    uuid: string
}

export class Node implements IAddOn, INode {
    public type: string
    public attachedTo: string
    public point: number[]
    public uuid: string

    constructor(
        attachedTo: string,
        c: INode
    ) {
        this.attachedTo = attachedTo
        this.type = CONNECTION_KEY
        this.point = c.point
        this.uuid = c.uuid
    }
}

function firstAndLast(array: any[]): any[] {
    var firstItem = array[0];
    var lastItem = array[array.length - 1];
    return [firstItem, lastItem];
}

const style : {[key : string] : any} = {
    color: '#0a766d',
    fillColor: '#4dccac',
    fillOpacity: 1,
    radius: 0.1,
    weight: 2
}

const selectedStyle : {[key : string] : any} = {
    color: '#14ebd9',
    fillColor: '#39c6a3',
    fillOpacity: 1,
    radius: 0.5,
    weight: 3
}

const createFeatures = (features: Feature[]): Feature[] => {
    let addOnFeatures: Feature[] = []
    for (var feature of features) {
        let info: IAddOnInfo = {

        }
        let endPoints = firstAndLast(feature.geometry.coordinates)
        
        for (var i = 0; i < endPoints.length; i++) {
            if (!Array.isArray(endPoints[i])){
                continue
            }          
            const id: string = sha256(`${feature.uuid}_${endPoints[i]}_${i}`)
            let addOn: Node = new Node(feature.uuid!, { point: endPoints[i], uuid: id } as INode)
            addOnFeatures.push(createAddOnFeature(id, endPoints[i], addOn, info))
        }
    }

    return addOnFeatures
}

export const createAddOns = (features: Feature[]): IAddOns => {
    return {features : createFeatures(features), style : style, selectedStyle : selectedStyle}
}