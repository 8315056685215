import { useState, useEffect } from 'react';
import { IAlert } from 'standard/alert';
import AlertMessage from 'standard/alert/components/alertMessage';
import MapPreview from './mapPreview';

import { Feature } from 'standard/geojson/@types';
import { useUploads } from './hooks/useUploads';
import { useUpload } from './hooks/useUpload';
import Loading from 'standard/loading/loading';

const DataPreviewMap = (props: { uploadKey: string, onEditComplete: () => void }) => {
  const { updateFeaturesOnLocal } = useUploads()
  let { upload, loading } = useUpload({ uploadKey: props.uploadKey })
  let [error, setError] = useState<IAlert>();
  const [graphChanged, setGraphChanged] = useState<boolean>(false);
  let [changedFeatures, setChangedFeatures] = useState<Feature[]>();

  useEffect(() => {
    document.title = 'Data Preview';
  }, [])

  useEffect(() => {

  }, [props.uploadKey])

  useEffect(() => {

  }, [loading])

  useEffect(() => {

  }, [upload])

  useEffect(() => {
    if (changedFeatures) {
      setGraphChanged(true)
    } else {
      setGraphChanged(false)
    }
  }, [changedFeatures])

  const onPreviewError = (error: IAlert) => {
    setError(error)
  }

  const onFeaturesChanged = (features: Feature[], doUpdate: boolean) => {
    if (doUpdate) {
      updateFeaturesOnLocal(props.uploadKey, features)
      setChangedFeatures(undefined)
    } else {
      setChangedFeatures(features)
    }
  }

  const onSaveGraph = () => {
    updateFeaturesOnLocal(props.uploadKey!, changedFeatures!)
    setChangedFeatures(undefined)
  }

  const backToUploads = () => {
    props.onEditComplete()
  }

  const getError = () => {
    return <div className='box columns is-centered'>
      <div className='column is-8'>
        <AlertMessage alert={error} />
        <br />
        <div className='button' onClick={backToUploads}>Back to uploads</div>
      </div>
    </div>
  }

  return <>
    
    {!error || getError()}    
      <div className='box ml-5 mr-5 p-0'>
      {!loading || <Loading loading={loading}/>}
      {(upload && !error && !loading) &&  <>
          <div className='box m-1 p-2 '>
            <div className='columns '>
              <div className='column ml-2'>
                <span className='clickable' onClick={backToUploads}>Uploads</span>
              </div>
              <div className='column mr-2'>
                <button className={`button smallText is-pulled-right ${!graphChanged || 'is-primary'}`} disabled={!graphChanged} key='new prop' onClick={() => onSaveGraph()}>
                  Save Graph
                </button>
              </div>
            </div>
          </div>
          <MapPreview
            features={upload.features}
            onError={onPreviewError}
            onFeaturesChanged={onFeaturesChanged}
          />
        </>}
      </div>
  </>
}

export default DataPreviewMap;