export enum MatchingType {
    CONTAINS = 'CONTAINS',
    EXACT = 'EXACT',
    STARTSWITH = 'STARTSWITH',
    ENDSWITH = 'ENDSWITH',
};

export interface IConversionMapping {
    id: string,
    input: string
    /**
     * @deprecated This property(value) is deprecated. Please use the values instead.
     */
    value?: string
    values: string[]
    output: string
    matchcriteria?: MatchingType
    newproperty?: string
}  

export const IConversionMappingFromJson = (value : {[key : string] : any}): IConversionMapping => {
    return {
            id : value.id,
            input : value.input,
            value : value.value,
            values : value.values,
            output : value.output,
            matchcriteria : value.matchingType,
            newproperty : value.newproperty
        } as IConversionMapping
}