import React, { useEffect, useState } from 'react';
import 'bulma/css/bulma.min.css';
import AlertMessage from 'standard/alert/components/alertMessage';
import { IAlert } from 'standard/alert';
import Autocomplete from 'standard/autocomplete/autocomplete';
import { v4 as uuidv4 } from 'uuid';

const TaggingControl = (props: { 
      tags : string[]
      suggestions: string[],
      onChange: (tags: string[]) => void,
      placeholder? : string ,
      alert?: IAlert,

    }
   ) => {
  const [tags, setTags] = useState<string[]>(props.tags);
  const [newTag, setNewTag] = useState<string>('');
  const [refreshKey, setRefreshKey] = useState<string>(uuidv4());

  useEffect(() => {

  }, [props.suggestions]);

  useEffect(() => {
    props.onChange(tags)
  }, [tags]);

  useEffect(() => {


  }, [newTag]);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newTag.trim() !== '') {
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleTagRemove = (tag: string) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const onChangeInput = (value: string) => {
    let tag = tags.find((t) => t === value.trim());
    if (tag === undefined) {
      setTags([...tags, value.trim()]);      
    }
    setNewTag('');
    setRefreshKey(uuidv4())
}


  return (
    <>
      <div className="tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag is-light is-normal">
            {tag}
            <button
              className="delete is-small"
              onClick={() => handleTagRemove(tag)}
            ></button>
          </span>
        ))}
      </div>
      <Autocomplete
          key={refreshKey}
          value={newTag}
          suggestions={props.suggestions}
          onChange={(newValue) => onChangeInput(newValue)}
          changeOnEnterKey={true}
          showNoSuggestions={false}
          style={{ text: 'smallText' }}
      />   

      {/* <input
        className="input smallText"
        type="text"
        placeholder={props.placeholder || "Add tags"}
        value={newTag}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      /> */}
      <AlertMessage alert={props.alert} style={"smallText"} />
    </>
  );
};

export default TaggingControl;
