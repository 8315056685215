import { MapWrraper } from "./mapWrapper";
import {  IS_ADD_ON_KEY, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import L, {LatLng } from 'leaflet';
import { sha256 } from 'js-sha256';
import { Feature } from "standard/geojson/@types";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { getMarkerIcon } from "./utils";
import { LayerEvents } from "./enums";

declare module './mapWrapper' {
    interface MapWrraper {
		createPoint (
			grouplayerKey: string,
			markerSize: any,
			feature: Feature,
			latlng: LatLng,
			iconDefinition? : IconDefinition,
		) : L.Marker
		onEachPointFeature  (
			feature: any,
			layer: any,
			info: LayerInfo,
			onLayerEvent : (key: string, event : LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void,
      ) : void


    }
}

MapWrraper.prototype.onEachPointFeature = function (
	feature: any,
	layer: any,
	info: LayerInfo,
	onLayerEvent : (key: string, event : LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void,
)  {

	const layerMetadata: LayerMetaData = {
		info: {...info, ...{[LAYER_UUID_KEY] : feature.uuid!}},
		layer: layer,
		feature: feature,
		showing: true,
		layerVersion: sha256(JSON.stringify(feature)),
		renderVersion: this.status.getRenderVersion()
	}

	this.status.featureLayers[feature.uuid!] = layerMetadata
	const hasFilters : boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetadata, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)

	let resetSelectedTimer: any

	layer.on('click', function (e) {
		L.DomEvent.stopPropagation(e);
	});

	if (info[IS_ADD_ON_KEY] === false){
		layer.on('mousedown', (e: any) => {
			this.map.dragging.disable();
			let meta : LayerMetaData = this.status.featureLayers[layer.feature.uuid]
			this.status.mouseEvents.setOnLayer(meta)
			L.DomEvent.stopPropagation(e);
			clearInterval(resetSelectedTimer);
			
			return
		})
		layer.on('mouseup', (e: any) => {
			let meta : LayerMetaData = this.status.featureLayers[layer.feature.uuid]
			this.onClickLayer(meta, onLayerEvent, setContextMenuOptionsInstance, onMouseUp);
			return
		})
	}else{
		layer.on('mousedown', (e: any) => {
			this.map.dragging.disable();
			this.status.mouseEvents.setOnLayer(layerMetadata)
			L.DomEvent.stopPropagation(e);			
			return
		})
	}
		
	layer.on('mouseover', (e: any) => {
		clearInterval(resetSelectedTimer);
		this.highlightLayer(layerMetadata, onLayerEvent)
		return
	})

	// layer.on('mouseup', function (e: any) {
	// 	onMouseUp()
	// });

	// layer.on('drag', function (e: any) {

	// });
}

MapWrraper.prototype.createPoint = function  (
	grouplayerKey: string,
	markerSize: any,
	feature: Feature,
	latlng: LatLng,
	iconDefinition? : IconDefinition,
): L.Marker {
	const icon: L.DivIcon = getMarkerIcon(feature?.properties?.layer, markerSize, '', iconDefinition)
	let leafletLayer: L.FeatureGroup = this.layers[grouplayerKey];
	let marker : L.Marker = L.marker(latlng, { icon: icon, draggable: true })
	leafletLayer.addLayer(marker);
	return marker
}