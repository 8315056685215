import React, { useState, useEffect } from 'react'
import MappingEdit from './mappingedit'
import { v4 as uuidv4 } from 'uuid'
import { type Input } from 'standard/forms/inputs'
import { IDomainModelProperty, type IDomainModel, type IDomainModelClass } from 'standard/ontology/interfaces'
import { type IColumnMapping } from 'standard/datatools/@types'
import { type Upload_Meta } from '../../types/uploads'
import { type ColumnMappings } from 'standard/datatools/@types/IMappingTemplate'
import Mapper from './mapper'
import ModalDialog from 'standard/dialog/modaldialog'
import { Inputs } from './inputs'
import { Feature } from 'standard/geojson/@types'

const MapperList = (props: {
  upload: Upload_Meta,
  features: Feature[],
  targetModel: IDomainModel
  referencedClasses: IDomainModelClass[]
  referencedDataProps: IDomainModelProperty[] 
  onMappingChange: (mappings: ColumnMappings) => void
}) => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false)

  useEffect(() => {

  }, [props.upload])


  useEffect(() => {

  }, [props.referencedClasses])

  useEffect(() => {

  }, [props.upload.mapping])

  useEffect(() => {

  }, [props.targetModel])

  const onAddNew = () => {
    setShowAddNew(true)
  }

  const onCloseDialog = () => {
    setShowAddNew(false)
  }

  const handleAddNew = (newinputs: Record<string, Input>) => {
    const id: string = uuidv4()
    const mapping = {
      id,
      input: newinputs.input.value,
      values: newinputs.values.value,
      mapping: newinputs.mapping.value
    } as IColumnMapping

    let mappings: ColumnMappings = {}
    if (props.upload.mapping.template && (props.upload.mapping.template.columnMappings != null)) {
      
      mappings = { ...props.upload.mapping.template.columnMappings }
    }

    mappings[id] = mapping
    props.onMappingChange(mappings)
    setShowAddNew(false)
  }

  const handleEdit = (id: string, newinputs: Record<string, Input>) => {
    const mapping = {
      id,
      input: newinputs.input.value,
      values: newinputs.values.value,
      mapping: newinputs.mapping.value
    } as IColumnMapping

    const mappings: ColumnMappings = { ...props.upload.mapping.template.columnMappings }
    mappings[id] = mapping
    props.onMappingChange(mappings)
  }

  const onDelete = (id: string) => {
    if (props.upload.mapping.template && (props.upload.mapping.template.columnMappings != null)) {
      const mappings: ColumnMappings = { ...props.upload.mapping.template.columnMappings }
      delete mappings[id]
      props.onMappingChange(mappings)
    }
  }

  const createHeaders = () => {
    return Object.values(Inputs()).map((value) => {
      return <div key={value.name} className="column is-2  list-header-cell cell p-0 pl-1 pr-1 m-0">
            {value.label}
        </div>
    })
  }

  const content = () => {
    return !showAddNew || <MappingEdit 
    title='New Mappping'
    upload={props.upload} 
    features={props.features}
    targetModel={props.targetModel} 
    referencedClasses={props.referencedClasses}
    referencedDataProps={props.referencedDataProps} 
    isEditMode={false} 
    onAdd={handleAddNew} 
    onCancel={onCloseDialog} />
  } 
  return (<>
            <div className='ml-5 mr-5  smallText is-centered'>
                <div className="columns list-header mt-1 mb-0 pb-0 has-text-weight-bold is-centered is-vcentered">
                    {createHeaders()}
                    <div className="column is-1 m-0 p-0 pb-1 clickable">
                        <button className="card-header-icon button is-primary" aria-label="more options" onClick={onAddNew}>
                            Create New
                        </button>
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                    </div>
                </div>
                <hr className="navbar-divider m-0 p-0" />
                {(props.upload.mapping.template && (props.upload.mapping.template.columnMappings != null)) && Object.keys(props.upload.mapping.template.columnMappings).map((key) => {
                  return <Mapper  
                            upload={props.upload}
                            features={props.features} 
                            targetModel={props.targetModel}
                            referencedClasses={props.referencedClasses}
                            referencedDataProps={props.referencedDataProps}
                            key={key} 
                            id={key} 
                            onEdit={handleEdit} 
                            onDelete={onDelete} />
                })}
            </div>
            <ModalDialog title='New Mappping' display={showAddNew}
                content={content()}
            />
        </>
  )
}

export default MapperList
