import { useState, useEffect } from 'react';
import { Multiselect } from 'components/standard';
import { Options } from 'standard/multiselect/@types/IOption';
import { getPropertyKeys } from 'standard/geojson/utils';
import { Upload_Meta } from './types/uploads';
import { ColumnDrops } from 'standard/datatools/@types/IMappingTemplate';
import { IOption } from 'standard/forms/interfaces/IOption';
import { Feature } from 'standard/geojson/@types';

const ColumnDrop = (props: {
  upload: Upload_Meta,
  features: Feature[],
    onSelectionChanged : (drops: ColumnDrops) => void,
  }) => {

  const [options, setOptions] = useState<Options>([]);
  const [selected, setSelected] = useState<Options>([]);

  useEffect(() => {
    document.title = 'Data Preview';
  }, [])

  useEffect(() => {
    if (props.upload.mapping.template.columnDrops){
      setSelected(options.filter((o : IOption) => props.upload.mapping.template.columnDrops?.includes(o.value)))
    }else{
      setSelected([])
    }

  }, [options]);

  useEffect(() => {
    if (!props.upload) {
        return
    }

    if (props.features) {
        let propertyKeys: string[] = getPropertyKeys(props.features)
        setOptions(propertyKeys.map((item) => { return { value: item, label: item } }));
    }
  }, [props.upload]);

  const onSelection = (selections: Options) => {
    props.onSelectionChanged(selections.map((v : IOption) => v.value))
  }

  return <>
    <Multiselect 
      id='dropcolumns' 
      label='Columns to Drop' 
      options={options} 
      selected={selected}
      onSelectionChanged={onSelection}
      />
  </>
}

export default ColumnDrop;