import { useEffect, useState } from 'react';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SELECT_SQUARE = 'SELECT_SQUARE'

const MapInteraction = (props : {
        onClicked: (action : string, actions : Set<string>) => any
    }
) => {
    let [activeActions, setActiveActions] = useState<Set<string>>(new Set<string>());

    useEffect(() => {
			
	}, [activeActions]);

    const onButtonClicked = (e: any, action: string) => {
        e.preventDefault();
        const newActions = new Set<string>(activeActions)
        if (newActions.has(action)){
            newActions.delete(action)
        }else{
            newActions.add(action)
        }
        setActiveActions(newActions)
        props.onClicked(action, newActions)
    }

	return (
		<div className='columns pl-5' z-index="500">
            <div className='column m-0 mt-4 p-1 box' style={{maxWidth: '4em'}}>
		        <div className='columns'>
                    <div className='column'>
                        <button className={`button mapButton ${activeActions.has(SELECT_SQUARE) ? "is-info is-outlined selected" : ""}`} onClick={(e) => onButtonClicked(e, SELECT_SQUARE)}>
                            <FontAwesomeIcon icon={faCropSimple}  />
                        </button>
                    </div>
                </div>
            </div>
		</div>
	)
}



export default MapInteraction;