import 'leaflet/dist/leaflet.css';
import { useEffect, useState, useRef, useCallback } from "react";
import { Feature } from 'standard/geojson/@types';
import MapViewer from 'components/digitalTwins/map/mapViewer';
import { IRelationships } from 'standard/ontology/interfaces/IRelationships';
import { ILayer } from 'components/digitalTwins/map/interfaces/ILayer';
import Layers from 'components/digitalTwins/map/layers';
import NodeProperties from 'components/digitalTwins/map/nodeProperties';
import SelectBox from 'standard/selectbox/SelectBox';
import { OnChangeValue } from 'react-select';
import { IOption } from 'standard/forms/interfaces/IOption';
import { IAlert } from 'standard/alert';
import { ADDON_KEY, CONNECTIONS_KEY, NODES_KEY } from 'components/utils/constants';
import { LAYER_NAME_KEY, IS_ADD_ON_KEY, LayerInfo } from 'components/digitalTwins/map/@types/layerInfo';
import * as connectioAddOns from 'standard/ontology/map/features/connectedTo';
import * as endPointAddOns from 'standard/ontology/map/features/node';
import { IAddOns } from 'standard/ontology/map/features/addOns';
import GraphCreator from 'components/digitalTwins/map/undirectedGraph/graphCreator';
import GraphFilter from 'components/digitalTwins/map/undirectedGraph/graphFilter';
import { ConnectionsFilter } from 'components/digitalTwins/map/@types/graphFilters';
import Coordinates from 'components/digitalTwins/map/coordinates/coordinates';

const MapPreview = (props: {
    features?: Feature[]
    onError?: (error: IAlert) => void
    onFeaturesChanged: (features: Feature[], doUpdate: boolean) => void
}) => {

    let [layeredFeatures, setLayeredFeatures] = useState<{ [key: string]: Feature[] } | undefined>();
    const [layers, setLayers] = useState<ILayer[]>();
    const [selectedLayers, setSelectedLayers] = useState<ILayer[]>([]);

    const [properties, setProperties] = useState<{ [key: string]: Set<string> }>();
    const [edittingNodeId, setEdittingNodeId] = useState<string>();
    const [edittingNode, setEdittingNode] = useState<Feature>();
    const selectedPropertyMenuRef = useRef<{ value: string; label: string; }>({ 'value': 'Layers', 'label': 'Layers' });
    const [selectedPropertyMenu, setSelectedPropertyMenu] = useState<{ value: string; label: string; }>();
    const [layersLoaded, setLayersLoaded] = useState<boolean>(false);
    const [addOnFeatures, setAddOnFeatures] = useState<{ [key: string]: IAddOns }>({});
    const [connectionsFilter, setConnectionsFilter] = useState<ConnectionsFilter>(ConnectionsFilter.ALL);
    
    const selectedFeatures = useRef<string[]>([]);

    const createLayers = useCallback((unlayeredFeatures: Feature[]): { [key: string]: Feature[] } => {
        let newFeatures: { [key: string]: Feature[] } = {}
        unlayeredFeatures.forEach((f: Feature) => {
            

            if (!newFeatures.hasOwnProperty(f.properties!.layer)) {
                newFeatures[f.properties!.layer] = []
            }
            // Only feature with geometry get mapped
            if (f.geometry) {
                if (!f.properties){
                    f.properties = {}
                }
                
                if (!f.properties!.layer){
                    f.properties!.layer = 'Unknown'
                }

                newFeatures[f.properties!.layer].push(f)
            }
        })
        return newFeatures
    }, [])

    const getProperties = useCallback(() => {
        let newProperties: { [key: string]: Set<string> } = {}
        if (props.features) {
            props.features.forEach(feature => {
                if (feature.properties) {
                    Object.keys(feature.properties).forEach((key: string) => {
                        if (!newProperties.hasOwnProperty(key)) {
                            newProperties[key] = new Set()
                        }
                        newProperties[key].add(feature.properties![key])
                    });
                }
            })
        }
        return newProperties
    }, [props.features])

    const updateProperties = (sourceFeature: Feature) => {
        let newProperties: { [key: string]: Set<string> } = { ...properties }
        if (sourceFeature.properties) {
            Object.keys(sourceFeature.properties).forEach((key: string) => {
                if (!newProperties.hasOwnProperty(key)) {
                    newProperties[key] = new Set()
                }
                newProperties[key].add(sourceFeature.properties![key])
            });
        }
        return newProperties
    }

    const addAllAddOnFeatures = (features: Feature[]) => {
        setAddOnFeatures({
            ...addOnFeatures,
            [NODES_KEY]: endPointAddOns.createAddOns(features),
            [CONNECTIONS_KEY]: connectioAddOns.createAddOns(features)
        })
    }

    const addEndPointFeatures = (features: Feature[]) => {
        setAddOnFeatures({ ...addOnFeatures, [NODES_KEY]: endPointAddOns.createAddOns(features) })
    }

    const addConnectionFeatures = (features: Feature[]) => {
        setAddOnFeatures({ ...addOnFeatures, [CONNECTIONS_KEY]: connectioAddOns.createAddOns(features) })
    }

    const deleteAddOnFeature = (uuid: string, addOnName: string) => {
        if (!addOnFeatures.hasOwnProperty(addOnName)) {
            return
        }
        let feature: Feature | undefined = addOnFeatures[addOnName].features.find((f: Feature) => { return f.uuid === uuid })
        if (feature) {
            deleteConnection(feature)
            // setAddOnFeatures({...addOnFeatures, [addOnName]: addOnFeatures[addOnName].filter((f :Feature) => {return f.uuid !== uuid})})
        }
    }

    const deleteConnection = (feature: Feature): Boolean => {
        if (feature.properties![ADDON_KEY]) {
            if (feature.properties![ADDON_KEY] instanceof connectioAddOns.ConnectedTo) {
                
            }
        }
        return false
    }

    useEffect(() => {
        if (props.features) {
            setLayeredFeatures(createLayers(props.features))
            setProperties(getProperties())
            addAllAddOnFeatures(props.features)
        }
    }, [props.features, createLayers, getProperties])

    useEffect(() => {
       
    }, [connectionsFilter])

    useEffect(() => {

    }, [properties])

    const updateLayers = useCallback(() => {
        if (layeredFeatures) {
            const ls: ILayer[] = Object.keys(layeredFeatures).map(key => { return { name: key, count: layeredFeatures![key].length } as ILayer })
            setLayers(ls)
        }
    }, [layeredFeatures])

    useEffect(() => {
        updateLayers()
        
    }, [layeredFeatures, updateLayers])

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (!layersLoaded && layers) {
            setSelectedLayers(layers)
            setLayersLoaded(true)
        }
    }, [layers, layersLoaded])

    useEffect(() => {

    }, [selectedLayers])

    useEffect(() => {

    }, [edittingNodeId])

    useEffect(() => {
        if (edittingNode) {
            setProperties(updateProperties(edittingNode))
        }
    }, [edittingNode, edittingNode?.properties])

    useEffect(() => {

    }, [selectedFeatures])

    useEffect(() => {

    }, [addOnFeatures])

    useEffect(() => {

    }, [selectedPropertyMenu])

    const onMouseOverFeature = (id: string) => {
        if (selectedPropertyMenuRef.current.value === "Properties") {
            showProperties(id)
        }        
    }

    const onMouseOutFeature = (id: string) => {
        if (selectedPropertyMenuRef.current.value !== "Properties") {
            return
        }
        if (selectedFeatures.current.length === 1) {
            showProperties(id)
            return
        }
        setEdittingNodeId(undefined)
        setEdittingNode(undefined)
    }

    const onFoundMapRelationships = (relationships: IRelationships) => {
        // setRelationships(relationships)
    }

    const showProperties = (id: string) => {
        if (edittingNodeId === id) {
            return
        }
        setEdittingNodeId(id)
        if (selectedPropertyMenuRef.current.value !== "Properties" && selectedPropertyMenuRef.current.value !== "Coordinates"){
            selectedPropertyMenuRef.current = { value: 'Properties', label: 'Properties' }
        }
        
        let feature: Feature | undefined
        if (props.features) {
            feature = props.features.find((f: Feature) => { return f.geometry!.uuid === id })
            if (feature) {
                setEdittingNode({ ...feature })
            }
        }
    }

    const onSelectedFeatures = (ids: string[]) => {
        selectedFeatures.current = ids
        if (ids.length === 1) {
            showProperties(ids[0])
        }
    }

    const menuOptions = (): IOption[] => {
        return [
            { value: 'Layers', label: 'Layers' },
            { value: 'Properties', label: 'Properties' },
            { value: 'Coordinates', label: 'Coordinates' },
            { value: 'UndirectedGraph', label: 'Undirected Graph' },
            { value: 'DirectedGraph', label: 'Directed Graph' }
        ]
    }

    const onPropertyMenuChange = (newValue: OnChangeValue<any, false>) => {
        if (newValue !== null) {
            if (newValue.value === 'Layers') {
                updateLayers()
            }
        }
        selectedPropertyMenuRef.current = (newValue === null ? { value: 'Layers', label: 'Layers' } : newValue)
        setSelectedPropertyMenu(selectedPropertyMenuRef.current)
    }

    const onMapError = (error: IAlert) => {
        if (props.onError) {
            props.onError(error)
        }
    }

    const onLayerNameChange = (item: ILayer, newName: string) => {
        let newFeatures = { ...layeredFeatures }
        if (newFeatures!.hasOwnProperty(newName)) {
            newFeatures![newName] = [...newFeatures![newName], ...newFeatures![item.name]]
            delete newFeatures[item.name]
        } else {
            newFeatures![newName] = [...newFeatures![item.name]]
            delete newFeatures[item.name]
        }
        newFeatures![newName].forEach((f: Feature) => {
            if (!f.properties) {
                f.properties = {}
            }
            f.properties["layer"] = newName
        })

        setLayeredFeatures(newFeatures)
    }

    const updateFeatures = (features: Feature[], persist : boolean) => {
        props.onFeaturesChanged(features, persist)
        
        setLayeredFeatures(createLayers(features))
        addAllAddOnFeatures(features)
    }

    const onChange = (name: string, value: string) => {
        let feature = props.features!.find((f: Feature) => { return f.geometry!.uuid === edittingNode!.geometry!.uuid })
        if (feature) {
            if (feature.properties === undefined) {
                feature.properties = {}
            }
            feature.properties![name] = value
            setEdittingNode({ ...feature })
            updateFeatures(props.features!, true)
        }
    }

    const onDeleteLayer = (item: ILayer) => {
        let newFeatures : Feature[] = props.features!.filter((f: Feature) => { return f.properties!.layer !== item.name })
        updateFeatures(newFeatures, true)
    }

    const onDeleteFeature = (uuid: string, layerInfo?: LayerInfo) => {
        if (layerInfo && layerInfo.hasOwnProperty(IS_ADD_ON_KEY) && layerInfo[IS_ADD_ON_KEY]) {
            deleteAddOnFeature(uuid, layerInfo[LAYER_NAME_KEY])
            props.onFeaturesChanged(props.features!, false)
        } else {
            let newFeatures = props.features!.filter((f: Feature) => { return f.uuid !== uuid })
            updateFeatures(newFeatures, true)
        }
    }

    const onDeleteProperty = (name: string) => {
        let feature = props.features!.find((f: Feature) => { return f.uuid === edittingNode!.uuid })
        if (feature) {
            if (feature.properties === undefined) {
                feature.properties = {}
            }
            delete feature!.properties![name]
            setEdittingNode({ ...feature })
            updateFeatures(props.features!, true)
        }
    }

    const onConnectionsChange = (features: Feature[]) => {
        addConnectionFeatures(features)
        props.onFeaturesChanged(props.features!, false)
    }

    const onConnectionsFilterChanged = (value: ConnectionsFilter) => {
        setConnectionsFilter(value)
    }
  
    return (
        <>
            <div className='columns ml-1 mt-0 pt-0'>
                <div className='box column is-2'>
                    <SelectBox
                        className='smallText'
                        options={menuOptions()}
                        onChange={onPropertyMenuChange}
                        isMulti={false}
                        selected={selectedPropertyMenuRef.current === null ? undefined : selectedPropertyMenuRef.current.value}
                    />
                    <div style={{ display: selectedPropertyMenuRef.current.value === 'Layers' ? 'block' : 'none' }}>
                        <Layers
                            layers={layers}
                            selectedLayers={selectedLayers}
                            onLayerNameChange={onLayerNameChange}
                            onSelectedLayers={setSelectedLayers}
                            onDeleted={onDeleteLayer}
                        />
                    </div>
                    {
                        (selectedPropertyMenuRef.current.value === 'Properties' && edittingNode) &&
                        <NodeProperties
                            feature={edittingNode}
                            properties={properties || {}}
                            onChange={onChange}
                            onDelete={onDeleteProperty}
                        />
                    }
                    {
                        (selectedPropertyMenuRef.current.value === 'Coordinates' && edittingNode) &&
                        <div className='box ml-0 mt-2 mb-0 p-1 smallText'>
                            <Coordinates
                                feature={edittingNode}
                            />
                        </div>
                    }
                    {
                        (selectedPropertyMenuRef.current.value === 'UndirectedGraph' && props.features !== undefined) &&
                        <>
                            <GraphCreator
                                features={props.features!}
                                onFeaturesChange={onConnectionsChange}
                                isExpanded={true}
                            />
                            <GraphFilter
                                onConnectionsFilterChanged={onConnectionsFilterChanged}
                            />
                        </>
                    }
                </div>
                <div className='column ml-5 mt-0 pt-0'>
                    {(layeredFeatures && layers) ? <MapViewer
                        features={layeredFeatures}
                        addOnFeatures={addOnFeatures}
                        numberOfTiles={10}
                        connectionsFilter={connectionsFilter}                        
                        onFoundMapRelationships={onFoundMapRelationships}
                        onMouseOverFeature={onMouseOverFeature}
                        onMouseOutFeature={onMouseOutFeature}
                        onSelectedFeatures={onSelectedFeatures}
                        onDeleteFeature={onDeleteFeature}
                        selectedLayers={selectedLayers}
                        onError={onMapError}
                    // center={mapCenter}
                    /> : 'Loading features...'}
                </div>
            </div>
        </>
    )
}


export default MapPreview;