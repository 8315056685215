import { useState, useEffect, useCallback } from 'react';
import Autocomplete from 'standard/autocomplete/autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'

const editTime : number = 10000

const NodeProperty = (props: {
        name : string,
        value? : string
        className?: string
        valueSuggestions: string[]
        onChanged: (key: string, value: string) => void
        onDelete: (key: string) => void
    }) => {
        
    let [isEditting, setIsEditting] = useState<boolean>(false);
    let [value, setValue] = useState<string>(""); 
    let [canEdit, setCanEdit] = useState<boolean>(false); 
    
    useEffect(() => {
        if (props.name === 'uuid'){
            return
        }    
        if (typeof props.value === 'object'){
            return
        }
        setCanEdit(true)
        setValue(props.value || "")
    }, [props.value])

    let [resetTimeout, setResetTimeout] = useState<any>(); 

    const editCompleteRequest = useCallback((e: any) => {
         clearTimeout(resetTimeout);
        setIsEditting(false)
        props.onChanged(props.name, value)
    }, [resetTimeout])

    useEffect(() => {      
       
        
    }, [isEditting])

    useEffect(() => {      
            
    }, [value])

    const editRequest = (e: any) => {
        if (!isEditting){
            setIsEditting(true)
            setResetTimeout(setTimeout(editCompleteRequest, editTime))
        }
    }

    const onChange = (value : string) => {
        setValue(value)
        clearTimeout(resetTimeout);
        setResetTimeout(setTimeout(editCompleteRequest, editTime))      
    }

    const handleDeleteClick = (e: any) => {
        e.preventDefault();
        props.onDelete(props.name)
    }


    return (
        <> {!canEdit || <div className={`${props.className ? props.className : ''}`}>
                            <div className='columns m-0 p-0'>
                                <div className='column is-four-fifths m-0 p-0'>
                                    <div className="field" key={props.name}>
                                        <label className="label smallText">{props.name}</label>
                                    </div>
                                </div>
                                <div className='column clickable is-one-fifth m-0 p-0 has-text-danger smallText' onClick={event => handleDeleteClick(event)}>
                                    <FontAwesomeIcon icon={faTrash}  />
                                </div>
                            </div>
                            <div className="control is-success edittable smallText mt-1 mb-1" >
                                {isEditting || <span onClick={(e: any) => editRequest(e)}>{value === '' ? '-' : value}</span>}
                                {!isEditting || <Autocomplete 
                                                    value={value} 
                                                    suggestions={props.valueSuggestions} 
                                                    onChange={onChange} 
                                                    onBlur={editCompleteRequest} 
                                                    showNoSuggestions={false}
                                                />}
                            </div>
                            <hr className='m-0 p-0'/>
                        </div>
            }
            
        </>
    )
}

export default NodeProperty;