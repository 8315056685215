// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTrash } from '@fortawesome/free-solid-svg-icons'

const Coordinate = (props: {
	index : number
	coordinate : number[]
	className? : string
    onDeleted: (index : number) => void
}) => {
	return (<div className={`columns mt-1 mb-0 pb-0 ${props.className ? props.className : ''}`}>
				<div className="column is-1 ml-2 mb-1 pb-0 mt-0 pt-0">
					<span className='is-pulled-right'>{props.index}.</span>
				</div>
				<div className="column is-5 mb-0 pb-1 mt-0 pt-0">
					<span className='is-pulled-right'>{props.coordinate[1].toFixed(6)}</span>
				</div>
				<div className="column is-5 mb-0 pb-1 mt-0 pt-0">
					<span className='is-pulled-right'>{props.coordinate[0].toFixed(6)}</span>
				</div>
				{/* <div className="column clickable is-2 has-text-danger mb-0 pb-1 mt-0 pt-0">
                    <span className='is-pulled-right'><FontAwesomeIcon  icon={faTrash} onClick={() => props.onDeleted(props.index)} /></span>
                </div> */}
            </div>
	)
}

export default Coordinate;