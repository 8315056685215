import { useEffect, useMemo } from 'react';
import Cell from './cell';
import { v4 as uuidv4 } from 'uuid';

const Row = (props: {
    headers: string[]
    idx: number
    rowData: any
    isEdittable: boolean
    style?: string
    onValueChange: (uuid: string, key: string, value: any) => void
    onValueClick: (e: any, key: string, value: any) => void
    onValueDoubleClick: (e: any, key: string, value: any) => void

}) => {
    const key = useMemo(
        () => uuidv4(),
        []);


    useEffect(() => {
        
    }, [])

    useEffect(() => {

    }, [props.rowData])
      
    const onValueChange = (key: string, value: any) => {
        props.onValueChange(props.rowData.uuid, key, value)
    }

    const displayContent = () => {        
        return <div key={key} className={`columns mt-1 mb-0 pb-0 ml-1 is-vcentered`}>
            {props.headers.map((header) => {
                return <Cell key={`${key + header} `} uuid={props.rowData.uuid} isEdittable={props.isEdittable} style={`${(props.idx + 1) % 2 === 0 ? 'alternate' : ''}`} propertyName={header} value={props.rowData[header]} onValueChange={onValueChange} onValueClick={props.onValueClick} onValueDoubleClick={props.onValueDoubleClick} />
            })}
        </div>               
    }

    return  displayContent()
}

export default Row;