import { IApiResponse, IDigitalTwin } from "../interfaces";
import { getDigitalTwinService } from "../utils";
import { ApiResponse, tryProcessResponse } from "./shared";

export async function getDigitalTwin(token: string, uuid: string, inScopeOnly : boolean, onlyMeta : boolean): Promise<IDigitalTwin> {
    const promise = new Promise<IDigitalTwin>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("inscopeonly", `${inScopeOnly}`);
        urlencoded.append("onlymeta", `${onlyMeta}`);

        const request = new Request(`${getDigitalTwinService()}/digitaltwin/${uuid}?${urlencoded}`, requestOptions);

        
        fetch(request)
            .then(response => {                    
                    return response.json()}
                )
            .then(result => {
                const response = tryProcessResponse(result);
                if(response !== undefined){
                    rej(response);
                    return;
                }
                const item : IDigitalTwin = {
                        uuid: result.response.uuid,
                        uri: result.response.uri,
                        labels: result.response.labels,
                        comments: result.response.comments,
                        individuals: result.response.individuals,
                        scopes: result.response.scopes,
                        imports: result.response.imports,
                        type: result.response.type,
                        isprivate: result.response.isprivate,
                    };
                res(item)
            })
            .catch(error => rej(error)
            );
    });
    return promise;
}

export async function getDigitalTwinWithUri(token: string, uri : string, inScopeOnly : boolean): Promise<IDigitalTwin> {
    const promise = new Promise<IDigitalTwin>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        var urlencoded = new URLSearchParams();
        urlencoded.append("uri", uri);
        urlencoded.append("inScopeOnly", `${inScopeOnly}`);

        fetch(`${getDigitalTwinService()}/digitaltwin?${urlencoded}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result);
                
                if(response !== undefined){
                    rej(response);
                    return;
                }
                const item : IDigitalTwin = {
                        uuid: result.response.uuid,
                        uri: result.response.uri,
                        labels: result.response.labels,
                        comments: result.response.comments,
                        individuals: result.response.individuals,
                        scopes: result.response.scopes,
                        imports: result.response.imports,
                        type: result.response.type,
                        isprivate: result.response.isprivate,
                    };
                res(item)
            })
            .catch(error => console.log('error', error));
    });
    return promise;
}


export async function createDigitalTwin(token: string, data: IDigitalTwin) : Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const request = new Request(`${getDigitalTwinService()}/digitaltwin`, requestOptions);

        fetch(request)
            .then(response => {
                return response.json()}
                )
            .then(result => {
                const response = tryProcessResponse(result);
                if(response !== undefined){
                    rej(response);
                    return;
                }
                res(result);
            })
            .catch(error => rej(
                error));
    })
    return promise;
}

export async function updateDigitalTwin(token: string, data: IDigitalTwin): Promise<IApiResponse> {
    const promise = new Promise<IApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");

        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(`${getDigitalTwinService()}/digitaltwin/${data.uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => {
                    rej(error);
            })

    });
    return promise;
}
