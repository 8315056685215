import { Feature, Features } from "standard/geojson/@types";
import { DxfParser } from "dxf-parser";
import { readDxfFile } from "../../helpers/parsingDataFile";

export const importDxfFile = (props : {
    features : Features, 
    res: (value: Features | PromiseLike<Features>) => void,
    rej: (reason?: any) => void,
    fileContent: string
    setEditingMetadata: (val: boolean) => void,
    setCurrentGeo: (val: Feature | undefined) => void
    }) => {
    let features = props.features;
    const loadLayers = ["Armatur zu", "Armatur", "Endkunde", "Flussratesensor", "Höhe", "Hydrant", "Inlet", "Outlet", "Rohre", "Bezeichnung"]
    const parser = new DxfParser();
    const dxf = parser.parseSync(props.fileContent);
    if (dxf) {
        features.features = readDxfFile(dxf, loadLayers);
        props.setEditingMetadata(true);
        props.setCurrentGeo(undefined);
        props.res(features);
    }
}