import { configureStore } from '@reduxjs/toolkit'
import { appContext } from 'standard/iam/context/reducers';
import { authentication } from 'standard/iam/reducers';
import { alert } from 'standard/alert/reducers';
import { navigation } from 'standard/navigation/redux/reducers'
import { sessionmanager } from 'standard/iam/redux/middleware/sessionmanager';
import { configuration } from 'standard/configuration/reducers';
import { ui } from 'standard/UI/reducers';
import { logoutMiddleware }  from './logoutMiddleware';

export const store = configureStore({
  reducer: {
    authentication: authentication,
    appContext: appContext,
    alert : alert,
    navigation : navigation,
    configuration: configuration,
    ui: ui
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .prepend(
        sessionmanager,
        logoutMiddleware,
      )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch