import { IColumnMapping, ConversionMapping, IRowMapping, MatchingType } from "./@types"
import { ColumnDrops } from "./@types/IMappingTemplate"

export const MapLayer = (mappings: IRowMapping[], item: { [k: string]: any }): { [k: string]: any } => {
    
    for (var mapping of mappings) {
        if (item.hasOwnProperty(mapping.input)) {
            
            if (mapping.value !== undefined && mapping!.values === undefined){
                mapping = {...mapping, ...{values : [mapping.value]}}
            }
            
            if (mapping.values && mapping.values.length > 0){ 
                for (let value of mapping.values){
                    if (item[mapping.input] === value) {
                        item['layer'] = mapping.mapping
                    }
                }
            }else{
                item['layer'] = mapping.mapping
            }
        }
    }
    return item
}

export const MapColumn = (mappings: IColumnMapping[], item: { [k: string]: any }): { [k: string]: any } => {
    for (var mapping of mappings) {
        if (item.hasOwnProperty(mapping.input) === false) continue;
        if (item[mapping.input] === null) continue;

        if (mapping.values.length === 0 || mapping.values.includes(item[mapping.input])){
            item[mapping.mapping] = item[mapping.input]
        }
    }
    return item
}

const compare = (value1 : string, value2 : string, machtType : MatchingType) : boolean => {
    if (value1 === undefined){
        return false
    }
    if (value2 === undefined){
        return false
    }
    value1 = value1.toLowerCase()
    value2 = value2.toLowerCase()
    
    if (machtType === MatchingType.EXACT){
        return value1 === value2
    }
    if (machtType === MatchingType.CONTAINS){
        return value1.includes(value2)
    }
    if (machtType === MatchingType.STARTSWITH){
        return value1.startsWith(value2)
    }
    if (machtType === MatchingType.ENDSWITH){
        return value1.endsWith(value2)
    }
    return false
}

export const Convert = (mappings: ConversionMapping[], item: { [k: string]: any }): { [k: string]: any } => {
    for (var mapping of mappings) {        
        if (item.hasOwnProperty(mapping.input)) {
            for (let value of mapping.values) {    
                if (compare(item[mapping.input]?.toString(), value.toString(), mapping.matchcriteria)) {   
                    if (mapping.newproperty !== undefined && mapping.newproperty !== '') {
                        item[mapping.newproperty] = mapping.output
                    } else {
                        item[mapping.input] = mapping.output
                    }
                }
            }         
        }
    }
    return item
} 

export const DropColumns = (columnDrops : ColumnDrops, item: { [k: string]: any }): { [k: string]: any } => {
    for (var col of columnDrops){ 
        delete item[col]
    }   
    return item
} 
