import ReactDOM from "react-dom/client";
import './index.css';
import 'bulma/css/bulma.min.css';
import App from './App';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import { store } from './helpers/store'
import { ErrorBoundary } from './components/configuration/ErrorBoundary'
// import { Auth0Provider } from "@auth0/auth0-react";
// import history from "standard/utils/history";
// import * as authConfig from "standard/auth0/config";

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.origin
//   );
// };

// const config = authConfig.getConfig();

// const providerConfig = {
//   domain: config.domain,
//   clientId: config.clientId,
//   scope:"offline_access",
//   onRedirectCallback,
//   authorizationParams: {
//     redirect_uri: `${process.env.REACT_APP_AUTHO_REDIRECT}`,
//     cacheLocation: 'localstorage',
//     useRefreshTokens: true,
//     ...(config.audience ? { audience: config.audience } : null),
//   },  
// };

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  //<Auth0Provider  {...providerConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
 // </Auth0Provider>
  // </React.StrictMode>
)

reportWebVitals();
