import { LINE_STRING, POINT, POLYGON } from "standard/geojsonMap/constants";
import { Feature, Geometry } from "./@types";
import * as Joi from 'joi';

export type column = {
    name: string
    inDomain: boolean
}


export const distance2Line = (line: number[][], point: number[]) => {
    let [x1, y1] = line[0];
    let [x2, y2] = line[line.length-1];
    let [x0, y0] = point;
    return Math.abs((x2-x1)*(y1-y0) - (x1-x0)*(y2-y1))/Math.sqrt(Math.pow(x2-x1, 2) + Math.pow(y2-y1, 2))
}

export const featureFactory = (  
    uuid: string,
    type: string,  
    geometry: Geometry,
    properties: {[k:string]: any},
) : Feature => {
    return {
        type: type,
        uuid: uuid,        
        properties: properties,
        geometry: geometry,
    } as Feature
}

export const geometryFactory = (    
    uuid: string,
    type: string, //polygon, point, linestring
    coordinates: any
) : Geometry => {
    return {uuid : uuid, type : type, coordinates : coordinates} as Geometry
}

export const getPropertyKeys = (features: Feature[]): string[]  => {
    let keys: Set<string>  = new Set()
    if (features) {
        features.forEach(feature => {
            if (feature.properties) {
                Object.keys(feature.properties).forEach((key: string) => {
                    keys.add(key)
                });
            }
        })
    }
    return Array.from(keys).sort();
}

export const getPropertyValues = (features: Feature[], property : string): string[] => {
    let values: Set<string> = new Set()
    if (features) {
        features.forEach(feature => {
            if (feature.properties && feature.properties[property])  {              
                values.add(feature.properties![property])
            }
        })
    }
    return Array.from(values).sort();
}

export const sortColumns = (a : column , b : column) => {
    if (a.name === 'layer') {
        return -1
    }
    if (b.name === 'layer') {
        return 1
    }
    if (a.name === 'id') {
        return -1
    }
    if (b.name === 'id') {
        return 1
    }
    if (a.name === 'uuid') {
        return -1
    }
    if (b.name === 'uuid') {
        return 1
    }
    if (a.inDomain && !b.inDomain) {
        return -1
    }
    if (!a.inDomain && b.inDomain) {
        return 1
    }
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if (x < y) { return -1; }
    if (x > y) { return 1; }
    return 0;
}

const standardizeFeature = (feature: any): any => {
    if (feature.geometry.coordinates === undefined) {
        feature.geometry.coordinates = feature.geometry.coords    
    }
    return feature
}

const jsonPointSchema = Joi.object({
    uuid: Joi.string().optional(),
    type: Joi.string().valid('Point').required(),
    coordinates: Joi.array().length(2).required()
});

export const getGeoValidFeatures = (features: Feature[]): Feature[] => {
    let validF : Feature[] = []
    features.forEach((f: any) => {
        if (f.geometry.coordinates || f.geometry.coords) {
            f = standardizeFeature(f)   
            
             if (f.geometry.type.toLowerCase() === LINE_STRING.toLowerCase()) {
                validF.push(f)
             } else if (f.geometry.type.toLowerCase() === POINT.toLowerCase()) {
                const result = jsonPointSchema.validate(f.geometry);
                if (!result.error){
                    validF.push(f)
                } 
             } else if (f.geometry.type.toLowerCase() === POLYGON.toLowerCase()) {
                validF.push(f)
             }
        }
    })
    


    return validF
}
