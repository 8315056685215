import { useState, useEffect } from 'react';
import { Feature } from 'standard/geojson/@types';
import UIWrapper from 'standard/UI/components/uiwrapper';
import DetailsPreview from './transformAndLoad/detailsPreview';
import { useUpload } from './hooks/useUpload';
import { Mapping } from './types/uploads';

const DataPreviewDetails = (props: {
  uploadKey: string,
  updateMapping: (key: string, mapping: Mapping, onComplete: () => void) => void,  onEditComplete: () => void  
  onNewUpload: (name: string, features: Feature[], onComplete: () => void) => void
  onUpdateFeatures: (key: string, features: Feature[], onComplete: () => void) => void
  setProcessing: (value: boolean) => void
}) => {
  const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
  let [changedDetails, setChangedDetails] = useState<Mapping>();
  let { upload, features, reload, onPropertyChanged, onFeatureAdd, onFeaturesDelete, onGeometryChanged } = useUpload({ uploadKey: props.uploadKey })


  useEffect(() => {
    document.title = 'Data Preview';
  }, [])

  useEffect(() => {

  }, [props.uploadKey])

  useEffect(() => {
    
  }, [upload, features])

  useEffect(() => {
    if (changedDetails) {
      setDetailsChanged(true)
    } else {
      setDetailsChanged(false)
    }
  }, [changedDetails])


  const onUpdateFeatures = (key: string, features: Feature[], onComplete: () => void) => {
    const combineCallbacks = () =>{
      reload()
      onComplete()
    }
    props.onUpdateFeatures(key, features, combineCallbacks)
  }

  const onDetailsChanged = (mapping: Mapping, doUpdate: boolean) => {
    if (doUpdate) {
      props.updateMapping(props.uploadKey, mapping, updateComplete)
      setChangedDetails(undefined)
    } else {
      setChangedDetails(mapping)
    }
  }

  const updateComplete = () => {
    reload()
  }

  const onSave = () => {
    props.updateMapping(props.uploadKey, changedDetails!, updateComplete)
    setChangedDetails(undefined)
  }

  const backToUploads = () => {
    props.onEditComplete()
  }

  return <>
    
    <>
      <UIWrapper>
        <div className='box m-1 p-2 '>
          <div className='columns'>
            <div className='column ml-2'>
              <nav className="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li><span className='clickable' onClick={backToUploads}>Uploads</span></li>
                  <li className="is-active">{upload?.name}</li>
                </ul>
              </nav>
              
            </div>
            <div className='column mr-2'>
              {!detailsChanged ||
                <button className={`button smallText is-pulled-right ${!detailsChanged || 'is-primary'}`} key='new prop' onClick={() => onSave()}>
                  Save Mapping
                </button>
              }
            </div>
          </div>
        </div>
      </UIWrapper>
      {(upload && features) && <DetailsPreview 
        upload={upload}
        features={features}
        onMappingChanged={onDetailsChanged}
        onNewUpload={props.onNewUpload} 
        onUpdateFeatures={onUpdateFeatures}
        onFeatureAdd={onFeatureAdd}
        onFeaturesDelete={onFeaturesDelete}
        setProcessing={props.setProcessing}
        onPropertyChange={onPropertyChanged}
        onGeometryChange={onGeometryChanged}
         />}
    </>

  </>
}

export default DataPreviewDetails;