import { useEffect } from 'react';
import { ILabelComment } from 'standard/ontology/interfaces';
import useNetworks from 'standard/ontology/digitalTwin/hooks/useNetworks';
import Selector from '../list/selector';

const SelectNetwork = (
    props: {title : string, 
    onSelectionChanged: (item : ILabelComment[]) => any }
    ) => {
    const { networks} = useNetworks({ inScopeOnly: true , onlyMeta : true})

    useEffect(() => {

    }, [networks])

    return (<Selector title={props.title} items={networks || []} onSelectionChanged={props.onSelectionChanged} />)
}

export default SelectNetwork;