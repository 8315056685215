import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux'
import { IDomainModel } from 'standard/ontology/interfaces';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { getDomainModels } from 'standard/ontology/domainModel/service';
import AlertMessage from "../../../standard/alert/components/alertMessage";
import { IAlert } from "standard/alert";

const SelectDomainModel =
    (props: {
        label: string,
        exclude?: string[],
        selected?: string,
        onSelect: (value: string) => any,
        alert?: IAlert
    }
    ) => {
        const [filteredDomainModels, setFilteredDomainModels] = useState<{ value: string; label: string; }[]>();
        const [options, setOptions] = useState<{ value: string; label: string; }[]>();
        const [selected, setSelected] = useState<{ value: string; label: string; }>();
        
        useEffect(() => {
            setSelected(options?.find((m) => m.value === props.selected))
        }, [props.selected, ]);

        useEffect(() => {
            getDomainModels()
            .then((items: Array<IDomainModel>) => {
                    setOptions(items.map((item) => { return { value: item.uri, label:  item.labels.default } }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }, []);

        useEffect(() => {
            setFilteredDomainModels(options)
            setSelected(options?.find((m) => m.value === props.selected))
        }, [options]);

        useEffect(() => {
        
        }, [selected]);

        useEffect(() => {
            
        }, [filteredDomainModels, selected]);

        const onInputChange = (inputValue: any, actionMeta: any) => {
            onSearch(inputValue)
        }

        const onSearch = (search: string) => {
            if (search.trim() === '') {
                setFilteredDomainModels(options)
                return
            }

            if (options !== undefined) {
                const searches = search.toLowerCase().split(' ')
                let filtered: any[] = options
                for (let i = 0; i < searches.length; i++) {
                    filtered = filtered &&
                        filtered.filter(option => (option.label).toLowerCase().indexOf(searches[i]) > -1)
                    if (filtered.length === 0) {
                        break
                    }
                }
                setFilteredDomainModels(filtered)
            }
        }

        const onChange = (newValue: OnChangeValue<any, false>,
            actionMeta: ActionMeta<any>) => {
                setSelected(newValue == null ? undefined : newValue);
                props.onSelect(newValue == null ? undefined : newValue.value)
            // const newimports = newValue.map((item: any) => { return item.value! })
            // domainModel!.imports = newimports
            // props.updateModel(domainModel!);

        }
        
        return (
            <div className='field mt-2'>
                <label className="label smallText" htmlFor="label">{props.label}</label>
                <div className="control mt-2">
                    <Select
                        value={selected}
                        isSearchable={true}
                        isClearable={true}
                        name="models"
                        options={filteredDomainModels}
                        onInputChange={onInputChange}
                        onChange={onChange}
                        className="basic-multi-select smallText"
                        classNamePrefix="select"
                    />
                </div>
                <AlertMessage alert={props.alert} />
                <hr className="list-divider" />
            </div>
        )
    }

export default SelectDomainModel;