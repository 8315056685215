import { LayerMetaData } from "./layerInfo"

export class MapMouseEvents {
	private down?: { x: number, y: number }

	private pos?: { x: number, y: number }

	private drag: boolean = false

	private onLayer?: LayerMetaData = undefined

	public layerMoved: boolean = false

	mouseDown(value: { x: number, y: number }) {
		this.down = value
	}

	mouseUp() {
		this.down = undefined
	}

	mouseMove(value: { x: number, y: number }) {
		this.pos = value
	}

	dragStart() {
		this.drag = true
	}

	dragEnd() {
		this.drag = false
	}

	isDragging(): boolean {
		return this.drag === true
	}

	getDown(): { x: number, y: number } | undefined {
		return this.down
	}

	getPos(): { x: number, y: number } | undefined {
		if (this.pos) {
			return this.pos
		} else if (this.down) {
			return this.down
		}

		return undefined
	}

	setOnLayer(value: LayerMetaData | undefined) {
		this.onLayer = value
		if (!value) {
			this.layerMoved = false
		}
	}

	getOnLayer(): LayerMetaData | undefined {
		return this.onLayer
	}
}