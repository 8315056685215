import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux'

import { IDigitalTwin } from '../../interfaces';
import { DigitalTwin } from '../digitaltwin';

import { getDigitalTwin } from '../service';
import { IAlert, ActionTypes } from "../../../alert";

const newModelKey = "newOntologyKey"
const useNetwork = (props: { OntUUID?: string, inScopeOnly?: boolean, metaOnly?: boolean }) => {
    const [network, setNetwork] = useState<IDigitalTwin>();
    const authenticated = useAppSelector(state => state.authentication)
    const [networkAlert, setNetworkAlert] = useState<IAlert>();

    useEffect(() => {
        loadExisting()
    }, [props.OntUUID]);


    const loadExisting = () => {
        if (props.OntUUID === undefined) {
            setNetwork(undefined)
            return
        }
        getDigitalTwin(
            authenticated.user!.token, props.OntUUID!,
            props.inScopeOnly !== undefined ? props.inScopeOnly : true,
            props.metaOnly !== undefined ? props.metaOnly : true
        )
            .then((item: IDigitalTwin) => {
                setNetwork(new DigitalTwin(item));
            })
            .catch((err) => {
                setNetwork(undefined);
                setNetworkAlert({
                    message: err.message,
                    type: ActionTypes.ERROR,
                } as IAlert)
            });
    }
            
    const reloadNetwork = () => {
        loadExisting()
    }

    const updateModelState = (newModel: IDigitalTwin) => {
        setNetwork(new DigitalTwin(newModel))
        localStorage.setItem(props.OntUUID ? props.OntUUID : newModelKey, JSON.stringify(newModel))
    }


    return { network, updateModelState, reloadNetwork, networkAlert };
}


export default useNetwork

