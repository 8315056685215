import '../style.css';
import { useState, useEffect, useRef } from 'react';
import SelectDomainModel from 'components/domainmodel/select/selectdomainmodel';
import { Mapping, Upload_Meta } from '../types/uploads';
import { default as RowMapper } from '../dataMappings/rows/mapperList';
import { default as ColumnMapperList } from '../dataMappings/columns/mapperList';
import { default as ValueConverter } from '../dataMappings/conversion/mapperList';
import { default as RecordDelete } from '../dataMappings/recordDelete/mapperList';

import useDomainModel from 'standard/ontology/domainModel/hooks/useDomainModel';
import { ColumnDrops, ColumnMappings, ConversionMappings, IMappingTemplate, RecordDeletes, RowMappings } from 'standard/datatools/@types/IMappingTemplate';
import DataList from 'standard/list/dataList';
import Map from 'standard/geojsonMap/map/mapRaw'
import * as Transformer from 'standard/datatools/Transformer';
import * as FeatureTransformer from 'standard/datatools/FeatureTransformer';
import ColumnDrop from '../columnDrop';
import { IOption, IOptions } from 'standard/forms/interfaces/IOption';
import { Feature } from 'standard/geojson/@types';
import useDomainModelsAllAttributes from 'standard/ontology/domainModel/hooks/useDomainModelAllClasses';
import { IRecordAdd } from 'standard/datatools/@types';
import { AppendRecordDelete, RecordDeleteFactory } from '../dataMappings/recordDelete/utils';
import HMenu from 'standard/navigation/hMenu/components/hMenu';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import MessageWrapper from '../messageWrapper';

import { useAppSelector } from 'hooks/redux';
import UIWrapper from 'standard/UI/components/uiwrapper';
import Summary from 'standard/summary/summary';
import CreateClone from '../export/createClone';
import OverwriteCurrent from '../export/overwriteCurrent';
import ExportToFile from '../export/exportToFIle';
import { getGeoValidFeatures } from 'standard/geojson/utils';
import { MENUS, menus } from '../menus';
import Import from '../import/import';
import ExportTemplate from '../export/exportTemplate';
import AddTemplate from './addTemplate';
import CenteredScreen from 'components/standard/centeredScreen/centeredScreen';
import Validated from 'standard/ontology/digitalTwin/components/features/validated';
import Unmapped from 'standard/ontology/digitalTwin/components/features/unmapped';
import ModalDialog from 'standard/dialog/modaldialog';
import AlertMessage from 'standard/alert/components/alertMessage';

const DetailsPreview = (props: {
    upload: Upload_Meta,
    features: Feature[],
    onMappingChanged: (mapping: Mapping, doUpdate: boolean) => void
    onPropertyChange: (uuid: string, properties : Record<string, any>) => void
    onFeatureAdd: (feature: Feature) => void
    onFeaturesDelete: (uuids: string[]) => void
    onGeometryChange: (uuid: string, index: number, value: number[]) => void
    onNewUpload: (name: string, features: Feature[], onComplete: () => void) => void
    onUpdateFeatures: (key: string, features: Feature[], onComplete: () => void) => void
    setProcessing: (value: boolean) => void
}) => {
    const ui = useAppSelector(state => state.ui)
    const [nextMenu, setNextMenu] = useState<string>();
    const [currentMenu, setCurrentMenu] = useState<string>('');
    const [mapping, setMapping] = useState<Mapping>();
    const { domainModel, domainModelAlert } = useDomainModel({ URI: props.upload.mapping && props.upload.mapping.targetModel, inScopeOnly: false, onlyMeta: false });
    const { referencedClasses, referencedDataProps } = useDomainModelsAllAttributes({ domainModel: domainModel });
    const [transformation, setTransformation] = useState<Feature[]>();
    const [geoValidFeatures, setGeoValidFeatures] = useState<Feature[]>();
    const [transformationProperties, setTransformationProperties] = useState<({ [k: string]: any; } | undefined)[]>();
    const [featureColumns, setFeatureColumns] = useState<string[]>();
    const [featureProperties, setFeatureProperties] = useState<({ [k: string]: any; } | undefined)[]>();
    const [transformationColumns, setTransformationColumns] = useState<string[]>();
    const resultsActions: IOption[] = [{ value: "saveclone", label: "Save Clone" }, { value: "saveoverwrite", label: "Save Overwrite" }]
    const [selectNetwork, setSelectNetwork] = useState<boolean>(true);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setMapping(props.upload.mapping)
    }, [props.upload])

    useEffect(() => {
        setMapping(props.upload.mapping)
    }, [props.upload])

    useEffect(() => {
        if (mapping)  {
            setTransformation(Transformer.ApplyTransformation(mapping.template, props.features))
        }
        setGeoValidFeatures(getGeoValidFeatures(props.features))
    }, [mapping, props.features]);

    useEffect(() => {

    }, [mapping?.targetModel])

    useEffect(() => {
        if (props.features) {
            const featureProperties: ({ [k: string]: any; } | undefined)[] = Object.values(props.features).map((f) => { 
                return f.properties
             })
            let keys: Set<string> = new Set()
            featureProperties.forEach((item: ({ [k: string]: any } | undefined)) => {
                Object.keys(item!).forEach((key: string) => {
                    keys.add(key)
                });
            });
            setFeatureProperties(featureProperties)
            setFeatureColumns(Array.from(keys).sort(function (a, b) {
                if (a === 'layer') {
                    return -1
                }
                if (b === 'layer') {
                    return 1
                }
                const x = a.toLowerCase();
                const y = b.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            }))
        }
    }, [props.features])

    useEffect(() => {

    }, [featureProperties]);


    useEffect(() => {


    }, [domainModel])

    useEffect(() => {


    }, [domainModelAlert])

    useEffect(() => {

    }, [ui.fullscreenenabled])

    useEffect(() => {

    }, [referencedClasses, referencedDataProps])

    useEffect(() => {
        if (transformation) {
            setTransformationProperties(Object.values(transformation).map((f) => { return f.properties }))
        }
        else {
            setTransformationProperties(undefined)
        }
    }, [transformation])

    useEffect(() => {
        if (transformationProperties) {
            let keys: Set<string> = new Set()
            transformationProperties.forEach((item: ({ [k: string]: any } | undefined)) => {
                Object.keys(item!).forEach((key: string) => {
                    keys.add(key)
                });
            });
            setTransformationColumns(Array.from(keys).sort(function (a, b) {
                if (a === 'layer') {
                    return -1
                }
                if (b === 'layer') {
                    return 1
                }
                const x = a.toLowerCase();
                const y = b.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            }))
        } else {
            setTransformationColumns(undefined);
        }
    }, [transformationProperties])


    useEffect(() => {

    }, [transformationColumns])

    useEffect(() => {

    }, [selectNetwork])

    useEffect(() => {
        if (nextMenu !== undefined) {
            setCurrentMenu(nextMenu)
        }
        setNextMenu(undefined)
    }, [nextMenu])

    useEffect(() => {
        if (transformation) return

        // let menu: IMenuItem = menus[currentMenu]
        // let loadTransformation: boolean = false

        // if (menu) {
        //     if (menu.parent && (menu.parent === MENUS.result || menu.parent === MENUS.export || menu.parent === MENUS.digitalTwin)) {
        //         //Ideally should be fully met in the swith case below
        //         loadTransformation = true
        //     }
        //     switch (menu.name) {
        //         case MENUS.mapattributes:
        //             loadTransformation = true
        //             break
        //         case MENUS.updatenetwork:
        //             loadTransformation = true
        //             break
        //         case MENUS.resultlist:
        //             loadTransformation = true
        //             break
        //         case MENUS.resultmap:
        //             loadTransformation = true
        //             break
        //         case MENUS.validated:
        //             loadTransformation = true
        //             break
        //     }
        // }

        // if (loadTransformation) {
        //     setTransformation(Transformer.ApplyTransformation(mapping.template, props.features));
        // } else {
        //     setTransformation(undefined)
        // }
        scrollToContent()
    }, [currentMenu])

    const onMenuSelected = (menu: IMenuItem) => {
        setNextMenu(menu.name)
    }

    const onResultAction = (option: IOption) => {
        if (option.value === 'saveclone') {
            props.onNewUpload(props.upload.name, FeatureTransformer.ApplyTransformation(mapping!.template, props.features), onNewUploadComplete)
        } else if (option.value === 'saveoverwrite') {
            props.onUpdateFeatures(props.upload.key, FeatureTransformer.ApplyTransformation(mapping!.template, props.features), onUpdateFeaturesComplete)
        }
    }

    const onNewUploadComplete = () => {

    }

    const onUpdateFeaturesComplete = () => {

    }

    const resultsOptions = (): IOptions => {
        return { options: resultsActions, onSelect: onResultAction }
    }
    const onTargetModelChanged = (value: string) => {
        props.onMappingChanged({ ...mapping!, ...{ targetModel: value } }, true)
    }

    const onRowMappingChanged = (mappings: RowMappings) => {
        let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ rowMappings: mappings } } : { rowMappings: mappings }
        props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
    }

    const onColumnMappingChanged = (mappings: ColumnMappings) => {
        let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ columnMappings: mappings } } : { columnMappings: mappings }
        props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
    }

    const onValueMappingChanged = (mappings: ConversionMappings) => {
        let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ conversionMappings: mappings } } : { conversionMappings: mappings }
        props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
    }

    const onListValueAction = (action: string, key: string, value: string) => {
        if (action === 'delete') {
            let recordDelete: IRecordAdd = RecordDeleteFactory(key, [value])
            let recordDeletes: RecordDeletes = AppendRecordDelete(props.upload, recordDelete)
            let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ recordDeletes: recordDeletes } } : { recordDeletes: recordDeletes }
            props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
        }
    }

    const onRecordDeletesChanged = (mappings: RecordDeletes) => {
        let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ recordDeletes: mappings } } : { recordDeletes: mappings }
        props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
    }

    const onColumnDropsChanged = (drops: ColumnDrops) => {
        let template: IMappingTemplate = mapping!.template ? { ...mapping!.template, ...{ columnDrops: drops } } : { columnDrops: drops }
        props.onMappingChanged({ ...mapping, ...{ template: template } }, true)
    }

    const onPropertyChange = (uuid: string, properties : Record<string, any>) => {
        props.onPropertyChange(uuid, properties)
    }

    const onFeatureAdd = (feature: Feature) => {
        props.onFeatureAdd!(feature)
    }

    const onGeometryChange = (uuid: string, index: number, value: number[]) => {
        props.onGeometryChange(uuid, index, value)
    }

    function mergeNestedObjects(obj1 : any, obj2 : any) {
        // Check if obj2 is not an object or is null, and return obj1
        if (typeof obj2 !== 'object' || obj2 === null) {
            return obj1;
        }

        // Create a new object to store the merged result
        const mergedObj = { ...obj1 };

        // Traverse the keys of obj2
        for (const key of Object.keys(obj2)) {
            // If the key exists in both objects and both values are objects, recursively merge them
            if (Array.isArray(mergedObj[key]) && Array.isArray(obj2[key])) {
                const uniqueValues = new Set([...mergedObj[key], ...obj2[key]]);
                mergedObj[key] = Array.from(uniqueValues);
                continue;
            }                  
                
            if (typeof mergedObj[key] === 'object' && typeof obj2[key] === 'object') {  
                mergedObj[key] = mergeNestedObjects(mergedObj[key], obj2[key]);
                continue
            }         
            // Otherwise, simply assign the value of obj2 to mergedObj    
            mergedObj[key] = obj2[key];
        }

        return mergedObj;
    }

    const onTemplateAdded = (uploadedMapping: Mapping, replace: boolean) => {
        if (replace) {
            mapping!.template = uploadedMapping.template;
            props.onMappingChanged(mapping!, true)            
        } else {
            props.onMappingChanged(mergeNestedObjects(uploadedMapping, mapping), true)
            // props.onMappingChanged({ ...mapping, ...{ template: uploadedMapping.template } }, true)
        }
    }

    const scrollToContent = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional: To have a smooth scroll animation
            });
        }
    };


    const selectNetworkContent = () => {

        return <div className='card no-border'>
            <header className="card-header">
                <p className="card-header-title">
                    Select Network Type
                </p>
                <button className="card-header-icon button is-primary" aria-label="more options" onClick={() => setSelectNetwork(false)}>
                    Cancel
                </button>
            </header>
            <div className='card-content'>
                <div className='columns is-centered'>
                    <div className='column is-6'>
                        <SelectDomainModel label="Network Type" selected={mapping!.targetModel} onSelect={onTargetModelChanged} />
                    </div>
                </div>
                <AlertMessage alert={domainModelAlert} />
            </div>
        </div>
    };

    const content = () => {

        switch (currentMenu) {
            case MENUS.networktype:
                return <div className='columns box'>
                    <div className='column is-4'>
                        <SelectDomainModel label="Network Type" selected={mapping!.targetModel} onSelect={onTargetModelChanged} />
                    </div>
                </div>
            case MENUS.addtemplate:
                return <div className='columns'>
                    <div className='column'>
                        <CenteredScreen>
                            <AddTemplate label="Add Template" onSelect={onTemplateAdded} />
                        </CenteredScreen>
                    </div>
                </div>
            case MENUS.maplayers:
                return (mapping!.targetModel !== undefined ?
                    ((domainModel && referencedClasses) && <RowMapper upload={props.upload}
                        features={props.features}
                        targetModel={domainModel}
                        referencedClasses={referencedClasses}
                        onMappingChange={onRowMappingChanged} />) :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Map Layers</h4>
                        <span className='has-text-info '>Please Select the network type in the network type</span>
                    </MessageWrapper>
                )
            case MENUS.mapattributes:
                return (mapping!.targetModel !== undefined ?
                    ((domainModel && referencedClasses && referencedDataProps && transformation) && <ColumnMapperList upload={props.upload} features={transformation}
                        targetModel={domainModel}
                        referencedClasses={referencedClasses}
                        referencedDataProps={referencedDataProps}
                        onMappingChange={onColumnMappingChanged} />) :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Map Attributes</h4>
                        <span className='has-text-info '>Please Select the network type in the network type</span>
                    </MessageWrapper>
                )
            case MENUS.convertvalues:
                return (mapping!.targetModel !== undefined ?
                    (domainModel && <ValueConverter upload={props.upload} features={props.features} targetModel={domainModel} onMappingChange={onValueMappingChanged} />) :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Convert Values</h4>
                        <span className='has-text-info '>Please Select the network type in the network type</span>
                    </MessageWrapper>)
            case MENUS.deleterecords:
                return (mapping!.targetModel !== undefined ?
                    (domainModel && <RecordDelete upload={props.upload} features={props.features} targetModel={domainModel} onMappingChange={onRecordDeletesChanged} />) :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Delete Records</h4>
                        <span className='has-text-info '>Please Select the network type in the network type</span>
                    </MessageWrapper>)
            case MENUS.dropattributes:
                return <div className='box'>
                    <ColumnDrop upload={props.upload} features={props.features} onSelectionChanged={onColumnDropsChanged} />
                </div>
            case MENUS.rawdatalist:
                return ((featureProperties && featureColumns) ?
                    <DataList
                        title='Raw Data'
                        isEdittable={true}
                        data={featureProperties} columns={featureColumns}
                        onValueAction={onListValueAction}
                        onValueChange={onPropertyChange}
                    /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Raw Data</h4>
                        <span className='has-text-info'>Loading Features</span>
                    </MessageWrapper>)
            case MENUS.rawdatamap:
                return (mapping!.targetModel !== undefined ?
                    ((domainModel && geoValidFeatures && referencedClasses) &&
                        <Map
                            dataSetId={props.upload.key}
                            title='Raw Data'
                            targetModel={domainModel}
                            referencedClasses={referencedClasses}
                            isEdittable={true}
                            features={geoValidFeatures}
                            onValueAction={onListValueAction}
                            onFeatureAdd={props.onFeatureAdd}
                            onFeaturesDelete={props.onFeaturesDelete}
                            onPropertyChange={onPropertyChange}
                            onGeometryChange={onGeometryChange}
                        />
                    ) :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Raw Data Map</h4>
                        <span className='has-text-info '>Please Select the network type in the network type</span>
                    </MessageWrapper>)
            case MENUS.resultlist:
                return ((transformationProperties && transformationColumns) ? <DataList
                    title='Results'
                    isEdittable={false}
                    options={resultsOptions()}
                    columns={transformationColumns}
                    data={transformationProperties}
                    onValueAction={onListValueAction}

                /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.resultmap:
                return ((domainModel && transformation && referencedClasses) ? <Map
                    dataSetId={props.upload.key}
                    title='Results'
                    targetModel={domainModel}
                    referencedClasses={referencedClasses}
                    features={transformation}
                    isEdittable={false}
                    onValueAction={onListValueAction}
                    onFeatureAdd={onFeatureAdd}
                    onFeaturesDelete={props.onFeaturesDelete}
                /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.validated:
                return ((domainModel && transformation) ? <Validated
                    title='Digital Twin'
                    domainModel={domainModel}
                    features={transformation}
                /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.unmapped:
                return ((domainModel && transformation) ? <Unmapped
                    title='Digital Twin'
                    domainModel={domainModel}
                    features={transformation}
                /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.resultsummary:
                return ((transformationProperties && transformationColumns) ? <Summary
                    columns={transformationColumns}
                    data={transformationProperties} /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.exportclone:
                return ((transformation) ? <CreateClone name={props.upload.name} features={transformation} onNewUpload={props.onNewUpload} /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.exportoverwrite:
                return ((transformation) ? <OverwriteCurrent key={props.upload.key} features={transformation} onUpdateFeatures={props.onUpdateFeatures} /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.exporttofile:
                return ((transformation) ? <ExportToFile name={props.upload.name} features={transformation} /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No transformations available</span>
                    </MessageWrapper>
                )
            case MENUS.exporttemplate:
                return ((mapping) ? <ExportTemplate name={props.upload.name} mapping={mapping} /> :
                    <MessageWrapper>
                        <h4 className="subtitle is-6">Results (list)</h4>
                        <span className='has-text-info '>No mappings to export</span>
                    </MessageWrapper>
                )
            case MENUS.updatenetwork:
                return <Import currentMenu={currentMenu} features={transformation} />
            default:
                break;
        }
    }

    return (<>
        {mapping && <ModalDialog
            title='Select Network Type'
            display={((mapping.targetModel && !domainModelAlert) || !selectNetwork) ? false : true}
            content={selectNetworkContent()}
            />
        }
        

        <div className='columns topstick'>
            <UIWrapper>
                <div className='column box p-0 m-0 ml-4 mt-4 is-2'>
                    <HMenu menuItems={Object.values(menus)} onselected={onMenuSelected} active={currentMenu} />
                </div>
            </UIWrapper>
            <span ref={contentRef}></span>
            {nextMenu ? 
                <div className='columns p-5'>                
                    <div className='column has-text-info '>Loading, please wait...</div>
                </div>            
              :
                 <div className={`column mr-5  ${ui.fullscreenenabled === false ? 'is-10 pr-6' : 'is-12'}`} ref={contentRef}>
                     {content()}
                 </div>
            }
        </div>
    </>
    )
}

export default DetailsPreview;