import { useEffect, useState } from "react"
import AlertMessage from "../alert/components/alertMessage";
import { IAlert } from "standard/alert";
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { Options } from "./@types/IOption";

const Multiselect = (props: {
    id: string
    label: string
    options: any[]
    selected?: any[]
    onSelectionChanged: (selections: Options) => void
    alert?: IAlert
}) => {
    const [selected, setSelected] = useState<any>(props.selected);
    const [filtered, setFiltered] = useState<any>();

    useEffect(() => {

    }, [props.alert]);

    useEffect(() => {
        setFiltered(props.options)
    }, [props.options]);

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected]);

    useEffect(() => {

    }, [selected]);

    const onInputChange = (inputValue: any, actionMeta: any) => {
        onSearch(inputValue)
    }

    const onSearch = (search: string) => {
        if (search.trim() === '') {
            setFiltered(props.options)
            return
        }

        if (props.options !== undefined) {
            const searches = search.toLowerCase().split(' ')
            let filtered: any[] = props.options
            for (let i = 0; i < searches.length; i++) {
                filtered = filtered &&
                    filtered.filter(option => {
                        return (option.label.toString()).toLowerCase().indexOf(searches[i]) > -1
                    }
                    )
                if (filtered.length === 0) {
                    break
                }
            }
            setFiltered(filtered)
        }
    }

    const onChange = (newValue: OnChangeValue<any, false>,
        actionMeta: ActionMeta<any>) => {
        setSelected(newValue);
        props.onSelectionChanged(newValue);
    }

    return (
        <div className='field is-small'>
            <label className="label" htmlFor="label">{props.label}</label>
            <div className="control mt-2">
                <Select
                    value={selected}
                    isMulti
                    isSearchable={true}
                    isClearable={true}
                    name={props.id}
                    options={filtered}
                    onInputChange={onInputChange}
                    onChange={onChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
            </div>
            <AlertMessage alert={props.alert} />
            <hr className="list-divider" />
        </div>
    )
}

export default Multiselect