import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

const Card = (props: {
    title : string,
    isExpanded : boolean
    children : any
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    
    useEffect(() => {     
        setIsExpanded(props.isExpanded);    
    }, [props.isExpanded])

    const onExpand = (e : any) => {
        setIsExpanded(true)
    }

    const onCollapse = (e : any) => {
        setIsExpanded(false)
    }

    return (
        <div className='card ml-0 mt-2 mb-0 p-1'> 
            <header className="card-header" >
                <span className="card-header-title smallText m-1 p-1">
                    {props.title}
                </span>
                {isExpanded || <button className="card-header-icon  clickable" aria-label="more options">
                    <FontAwesomeIcon icon={faAngleLeft} onClick={(e) => onExpand(e)} />
                </button>}
                {!isExpanded || <button className="card-header-icon clickable" aria-label="more options">
                    <FontAwesomeIcon icon={faAngleDown} onClick={(e) => onCollapse(e)} />
                </button>}
            </header>
            <div className="card-content p-1 m-1">
                {!isExpanded || props.children}
            </div>
        </div>

    )
}
    

export default Card;