import { Feature } from "standard/geojson/@types"

export const LAYER_UUID_KEY = 'uuid'
export const LAYER_UUID_KEYS = 'uuids'
export const LAYER_NAME_KEY = 'name'
export const IS_ADD_ON_KEY = 'isAddOn'
export const IS_ADD_ON_TO = 'isAddOnTo'
export const LAYER_ICON = 'layerIcon'
export const LAYER_ICON_SIZE = 'layerIconSize'
// This value is always 0 for markers of actual features. In case the marker is generated by points of an edge, 
// this value will reflect the index of point in the geometry coordinates 
export const GEOMETRY_INDEX = 'geometryIndex'

export type LayerInfo = { [key: string]: any }

export type LayerMetaData = { 
	info: LayerInfo,
	feature : Feature
	layer: any
	decorator?: any
	showing: boolean
	renderVersion: string
	layerVersion: string	
	attachments?: string[]
}

export type LayerStyling = { 
	color: string
	weight : number
	opacity: number
}