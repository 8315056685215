import { useEffect, useState, useRef } from 'react';
import TextSummary from 'standard/UI/components/textSummary';

const Cell = (props: {
    uuid: string
    propertyName: string
    value?: any
    isEdittable: boolean
    style?: string
    onValueChange: (key: string, value: any) => void
    onValueClick: (e: any, key: string, value: any) => void
    onValueDoubleClick: (e: any, key: string, value: any) => void
}) => {
    const [isEditting, setIsEditting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const [editValue, setEditValue] = useState<string>('');

    useEffect(() => {
         
    }, [])
    

    useEffect(() => {
        if (!isLoading) {
            props.onValueChange(props.propertyName, editValue);
        }
        setIsLoading(false)
    }, [props.propertyName, editValue])

    useEffect(() => {
        if (isEditting) {
            setEditValue(props.value || '')
            if (inputRef.current) {
                inputRef.current!.select()
            }
        }
    }, [isEditting])

    useEffect(() => {
      
    }, [editValue])

    const onValueDoubleClick = (e: any, key: string) => {
        if (props.isEdittable) {
            props.onValueDoubleClick(e, key, props.value);
            setIsEditting(true);
        }
    }

    const onEditComplete = (e: any) => {
        setIsEditting(false);
    }

    const onTextChange = (e: any) => {
        setEditValue(e.currentTarget.value)
    };

    const displayContent = (propertyName: string) => {
        
        let tooltip: string = ''
        if (isEditting) {
            return displayEditContent(propertyName)
        }

        if (props.value === undefined || props.value === null) {
            return displayEmptyContent(propertyName)
        }
        
        let valueToDisplay = props.value
        if (typeof valueToDisplay === 'object') {
            valueToDisplay = JSON.stringify(props.value)
            // return displayComplexContent()
        }

        return <>
            <div
                className={`pl-1 pr-1`} title={tooltip}
                onClick={(e) => props.onValueClick(e, propertyName, props.value)}
                onDoubleClick={(e) => onValueDoubleClick(e, propertyName)}>
                <TextSummary value={valueToDisplay} prefixLength={20} suffixLength={5} />
            </div>
        </>
    }

    const displayEditContent = (propertyName: string) => {
        return <div
            className="pl-1 pr-1"
        >
            <input type="text"
                className={`input fullWidth`}
                ref={inputRef}
                value={editValue}
                onChange={onTextChange}
                onBlur={onEditComplete}
            />
        </div>
    }

    const displayComplexContent = () => {
        return <div>-Is Object-</div>
    }

    const displayEmptyContent = (propertyName: string) => {
        return <div onDoubleClick={(e) => onValueDoubleClick(e, propertyName)}>-</div>
    }


    return <div key={`${props.uuid}-${props.propertyName}`} className={`column cell p-0 m-0 ${props.style && props.style}`}>
        {displayContent(props.propertyName)}
    </div>
}

export default Cell;