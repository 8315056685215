import { IRecordAdd } from 'standard/datatools/@types';
import { RecordDeletes } from 'standard/datatools/@types/IMappingTemplate';
import { Input } from 'standard/forms/inputs'
import { v4 as uuidv4 } from 'uuid';
import { Upload_Meta } from '../../types/uploads';

export const RecordDeleteFactoryFromInputs = (newinputs: { [key: string]: Input }) : IRecordAdd => {
    let recordDelete : IRecordAdd = Object.entries(newinputs).reduce((obj, [key, value]) => {
        obj[key] = value.value;
      return obj;
    }, {} as IRecordAdd);

    recordDelete.id = uuidv4()
    return recordDelete;
}

export const RecordDeleteFactory = (input : string, values : string[]) : IRecordAdd => {
    return {id : uuidv4() , input : input, values : values} as IRecordAdd
}

export const AppendRecordDelete = (upload : Upload_Meta, recordDelete : IRecordAdd) => {    
    let recordDeletes: RecordDeletes = {}
    if (upload.mapping.template && upload.mapping.template.recordDeletes) {
        recordDeletes = {...upload.mapping.template.recordDeletes}
    }
    recordDeletes[recordDelete.id] = recordDelete
    return recordDeletes
}