import { useEffect, useState } from "react";
import SelectNetwork from "components/selectors/selectNetwork/selectNetwork";
import Importer from "./importer";
import { ILabelComment } from "standard/ontology/interfaces";
import { Feature } from "standard/geojson/@types";

const ImportData = (props: {
    features: Feature[]
}) => {

    const [networkId, setNetworkId] = useState<string>()

    useEffect(() => {

    }, [props.features])


    useEffect(() => {

    }, [networkId])


    const onSelectedNetork = (items: ILabelComment[]) => {
        if (items.length === 1) {
            setNetworkId(items[0].uuid)
        }
    }

    const onImportComplete = () => {
       
    }

    const restart = () => {
        setNetworkId(undefined)
    }

    const changeNetwork = () => {
        setNetworkId(undefined)
    }


    return (<div className="smalltext">
        {!networkId && <div className="columns is-centered">
                            <div className="column is-5">
                                <SelectNetwork title="Select Network" onSelectionChanged={onSelectedNetork} />
                            </div>
                        </div>}
        {
            (networkId) && <Importer
                networkId={networkId!}
                features={props.features}
                restart={restart}
                onComplete={onImportComplete}
                onRequestChangeNetwork={changeNetwork}
            />
        }
    </div>);
}

export default ImportData;