import { useState, useEffect } from 'react';
import { Feature, geometryHash } from "standard/geojson/@types";

import { useAppSelector } from "hooks/redux";
import { IDomainModelClass, IDomainModelProperty, IIndividual } from "standard/ontology/interfaces";
import { IDigitalTwin } from "standard/ontology/interfaces/IDigitalTwin";
import { IAlert, ActionTypes } from "standard/alert";
import { newDataImport } from 'standard/datatools/@types/Import';
import { IDataImport } from 'standard/datatools/@types/IDataImport';
import { IDataImportStatus } from 'standard/datatools/@types/IDataImportStatus';
import { newIndividual } from 'standard/ontology/ontologyindividual';
import { DomainModels } from 'standard/ontology/domainModel/domainmodels';
import { updateIndividuals } from 'standard/ontology/digitalTwin/service_individuals';
import { useGeoClass } from '../../../../standard/ontology/domainModel/hooks/useGeoClass';
import { createGeoIndividual } from '../../../../standard/ontology/digitalTwin/components/utils';

export const useImportNetworkIndividuals = (props: { domainModels: DomainModels | undefined, network: IDigitalTwin | undefined }) => {
    const [canImport, setCanImport] = useState<boolean>(false);
    const [domainModels, setDomainModels] = useState<DomainModels>();    
    const authenticated = useAppSelector(state => state.authentication)

    const { geoClass } = useGeoClass({
        domainModels: props.domainModels
    }
    );

    useEffect(() => {
        if (!props.domainModels) {
            setCanImport(false)
            return;
        }

        if (!props.network) {
            setCanImport(false)
            return
        }
        if (!geoClass) {
            setCanImport(false)
            return
        }

        setCanImport(true)

    }, [props.domainModels, props.network, geoClass]);

    useEffect(() => {
        if (domainModels) {
            setCanImport(true)
        }
    }, [domainModels]);

    useEffect(() => {

    }, [canImport]);

    async function* importIndividuals(domainModels: DomainModels, importFeatures: Feature[], batchSize: number): AsyncGenerator<IAlert, void, void> {
        if (!canImport) {
            yield {
                message: "Import is not properly configured",
                type: ActionTypes.ERROR,
                data: {
                    networkSet: props.network !== undefined,
                    importFeaturesSet: importFeatures !== undefined
                }
            } as IAlert
            return
        }

        let importStatus: IDataImport = newDataImport(importFeatures!.length)
        importStatus.status.started = new Date()
        importStatus.status.running = true
        importStatus.status.unmapped = []
        yield {
            message: `Started import`,
            type: ActionTypes.SUCCESS,
            data: { status: importStatus }
        } as IAlert

        // batchSize = 1
        let start: number = 0
        let to: number = batchSize

   
        while (start < importFeatures!.length) {
            
            // if (start > 1) break
            let result: IAlert | undefined
            try {
                result = await importBatch(domainModels, importFeatures.slice(start, to))
            } catch (error: any) {
                yield {
                    message: error.message || error,
                    type: ActionTypes.ERROR_SERVER,
                    data: { batch: { from: start, to: to } }
                } as IAlert
                return
            }
            if (result!.type === ActionTypes.SUCCESS) {
                let batchUpdate: IDataImportStatus = result!.data || {}
                importStatus.status.recordsProcessed += batchUpdate.recordsProcessed
                importStatus.status.numberOfImports += batchUpdate.numberOfImports
                importStatus.status.mapped += batchUpdate.mapped
                importStatus.status.unmapped = [...importStatus.status.unmapped, ...batchUpdate.unmapped]
            }
            yield {
                message: `Batch import successfully; Records ${start} to ${to}`,
                type: ActionTypes.SUCCESS,
                data: { status: importStatus }
            } as IAlert
            start += batchSize
            to += batchSize
            to = to > importFeatures!.length ? importFeatures!.length : to
        }

        importStatus.status.ended = new Date()
        importStatus.status.running = false
        
        yield {
            message: `Data successfully imported`,
            type: ActionTypes.SUCCESS,
            data: { status: importStatus }
        } as IAlert

    }

    async function importBatch(domainModels: DomainModels, features: Feature[]): Promise<IAlert> {
        let batchStatus: IDataImportStatus = { running: true, recordsProcessed: 0, numberOfImports: 0, mapped: 0, unmapped: [] }
        const promise = new Promise<IAlert>((res, rej) => {
            let individuals: IIndividual[] = [];            
            for (let feature of features) {       
                if (feature.properties!) {
                    let cls: IDomainModelClass | undefined = domainModels!.getClassById(feature.class!);
                    
                    if (cls) {
                        let individual = newIndividual(cls!.uuid!)

                        individual.uuid = feature.uuid!
                        individual.labels.default = individual.uuid! || geometryHash(props.network!.uuid!, cls!.uuid!, feature.geometry)

                        individual.dataProperties = {}
                        individual.objectProperties = {}

                        if (feature.properties) {
                            Object.keys(feature.properties).forEach(key => {
                                let dataProp: IDomainModelProperty | undefined = cls!.dataProps!.find(property => property.labels.default === key)
                                if (dataProp) {
                                    individual.dataProperties![dataProp.uuid!] = feature.properties![key]
                                }
                            })
                        }

                        let geoIndividual = createGeoIndividual(props.network!.uuid!, feature, geoClass!)
                        let objPropsWithGeometryRange = cls!.objProps!.filter(p => p.range.includes(geoClass!.uuid!))
                        for (let objProp of objPropsWithGeometryRange) {
                            individual.objectProperties[objProp.uuid!] = geoIndividual.iri
                        }
                        individuals.push(geoIndividual)
                        individuals.push(individual)
                        batchStatus.mapped += 1

                        batchStatus.numberOfImports = individuals.length
                        batchStatus.recordsProcessed += 1
                    } else {
                        batchStatus.unmapped!.push(feature)
                    }
                }
            }

            if (individuals.length > 0) {
                updateIndividuals(authenticated!.user!.token, props.network!.uuid!, individuals).then((result: any) => {
                    res({
                        message: `Data successfully imported`,
                        type: ActionTypes.SUCCESS,
                        data: batchStatus
                    } as IAlert)
                }).catch((err) => {
                    rej({
                        message: `Unable to import data`,
                        type: ActionTypes.ERROR,
                        data: batchStatus
                    } as IAlert)
                });
            } else {
                res({
                    message: `No data to import`,
                    type: ActionTypes.SUCCESS,
                    data: batchStatus
                } as IAlert)
            }

        })
        return promise
    }


    return { importIndividuals, canImport};
}