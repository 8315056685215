import { actionTypes } from './actionsTypes';

export const uiActions = {
    enableFullscreen,
    disableFullscreen,
};

function enableFullscreen() {
    return { type: actionTypes.ENABLE_FULLSCREEN };  
}

function disableFullscreen() {
    return { type: actionTypes.DISABLE_FULLSCREEN };  
}