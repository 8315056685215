import { IDomainModel, IDomainModelTag, IDomainModelChange, IDomainModelClass } from "../interfaces";
import { IAlert, ActionTypes } from "../../alert";
import { getDigitalTwinService } from "../utils";
import { domainmodels } from "./domainModelsLocal";
import { domainmodelImports } from "./domainModelImports";

export interface ApiResponse {
    status: string
    response: any
}

function setDomainModel(x: any) :IDomainModel{
    return  {
        uuid: x.uuid,
        uri: x.uri,
        labels: x.labels,
        comments: x.comments,
        imports: x.imports,
        type: x.type,
        isprivate: x.isprivate,
        classes: x.classes,
        objProps: x.objProps,
        dataProps: x.dataProps,
        individuals: x.individuals,
        scopes: x.scopes
    } as IDomainModel
}



export async function getDomainModels(): Promise<Array<IDomainModel>> {
    const promise = new Promise<Array<IDomainModel>>((res, rej) => {
        res(domainmodels())
    });
            
    return promise;
}


export async function getDomainModelWithUri(uri: string): Promise<IDomainModel> {
    const promise = new Promise<IDomainModel>((res, rej) => {
        const domainModel : IDomainModel | undefined = domainmodels().find(domainmodel => domainmodel.uri === uri)
        if (domainModel) {
            res(domainModel)
        }else {
            rej(`Domain model with uri ${uri} not found.`)
        }
    });
    return promise;
}

export async function getDomainModel(token: string, uuid: string, inScopeOnly : boolean): Promise<IDomainModel> {
    const promise = new Promise<IDomainModel>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        var urlencoded = new URLSearchParams();
        urlencoded.append("inscopeonly", `${inScopeOnly}`);

        fetch(`${getDigitalTwinService()}/domainmodel/${uuid}?${urlencoded}}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                const i = setDomainModel(result.response)                
                res(i)
            })
            .catch(error => rej(error));

    });
    return promise;
}

export async function getDomainModelImports(uuid: string): Promise<Array<IDomainModel>> {
    const promise = new Promise<Array<IDomainModel>>((res, rej) => {
        const domainModels : IDomainModel[] | undefined = domainmodelImports()[uuid]
        if (domainModels) {
            res(domainModels)
        }else {
            rej(`Domain model imports for model with uuid ${uuid}.`)
        }
    });
    return promise;
}

export async function getSubDomains(token: string, uuid: string): Promise<Array<IDomainModel>> {
    const promise = new Promise<Array<IDomainModel>>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${getDigitalTwinService()}/domainmodel/${uuid}/subdomains`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                const items = result.response.map((x: any) => {
                    return setDomainModel(x)
                });
                res(items)
                return
            })
            .catch(error => rej(error));

    });
    return promise;
}


export async function getDomainModelClasses(token: string, uuid: string): Promise<Array<IDomainModelClass>> {
    const promise = new Promise<Array<IDomainModelClass>>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${getDigitalTwinService()}/domainmodel/${uuid}/import`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                let classes : IDomainModelClass[] = [] 
                
                // for (var x in result.response){
                
                //     // classes = [...classes, ...x.classes]
                // }

                // const items = result.response.map((x: any) => {
                //     classes = [...classes, ...x.classes]
                //     return setDomainModel(x)
                // });
                res(classes)
                return
            })
            .catch(error => rej(error));

    });
            
    return promise;
}

export async function createDomainModel(token: string, data: IDomainModel): Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${getDigitalTwinService()}/domainmodel`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => rej(error));
    });
    return promise;
}

export async function updateDomainModel(token: string, data: IDomainModel): Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "text/plain");

        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(`${getDigitalTwinService()}/domainmodel/${data.uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => {
                rej(error);
            })

    });
    return promise;
}

export async function tagDomainModel(OntUUID: string, name: string): Promise<ApiResponse> {
    const promise = new Promise<ApiResponse>((res, rej) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        // myHeaders.append("Authorization", `Bearer ${token}`);

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", name);

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
        };

        fetch(`${getDigitalTwinService()}/domainmodel/${OntUUID}/tag`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                res(result)
            })
            .catch(error => rej(error));

    });
    return promise;
}

function tryProcessResponse(response: any) {
    if (response.status === "200" || response.status === 200) {
        return undefined
    }

    return {
        message: response.description || response.error,
        type: ActionTypes.ERROR,
    } as IAlert
}

export async function getDomainModelTags(OntUUID: string): Promise<Array<IDomainModelTag>> {
    const promise = new Promise<Array<IDomainModelTag>>((res, rej) => {
        if (OntUUID === undefined) {
            rej({
                message: "Ontology ID is undefined",
                type: ActionTypes.ERROR,
            } as IAlert);
            return
        }
        fetch(`${getDigitalTwinService()}/domainmodel/${OntUUID}/tag`)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                const items = result.response.map((x: any) => {
                    return {
                        uuid: x.uuid,
                        labels: x.labels,
                        comments: x.comments,
                        tags: x.tags,
                        hash: x.hash,
                        createddate: x.createdDate,
                        ontology: x.ontology,
                    } as IDomainModelTag;

                });
                res(items)
            })
            .catch(error => {
                rej({
                    message: error.message,
                    type: ActionTypes.ERROR,
                } as IAlert)
            })
    });
    return promise;
}

export async function getDomainModelTag(ontuuid: string, taguuid: string): Promise<IDomainModelTag> {
    const promise = new Promise<IDomainModelTag>((res, rej) => {
        fetch(`${getDigitalTwinService()}/domainmodel/${ontuuid}/tag/${taguuid}`)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                const tag = {
                    uuid: result.response.uuid,
                    labels: result.response.labels,
                    comments: result.response.comments,
                    tags: result.response.tags,
                    hash: result.response.hash,
                    createddate: result.response.createdDate,
                    ontology: result.response.ontology,
                } as IDomainModelTag;
                res(tag)
            })
            .catch(error => rej(error));
    });
    return promise;
}

export async function getDomainModelChanges(uuid: string): Promise<Array<IDomainModelChange>> {
    const promise = new Promise<Array<IDomainModelChange>>((res, rej) => {
        fetch(`${getDigitalTwinService()}/domainmodel/${uuid}/changes`)
            .then(response => response.json())
            .then(result => {
                const response = tryProcessResponse(result)
                if (response !== undefined) {
                    rej(response)
                    return
                }
                const items = result.response.map((x: any) => {
                    return {
                        op: x.op,
                        path: x.path,
                        value: x.value,
                    } as IDomainModelChange;

                });
                res(items)
            })
            .catch(error => rej(error));
    });
    return promise;
}
