import { LatLng, LatLngBounds, Point } from "leaflet";

export type BBox = [number, number, number, number];


export class Box  {
    public bounds?: L.LatLngBounds

    constructor() {
      
    }
  
    mergeBox(box: L.LatLngBounds)  {
        if (!this.bounds){
            this.bounds = box
            return
        }

        let northEast : LatLng = box.getNorthEast() 
        northEast.lat = northEast.lat > this.bounds!.getNorthEast().lat ? northEast.lat : this.bounds.getNorthEast().lat
        northEast.lng = northEast.lng > this.bounds!.getNorthEast().lng ? northEast.lng : this.bounds.getNorthEast().lng

        let southWest : LatLng = box.getSouthWest()
        southWest.lat = southWest.lat < this.bounds!.getSouthWest().lat ? southWest.lat : this.bounds.getSouthWest().lat
        southWest.lng = southWest.lng < this.bounds!.getSouthWest().lng ? southWest.lng : this.bounds.getSouthWest().lng
        this.bounds = new  LatLngBounds(southWest, northEast)
        
    }

    split(rows : number, cols : number) : number[] | undefined {
        if (!this.bounds){            
            return undefined
        }

        let north : number = this.bounds!.getNorth()
        let south : number = this.bounds!.getSouth()
        let west : number = this.bounds!.getWest()
        let east : number = this.bounds!.getEast()
       
        let northToSouth = north - south
        let northToSouthSplitSize = northToSouth / rows
        let westToEast = west - east
        let westToEastSplitSize = westToEast / cols

        let tiles = new Array(rows)
        for (let i = 0; i < rows; i++) {  
            tiles[i] = new Array(cols)        
            for (let j = 0; j < cols; j++) {
                tiles[i][j] =[
                    [north - (i * northToSouthSplitSize), east - (j * westToEastSplitSize)], 
                    [north - ((i + 1) * northToSouthSplitSize), east + (j * westToEastSplitSize)]
                ] 
            }
        }
        return  tiles
    }
}

export const boxWithOffset = (center: Point, offset : number) : L.LatLngBounds => {
    let northEast : LatLng = new LatLng(center.x + offset/2, center.y + offset)
    let southWest : LatLng = new LatLng(center.x - offset/2, center.y - offset)
    
    return new LatLngBounds(southWest, northEast)
}