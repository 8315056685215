import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { LayerInfo } from '../@types/layerInfo'
import { Feature } from 'standard/geojson/@types'

const MapViewerPopup = (props: {
	feature : Feature
	layerInfo? : LayerInfo
	canDelete?: boolean
	onDelete: (uuid: string, layerInfo? : LayerInfo) => void
}) => {
	
	const onDelete = (e : any) => {
		props.onDelete(props.feature.uuid!, props.layerInfo)
	}

	return (
		<div className='columns'>
			<div className='column'>
				<div className='columns'>	
					<div className='column m-0 p-0'>
						{`${props.feature.uuid!}`.substring(0, 5)}
					</div>
				</div>
				<div className='columns m-0 p-0'>	
					<div className='column'>
						<span className='button is-danger is-inverted' ><FontAwesomeIcon icon={faTrash} onClick={onDelete}/></span>
					</div>
				</div>
				{/* <div className='columns'>	
					<div className='column m-0 p-0'>
						<Coordinates feature={props.feature}/>
					</div>
				</div> */}
			</div>
			
		</div>
	)
}

export default MapViewerPopup;