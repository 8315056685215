import { useState, useEffect } from 'react';
import { Feature } from 'standard/geojson/@types';
import NodeProperty from './nodeProperty';
import NodePropertyNew from './nodePropertyNew';

const NodeProperties = (props: {
    feature: Feature
    properties: { [key: string]: Set<string> }
    onChange: (name: string, value: string) => void
    onDelete: (name: string) => void
}) => {

    const [keys, setKeys] = useState<string[]>();


    const onChanged = (name: string, value: string) => {
        props.onChange(name, value);
    }

    const onAddProperty = (name: string, value: string) => {
        props.onChange(name, value);
    }



    useEffect(() => {

        const filterProps = (properties: { [key: string]: any }): string[] => {
            let keys = Object.keys(properties)
            for (const [key, value] of Object.entries(properties)) {
                if (typeof value === 'object') {
                    keys = keys.filter((k) => k !== key)
                }
            }

            keys = keys.filter((k) => k !== 'uuid')

            return keys
        }

        if (props.feature.properties) {
            let sorted = filterProps(props.feature.properties)
            sorted.sort((a, b) => {
                if (a === 'id') {
                    return -1
                }
                if (a === 'uuid') {
                    return -1
                }
                if (b === 'id') {
                    return 1
                }
                if (b === 'uuid') {
                    return 1
                }
                return a.toLowerCase() < b.toLowerCase() ? -1 : 1
            }
            )
            setKeys(sorted)
        } else {
            setKeys([])
        }

    }, [props.feature])

    return (
        <div className='box ml-0 mt-2 mb-0 p-1'>
            {/* <h6 className="title is-6">Properties</h6>  */}
            {
                keys && keys.map((k: string, index: number) => {
                    return <NodeProperty
                        key={k} name={k}
                        value={props.feature.properties![k] || ""}
                        valueSuggestions={Array.from(props.properties[k])}
                        className={`pl-2 pt-2 ${(index + 1) % 2 === 0 ? 'alternate' : ''}`}
                        onChanged={onChanged}
                        onDelete={props.onDelete}
                    />
                })
            }
            <NodePropertyNew
                feature={props.feature}
                properties={props.properties}
                onAdd={onAddProperty}
            />
        </div>

    )
}

export default NodeProperties;