import { Fragment } from 'react';
import { useAppSelector } from 'hooks/redux'
import { ILabelComment } from 'standard/ontology/interfaces';

const SelectorItem = (props: {
    item: ILabelComment,
    onSelected: (item: ILabelComment, value: boolean) => any
    style?: string
}) => {

    return (
        <Fragment>
            <div className={`columns m-0 p-0 clickable ${props.style && props.style}`} key={props.item.uuid!} onClick={() => props.onSelected(props.item, true)}>
                <div className={`column is-4 m-1 ml-2 p-0`} >
                    {props.item.labels.default}
                </div>
                <div className="column ml-2 is-6 m-1 p-0">
                    {props.item.comments && (props.item.comments.default)}
                </div>
            </div>
            <hr className='list-divider' />
        </Fragment>
    )
}

export default SelectorItem;