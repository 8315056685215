import { v4 as uuidv4 } from 'uuid';

export interface ITokenizedMessage {
    token : string
    message : any
}

export class TokenizedMessage  implements ITokenizedMessage {
    public token: string
    public message: any

    constructor(message : any) {
      this.token = uuidv4();   
      this.message = message;
    }
  }
  