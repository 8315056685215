import { useState, useEffect } from 'react';
import { ILabelComment } from 'standard/ontology/interfaces';
import SelectorItem from './selectorItem';

const Selector = (props: {
    title?: string
    items: ILabelComment[],
    onSelectionChanged: (items: ILabelComment[]) => any,
    size?: string
}
) => {
    const [items, setItems] = useState<ILabelComment[]>();
    const [selected, setSelected] = useState<ILabelComment[]>([]);
 
    useEffect(() => {
        let sorted = props.items
        sorted.sort((a, b) => a.labels.default.localeCompare(b.labels.default))
        setItems(sorted)
    }, [props.items])

    useEffect(() => {
        props.onSelectionChanged(selected)
    }, [selected])

    const onSelected = (item: ILabelComment, value: boolean) => {
        if (value) {
            setSelected([item])
            return
        } else {
            setSelected(selected.filter((nodeId) => nodeId.uuid! !== item.uuid))
        }
    }
 
    return (
        <div className={`columns is-centered smallText`} >
            <div className={`column ${props.size ? " is-" + props.size : ""}`}>
                <div className={`card`}>
                    {props.title && <header className="card-header">
                        <p className="card-header-title">
                            {props.title}
                        </p>             
                    </header>}                  
                    {items && items.map((m: ILabelComment, idx: number) => { 
                        return <SelectorItem 
                                    key={m.uuid} 
                                    item={m} 
                                    onSelected={onSelected} 
                                    style={(idx + 1) % 2 === 0 ? 'alternate' : ''}
                                /> 
                            }
                    )}
                </div>
            </div>
        </div>
    )
}

export default Selector;