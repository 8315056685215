import { actionTypes } from './actionsTypes';
import { IConfiguration } from './interfaces';

const initialState : IConfiguration =  { hasError: false, isLoaded : false } 

export function configuration(state = initialState, action:any) : IConfiguration {
  switch (action.type) {
    case actionTypes.CONFIG_CHECK_SUCCESS:
    return {
        hasError: false,
        isLoaded: true,
      };
    case actionTypes.CONFIG_CHECK_FAILURE:
      return {
        hasError: true,
        isLoaded: true,
      };
    default:
      return state
  }
}