
import { IInput } from "./interfaces"
import { InputType } from "./enums"

export class Input implements IInput {
  public name: string
  public label: string
  public value: any
  public type: InputType
  public required: boolean
  public ordinality: number
  public parent?: string
  public options?: any
  public prompt?: string
  public data?: {
    [key: string]: string
  }
  constructor(i: IInput) {
    this.name = i.name
    this.label = i.label
    this.value = i.value
    this.type = i.type
    this.required = i.required
    this.ordinality = i.ordinality
    this.parent = i.parent
    this.options = i.options
    this.prompt = i.prompt
    this.data = i.data
  }
}

export const clearChildren = (inputs : { [key: string]: Input }, parentKey : string) :  { [key: string]: Input } => {
  let clone = {...inputs}
  Object.keys(clone).forEach(key => {
    if (clone[key].parent && clone[key].parent === parentKey) {
      if (Array.isArray(clone[key].value)) {
        clone[key].value = []
      }else{
        clone[key].value = ''
      }     
    }
  })
  return clone
}