import { ActionTypes, IAlert } from "../../alert";
import { IIndividual } from "../interfaces";
import { IDigitalTwin } from "../interfaces/IDigitalTwin";
import { IResponseSummary } from "../interfaces/IResponseSummary";
import { DigitalTwin } from "./digitaltwin";

export function tryProcessResponse(response: any) {
  if (response.status === "200" || response.status === 200) {
      return undefined
  }

  return {
      message: response.description || response.error,
      type: ActionTypes.ERROR,
  } as IAlert
}

export interface ApiResponse {
  status: string
  response: any
}

export function newDigitalTwin(uri : string): DigitalTwin {
    let iDM: IDigitalTwin = {
      uri: uri,
      uuid: undefined,
      labels: {},
      comments: {},
      imports: [],
      type: 0,
      isprivate: true,
      scopes: {},    
    }
    return new DigitalTwin(iDM)
  }

export function setDigitalTwin(x: any) :IDigitalTwin{
    return  {
        uuid: x.uuid,
        uri: x.uri,
        labels: x.labels,
        comments: x.comments,
        imports: x.imports,
        type: x.type,
        isprivate: x.isprivate,
        individuals: x.individuals,
        scopes: x.scopes
    } as IDigitalTwin
}

export function setIndividual(x: any) :IIndividual{
  return  {
      uuid: x.uuid,
      labels: x.labels,
      comments: x.comments,
      types: x.types,
      dataProperties: x.dataProperties,
      objectProperties: x.objectProperties
  } as IIndividual
}

export function setResponseSummary(x: any) :IResponseSummary |  undefined {
  if (!x){
    return undefined
  } 
  return  {
      numberOfRecords: x.numberOfRecords,
      responseTime: x.responseTime,
  } as IResponseSummary
}


