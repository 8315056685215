import { useState, useEffect } from 'react';
import { ILayer } from './interfaces/ILayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import LayerItem from './layerItem';

const Layers = (props: {
        layers? : ILayer[],
        selectedLayers? : ILayer[],
        onSelectedLayers: (layers : ILayer[]) => any
        onLayerNameChange: (item: ILayer, newName: string) => any
        onDeleted: (item : ILayer) => void
    }) => {
    
    const [selectAll, setSelectAll] = useState<boolean>(true);    
    const [selectedLayers, setSelectedLayers] = useState<ILayer[]>([]);
    const [layers, setLayers] = useState<ILayer[]>(); 
    const [loaded, setLoaded] = useState<boolean>(false); 

    useEffect(() => {       
        if (props.layers){
            let sorted = [...props.layers]
            sorted.sort((a, b) => a.name < b.name ? -1 : 1)
            setLayers( sorted)
        }else{
            setLayers([])
        }
        setSelectedLayers(props.selectedLayers || props.layers || [])
        setLoaded(true)
    
    }, [props.layers])

    useEffect(() => {
        props.onSelectedLayers(selectedLayers)
    }, [props, selectedLayers])

    useEffect(() => {    
        if (!selectAll) {
            setSelectedLayers([])
        }else{
            setSelectedLayers(props.layers!)
        }
    }, [selectAll])

    const onViewAll = (e: any) => {
        setSelectAll(!selectAll)
    } 

    const onSelected = (item: ILayer, value: boolean) => {
        if (value) {            
            if (selectedLayers.filter((l) => l.name === item.name).length > 0) {
                return
            }
            setSelectedLayers([...selectedLayers, ...[item]])
        } else {
            setSelectedLayers(selectedLayers.filter((l) => l.name !== item.name))
        }
    }
    
    const onLayerNameChange = (item: ILayer, newName: string) => {
        props.onLayerNameChange(item, newName)
    }

    const onDeleted = (item: ILayer) => {
        props.onDeleted(item)
    }

    return (
        <>        
            {/* <h6 className="title is-6">Layers</h6>                 */}
            {(layers && selectedLayers)&& 
                <>  
                    <span className='box ml-0 mt-2 mb-0 p-1'>
                        {selectAll ? <FontAwesomeIcon className='checkbox has-text-success' icon={faToggleOn} onClick={(e : any) => onViewAll(e)}  /> 
                                   : <FontAwesomeIcon className='checkbox has-text-dark' icon={faToggleOff} onClick={(e : any) => onViewAll(e)}  />
                        }    
                    </span>                        
                    {layers.map((m: ILayer) => { 
                        return <LayerItem 
                                    key={m.name} 
                                    item={m}
                                    valueSuggestions={props.layers!.map((l) => l.name)} 
                                    onSelected={onSelected} 
                                    selected={selectedLayers.findIndex((l) => l.name === m.name) !== -1}
                                    onNameChange={onLayerNameChange} 
                                    onDeleted={onDeleted}
                                /> 
                    })}
                </>
            }
        </>

    )
}

export default Layers;