import { Mapping } from "components/dataTools/datapreview/types/uploads";
import { readTextFile } from "standard/geoData/textReader";
import { ActionTypes } from "standard/alert";
import { IAlert } from "standard/alert";


export async function getMapping(files: any[]): Promise<Mapping> {
    const promise = new Promise<Mapping>((res, rej) => {
        try {
            if (files) {
                let file = files[0];                
                readTextFile(file).then((fileContent: any) => {
                    try {
                        var data = JSON.parse(fileContent);
                        res(data);
                    } catch (error) {
                        rej(error)
                    }
                });
            } else {
                rej({
                    message: "No files to upload",
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        } catch (error: any) {
            rej({
                message: error,
                type: ActionTypes.ERROR,
            } as IAlert)
        }
    })
    return promise;
};