import BaseStructure from "./domainModel/basestructure"
import {  IIndividual } from "./interfaces"
import { ILabelComment } from './localization/interfaces';


export function  newIndividual(type : string) {
  let c: IIndividual = {
      uuid: undefined,
      iri:'', 
      labels: {},
      comments: {},
      types: [type],
      dataProperties: {},
      objectProperties: {},
  }

  return new Individual(
    c.iri,
    c.labels,
    c.types,
    c.comments,
    c.dataProperties,
    c.objectProperties,
    c.uuid,
  )
}

export class Individual extends BaseStructure implements IIndividual, ILabelComment {
  constructor(
    public iri: string,
    public labels: { [key: string]: string },
    public types: string[],
    public comments?: { [key: string]: string },
    public dataProperties?: { [key: string]: {} },
    public objectProperties?: { [key: string]: string },
    public uuid?: string,
) {
    super( labels, comments, uuid);
  }
}