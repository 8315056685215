import { useEffect, useState } from 'react';
import { IDomainModel, IDomainModelClass, IDomainModelProperty } from '../../interfaces';
import { IAlert } from "../../../alert";
import useDomainModelsImports from './useDomainModelImports';

const useDomainModelsAllAttributes = (props : {domainModel?: IDomainModel }) => {
    const [referencedClasses, setReferencedClasses] = useState<Array<IDomainModelClass>>();
    const [referencedDataProps, setReferencedDataProps] = useState<Array<IDomainModelProperty>>();
    const [referencedObjProps, setReferencedObjProps] = useState<Array<IDomainModelProperty>>();
    const [componentAlert, setComponentAlert] = useState<IAlert>();
    const { importedClasses, importedDataProps, importedObjectProps } = useDomainModelsImports({ OntUUID: props.domainModel && props.domainModel.uuid });

    useEffect(()=>{
      
    },[props.domainModel]);

    useEffect(()=>{
        if (importedClasses !== undefined){
            let c: IDomainModelClass[]
            
            // if (!props.domainModel!.classes) {
            //     setReferencedClasses(undefined);
            //     return
            // }
            c = props.domainModel!.classes! || []
            c = [...c, ...importedClasses];
            setReferencedClasses(c)
        }
    },[importedClasses]);

    useEffect(()=>{
        if (importedDataProps !== undefined){
            let d: IDomainModelProperty[]
            let dPs : IDomainModelProperty[] = props.domainModel!.dataProps?.map((d) => {return {...d, Ontology: props.domainModel!} as IDomainModelProperty} ) || []
            d = [...dPs, ...importedDataProps];
            setReferencedDataProps(d)
        }
    },[importedDataProps]);

    useEffect(()=>{
        if (importedObjectProps !== undefined){
            let d: IDomainModelProperty[]
            let dPs : IDomainModelProperty[] = props.domainModel!.objProps?.map((d) => {return {...d, Ontology: props.domainModel!} as IDomainModelProperty} ) || []
            d = [...dPs, ...importedObjectProps];
            setReferencedObjProps(d)
        }
    },[importedObjectProps]);

    useEffect(()=>{
      
    },[referencedClasses]);
    
    return {referencedClasses, referencedDataProps, referencedObjProps,  componentAlert};
}


export default useDomainModelsAllAttributes

