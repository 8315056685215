import { roundTo } from 'components/utils/math';
import { useState, useEffect } from 'react';
import { MapPosition } from 'standard/ontology/map/geo';

const MousePosition = (props: {
	position?: MapPosition
}) => {

	const [position, setPosition] = useState<MapPosition>();

	useEffect(() => {
		if (props.position) {
			setPosition(props.position)
		}
	}, [props.position]);

	return (<>
		{	
			<div id='mousePosition'>
				lat , long : ({position ? roundTo(position!.lat, 0.000001) : '-'} , {position ? roundTo(position!.lng, 0.000001) : '-'})
			</div>		
		}
	</>
	)
}

export default MousePosition;