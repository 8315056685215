import './styles.css';

const CenteredScreen = (props: {children : any, size? : string}) => {
    return <div className="main">
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered element sticky">
            <div className={`column is-${props.size ? props.size : 8}`}>
              <div className="box p-0">
                <div className="columns m-0 p-0">
                  <div className="column">
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  </div >;
}

export default CenteredScreen;