import { useEffect, useState } from "react";
import AlertMessage from "../../alert/components/alertMessage";
import { IAlert } from "standard/alert";
import { IStyling } from "standard/styling/IStyling";

const Text = (props: {
    id: string,
    label: string,
    value: string,
    alert?: IAlert,
    inputOnly?: boolean
    style?: IStyling
    onChange: (key: string, value: string) => void,
}
) => {
    const [value, setValue] = useState<string>("");
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {

    }, [props.alert]);

    const onChange = (e: any) => {
        e.preventDefault()
        setValue(e.target.value)
        props.onChange(props.id, e.target.value)        
    }

    const input = () => {
        if (props.inputOnly !== undefined && props.inputOnly === true) {
            return  <>
                    <input className={`input ${props.style && props.style.text}`} id="name" type="text" value={value} onChange={onChange} placeholder={props.label}/>
                    <AlertMessage alert={props.alert}  style={"smallText"}/>
            </>

        }
        else {
            return <div className='field'>
                <label className="label" htmlFor="label">{props.label}</label>
                <div className="control mt-2">
                    <input className="input" id="name" type="text" value={value} onChange={onChange}/>
                </div>
                <AlertMessage alert={props.alert} style={"smallText"}/>
                <hr className="list-divider" />
            </div>
        }
    }


    return input()
}

export default Text;





