import { ILabelComment } from "standard/ontology/interfaces"

export const labels = (vals : ILabelComment[], filter? : string[]) : string => {
    if (!filter){
        const l = vals.map((obj :  ILabelComment) => obj.labels.default).join(', '); 
        if (l === "") return "" 
        return `(${l})`
    }

    const l = vals.filter((obj : ILabelComment) => filter!.includes(obj.uuid!)).map((obj :  ILabelComment) => obj.labels.default).join(', '); 
    if (l === "") return "" 
    return `(${l})`

}

