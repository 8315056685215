import { useEffect, useState } from "react";
import { summarizeText } from "standard/utils/summarizeText";

const TextSummary = (props: {
    value : string
    prefixLength : number
    suffixLength : number
}
) => {   
    const [summary, setSummary] = useState<string>('');
    const [isSummarized, setIsSummarrized] = useState<boolean>(false);
    
        
    useEffect(() => {
        
    }, [isSummarized])

    useEffect(() => {
        setSummary(summarizeText(props.value, props.prefixLength, props.suffixLength))
    }, [props.value, props.prefixLength, props.suffixLength])

    useEffect(() => {
        if (summary !== props.value){
            setIsSummarrized(true)  
        }else{
            setIsSummarrized(false)
        }
    }, [summary, props.value])

    return <span title={`${isSummarized ? props.value : '' }`}>{summary}</span>
}

export default TextSummary;