import { MapWrraper } from "./mapWrapper";
import { Tools } from "./enums";

declare module './mapWrapper' {
    interface MapWrraper {
		toggleTool (
			tool: Tools,
			onActivateCallBacks: ((tool : Tools, activated : boolean) => void)[],
			onDectivateCallBacks: ((tool : Tools, activated : boolean) => void)[]
		) : Set<string>;
		activateTool (
			tool: Tools
		) : void
		deactivateTool (
			tool: Tools
		) : void
    }
}

MapWrraper.prototype.toggleTool = function (
	tool: Tools,
	onActivateCallBacks: ((tool : Tools, activated : boolean) => void)[],
	onDeactivateCallBacks: ((tool : Tools, activated : boolean) => void)[]
)  {
	if (this.status.activeTools.has(tool)) {
        this.deactivateTool(tool);	
		onDeactivateCallBacks.forEach((c) => c(tool, false))
    } else {
        this.activateTool(tool);
		onActivateCallBacks.forEach((c) => c(tool, true))
    }
	return new Set([...this.status.activeTools]);
}

MapWrraper.prototype.activateTool = function (
	tool: Tools,
)  {
	this.status.activeTools.add(tool);
}

MapWrraper.prototype.deactivateTool = function  (
	tool: Tools
): void {
	this.status.activeTools.delete(tool);
}