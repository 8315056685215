import { Middleware } from 'redux'
import jwt from 'jwt-decode'
import { IAuthenticatedUser } from '../../interfaces'

const warningSeconds = process.env.REACT_APP_TIMEOUT_WARNING || 30

export const sessionmanager: Middleware<{}> = store => next => action => {
    if (action.type === "PAGE_LOADED"){
        RefreshToken(store)
    } 
    let result = next(action)
    return result
}

function TimeLeft(authentication : IAuthenticatedUser) : number {
    const decodedJwt : any = jwt(authentication.token);   
    const diffMs = (decodedJwt.exp * 1000 - Date.now());   
    const totalSeconds = diffMs / 1000
    return totalSeconds
}

function RefreshToken(store : any) : void {
    const state = store.getState()
    const refreshingtoken : boolean = state.authentication && state.authentication.refreshingtoken 

    if (refreshingtoken !== undefined && refreshingtoken ===  true) {
        return
    }
    const user : IAuthenticatedUser = state.authentication && state.authentication.user 

    if (!user) return
    if (!user.token) return
    if (!user.refreshToken) return
    // if (TimeLeft(user) <= warningSeconds){
    //     store.dispatch(userActions.refreshToken(user.token, user.refreshToken, user.scope))
    // }
}