import './dialog.css'
import React, { ReactNode, useEffect } from 'react';

interface ModalDialogProps {
    title: string
    display: boolean
    content: ReactNode;
    headerButtons?: ReactNode;
  }

const ModalDialog : React.FC<ModalDialogProps> = (props: {
    title: string
    display: boolean
    headerButtons?: ReactNode;
    content: ReactNode;
}) => {

    useEffect(() => {

    }, [props.content]);

    useEffect(() => {

    }, [props.display]);

    return props.display ? <div className={"modal"}>
        <div className='modal-content m-2 p-1'>
            {props.content}                   
        </div>
    </div> : <></>
}

export default ModalDialog;