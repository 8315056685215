export const readTextFile = (file: any) => {
    return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            var arrayBuffer: string = fileReader.result as string;
            if (arrayBuffer) {
                resolve(arrayBuffer);
            }
        }
        fileReader.readAsText(file);
    });
}