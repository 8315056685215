import { sha256 } from 'js-sha256';

export interface Geometry {
    uuid?: string, //individual uuid
    bbox?: any[],
    type: string, //polygon, point, linestring
    coordinates: any
}

export const featureHash = (digitalTwin : string, uid : string) : string  =>{
    let data = {
        digitalTwin : digitalTwin, 
        uid : uid}
    let hash : string = sha256(JSON.stringify(data));
    return hash
}

export const geometryHash = (digitalTwin : string, classUUID : string , geometry : Geometry) : string  =>{
    let data = {
        digitalTwin : digitalTwin, 
        classUUID : classUUID,
        type : geometry.type, 
        coordinates : geometry.coordinates}
    let hash : string = sha256(JSON.stringify(data));
    return hash
}

export interface Feature {
    id?: string,
    uuid?: string,
    type?: string, 
    class?: string, //uuid of class in domainmodel
    properties?: {[k:string]: any}, //metadata
    geometry: Geometry,
    highlight?: boolean,    
    originOrder?: number
}


export interface Features {
    originName: string
    features: Feature[]
}

export interface GeoJSON {
    type: string
    features: Feature[]
}
