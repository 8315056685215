import { MapWrraper } from "./mapWrapper";
import { v4 as uuidv4 } from 'uuid';
import { LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import { IMenuItem } from "standard/navigation/interfaces/IMenuItem";

declare module './mapWrapper' {
    interface MapWrraper {
		addLayerContextMenu  (
			layer: any, title: string, menuItems: IMenuItem[], setContextMenuOptionsInstance: (key: string) => void
      ) : void
    }
}

MapWrraper.prototype.addLayerContextMenu = function (layer: any, title: string, menuItems: IMenuItem[], setContextMenuOptionsInstance: (key: string) => void) {
		const handleContextMenu = (e : any) => {
			if (e.layer === undefined) {
				return
			}
			let layerMetaData : LayerMetaData = this.status.featureLayers[e.layer.feature.uuid]
			menuItems.forEach((m : IMenuItem) => {m.data = layerMetaData.info})
			this.status.contextMenuOptions = {
				...this.status.contextMenuOptions,
				...{
					visible: true,
					title: title,
					mapPostion: e.latlng,
					clientPosition: { x: e.originalEvent.clientX, y: e.originalEvent.clientY },
					menuItems: menuItems
				}
			};
			setContextMenuOptionsInstance(uuidv4())
		}

		layer.on('contextmenu', handleContextMenu.bind(this));
	}