import React, { useEffect, useState } from 'react';
import ModalDialog from './modaldialog';
import './dialog.css'

const AlertDialog = (props: {
    title: string
    ok: () => void
    cancel: (event: any) => void
    okText: string
    cancelText: string
    show: boolean
    children: any
}) => {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(props.show)
    }, [props.show]);

    useEffect(() => {

    }, [show])


    const handleOk = () => {
        let okCallback = props.hasOwnProperty('ok') ? props.ok : null;
        if (typeof okCallback === "function") {
            okCallback();
        }
    }

    const handleCancel = (event: any) => {
        setShow(false)
        let cancelCallback = props.hasOwnProperty('cancel') ? props.cancel : null;
        if (typeof cancelCallback === "function") {
            cancelCallback(event);
        }
    }

    const content = () => {
        return <>
            <div className="modal-scroll">
                {props.children}
            </div>
            <div className="modal-bottom">
                <button className={"button m-2"} onClick={handleOk}>{props.okText}</button>
                <button className="button m-2" onClick={handleCancel}>{props.cancelText || "Cancel"}</button>
            </div></>
    }

    return (
        <ModalDialog title={props.title} display={show}
            content={content()}
        />
    );
}

export default AlertDialog