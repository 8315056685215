import { useEffect, useState } from "react";
import CenteredScreen from "components/standard/centeredScreen/centeredScreen";
import { Feature } from "standard/geojson/@types";
import Text from "standard/forms/text/text"

const CreateClone = (props: {
  name: string
  features: Feature[]
  onNewUpload: (name: string, features: Feature[], onComplete: () => void) => void
}) => {
  const [uploadName, setUploadName] = useState<string>(props.name);
  const [cloningComplete, setCloningComplete] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Export : Create Clone';
  }, [])

  useEffect(() => {
   
  }, [uploadName])

  useEffect(() => {
    if (isSaving) {
      props.onNewUpload(uploadName, props.features, onNewUploadComplete) 
    }
  }, [isSaving])

  useEffect(() => {

  }, [cloningComplete])

  const onNameChange = (id : string, value : string) => {
    setUploadName(value)
  }

  const clone = () => {
    setIsSaving(true)
  }

  const onNewUploadComplete = () => {
    setIsSaving(false)
    setCloningComplete(true)
  }

  return (<CenteredScreen>
    {(isSaving || cloningComplete) ||
      (<><h3 className="subtitle is-6">Clone the results into a new upload.</h3>
      <Text id='cloneName' label='Name of Clone' value={uploadName} onChange={onNameChange} />
      <button className="button is-info is-pulled-left" onClick={clone}>
        Clone
      </button></>)
    }
    {!isSaving || <><div className="control is-loading"></div><h3 className="subtitle is-6">Creating clone, please wait...</h3></>}
    {!cloningComplete || (<h3 className="subtitle is-6">Clone ({uploadName}) was successfully created.</h3>)}
  </CenteredScreen>);
}
export default CreateClone;