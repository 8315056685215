import { EmptyFilter, RangeFilter, StringFilter } from "./@types/filter";

export const filterEmptyString = (filter: EmptyFilter, row: { [key: string]: any }, split: boolean) => {
    const value = row![filter.property]
    

    if (value === null || value === undefined) {
        return true
    }

    return false
}

export const filterExactString = (filter: StringFilter, row: { [key: string]: any }, split: boolean) => {
    const value = row![filter.property]


    if (value === null || value === undefined) {
        return false
    }

    let valueSearch: boolean = true

    if (value.toString().toLowerCase() !== filter.value!.toString().toLowerCase()) {
        valueSearch = false
    }        

    if (valueSearch) {
        return true
    }

    return false
}


export const filterContainsString = (filter: StringFilter, row: { [key: string]: any }, split: boolean) => {
    const value = row![filter.property]

    if (value === null || value === undefined) {
        return false
    }

    let filterSearch: string[] | undefined = split ? `${filter.value}`.toLowerCase().split(' ') : [`${filter.value}`.toLowerCase()]
    filterSearch = filterSearch!.length === 0 ? undefined : filterSearch

    if (filterSearch === undefined) {
        return false
    }

    let valueSearch: boolean = true

    for (const searchString of Object.values(filterSearch)) {      
        if (value.toString().toLowerCase().indexOf(searchString) === -1) {
            valueSearch = false
            break
        }        
    }

    if (valueSearch) {
        return true
    }

    return false
}

export const filterOutString = (filter: StringFilter, row: { [key: string]: any }, split : boolean) => {
    const value = row![filter.property]

    if (value === null || value === undefined) {
        return false
    }

    let filterSearch: string[] | undefined = split ? `${filter.value}`.toLowerCase().split(' ') : [`${filter.value}`.toLowerCase()]
    filterSearch = filterSearch!.length === 0 ? undefined : filterSearch

    if (filterSearch === undefined) {
        return false
    }

    let valueSearch: boolean = true

    for (const searchString of Object.values(filterSearch)) {
        if (value.toString().toLowerCase().indexOf(searchString) !== -1) {
            valueSearch = false
            break
        }
    }

    if (valueSearch) {
        return true
    }

    return false
}


export const filterNumericRange = (filter: RangeFilter, row: { [key: string]: any }) => {
    const value = row![filter.property]

    if (value === null || value === undefined) {
        return false
    }

    if (filter.fromValue !== undefined){
        if (filter.fromValue !== ''){
            if (parseFloat(value) < parseFloat(filter.fromValue)) {
                return false
            }
        }
    }

    if (filter.toValue !== undefined){
        if (filter.toValue !== ''){
            if (value > filter.toValue) {
                return false
            }
        }
    }

    return true
}