import { ActionTypes } from './actionsTypes'

export function alert(state = {}, action: any) {
  switch (action.type) {
    case ActionTypes.SUCCESS:
    case ActionTypes.ERROR:
    case ActionTypes.ERROR_SERVER:
      return {
        type: action.type,
        message: action.message
      };
    case ActionTypes.CLEAR:
      return {};
    default:
      return state
  }
}