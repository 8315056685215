import { Features } from "standard/geojson/@types";
import { IAlert, ActionTypes } from "standard/alert";

export const useExport = () => {
    async function exportFeatures(fileName : string, features : Features): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                    JSON.stringify(features)
                )}`;
                const link = document.createElement("a");
                link.href = jsonString;
                link.download = fileName;
                link.click();
                res({
                    message: "Geo data exported to file",
                    type: ActionTypes.SUCCESS,
                } as IAlert)
            } catch (error: any) {
                rej({
                    message: `Unable to export geo data to file ${error}`,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })
        return promise
    }

    return { exportFeatures};
}