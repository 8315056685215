import { useEffect } from 'react';
import { IAggregate, ISummary } from 'standard/summary/@types/ISummary';
import TextSummary from 'standard/UI/components/textSummary';

const Cell = (props: {
    rowKey : string;
    propertyName: string
    value: ISummary
    prevValue?: ISummary
    style?: string
    onValueClick: (e: any, key: string, value: ISummary) => void
    onValueDoubleClick: (e: any, key: string, value: any) => void
}) => {


        
    useEffect(() => {

    }, [])

    const {propertyName} = props

    const displayContent = (propertyName: string) => {
        let repeatingStyle : string = ''
        let tooltip: string = ''        
        if (props.prevValue){
            if (props.prevValue.key === props.value.key){
                repeatingStyle = 'repeating-style'
            }
        }
        
        return <>
            <div 
                key={props.value.key}
                className={`pl-1 pr-1 level-start ${repeatingStyle}`} title={tooltip}
                onClick={(e) => props.onValueClick(e, propertyName, props.value)}>
                {/* {displayDetail()} */}
                {displayAggregate(props.value.aggregates.count)}
            </div>           
        </>
    }
    
    // const displayDetail = () => {
    //     return <div className='columns m-0 p-0  list-header'>
    //         <div className='column is-narrow ml-1 p-0'>
    //             <TextSummary value={props.value.value ? props.value.value : '-'} prefixLength={20} suffixLength={5}/>
    //         </div>
    //     </div>
    // }

    const displayAggregate = (aggregate: IAggregate) => {
        return aggregate.value && <div className='columns m-0 p-0'>
            <div className='column is-narrow ml-1 p-0'>
                {aggregate.type}
            </div>
            <div className='column is-narrow ml-1 p-0'>
                :
            </div>
            <div className='column ml-1 p-0'>
                {aggregate.value}
            </div>
        </div>
    }

    return <div className='card ml-0 mt-2 mb-0 p-1'> 
    {/* <header className="card-header" >
        <span className="card-header-title smallText m-1 p-1">
            {props.propertyName} : <TextSummary value={props.value.value ? props.value.value : '-'} prefixLength={20} suffixLength={5}/>
        </span>
    </header> */}
    <div className="card-content p-1 m-1">
        {displayContent(propertyName)}
    </div>
</div>
    
    
    // <div className='card'>
    //  <div key={props.value.key} className={`column cell p-0 m-0`}>     
    //     {displayContent(propertyName)}
    // </div>
    // </div>
}

export default Cell;