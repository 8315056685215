import { actionTypes } from './actionsTypes';

const initialState = {};

export function navigation(state = initialState, action:any) {
  switch (action.type) {
    case actionTypes.PAGE_LOADED:
      return {
        page: action.page
      };
    default:
      return state
  }
}