import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { IStyling } from 'standard/styling/IStyling';

const Search = (props: {
    onSearch: (search: string) => void
    onSearchChange?: () => void
    style?: IStyling
}
) => {
    var [searchPhrase, setSearchPhrase] = useState<string>("")
    var [searchTrigger, setSearchTrigger] = useState<any>()

    useEffect(() => {
        if (props.onSearchChange){
            props.onSearchChange()
        }
    }, [searchPhrase])

    const onSearchChange = (e: any) => {
        clearTimeout(searchTrigger);
        setSearchTrigger(undefined)
        setSearchPhrase(e.target.value)
        const t = setTimeout(() => doSearch(e.target.value), 2000)
        setSearchTrigger(t);
    }

    function doSearch(search : string) {
        props.onSearch(search)
    }

    return (
    <div className={`${(props.style && props.style.container) && props.style!.container!}`}>
        <div className={`control searchInput has-icons-left ${(props.style && props.style.text) && props.style!.text!}`}>
            <input className={`input is-info ${(props.style && props.style.text) && props.style!.text!}`} type="text" placeholder="Search.." value={searchPhrase} onChange={onSearchChange} />
            <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <i className="fas fa-user"></i>
            </span>
        </div>
    </div>

    )
}

export default Search;