import { useEffect, useState } from 'react';
import '../style.css';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import HMenuRoot from './hMenuRoot';


const HMenu = (props: {
    menuItems: IMenuItem[]
    onselected: (item: IMenuItem) => void
    active?: string
}) => {
    const [active, setActive] = useState<string>('');
    const { menuItems } = props;
    const rootNodes = menuItems && menuItems.filter((node: IMenuItem) => node.parent === undefined)

    useEffect(() => {

    })

    useEffect(() => {
        setActive(props.active ? props.active : '')
    }, [props.active])

    const parentNode = (item: IMenuItem) => {
        const children = menuItems.filter((node: IMenuItem) => node.parent === item.name)
        return <HMenuRoot key={item.name} item={item} children={children} active={active} onselected={props.onselected}/>
    }
    return <div className="hmenu m-0 p-0">
        {rootNodes && rootNodes.map((item: IMenuItem) =>
            parentNode(item)
        )}
    </div>
};

export default HMenu;