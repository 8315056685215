import { useEffect, useCallback, useState } from 'react';
import localforage from 'localforage';
import { Mapping, Uploads_Meta, Upload_Meta } from '../types/uploads';
import { Feature } from 'standard/geojson/@types';
import { UPLOAD_META_LOCAL_STORAGE_KEY } from 'components/utils/constants';
import { sha256 } from 'js-sha256'
import { ActionTypes, IAlert } from 'standard/alert';
import { updateFeatures } from './utils';

export const useUploads = () => {
    let [uploads_Meta, setUploads] = useState<Uploads_Meta>();
    let [loading, setLoading] = useState<boolean>(false);
    let [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
    }, [])

    useEffect(() => {

    }, [uploads_Meta])


    const loadData = useCallback(() => {
        if (loading === true || reloading === true) {
            localforage.getItem(UPLOAD_META_LOCAL_STORAGE_KEY, function (err, value) {
                if (value) {
                    setUploads(value as Uploads_Meta)
                } else {
                    setUploads({})
                }
                setLoading(false)
                setReloading(false)
            });
        }
    }, [loading, reloading])

    useEffect(() => {
        if (loading) {
            loadData()
        }
    }, [loading, loadData])

    useEffect(() => {
        if (reloading) {
            loadData()
        }
    }, [reloading, loadData])

    const reload = useCallback(() => {
        setReloading(true)
    }, [])

    const updateFeaturesOnLocal = (key: string, newFeatures: Feature[], onComplete?: () => void) => {
        updateFeatures(key, newFeatures).then(() => {
            updateFeaturesDate(key)
        }).then(() => {
            if (onComplete){
                onComplete()
            }

        }
        ).catch(function (err) {

        });
    }

    function storeNewUpload(name: string, newFeatures: Feature[], onComplete: () => void): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                let dt = new Date()
                const uploadsStoreKey: string = sha256(`${name} ${dt}`)
                let newUploadStore: Uploads_Meta = { ...uploads_Meta }
                let newUpload: Upload_Meta = {
                    key: uploadsStoreKey,
                    name: name,
                    timestamps: {
                        created : dt,
                        featuresUpdated: dt,
                        metadataUpdated: dt
                    },
                    mapping: { template: {} }
                }
                newUploadStore[uploadsStoreKey] = newUpload
                updateFeatures(uploadsStoreKey, newFeatures)
                storeUploads(newUploadStore, onComplete)
                res({ message: '', type: ActionTypes.SUCCESS })
            } catch (error) {
                rej({ message: error, type: ActionTypes.ERROR })
            }
        });
        return promise;
    }

    // const storeNewUpload = (name: string, newFeatures:  Feature[] ) => {
    //     let dt = new Date()
    //     const uploadsStoreKey : string = sha256(`${name} ${dt}`)
    //     let newUploadStore: Uploads = { ...uploads}
    //     let newUpload : Upload = {key : uploadsStoreKey, name :name, date: dt, features: newFeatures, mapping: {template:{}}}
    //     newUploadStore[uploadsStoreKey] = newUpload
    //     storeUploads(newUploadStore)
    // }



    const storeUploads = (uploadsToStore: Uploads_Meta, onComplete?: () => void) => {
        localforage.setItem(UPLOAD_META_LOCAL_STORAGE_KEY, uploadsToStore).then(function () {
        }).then(function (value) {
            reload()
            if (onComplete){
                onComplete()
            }
        }).catch(function (err) {
            // we got an error
        });
    }

    const updateMapping = (key: string, mapping: Mapping, onComplete: () => void) => {
        let newUploadStore: Uploads_Meta = { ...uploads_Meta }
        newUploadStore[key].mapping = mapping
        storeUploads(newUploadStore, onComplete)
    }

    const updateMetaDataDate = (key: string, onComplete?: () => void) => {
        let newUploadStore: Uploads_Meta = { ...uploads_Meta }
        newUploadStore[key].timestamps.metadataUpdated = new Date()
        storeUploads(newUploadStore, onComplete)
    }

    const updateFeaturesDate = (key: string, onComplete?: () => void) => {
        let newUploadStore: Uploads_Meta = { ...uploads_Meta }
        newUploadStore[key].timestamps.featuresUpdated = new Date()
        storeUploads(newUploadStore, onComplete)
    }

    const deleteUpload = (key: string, onComplete: () => void) => {
        let newUploads = { ...uploads_Meta }
        delete newUploads![key]
        storeUploads(newUploads, onComplete)
        deleteUploadFeatures(key)
    }

    const deleteUploadFeatures = (key: string) => {
        localforage.removeItem(key).then(function () {
        }).then(function (value) {
            // reload()
        }).catch(function (err) {
            // we got an error
        });
    }

    return { uploads_Meta, reload, loading, storeUploads, storeNewUpload, updateFeaturesOnLocal, updateMapping, deleteUpload };
}