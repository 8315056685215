import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useEffect, useState, useRef } from 'react';
import AlertMessage from "standard/alert/components/alertMessage";
import { IAlert } from "standard/alert";
import { IOption } from 'standard/forms/interfaces/IOption';
import { IStyling } from 'standard/styling/IStyling';

const SelectBox =
    (props: {
        options: IOption[],
        onChange: (newValue?: any) => any,
        isMulti: boolean,
        selected?: string,
        alert?: IAlert,
        className?: string
        style?: IStyling
    }

    ) => {
        const [selected, setSelected] = useState<IOption>();
        const [filtered, setFiltered] = useState<any>();
        const ref = useRef(null);



        useEffect(() => {
            setFiltered(props.options);
            if (props.selected) {
                setSelected(props.options.find((o : IOption) => o.value === props.selected));
            }
        }, [props.options, props.selected]);

        const onInputChange = (inputValue: any, actionMeta: any) => {
            onSearch(inputValue)
        }
        const onSearch = (search: string) => {
            // if (search.trim() === '') {
            //     setFiltered(undefined)
            //     return
            // }

            if (props.options !== undefined) {
                const searches = search.toLowerCase().split(' ')
                let filtered: any[] = props.options
                for (let i = 0; i < searches.length; i++) {
                    filtered = filtered &&
                        filtered.filter(option => (option.label).toLowerCase().indexOf(searches[i]) > -1)
                    if (filtered.length === 0) {
                        break
                    }
                }
                setFiltered(filtered)
            }
        }

        const onChange = (newValue: OnChangeValue<any, false>,            
             actionMeta: ActionMeta<any>) => {    
            setSelected(newValue);
            props.onChange(newValue);
        }
        
        return (
            <>
                <Select
                    ref={ref}
                    isMulti={props.isMulti}
                    value={selected}
                    isSearchable={true}
                    isClearable={true}
                    name="domainModels"
                    options={filtered}
                    onInputChange={onInputChange}
                    onChange={onChange}
                    className={`basic-multi-select ${props.className ? props.className : ''} ${props.style && props.style.text}`}
                    classNamePrefix="select"
                />
                <AlertMessage alert={props.alert} style={"smallText"} />
            </>
        )
    }

export default SelectBox;