import { useState, useEffect } from 'react';
import { Feature } from 'standard/geojson/@types';
import { IDomainModel } from 'standard/ontology/interfaces';
import useDomainModelTree from 'standard/ontology/domainModel/hooks/useDomainModelTree';
import { ValidatedFeatures, validateFeatures } from '../utils';
import { IOption } from 'standard/multiselect/@types/IOption';
import FeaturesList from '../list';

const Validated = (props: {
    title : string
    domainModel: IDomainModel,
    features: Feature[]
}) => {
    const [actions, setActions] = useState<IOption[]>();
    const { domainModels} = useDomainModelTree({ domainModel: props.domainModel});
    const [validated, setValidated] = useState<Record<string, Feature[]>>();
    const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>();
    const [currentLayer, setCurrentLayer] = useState<IOption>();

    useEffect(() => {

    }, [domainModels]);

    useEffect(() => {
        if (domainModels){
            validateFeatures('', domainModels, props.features).then((result : ValidatedFeatures) =>{
                setValidated(result.validated);
            })
        }
    }, [domainModels, props.features]);

    useEffect(() => {   
        if (validated) {   
            let a : IOption[] = Object.keys(validated).map(k => {return {value : k, label: `${k} (${validated[k].length})`}})
            a = a.sort((a, b) => a.label.localeCompare(b.label))
            setActions(a);
        }
    }, [validated]);

    useEffect(() => {
        if (actions && currentLayer === undefined) {
            if (actions.length > 0) {
                setCurrentLayer( actions[0] )
            }
        }
    }, [actions]);

    useEffect(() => {
        if (validated && currentLayer) { 
            setSelectedFeatures(Object.values(validated[currentLayer.value]))
        }else {
            setSelectedFeatures(undefined)
        }
    }, [validated, currentLayer])
  
    useEffect(() => {

    }, [currentLayer])
   
    const onResultAction = (option: IOption) => {
        setCurrentLayer(option);
    }

    return  <FeaturesList
                title={`${props.title} ${currentLayer && (' : ' + currentLayer.label) }`}
                features={selectedFeatures}
                options={{ title:"Select Layer", options: actions || [], selected : currentLayer , onSelect: onResultAction }}
            />
}

export default Validated;