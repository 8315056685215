import { useState, useEffect } from 'react';
import MappingEdit from './mappingedit'
import { Input } from 'standard/forms/inputs'
import { IDomainModel } from 'standard/ontology/interfaces';
import { IRecordAdd } from 'standard/datatools/@types';
import { Upload_Meta } from '../../types/uploads';
import {  RecordDeletes } from 'standard/datatools/@types/IMappingTemplate';
import { default as Mapping } from './mapping';
import ModalDialog from 'standard/dialog/modaldialog';
import { Inputs } from './inputs';
import { Feature } from 'standard/geojson/@types';
import { RecordDeleteFactoryFromInputs } from './utils';

const MapperList = (props: {
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    onMappingChange: (mappings: RecordDeletes) => void
}) => {
    const [showAddNew, setShowAddNew] = useState<boolean>(false);

    useEffect(() => {
        
    }, [props.upload])

    useEffect(() => {

    }, [props.upload.mapping])

    useEffect(() => {

    }, [props.targetModel])

    const onAddNew = () => {
        setShowAddNew(true)
    }

    const onCloseDialog = () => {
        setShowAddNew(false)
    }

    const handleAddNew = (newinputs: { [key: string]: Input }) => {      
        let recordDelete : IRecordAdd = RecordDeleteFactoryFromInputs(newinputs)

        let recordDeletes: RecordDeletes = {}
        if (props.upload.mapping.template && props.upload.mapping.template.recordDeletes) {
            recordDeletes = {...props.upload.mapping.template.recordDeletes}
        }

        recordDeletes[recordDelete.id] = recordDelete
        props.onMappingChange(recordDeletes)
        setShowAddNew(false)
    }

    const handleEdit = (id : string, newinputs: { [key: string]: Input }) => {
        let recordDelete : IRecordAdd = Object.entries(newinputs).reduce((obj, [key, value]) => {
            obj[key] = value.value;
          return obj;
        }, {} as IRecordAdd);

        recordDelete.id = id

        let recordDeletes: RecordDeletes = {}
        if (props.upload.mapping.template && props.upload.mapping.template.recordDeletes) {
            recordDeletes = {...props.upload.mapping.template.recordDeletes}
        }
        recordDeletes[id] = recordDelete
        props.onMappingChange(recordDeletes)        
    }

    const onDelete = (id: string) => {
        if (props.upload.mapping.template && props.upload.mapping.template.recordDeletes) {
            let recordDeletes: RecordDeletes = {}
            recordDeletes = {...props.upload.mapping.template.recordDeletes}
            delete recordDeletes[id]
            props.onMappingChange(recordDeletes)
        }
    }

    const createHeaders = () => {
        return Object.values(Inputs()).map((value) => {return <div key={value.name} className="column is-2 list-header-cell cell p-0 pl-1 pr-1 m-0">
            {value.label}
        </div>})
      }
  
    const content = () => {
        return !showAddNew || <MappingEdit 
                                title='Edit Delete Mapping'
                                upload={props.upload} 
                                features={props.features} 
                                targetModel={props.targetModel} 
                                isEditMode={false} 
                                onAdd={handleAddNew} 
                                onCancel={onCloseDialog} 
                        />
    }

    return (
        <>
            <div className='ml-5 mr-5  smallText is-centered'>
                <div className="columns list-header mt-1 mb-0 pb-0 has-text-weight-bold is-centered is-vcentered">
                    {createHeaders()}                    
                    <div className="column is-1 m-0 p-0 pb-1 clickable">
                        <button className="card-header-icon button is-primary" aria-label="more options" onClick={onAddNew}>
                            Create New
                        </button>
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                    </div>
                </div>
                <hr className="navbar-divider m-0 p-0" />
                {(props.upload.mapping.template && props.upload.mapping.template.recordDeletes) && Object.keys(props.upload.mapping.template.recordDeletes).map((key) => {
                    return <Mapping upload={props.upload} features={props.features} targetModel={props.targetModel} key={key} id={key} onEdit={handleEdit} onDelete={onDelete} />
                })}

            </div>
            <ModalDialog title='New Mappping' display={showAddNew}
                content = {content()}
            />
        </>
    )
}

export default MapperList;