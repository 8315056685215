import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IAlert } from "standard/alert";
import { IDomainModel } from 'standard/ontology/interfaces'
import MappingEdit from './mappingedit'
import { Input } from 'standard/forms/inputs'
import { Upload_Meta } from '../../types/uploads';
import ModalDialog from 'standard/dialog/modaldialog';
import * as FormInputs from 'standard/forms/inputs'
import { Feature } from 'standard/geojson/@types';
import { IRecordAdd } from 'standard/datatools/@types';
import TextSummary from 'standard/UI/components/textSummary';

const Mapping = (props: {
    id: string
    upload: Upload_Meta,
    features: Feature[],
    targetModel: IDomainModel
    onEdit?: (id: string, inputs: { [key: string]: Input }) => void
    onDelete: (id: string) => void
}) => {
    const [editting, setEditting] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlert>();
    const [recordDelete, setRecordDelete] = useState<IRecordAdd | undefined>();

    useEffect(() => {
        let m : IRecordAdd = props.upload.mapping.template.recordDeletes![props.id]
        setRecordDelete(m);
    }, [props.upload]);

    useEffect(() => {
        if (recordDelete){
            if (recordDelete.value !== undefined && recordDelete!.values === undefined){
                setRecordDelete({...recordDelete, ...{values : [recordDelete.value]}})
            }
        }
    }, [recordDelete]);

    const handleEditClick = (e: any) => {
        e.preventDefault();
        setEditting(true)
    }

    const handleDeleteClick = (e: any) => {
        e.preventDefault();
        props.onDelete(props.id)
    }

    const onEdit = (id: string, inputs: { [key: string]: FormInputs.Input }) => {
        setEditting(false)
        props.onEdit!(id, inputs)
    }

    const onCancelEdit = () => {
        completeEdit()
    }

    const completeEdit = () => {
        setAlert(undefined)
        setEditting(false)
    }

    const content = () => {
        return !editting || <MappingEdit
        title='Edit Delete Mapping'
        upload={props.upload}
        features={props.features}
        targetModel={props.targetModel}
        key={recordDelete!.input}
        mapping={recordDelete}
        isEditMode={true}
        onEdit={onEdit}
        onCancel={onCancelEdit} />
    }

    return (
        <>
            {(recordDelete) ?
                editting === true ||
                <div className="columns mt-0 pt-1 mb-1 is-centered" key={recordDelete.input}>
                    <div className="column is-2 m-0 p-0">
                        {recordDelete.input}
                    </div>
                    <div className="column is-2 m-0 p-0">
                        {recordDelete.values && <TextSummary value={recordDelete.values.concat().join(', ')} prefixLength={20} suffixLength={5}/>}
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faPencil} onClick={handleEditClick} />
                    </div>
                    <div className="column is-1 m-0 p-0 clickable">
                        <FontAwesomeIcon icon={faTrash} onClick={event => handleDeleteClick(event)} />
                    </div>
                </div>
                : null
            }
            <hr className="navbar-divider p-0 m-0" />
            <ModalDialog title='Edit Value Mapping' display={editting}
                content={content()}
            />
        </>
    )
}

export default Mapping;