  // Function to set the URL path without navigation
  export const setPathWithoutNavigation = (newPath : string) => {
    const originalRoute = window.location.pathname;
    window.history.pushState(null, '', newPath);
  };

  export const updateURLParameters = (newParams : Record<string, string>) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Update or set new query parameters
    for (const [key, value] of Object.entries(newParams)) {
      searchParams.set(key, value);
    }

    // Replace the current URL without triggering navigation
    window.history.replaceState(null, '', `${url.pathname}?${searchParams.toString()}`);
  };

  export const removeURLParameters = (keys : string[]) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Update or set new query parameters
    for (const key of keys) {
      searchParams.delete(key);
    }
    
    // Replace the current URL without triggering navigation
    window.history.replaceState(null, '', `${url.pathname}?${searchParams.toString()}`);
  };
