import { actionTypes } from './actionsTypes';
import { IUI } from './interfaces';

const initialState : IUI = { fullscreenenabled : false }

export function ui(state = initialState, action:any) : IUI {
  switch (action.type) {
    case actionTypes.ENABLE_FULLSCREEN:
      return {
        fullscreenenabled: true
      };
    case actionTypes.DISABLE_FULLSCREEN:
      return {
        fullscreenenabled: false
      };
    default:
      return state
  }
}