import { useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';

const UIWrapper = (props: {
    children?: any
}
) => {
    const ui = useAppSelector(state => state.ui)

    useEffect(() => {
        
    }, [ui.fullscreenenabled])
    
    return (
        (ui.fullscreenenabled) ? 
            <></>
            : 
            props.children
        
    )
}


export default UIWrapper;