import { useEffect, useState, useCallback } from 'react';
import '../styles.css'
import { Feature } from 'standard/geojson/@types';
import Properties from '../properties';
import { IDomainModel, IDomainModelClass } from 'standard/ontology/interfaces';
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import AlertMessage from 'standard/alert/components/alertMessage';
import { ActionTypes, IAlert } from 'standard/alert';
import { MapFormat, MapFormatting, MapFormattings } from './mapFormat';
import { DomainModels } from 'standard/ontology/domainModel/domainmodels';
import { ClassProperties } from 'standard/ontology/domainModel/types/classProperties';

enum Tabs {
    properties = 'properties',
    override = 'override',
    format = 'format',
};

const PropertiesBar = (props: {
    feature: Feature | undefined,
    override?: Feature | undefined
    targetModel: IDomainModel
    models: DomainModels | undefined
    classProperties: ClassProperties | undefined
    formattings: MapFormattings
    referencedClasses: IDomainModelClass[]
    defaultClass?: string
    isEdittable: boolean
    onFilter: (filter: any) => void
    onFormatChange: (formattings: MapFormattings) => void
    onSetFilterProperty?: (property : string) => void
    onPropertyChanges?: (uuid : string, properties : Record<string, any>) => void
}) => {

    const [filteredData, setFilteredData] = useState<Feature[]>();
    const [doingSearch, setDoingSearch] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>();
    const [showMenu, setShowMenu] = useState(false);
    const [newItemsMenu, setNewItemsMenu] = useState<IMenuItem[]>();
    const [defaultClass, setDefaultClass] = useState<string>();
    const [defaultFeature, setDefaultFeature] = useState<Feature>();
    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.properties);
    const [overrideAlert, setOverrideAlert] = useState<IAlert>();
    let [overrideProperties, setOverrideProperties] = useState<string[]>([]);

    useEffect(() => {
        
    }, [activeTab]);

    useEffect(() => {

    }, [props.classProperties]);
    
    useEffect(() => {
        if (activeTab === Tabs.override && props.override === undefined) {
            setActiveTab(Tabs.properties);
        }
    }, [props.override]);

    useEffect(() => {
        setDoingSearch(false)
    }, [filteredData])

    useEffect(() => {
        let domainMenuItems : IMenuItem[] = props.targetModel!.classes!.map((f : IDomainModelClass, idx : number) => {return {name : f.uuid!, 
            label : `${f.labels.default}`, 
            ordinality : idx, 
            parent: f.isSubClassOf ? f.isSubClassOf : undefined, 
            }})
        setNewItemsMenu(domainMenuItems)
    }, [props.targetModel])

    useEffect(() => {
        
    }, [newItemsMenu])

    const closeMenu = useCallback(() => {
        setShowMenu(false)
    }, [])

    useEffect(() => {
        closeMenu()
    }, [doingSearch, closeMenu])


    useEffect(() => {

    }, [showMenu])


    useEffect(() => {

    }, [overrideAlert])

    useEffect(() => {

    }, [props.feature])

    useEffect(() => {
        if (props.override) {
            setActiveTab(Tabs.override)
        }
    }, [props.override])

    useEffect(() => {

    }, [filters])

    const onDeleteFilter = (id: string) => {
        let newFilters = { ...filters }
        delete newFilters[id]
        setFilters(newFilters)
    }

    const onDeletePropertyFilters = (property: string) => {
        let newFilters = {}
        Object.values(filters!).forEach((value, key) => {
            if (value.property !== property) {
                newFilters[key] = value;
            }
        });
        setFilters(newFilters)
    }

    const onFilter = (filter: any) => {
        setDoingSearch(true)
        const currentFilters = filters ? filters : {}
        setFilters({ ...currentFilters, ...{ [filter.id]: filter } })
        // setShowFilter(false)
    }

    const onPropertyChange = (uuid: string, key: string, value: any) => {
        props.onPropertyChanges!(uuid, {[key]: value})
    }

    const onDefaultFeatureChange = (feature : Feature | undefined) => {
        setDefaultFeature(feature)        
    }

    const changeTab = (tab : Tabs) => {
        setActiveTab(tab)      
    }

    const applyOverride = () => {   
        if (overrideProperties.length === 0) {
            setOverrideAlert({
                message: "Select at least one property to override",
                type: ActionTypes.ERROR
            } as IAlert)    
            return
        }
        setOverrideAlert(undefined)
        let overrides : Record<string, any> = {}
        for (const p of overrideProperties) {
            overrides[p] = props.override!.properties![p]
        }
        props.onPropertyChanges!(props.feature!.uuid!, overrides)    
    }

    const onOverridePropertySelection = (selection : string[]) => {
        setOverrideProperties(selection)        
    }

   

    return (
            <div className=''>
                <div className="tabs is-small is-boxed">
                    <ul>
                        <li className={`${activeTab === Tabs.properties && 'is-active'}`} onClick={() => changeTab(Tabs.properties)}>
                            <a>Properties</a>
                        </li>
                        {
                            props.override &&
                            <li className={`${activeTab === Tabs.override && 'is-active'}`} onClick={() => changeTab(Tabs.override)}>
                                <a>Override</a>
                            </li>    
                        }                        
                        <li className={`${activeTab === Tabs.format && 'is-active'}`} onClick={() => changeTab(Tabs.format)}>
                            <a>Format</a>
                        </li>  
                    </ul>
                </div>
                {
                    activeTab === Tabs.properties && 
                    <Properties
                        targetModel={props.targetModel}
                        referencedClasses={props.referencedClasses}
                        selectedFeature={props.feature}
                        isEdittable={props.isEdittable}
                        classProperties={props.classProperties}
                        onFilter={onFilter}
                        filters={filters}
                        onSetFilterProperty={props.onSetFilterProperty}
                        defaultClass={defaultClass}
                        onDeleteFilter={onDeleteFilter}
                        onDeletePropertyFilters={onDeletePropertyFilters}
                        onPropertyChange={onPropertyChange}
                        onFeatureChange={onDefaultFeatureChange}
                    />
                }
                {
                    activeTab === Tabs.override && 
                    <>
                        <Properties
                            targetModel={props.targetModel}
                            referencedClasses={props.referencedClasses}
                            selectedFeature={props.override}
                            selectedProperties={overrideProperties}
                            disabledProperties={['uuid']}
                            isEdittable={false}
                            classProperties={props.classProperties}
                            canFilter={false}
                            canSelect={true}
                            onFilter={onFilter}
                            filters={filters}
                            onSetFilterProperty={props.onSetFilterProperty}
                            defaultClass={defaultClass}
                            onDeleteFilter={onDeleteFilter}
                            onDeletePropertyFilters={onDeletePropertyFilters}
                            onPropertyChange={onPropertyChange}
                            onFeatureChange={onDefaultFeatureChange}
                            onSelection={onOverridePropertySelection}
                        />
                        {props.override &&
                            <div className='box has-text-centered p-2'>
                            <button className="button is-link" onClick={applyOverride}>Apply Override</button>
                            <AlertMessage alert={overrideAlert} style={"smallText"}/>                        
                        </div>
                        }                        
                    </>
                }
                {
                    activeTab === Tabs.format && 
                    <>
                        <MapFormat onFormatChange={props.onFormatChange} formattings={props.formattings}/>
                    </>
                }
            </div>
    )
}

export default PropertiesBar;