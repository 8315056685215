import { useEffect } from "react";
import { Feature } from 'standard/geojson/@types'
import Coordinate from './coordinate'

const Coordinates = (props: {
	feature : Feature
}) => {
	
	useEffect(() => {

    }, [props.feature])

	const onDeleted = (index : number) => {
       
    }

	return (<>	<hr className="m-0 p-0"/>
				{props.feature.geometry.coordinates.map((coordinate: number[], index : number) => { 
					return <>
								<Coordinate 
									key={index} 
									coordinate={coordinate} 
									// className={`pl-2 pt-2 ${(index + 1) % 2 === 0 ? 'alternate': ''}`}
									index={index}
									onDeleted={onDeleted}
								/>
								<hr className="m-0 p-0"/>
							</>
				})}
            </>
	)
}

export default Coordinates;