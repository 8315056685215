import { useEffect, useState } from 'react';
import { getNetworks } from '../service_networks';
import { useAppSelector } from 'hooks/redux'
import { IDigitalTwin } from '../../interfaces';
import { IAlert, ActionTypes } from "../../../alert";

const useNetworks = (props: { inScopeOnly: boolean, onlyMeta: boolean }) => {
    const [networks, setNetworks] = useState<Array<IDigitalTwin>>();
    const authenticated = useAppSelector(state => state.authentication)
    const [networksAlert, setNetworksAlert] = useState<IAlert>();
    let [loading, setLoading] = useState<boolean>(false);
    let [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        load()
    }, []);

    const load = () => {
        getNetworks(authenticated.user!.token, props.inScopeOnly, props.onlyMeta)
            .then((items: IDigitalTwin[]) => {
                setNetworks(items);
            })
            .catch((err) => {
                setNetworks(undefined);
                setNetworksAlert({
                    message: err.message,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }).finally(() => {
                setLoading(false)
                setReloading(false)
            });
    }

    useEffect(() => {
        if (reloading) {
            load()
        }
    }, [reloading])

    const reloadNetworks = () => {
        setReloading(true)
    }

    return { networks, reloadNetworks, networksAlert, loading, reloading };
}


export default useNetworks

