import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/redux'
import {configActions } from 'standard/configuration/actions'
const required = [
    // 'REACT_APP_AUTHO_DOMAIN',
    // 'REACT_APP_AUTHO_CLIENTID',
    // 'REACT_APP_AUTHO_REDIRECT',
    'REACT_APP_NETWORKS_MODEL_FILTER',
    'REACT_APP_TEMPLATES_MODEL_FILTER',
    'REACT_APP_GEOMETRY_CLASS_NAME',
    'REACT_APP_HAS_GEOMETRY_PROPERTY_NAME',
    'REACT_APP_ENTITY_CLASS_IRI',
]

const useEnvironment = () => {
    const [configs, setConfigs] = useState<{ [key: string]: string | undefined }>({});
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(configActions.configCheckRequest())
        getEnvironmentVariables()
    }, []);

    const getEnvironmentVariables = () => {
        const newValues: { [key: string]: string | undefined } = {}
        for (let r in required) {
            const n: string = required[r]
            newValues[n] = process.env[required[r]]
        }
   
        setConfigs({
            ...configs, ...newValues
        })  
    }

    useEffect(() => {
        const missing = Object.entries(configs).filter(([key, value]) => {
            return !value
        })
        if (missing.length > 0) {
            dispatch(configActions.configCheckFailure())
            const missingObject = Object.fromEntries(missing)
            throw new Error(`Missing configurations ${Object.keys(missingObject)}`)
        }
        dispatch(configActions.configCheckSuccess())
    }, [configs]);
    
    return { configs: configs };
}

export default useEnvironment