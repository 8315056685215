

const StatusBar = (props: {
	children : any
}) => {

	

	return (
		<div className="columns box p-0 m-1">
			<div className="column">
				{props.children}
			</div>
		</div>
	)
}

export default StatusBar;