import { Feature, Geometry } from "standard/geojson/@types";
import { POLYGON, LINE_STRING, POINT } from "../../constants/variableName"

export const readKMLfile = (kml: Document, schemaId?: string) => {
    let placeMark = Array.from(kml.getElementsByTagName("Placemark"));
    let importedFeatures:Feature[] = [];
    
    placeMark.forEach((place)=>{
        let metadata = Array.from(place.getElementsByTagName("ExtendedData"));
        let props : {[k:string]: string} | undefined;

        if(schemaId){
            if(metadata && metadata.length === 1){
                let schemaUrl = metadata[0].getElementsByTagName("SchemaData")[0].getAttribute("schemaUrl");
                if(schemaUrl !== `#${schemaId}`){
                    return;
                }
            }else{
                return;
            }
        }

        if(metadata && metadata.length > 0){
            for(const ele of Array.from(metadata[0].getElementsByTagName("SimpleData"))){
                if(props === undefined){
                    props = {};
                }
                let propKey = ele.getAttribute("name");
                if(propKey){
                    props[propKey] = ele.innerHTML;
                }
            }
        }
        let geo = place.getElementsByTagName("Point");
        if(geo && geo.length > 0){
            let pointCoordinate = getCoordinateFromElement(geo[0], POINT);
            let pointGeo:Geometry = {type: POINT, coordinates:pointCoordinate};
            importedFeatures.push({type: "Feature", geometry:pointGeo, properties: props});
        }else {
            geo = place.getElementsByTagName("LineString")
            if(geo && geo.length > 0){
                let lineCoordinate = getCoordinateFromElement(geo[0], LINE_STRING);
                let lineGeo: Geometry = { type: LINE_STRING, coordinates: lineCoordinate };
                importedFeatures.push({ type: "Feature", geometry: lineGeo, properties: props });
            }else{
                geo = place.getElementsByTagName("Polygon")
                if(geo && geo.length > 0){
                    let polyCoordinate = getCoordinateFromElement(geo[0], POLYGON);
                    let polyGeo: Geometry = { type: POLYGON, coordinates: [polyCoordinate] };
                    importedFeatures.push({ type: "Feature", geometry: polyGeo, properties: props });
                }
            }
        } 
    })
    return importedFeatures;
}

const getCoordinateFromElement = (ele: any, type: string) => {
    let sCoordinate = ele.getElementsByTagName("coordinates")[0].innerHTML.trim();
    let pointCoor = sCoordinate.split(",");
    if(type === POINT){
        return [parseFloat(pointCoor[1]), parseFloat(pointCoor[0])];
    }
    let aCoordinates
    if(/\n/.exec(sCoordinate)){
        aCoordinates = sCoordinate.split("\n");
    }else if(/\r/.exec(sCoordinate)){
        aCoordinates = sCoordinate.split("\r");
    }else{
        aCoordinates = sCoordinate.split(" ");
    }
    let coordinates : any[] = [];
    for (let coord of aCoordinates) {
        let coords = coord.split(",");
        coordinates.push([parseFloat(coords[1]), parseFloat(coords[0])]);
    }
    return coordinates;
}