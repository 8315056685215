import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';

export enum MENUS {
    networktype = 'networktype',
    addtemplate = 'addtemplate',
    maplayers = 'maplayers',
    mapattributes = 'mapattributes',
    dropattributes = 'dropattributes',
    addrecords = 'addrecords',
    deleterecords = 'deleterecords',
    convertvalues = 'convertvalues',
    rawdatalist = 'rawdatalist',
    result = 'result',
    resultlist = 'resultlist',
    rawdatamap = 'rawdatamap',
    resultmap = 'resultmap',
    digitalTwin = 'digitaltwin',
    validated = 'validated',
    unmapped = 'unmapped',
    resultsummary = 'resultsummary',
    export = 'export',
    exportclone = 'exportclone',
    exportoverwrite = 'exportoverwrite',
    exporttofile = 'exporttofile',
    exporttemplate = 'exporttemplate',
    createdigitaltwin = 'createdigitaltwin',
    updatenetwork = 'updatenetwork',
};

export const menus: Record<string, IMenuItem> = {
    'general': { name: 'general', label: 'General', ordinality: 1 },
    [MENUS.networktype]: { name: MENUS.networktype, label: 'Network Type', ordinality: 1, parent: 'general' },
    [MENUS.addtemplate]: { name: MENUS.addtemplate, label: 'Add Template', ordinality: 2, parent: 'general' },
    'edit': { name: 'edit', label: 'Edit', ordinality: 2 },
    [MENUS.deleterecords]: { name: MENUS.deleterecords, label: 'Delete Records', ordinality: 1, parent: 'edit' },
    [MENUS.maplayers]: { name: MENUS.maplayers, label: 'Map Layers', ordinality: 2, parent: 'edit' },
    [MENUS.convertvalues]: { name: MENUS.convertvalues, label: 'Convert Values', ordinality: 3, parent: 'edit' },
    [MENUS.mapattributes]: { name: MENUS.mapattributes, label: 'Map Attributes', ordinality: 4, parent: 'edit' },
    // [MENUS.addrecords]: { name: MENUS.addrecords, label: 'Add Records', ordinality: 4, parent: 'edit' },    
    [MENUS.dropattributes]: { name: MENUS.dropattributes, label: 'Drop Attributes', ordinality: 5, parent: 'edit' },
    'rawdata': { name: 'rawdata', label: 'Raw Data', ordinality: 3 },
    [MENUS.rawdatalist]: { name: MENUS.rawdatalist, label: 'List', ordinality: 1, parent: 'rawdata' },
    [MENUS.rawdatamap]: { name: MENUS.rawdatamap, label: 'Map', ordinality: 2, parent: 'rawdata' },
    [MENUS.result]: { name: 'result', label: 'Result', ordinality: 4 },
    [MENUS.resultlist]: { name: MENUS.resultlist, label: 'List', ordinality: 1, parent: 'result' },
    [MENUS.resultmap]: { name: MENUS.resultmap, label: 'Map', ordinality: 2, parent: 'result' },
    [MENUS.digitalTwin]: { name: MENUS.digitalTwin, label: 'Digital Twin', ordinality: 5 },
    [MENUS.validated]: { name: MENUS.validated, label: 'Validated', ordinality: 1, parent: MENUS.digitalTwin },
    [MENUS.unmapped]: { name: MENUS.unmapped, label: 'Unmapped', ordinality: 2, parent: MENUS.digitalTwin },
    // [MENUS.resultsummary]: { name: MENUS.resultsummary, label: 'Summary', ordinality: 3, parent: 'result' },
    [MENUS.export]: { name: 'export', label: 'Export', ordinality: 6 },
    [MENUS.exportclone]: { name: MENUS.exportclone, label: 'Create Clone', ordinality: 1, parent: 'export' },
    [MENUS.exportoverwrite]: { name: MENUS.exportoverwrite, label: 'Overwrite Current', ordinality: 2, parent: 'export' },
    [MENUS.exporttofile]: { name: MENUS.exporttofile, label: 'Export to File', ordinality: 3, parent: 'export' },
    [MENUS.exporttemplate]: { name: MENUS.exporttemplate, label: 'Export Template', ordinality: 4, parent: 'export' },
    'import': { name: 'import', label: 'Import', ordinality: 7 },
    [MENUS.updatenetwork]: { name: MENUS.updatenetwork, label: 'Update Network', ordinality: 1, parent: 'import' },
}
