import {toSvg} from 'jdenticon';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition, faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const Avatar = (props: {hashCode : string, size : number, iconDef? : IconDefinition, style? : string}) => {
    const [svg, setSVG] = useState<string>();

    useEffect(() => {
        setSVG(toSvg(props.hashCode, props.size));
	}, [props.hashCode, props.size]);

    useEffect(() => {

	}, [svg]);
    
    return <div className={`${props.style ? props.style : ''}`}>
    {svg ? <div dangerouslySetInnerHTML={{ __html: svg }} /> : <FontAwesomeIcon size={`${props.size}x` as SizeProp} icon={props.iconDef || faCircleDot}/>}
    </div>	
}

export default Avatar;