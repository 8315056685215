import { Component } from "react"

export class ErrorBoundary extends Component {
        state = { hasError: false };
    
    constructor(props: any) {
        super(props);
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI    
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service    
        //logErrorToMyService(error, info);
        console.log(info)
        console.log(error)
    }
    
    render() {
        if (this.state.hasError) {
            return <div className="main">
                <section className="hero is-fullheight">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-centered">
                                <div className="column is-3-tablet is-4-desktop is-4-widescreen">
                                    <div className='card'>
                                        <p className="card-header">
                                            <h4 className="title is-4">hopla! Something went wrong!</h4>
                                        </p>       
                                        <div className='content mt-5'>
                                            This error should has been reported to the administrator.
                                            Please contact the administrator if the problem persists.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>;
        }
        return this.props.children;
    }
}
