import { IDomainModel, IDomainModelClass, IDomainModelProperty } from '../../../ontology/interfaces';
import { IOption } from 'standard/forms/interfaces/IOption';
import { ClassProperties } from '../types/classProperties';

export interface IClassTreeNodeData {
    root?: string;
    type? : string
    isEditable? : boolean
    style? : string
    error? : string
}

export const findClassByUUID = (models: IDomainModel[],  uuid : string): IDomainModelClass | undefined => {
    var cls: IDomainModelClass | undefined
    for (var idx in models){
        cls =  models[idx].classes && models[idx].classes!.find(c => c.uuid === uuid)
        if (cls !== undefined){
            return cls
        }
    }
    
    return cls
}

export const findClassByName = (models: IDomainModel[],  name : string): IDomainModelClass | undefined => {
    var cls: IDomainModelClass | undefined
    for (var idx in models){
        cls =  models[idx].classes && models[idx].classes!.find(c => c.labels.default === name)
        if (cls !== undefined){
            return cls
        }
    }
    
    return cls
}

const getClassHierarchy = (currentClass : IDomainModelClass, currentHierarchy : Record<string, IDomainModelClass>, classes : IDomainModelClass[]) : Record<string, IDomainModelClass> => {
    if (Object.hasOwn(currentHierarchy, currentClass.uuid!)) return currentHierarchy //Avoids Circular Looping

    currentHierarchy[currentClass.uuid!] = currentClass
    if (currentClass.isSubClassOf){
        let nextClass = classes.find((c : IDomainModelClass) => c.uuid === currentClass.isSubClassOf)
        if (nextClass){
            return getClassHierarchy(nextClass, currentHierarchy, classes)                        
        }
    }
    return currentHierarchy
} 

export const getClassProperties = (cls : IDomainModelClass, models: IDomainModel[]) : ClassProperties => {
    
    let oP : IDomainModelProperty[] = []
    let dP : IDomainModelProperty[] = []

    let classes : IDomainModelClass[] = []
    models.forEach(m => {
        classes = [...classes, ...m.classes || []]
    })
    
    let classHierarchy = getClassHierarchy(cls , {}, classes)
    for (let clsUUID in classHierarchy) {
        for (let model of models){
            if (model.dataProps){
                dP = [...dP, ...(model.dataProps.filter(prop => prop.domain.includes(clsUUID)))]
            }
            if (model.objProps){
                oP = [...oP, ...(model.objProps.filter(prop => prop.domain.includes(clsUUID)))]
            }
        }
    }

    return {
        modelClass : cls,
        dataProps: dP,
        objProps: oP
    }   
}

export const classOptions = (models: IDomainModel[], showDomainModel : boolean): IOption[] => {
    if (!showDomainModel) {
        let classes = models.flatMap(obj => obj.classes || [])  
        return classes.map((item) => { return { value: item.uuid!, label: item.labels.default } })
    }else{        
        var options: IOption[] = [];
        models.sort((a, b) => a.labels.default.localeCompare(b.labels.default))
        for (let model of models){
            if (!model.classes) continue;
            model.classes.sort((a, b) => {
                if (!a.labels.default){
                    return -1;
                }
                if (!b.labels.default){
                    return -1;
                }
                return a.labels.default.localeCompare(b.labels.default)
            })
            options = [...options, ...model.classes!.map((item) => { return { value: item.uuid!, label: `${item.labels.default} (${model.labels.default})`}} )]              
          
        }
        return options
    }

}