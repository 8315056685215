import React, { useEffect } from "react";

const NoPage : React.FC = () => {
  useEffect(() => {
    document.title = 'No Page';
  }, []);

  return <div className="main">
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="box p-0">
                <div className="columns m-0 p-0">
                  <div className="column">                    
                    <h2 className="subtitle">No Page</h2>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  </div >
};

export default NoPage;
