export const getKeyValues = (data: ({ [k: string]: any; } | undefined)[], property : string): string[] => {
    let values: Set<string> = new Set()
    if (data) {
        data.forEach(item => {
            if (item && item[property])  {              
                values.add(item![property])
            }
        })
    }
    return Array.from(values).sort();
}

export function getInterfaceName<T>(iface: { new(): T }): string {
    return (iface as any).name;
}
