import { Input } from 'standard/forms/inputs'
import { InputType } from 'standard/forms/enums'

export const Inputs = (): { [key: string]: Input } => {
    let inputs: { [key: string]: Input } = {};
    // inputs["input"] = { name: 'input', label: 'Property Name', type: InputType.STRING, value: '', required: true, ordinality: 1, data : {liststyle : "is-3" }}
    inputs["input"] = {
        name: 'input',
        label: 'Column Name',
        type: InputType.OPTION,
        value: '',
        required: true,
        ordinality: 1,
        data: { liststyle: "is-3" }
    }

    // inputs["value"] = {
    //     name: 'value',
    //     label: 'Property Value',
    //     type: InputType.AUTOCOMPLETE,
    //     value: '',
    //     required: true,
    //     ordinality: 2,
    //     parent: 'input',
    //     data: { liststyle: "is-3" }
    // }

    inputs["values"] = {
        name: 'values',
        label: 'Column Values',
        type: InputType.MULTISELECT,
        value: [],
        required: false,
        ordinality: 3,
        parent: 'input',
        data: { liststyle: "is-3" }
    }

    inputs["mapping"] = {
        name: 'mapping',
        label: 'Layer',
        type: InputType.OPTION,
        value: '',
        required: true,
        ordinality: 4,
        data: { liststyle: "is-4" }
    }
    return inputs
}
