import { useEffect, useState } from "react";
import "./dropdown.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { IOption, IOptions } from "standard/forms/interfaces/IOption";

const Dropdown = (props: {
  title: string
  options: IOptions
}
) => {
  const [selected, setSeleted] = useState<IOption | undefined>(props.options.selected);

  useEffect(() => {
    // if (props.options.selected) {
    //   setSeleted(props.options.selected!);
    // } 
  }, [])

  useEffect(() => {
    if (selected){
      props.options.onSelect(selected)
    }
  }, [selected])

  useEffect(() => {
    if (props.options.selected) {
      setSeleted(props.options.selected);
    } 
  }, [props.options])

  useEffect(() => {
    
  }, [])

  return (<div className="dropdown is-hoverable">
    <div className="dropdown-trigger">
      <button className="button is-small" aria-haspopup="true" aria-controls="dropdown-menu">
        <span>{selected?.value || props.title}</span>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faAngleDown} />
          <i className="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div className="dropdown-menu" id="dropdown-menu" role="menu">
      <div className="dropdown-content">
        {Object.values(props.options.options).map((value: IOption) => {
          return <div key={value.value} className="dropdown-item is-small" onClick={() => props.options.onSelect(value)}>
            {value.label}
          </div>
        })}
      </div>
    </div>
  </div>
  )
}

export default Dropdown;