import { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { IOptions } from 'standard/forms/interfaces/IOption';
import './dataList.css'
import StandardList from './wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import ModalDialog from 'standard/dialog/modaldialog';
import PropertyFilter from './propertyFilter';
import { v4 as uuidv4 } from 'uuid';
import { IHoverMenuItem } from './@types/IHoverMenuItem';
import { useAppSelector } from 'hooks/redux';
import { FilterType, IStringFilter, StringFilter } from 'standard/filter/@types/filter';
import { filterContainsString, filterExactString, filterNumericRange, filterOutString } from './utils';
import Row from './row';
import { ISummary } from 'standard/summary/@types/ISummary';
import { filter } from 'standard/geojsonMap/map/search';

enum hoverMenuOptions {
    filtercontains = 'filterconatins',
    filterexact = 'filterexact',
    filterout = 'filterOut',
    delete = 'delete',
};

const menuItems: IHoverMenuItem[] = [
    // { id: hoverMenuOptions.filterexact, label: "Filter Exact", section: "filter" },
    // { id: hoverMenuOptions.filtercontains, label: "Filter Contains", section: "filter" },
    // { id: hoverMenuOptions.filterout, label: "Filter Out", section: "filter" },
    // { id: hoverMenuOptions.delete, label: "Delete", section: "mapping" },
];

const SummaryList = (props: {
    title: string,
    data: ({ [k: string]: ISummary; })[]
    options?: IOptions
    columns? : string[]
    onValueAction?: (action: string, key: string, value: string) => void
    onValueChange?: (uuid: string, key: string, value: any) => void
}) => {
    const [columns, setColumns] = useState<string[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [data, setData] = useState<({ [k: string]: ISummary; })[]>();
    const [doingSearch, setDoingSearch] = useState<boolean>(false);
    const [search, setSearch] = useState<string>();
    const [filters, setFilters] = useState<Record<string, any>>();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showFilterProperty, setShowFilterProperty] = useState<string>();
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
    const [menuData, setMenuData] = useState({ key: '', value: '' });
    const menuRef = useRef<HTMLInputElement>(null);
    const ui = useAppSelector(state => state.ui)

    useEffect(() => {
        setData(props.data)
        if (props.columns){
            setColumns(props.columns)
        }else{
            let keys: Set<string> = new Set()
            props.data.forEach((item: ({ [k: string]: any } | undefined)) => {
                Object.keys(item!).forEach((key: string) => {
                    keys.add(key)
                });
            });            
            setColumns(Array.from(keys))
        }
       
    }, [props.data, props.columns])
       

    useEffect(() => {
        setDoingSearch(false)
    }, [data])

    useEffect(() => {
        doSearch(search, filters)
    }, [search, filters])


    const onSearchChange = () => {
        setDoingSearch(true)
    }
    const closeMenu = useCallback(() => {
        setMenuData({ key: "", value: "" })
        setShowMenu(false)
    }, [])

    useEffect(() => {
        closeMenu()
    }, [showFilterProperty, showFilter, closeMenu])

    useEffect(() => {
        closeMenu()
    }, [doingSearch, closeMenu])

    useEffect(() => {

    }, [columns])

    useEffect(() => {

    }, [menuPosition])

    useEffect(() => {

    }, [showMenu])

    // useEffect(() => {
    //     const handleClick = (event) => {
    //         if (showMenu){
    //             setShowMenu(false)
    //         }
    //     };

    //     document.addEventListener('click', handleClick);

    //     return () => {
    //       document.removeEventListener('click', handleClick);
    //     };
    //   }, []);






    const onPageSelect = (page: number) => {
        setCurrentPage(page)
    }

    const doSearch = (newSearch?: string, filters?: Record<string, any>) => {
        if (!data) {
            setDoingSearch(false)
            return
        }
        let splitSearch = newSearch?.toLowerCase().split(' ')
        if (splitSearch) {
            splitSearch = splitSearch!.length === 0 ? undefined : splitSearch
        }

        if (!splitSearch && !filters) {
            setData(props.data)
            return
        }

        const search = (searches: string[], row: { [key: string]: ISummary }) => {
            for (const value of Object.values(row!)) {
                if (value.value === null || value.value === undefined) {
                    continue
                }
                let valueSearch: boolean = true
                for (const searchString of Object.values(searches)) {
                    if (value.value.toString().toLowerCase().indexOf(searchString) === -1) {
                        valueSearch = false
                        break
                    }
                }

                if (valueSearch) {
                    return true
                }
            }
            return false
        }

       

        const searchResult = props.data!.filter((d) => {
            let found: boolean = true
            if (splitSearch && d) {
                if (!search(splitSearch, d)) {
                    found = false
                }
            }

            if (found) {
                if (filters && d) {
                    if (!filter(filters, d)) {
                        found = false
                    }
                }
            }
            return found

        })
        setData(searchResult)
    }

    const createHeaders = (headers: string[]) => {
        let currFilters = filters ? Object.values(filters) : []
        return headers.map((value) => {
            let hasFilter = currFilters.findIndex((f: any) => { return f.property === value })
            hasFilter = hasFilter !== undefined ? hasFilter : -1
            return <div key={value} className="column list-header-cell cell p-0 pl-1 pr-1 m-0">
                {value}
                <FontAwesomeIcon className={`ml-1 button headericon is-inverted ${hasFilter !== -1 ? 'is-danger' : ''}`} icon={faFilter} onClick={() => onShowFilter(value)} />
            </div>
        })
    }

    const onShowFilter = (column: string) => {
        setShowFilterProperty(column)
        setShowFilter(true)
    }

    const onDeleteFilter = (id: string) => {
        let newFilters = { ...filters }
        delete newFilters[id]
        setFilters(newFilters)
    }

    const onDeletePropertyFilters = (property: string) => {
        let newFilters = {}
        Object.values(filters!).forEach((value, key) => {
            if (value.property !== property) {
                newFilters[key] = value;
            }
        });
        setFilters(newFilters)
    }

    const onCloseFilter = () => {
        setShowFilter(false)
    }

    const onFilter = (filter: any) => {
        setDoingSearch(true)
        const currentFilters = filters ? filters : {}
        setFilters({ ...currentFilters, ...{ [filter.id]: filter } })
        // setShowFilter(false)
    }

    const onValueClick = (e: any, key: string, value) => {

        const left: number = e.pageX;
        const top: number = e.pageY;
        setMenuPosition({ left, top });
        setMenuData({ key, value })
        setShowMenu(true)
    }

    const onValueDoubleClick = (e: any, key: string, value) => {
        closeContextMenu()
    }

    const closeContextMenu = () => {
        setMenuData({ key: '', value: '' })
        setShowMenu(false)
    }

    const onMenuClick = (e: any, action: string) => {
        if (action === hoverMenuOptions.filtercontains) {
            onFilter(new StringFilter({ id: uuidv4(), type: FilterType.STRINGCONTAINS, property: menuData.key, value: menuData.value } as IStringFilter))
        }else if (action === hoverMenuOptions.filterexact) {
            onFilter(new StringFilter({ id: uuidv4(), type: FilterType.STRINGEXACT, property: menuData.key, value: menuData.value } as IStringFilter))
        } else if (action === hoverMenuOptions.filterout) {
            onFilter(new StringFilter({ id: uuidv4(), type: FilterType.STRINGEXCLUDE, property: menuData.key, value: menuData.value } as IStringFilter))
        } else if (action === hoverMenuOptions.delete) {
            if (props.onValueAction) {
                props.onValueAction(action, menuData.key, menuData.value)
            }
        }
        closeContextMenu()
    }

    const displayContent = (headers: string[], from: number, to: number) => {
        if (!data) {
            return <></>
        }
        let prevRow : { [k: string]: ISummary; } | undefined = undefined
        return data.slice(from, to).map((row, idx) => {
                    let rowElement = <Row 
                                key={idx}
                                idx={idx} 
                                headers={headers} 
                                rowData={row} 
                                prevRowData={prevRow} 
                                onValueClick={onValueClick} 
                                onValueDoubleClick={onValueDoubleClick}
                    
                    />
                    prevRow={...row} 
                    return rowElement
                })
            
    }

    const getHoverMenu = (items: IHoverMenuItem[]) => {
        let prevSection: string | undefined = undefined
        return items.map((item, idx) => {
            const addDivider: boolean = prevSection ? prevSection !== item.section : false
            prevSection = item.section

            return <Fragment key={item.id}>
                {addDivider ? <div className='columns p-1'><hr className="hoverMenu-divider" /></div> : <></>}
                <div key={idx} className={`columns ${(idx + 1) % 2 === 0 ? 'alternate' : ''}`}>
                    <div key={item.id} className="column m-1 p-1 hoverMenu-item" onClick={(e) => onMenuClick(e, item.id)}>
                        {item.label}
                    </div>
                </div>
            </Fragment>
        })
    }

    const content = () => {
        return <PropertyFilter
        data={props.data}
        property={showFilterProperty!}
        onCancel={onCloseFilter}
        onFilter={onFilter}
        onDeleteFilter={onDeleteFilter}
        onDeletePropertyFilters={onDeletePropertyFilters}
        filters={filters}
    />
    }


    return (<>
        <StandardList
            title={props.title}
            onPageSelect={onPageSelect}
            currentPage={currentPage}
            itemCount={data ? data.length : 0}
            pageSize={pageSize}
            onSearch={setSearch}
            onSearchChange={onSearchChange}
            options={props.options}
        >
            {doingSearch === true ?
                'Searching...'
                : <div className={`pl-6 is-centered ${ui.fullscreenenabled ? 'list-content-max' : 'list-content'}`}>
                    <div className="columns list-header mt-1 mb-0 pb-0 ml-1 has-text-weight-bold is-vcentered">
                        {columns && createHeaders(columns)}
                    </div>
                    {columns && displayContent(columns, (currentPage - 1) * pageSize, ((currentPage - 1) * pageSize) + pageSize)}
                </div>
            }
        </StandardList>
        {(showFilterProperty) &&
            <ModalDialog title={`Filter ${showFilterProperty}`} display={showFilter}
                content={content()}
            />
        }
        {(showMenu && menuItems.length > 0) && (
            <div className='box hoverMenu m-1 p-5' style={{ left: menuPosition.left, top: menuPosition.top }} ref={menuRef} onBlur={() => closeMenu()}>
                {
                    getHoverMenu(menuItems)
                }
            </div>
        )}
    </>

    )
}

export default SummaryList;