import { useRef, useEffect, useState, useCallback } from 'react';
import ReactDOM from "react-dom/client";
import L, { LatLng } from 'leaflet';
import { MapPosition } from 'standard/ontology/map/geo';
import { Feature,Geometry } from 'standard/geojson/@types';
import { LINE_STRING, POINT } from './constants';
import "./mapViewer.css";
import { Box } from './bbox';
import MapInteraction from './mapInteraction';
import { MouseDown, MouseMove } from './interfaces/mouseEvents';
import { SelectedLayer as LayerOnFocus } from './interfaces/selectedLayer';
import { getSingleTile } from './utils/tiles';
import { MapViewLayers, MapViewLayer } from './interfaces/mapViewerLayer'
import { DraggablePoint } from './@types/draggablePoint';
import { IRelationships } from 'standard/ontology/interfaces/IRelationships';
import { ILayer } from './interfaces/ILayer';
import { IAlert } from 'standard/alert';
import MapViewerPopup from './popup/popup';
import { LAYER_NAME_KEY, IS_ADD_ON_KEY, LayerInfo, LayerMetaData } from './@types/layerInfo';
import { ADDON_KEY, CONNECTIONS_KEY } from 'components/utils/constants';
import { FEATURE_MISSING_ADDON_PROPERTY, FEATURE_MISSING_PROPERTIES } from 'components/utils/errors';
import { IAddOn } from 'standard/ontology/map/features/addOn';
import { IAddOns } from 'standard/ontology/map/features/addOns';
import MousePosition from './mousePosition';
import StatusBar from './statusBar';
import { ConnectionsFilter } from './@types/graphFilters';


const openStreetMapTileLayer = L.tileLayer(
	'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
	{
		attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
		maxZoom: 50,
		maxNativeZoom: 18
	});

const arcgisOnlineMapTileLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
	{
		attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
		maxZoom: 50,
		maxNativeZoom: 18
	});


const anonymousMapLayer = L.tileLayer('');

let baseMaps = {
	"Anonymous Mode": anonymousMapLayer,
	"Open Street Map": openStreetMapTileLayer,
	"Arcgis Online Map": arcgisOnlineMapTileLayer
}

enum MapStatus {
	Prerender = 1,
	RenderComplete,
	NotLoaded,
	UnLoaded,
}



let lineStyle = {
	"color": "#192434",
	"weight": 5
};

let lineMouseOverStyle = {
	"color": "#23334a",
	"weight": 7
};

let lineMouseDownStyle = {
	"color": "#3b557b",
	"weight": 8
};

let lineSelectedStyle = {
	"color": "#5377ac",
	"weight": 5
};


const MapViewer = (props: {
	features: { [key: string]: Feature[] },
	addOnFeatures: { [key: string]: IAddOns },
	numberOfTiles: number,
	center?: LatLng
	selectedLayers?: ILayer[]
	connectionsFilter: ConnectionsFilter
	onMouseOverFeature?: (uuid: string) => void
	onMouseOutFeature?: (uuid: string) => void
	onSelectedFeatures?: (uuid: string[]) => void
	onFoundMapRelationships?: (relationships: IRelationships) => void
	onDeleteFeature: (uuid: string, layerInfo?: LayerInfo) => void
	onError?: (error: IAlert) => void
}) => {
	const mapRef = useRef<L.Map | null>(null);
	const controlLayerRef = useRef<L.Control.Layers | null>(null);
	const selectionLayerRef = useRef<L.FeatureGroup>(new L.FeatureGroup());
	const pointsLayerRef = useRef<{ [key: string]: L.FeatureGroup }>({});
	const layersRef = useRef<{ [key: string]: L.FeatureGroup }>({});
	const mouseDown = useRef<MouseDown>();
	const mouseMove = useRef<MouseMove>();
	const [layers, setLayers] = useState<MapViewLayers | undefined>(undefined);
	const [mapStatus, setMapStatus] = useState<MapStatus>(MapStatus.NotLoaded);
	const [isDrawn, setIsDrawn] = useState<boolean>(false);
	const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);
	const [mousePos, setMousePos] = useState<MapPosition>();

	const featureLayersRef = useRef<{ [key: string]: LayerMetaData }>({});

	const layersOnFocus = useRef<{ [focusType: string]: { [featureId: string]: LayerOnFocus } }>({ selected: {}, hovered: {} });

	const draggingPoint = useRef<L.CircleMarker>();

	useEffect(() => {
		setMapStatus(MapStatus.Prerender)
		return () => {
			if (mapRef!.current !== null) {
				mapRef.current.off();
				mapRef.current.remove();
			}
		}
	}, []);

	useEffect(() => {

	}, [featuresLoaded]);

	useEffect(() => {
		applyGraphFilters(props.connectionsFilter)
	}, [props.connectionsFilter]);


	useEffect(() => {

	}, [mousePos]);

	useEffect(() => {
		if (props.selectedLayers) {
			Object.keys(layersRef.current!).forEach((l) => {
				if (props.selectedLayers!.findIndex((sl) => sl.name === l) !== -1) {
					// layersRef.current![l].setStyle({
					// 	opacity: 1
					// })
					showLayerFeatures(l)
				} else {
					hideLayerFeatures(l, 0)
					// layersRef.current![l].setStyle({
					// 	opacity: 0
					// })
					
					// //Deselect features if the layer is hidden

					// if (props.features[l] !== undefined) {
					// 	props.features[l].forEach((f) => {
					
					// 		delete layersOnFocus.current!.selected[f.uuid!]
					
					// 	})
					// }
				}
			})
		} else {
			Object.keys(layersRef.current!).forEach((l) => {
				layersRef.current![l].setStyle({
					opacity: 1
				})
			})
		}
	}, [props.selectedLayers]);

	useEffect(() => {
		if (!props.center) {
			return
		}
		if (mapRef.current !== null) {
			mapRef.current!.flyTo(props.center, 15)
		}
	}, [props.center]);

	useEffect(() => {
		featureUpdate()
		if (!props.features) {
			setLayers(undefined);
			return
		}

		let ls: MapViewLayers = {};
		Object.keys(props.features).forEach((layerKey: string) => {
			ls[layerKey] = getSingleTile(props.features[layerKey])
		})
		setIsDrawn(false)
		setLayers(ls)

	}, [props.features]);

	useEffect(() => {
		if (!props.addOnFeatures) {
			return
		}

		if (!featuresLoaded) {
			return
		}
		
		drawAddOns(props.addOnFeatures)

	}, [props.addOnFeatures, isDrawn, mapStatus, featuresLoaded]);

	const reportSelection = () => {
		if (props.onSelectedFeatures) {
			if (layersOnFocus.current.selected) {
				props.onSelectedFeatures(Object.values(layersOnFocus.current.selected!).map((l) => l.feature.geometry.uuid!))
			}
		}
	}

	const removeFeature = (uuid: string, layer: any) => {
		layer.remove()
		mapRef.current!.removeLayer(layer)
		delete featureLayersRef.current![uuid]
	}

	const applyGraphFilters = (filter : ConnectionsFilter) => {
		if (filter === ConnectionsFilter.ALL){
			Object.values(featureLayersRef.current).forEach((f) => {
				if (f.info[IS_ADD_ON_KEY] === false){
					showFeature(f)
				} 
			})
			return
		}

		Object.values(featureLayersRef.current).forEach((f) => {
			if (f.info[IS_ADD_ON_KEY] === false){
				if (getConnectionStatus(f) === filter)	{
					showFeature(f)
				}else{
					hideFeature(f, 0.1)
				}
			}
		})		
	}

	const getConnectionStatus = (layerMetaData : LayerMetaData) : ConnectionsFilter => {
		if (layerMetaData.feature.properties![CONNECTIONS_KEY] === undefined)	{
			return ConnectionsFilter.NOTCONNECTED
		}
		
		if ((layerMetaData.feature.properties![CONNECTIONS_KEY]).length === 0)	{
			return ConnectionsFilter.NOTCONNECTED
		}

		if ((layerMetaData.feature.properties![CONNECTIONS_KEY]).length === 1)	{
			return ConnectionsFilter.PARTIALLYCONNECTED
		}

		return ConnectionsFilter.FULLYCONNECTED
	}

	const showLayerFeatures = (layerKey : string) => {
		Object.values(featureLayersRef.current).forEach((f) => {
			if (f.info[LAYER_NAME_KEY] === layerKey){
				showFeature(f)
			} 
		})
	}

	const hideLayerFeatures = (layerKey : string, opacity : number) => {
		Object.values(featureLayersRef.current).forEach((f) => {
			if (f.info[LAYER_NAME_KEY] === layerKey){
				hideFeature(f, opacity)
			} 
		})
	}

	const showFeature = (layerMetaData : LayerMetaData) => {		
		let style = layerMetaData.layer.style || {}			
		layerMetaData.layer.setStyle({...style, opacity: 1})	
		layerMetaData.showing = false
		if (layerMetaData.attachments){		
			for (var attachment of layerMetaData.attachments){
				showFeature(featureLayersRef.current[attachment])
			}
		}
	}

	const hideFeature = (layerMetaData : LayerMetaData,  opacity : number) => {
			
		let style = layerMetaData.layer.style || {}	
		// if (isAttachment) {
		
		// }			
		layerMetaData.layer.setStyle({...style, opacity: 0.05, fillColor : '#FFFFFF00'})
		layerMetaData.showing = false
		if (layerMetaData.attachments){
			for (var attachment of layerMetaData.attachments){								
				hideFeature(featureLayersRef.current[attachment], opacity)
			}
		}		
	}

	const featureUpdate = () => {
		let flatFeatures: Feature[] = []

		Object.values(props.features).forEach((l: Feature[]) => {
			flatFeatures = [...flatFeatures, ...l]
		})

		if (flatFeatures.length === 0) {
			//remove all
			Object.keys(featureLayersRef.current!).forEach((k: string) => {
				removeFeature(k, featureLayersRef.current![k].layer)
			})
		} else {
			//remove missing keys
			Object.keys(featureLayersRef.current!).forEach((k: string) => {
				let idx: number = flatFeatures.findIndex((f: Feature) => { return f.uuid === k })
				if (idx === -1) {
					removeFeature(k, featureLayersRef.current![k].layer)
				}
			})
		}
	}

	const loadMap = useCallback(() => {
		let overlayLayers = {};
		mapRef.current = L.map("pp-map", {
			maxZoom: 30,
			attributionControl: false,
			zoomControl: false,
			layers: [anonymousMapLayer],
			renderer: L.canvas()
		});
		mapRef.current.doubleClickZoom.disable();
		// mapRef.current.on('mousedown', onMouseDown)				
		mapRef.current.on('mousemove', onMouseMove)
		// mapRef.current.on('mouseup', onMouseUp)	 

		L.control.zoom({ position: 'topright' }).addTo(mapRef.current);

		renderMap({
			map: mapRef.current,
			controlLayerRef: controlLayerRef,
			overlayLayers: overlayLayers
		});
		setMapStatus(MapStatus.RenderComplete)
	}, [])

	useEffect(() => {
		if (mapStatus === MapStatus.Prerender) {
			loadMap()
		}
	}, [mapStatus, loadMap]);


	const drawLayers = useCallback((layers: MapViewLayers) => {
		const deselectLayers = () => {
			if (!layersOnFocus.current!.selected) {
				return
			}
			removeLinePoints("selected")

			Object.values(layersOnFocus.current!.selected).forEach((l) => {
				let layer: any = l.layer
				layer.setStyle(lineStyle);
			}
			)
			delete layersOnFocus.current!.selected
		}

		const deselectLayer = (uuid: string) => {
			if (!layersOnFocus.current!.selected) {
				return
			}

			if (layersOnFocus.current!.selected.hasOwnProperty(uuid)) {
				let layer: any = layersOnFocus.current!.selected[uuid].layer;
				layer.setStyle(lineStyle);
				// for (var point of layersOnFocus.current!.selected[uuid].points){
				// 	removeLinePoint("selected", point)
				// }
			}
		}

		const onEachLineFeature = (feature: any, layer: any, group: L.FeatureGroup, info: LayerInfo) => {
			featureLayersRef.current![feature.uuid!] = { info: info, layer: layer, feature: feature, showing : true } as LayerMetaData

			const popupNode = document.createElement('div');
			ReactDOM.createRoot(popupNode).render(<MapViewerPopup feature={feature} onDelete={props.onDeleteFeature} />)
			// L.popup({
			//     closeButton: false,
			// }).setLatLng([e.latlng.lat, e.latlng.lng]).setContent(popupNode).openOn(mapRef.current!);
			layer.bindPopup(popupNode)

			layer.setStyle(lineStyle);
			layer.on('click', (e) => {
				return onFeatureClicked(e, feature, group)
			})
			layer.on('mouseover', (e) => {
				layer.setStyle(lineMouseOverStyle);
				return onFeatureMouseOver(e, feature, group)
			})
			layer.on('mousedown', (e) => {
				layer.setStyle(lineMouseDownStyle);
				return
			})
			layer.on('mouseout', (e) => {
				if (!layersOnFocus.current!.selected.hasOwnProperty(feature.uuid!)) {
					layer.setStyle(lineStyle);
				} else {
					layer.setStyle(lineSelectedStyle);
				}
				return onFeatureMouseOut(e, feature)
			})
		}

		const onEachPointFeature = (feature: any, layer: any, tileLayer: L.FeatureGroup) => {
			layer.on('click', (e) => {
				return onFeatureClicked(e, feature, tileLayer)
			})
			layer.on('mouseover', (e) => {
				//layer.setStyle(mouseOverStyle);

				return onFeatureMouseOver(e, feature, tileLayer)
				// return onFeatureClicked(e, feature.uuid)
			})
			layer.on('mouseout', (e) => {
				//layer.setStyle(style);
			})
		}

		const onFeatureClicked = (e: any, feature: Feature, group: L.FeatureGroup) => {
			// deselectLayers()

			if (!layersOnFocus.current!.selected) {
				layersOnFocus.current!.selected = {}
			}
			if (layersOnFocus.current!.selected.hasOwnProperty(feature.uuid!)) {
				deselectLayer(feature.uuid!)
				delete layersOnFocus.current!.selected[feature.uuid!]
				e.target.closePopup()
				selectAttachements(feature.uuid!, false)
			} else {
				// let points: DraggablePoint[] = pointsFactory(geometry.coordinates as [number[]], pointEvents, 0.00001, geometry.uuid!)
				// addPoints("selected", points)
				//e.target.openPopup()
				e.target.setStyle(lineSelectedStyle);
				selectAttachements(feature.uuid!, true)
				layersOnFocus.current!.selected[feature.uuid!] = { layer: e.target, feature: feature, group: group } as LayerOnFocus
			}
			reportSelection()
		}

		const selectAttachements = (uuid: string, selected: boolean) => {
			for (var attachmentUuid of featureLayersRef.current![uuid].attachments) {
				let attachement: LayerMetaData = featureLayersRef.current![attachmentUuid]
				const addOns : IAddOns = props.addOnFeatures[attachement.info[LAYER_NAME_KEY]]
				attachement.layer.setStyle(selected ? addOns.selectedStyle : addOns.style)
			}
		}

		const onFeatureMouseOver = (e: any, feature: Feature, group: L.FeatureGroup) => {
			if (props.onMouseOverFeature) {
				props.onMouseOverFeature(feature.uuid!)
			}
			// let points: DraggablePoint[] = pointsFactory(geometry.coordinates as [number[]], pointEvents, geometry.uuid)
			// addPoints("hovered", points)
			if (!layersOnFocus.current!.hovered) {
				layersOnFocus.current!.hovered = {}
			}
			layersOnFocus.current!.hovered[feature.uuid!] = { layer: e.target, feature: feature, group: group } as LayerOnFocus
		}

		const onFeatureMouseOut = (e: any, feature: Feature) => {
			if (props.onMouseOutFeature) {
				props.onMouseOutFeature(feature.uuid!)
			}
			removeHoverLayer(feature.uuid!)
		}

		let layersBox: any = new Box()
		let hasContent: boolean = false


		Object.keys(layers).forEach((layerKey) => {
			if (layersRef.current!.hasOwnProperty(layerKey)) {
				mapRef.current!.removeLayer(layersRef.current![layerKey])
				controlLayerRef.current!.removeLayer(layersRef.current![layerKey])
				delete layersRef.current![layerKey]
			}

			let leafletLayer: L.FeatureGroup = new L.FeatureGroup()

			layersRef.current![layerKey] = leafletLayer
			controlLayerRef.current!.addOverlay(leafletLayer, layerKey);
			layersRef.current![layerKey].setStyle({
				opacity: 1,
				fillColor: '#F0F0F0',
				fillOpacity: 1,
			})

			mapRef.current!.addLayer(layersRef.current![layerKey])
			leafletLayer.bringToBack()
			const layer: MapViewLayer = layers[layerKey]
			if (Object.keys(layer.types[LINE_STRING]).length > 0) {
				let info: LayerInfo = {[LAYER_NAME_KEY] : layerKey, [IS_ADD_ON_KEY]: false }
				const geoJSONLayer = L.geoJSON(Object.values(layer.types[LINE_STRING]), {
					onEachFeature: ((feature: any, layer: any) => onEachLineFeature(feature, layer, leafletLayer, info))
				})

				leafletLayer.addLayer(geoJSONLayer)
				layersBox.mergeBox(leafletLayer.getBounds())
				hasContent = true
			} else if (Object.keys(layer.types[POINT]).length > 0) {
				// try {
				// 	const geoJSONLayer = L.geoJSON(Object.values(layer.types[POINT]), {
				// 		pointToLayer: (feature: any, latlng: any) => {
				// 			// const id = feature.properties.Title;
				// 			return L.marker(latlng);
				// 		},
				// 		onEachFeature: ((feature: any, layer: any) => onEachPointFeature(feature, layer, leafletLayer))
				// 	})

				// 	leafletLayer.addLayer(geoJSONLayer)
				// 	layersBox.mergeBox(leafletLayer.getBounds())
				// 	hasContent = true
				// } catch (e: any) {
				// 	if (props.onError) {
				// 		props.onError({ message: `The following error occurred while loading map : "${e.message}". Please check your data is well formatted.`, type: ActionTypes.ERROR } as IAlert)
				// 	}
				// }
			}

			// addLinePoints(tile)
		})

		setFeaturesLoaded(true)
		if (hasContent) {
			var z = mapRef.current!.getBoundsZoom(layersBox.bounds, true);
			var center = layersBox.bounds.getCenter()
			mapRef.current!.setView(center, z);
			// props.onFoundMapRelationships!(getOverlappings(pointsLayerRef.current["linepoints"].getLayers(), "overlapping"))
		}

	}, [props])

	const drawAddOns = useCallback((layers: { [key: string]: IAddOns }) => {
		
		// const onEachLineFeature = (feature: any, layer: any, group: L.FeatureGroup) => {
		// 	layer.setStyle(lineStyle);
		// 	layer.on('click', (e) => {
		// 		return onFeatureClicked(e, feature, group)
		// 	})
		// 	layer.on('mouseover', (e) => {
		// 		layer.setStyle(lineMouseOverStyle);
		// 		return onFeatureMouseOver(e, feature, group)
		// 	})
		// 	layer.on('mousedown', (e) => {
		// 		layer.setStyle(lineMouseDownStyle);
		// 		return
		// 	})
		// 	layer.on('mouseout', (e) => {
		// 		return onFeatureMouseOut(e, feature)
		// 	})
		// }

		const appendAddOnToFeature = (feature: any, layer: any) => {
			if (!feature.properties) {
				throw new Error(FEATURE_MISSING_PROPERTIES)
			}
			if (!feature.properties.hasOwnProperty([ADDON_KEY])) {
				throw new Error(FEATURE_MISSING_ADDON_PROPERTY)
			}

			if (featureLayersRef.current!.hasOwnProperty(feature.uuid!)) {
				let addOns: IAddOn = feature.properties![ADDON_KEY]
				if (!featureLayersRef.current![addOns.attachedTo].attachments) {
					featureLayersRef.current![addOns.attachedTo].attachments = []
				}
				featureLayersRef.current![addOns.attachedTo].attachments.push(feature.uuid!)
			}
		}

		const onEachPointFeature = (feature: any,
			layer: any,
			tileLayer: L.FeatureGroup,
			info: LayerInfo,
		) => {
			tileLayer.addLayer(layer);
			if (featureLayersRef.current![feature.uuid!] !== undefined) {
				removeFeature(feature.uuid!, featureLayersRef.current![feature.uuid!].layer)
			}
			featureLayersRef.current![feature.uuid!] = { info: info, layer: layer, feature: feature, showing: true } as LayerMetaData
			tileLayer.bringToFront()
			appendAddOnToFeature(feature, layer)
			// layer.on('click', (e) => {
			// 	return onFeatureClicked(e, feature, tileLayer)
			// })
			layer.on('mouseover', (e) => {
				const popupNode = document.createElement('div');
				ReactDOM.createRoot(popupNode).render(<MapViewerPopup feature={feature} onDelete={props.onDeleteFeature} />)
				// L.popup({
				//     closeButton: false,
				// }).setLatLng([e.latlng.lat, e.latlng.lng]).setContent(popupNode).openOn(mapRef.current!);
				layer.bindPopup(popupNode)
				return onFeatureMouseOver(e, feature, tileLayer)
			})
			layer.on('mouseout', (e) => {
				return onFeatureMouseOut(e, feature)
			})
		}

		const onFeatureClicked = (e: any, geometry: Geometry, group: L.FeatureGroup) => {
			// // deselectLayers()


			// if (!layersOnFocus.current!.selected) {
			// 	layersOnFocus.current!.selected = {}
			// }
			// if (layersOnFocus.current!.selected.hasOwnProperty(geometry.uuid!)){
			// 	deselectLayer(geometry.uuid!)
			// 	delete layersOnFocus.current!.selected[geometry.uuid!]

			// }else{
			// 	// let points: DraggablePoint[] = pointsFactory(geometry.coordinates as [number[]], pointEvents, 0.00001, geometry.uuid!)
			// 	// addPoints("selected", points)

			// 	e.target.setStyle(lineSelectedStyle);
			// 	layersOnFocus.current!.selected[geometry.uuid!] = { layer: e.target, geometry: geometry, Group: group} as LayerOnFocus
			// 	showTouching()
			// }
			// reportSelection()
		}

		const onFeatureMouseOver = (e: any, feature: Feature, group: L.FeatureGroup) => {
			// if (!layersOnFocus.current!.hovered) {
			// 	layersOnFocus.current!.hovered = {}
			// }
			// if (feature.properties!.hasOwnProperty("connection")) {
			// 	let connection: ConnectedTo = feature.properties!.connection
			// 	let ids: string[] = [connection.fromUuid, connection.toUuid]
			// 	ids.forEach((id: string) => {
			// 		featureLayersRef.current![id].layer.setStyle(lineMouseOverStyle);
			// 		layersOnFocus.current!.hovered[id] = { layer: featureLayersRef.current![id].layer, feature: feature, group: group } as LayerOnFocus
			// 	})
			// }
		}

		const onFeatureMouseOut = (e: any, feature: Feature) => {
			// if (feature.properties!.hasOwnProperty("connection")) {
			// 	let connection: ConnectedTo = feature.properties!.connection
			// 	let ids: string[] = [connection.fromUuid, connection.toUuid]
			// 	ids.forEach((id: string) => {
			// 		if (!layersOnFocus.current!.selected.hasOwnProperty(id)) {
			// 			featureLayersRef.current![id].layer.setStyle(lineStyle);
			// 		} else {
			// 			featureLayersRef.current![id].layer.setStyle(lineSelectedStyle);
			// 		}
			// 		removeHoverLayer(id)
			// 	})
			// }
		}



		Object.keys(layers).forEach((layerKey) => {
			if (layersRef.current!.hasOwnProperty(layerKey)) {
				mapRef.current!.removeLayer(layersRef.current![layerKey])
				controlLayerRef.current!.removeLayer(layersRef.current![layerKey])
				delete layersRef.current![layerKey]
			}

			let leafletLayer: L.FeatureGroup = new L.FeatureGroup()
			layersRef.current![layerKey] = leafletLayer
			controlLayerRef.current!.addOverlay(leafletLayer, layerKey);
			layersRef.current![layerKey].setStyle({
				opacity: 1,
				fillColor: '#F0F0F0',
				fillOpacity: 1,
			})
			mapRef.current!.addLayer(layersRef.current![layerKey])
			
			layers[layerKey].features.forEach((f: any) => {
				let info: LayerInfo = { [LAYER_NAME_KEY]: layerKey, [IS_ADD_ON_KEY]: true, type: f.properties!.type }
				L.geoJSON(f, {
					pointToLayer: (feature, latlng) => {
						// if (feature.properties.radius) {
						return new L.Circle(latlng, layers[layerKey].style.radius, layers[layerKey].style as any);
						// } else {
						// 	return new L.Marker(latlng);
						// }
					},
					onEachFeature: ((feature: any, layer: any) => onEachPointFeature(
						feature, 
						layer, 
						layersRef.current![layerKey], 
						info)
						)
				});
			})
			
		})
	}, [props])

	// const drawConnection = (latlng : number[], mouseEventCallBack : (e : L.LeafletMouseEvent) => void, offset : number, id? : string) : DraggablePoint => {
	// 	var pointStyle = { color: '#293241', radius : 2, weight: 1, opacity: 0.7, fillOpacity: 0.1 }
	// 	var pointMouseOverStyle = { color: '#ee6c4d', radius : 3,  weight: 1, opacity: 1, fillOpacity: 0.7 }
	// 	let box = boxWithOffset(new L.Point(latlng[1], latlng[0]), offset)
	// 	var point = new DraggablePoint(box, pointStyle);
	// 	point.id = id

	// 	point.on('mousedown', (e) => {
	// 		point.setStyle(pointMouseOverStyle);
	// 		point.selected = true;
	// 		mouseEventCallBack(e)
	// 	})

	// 	point.on('mouseover', (e) => {
	// 		point.setStyle(pointMouseOverStyle);
	// 		mouseEventCallBack(e)
	// 	})
	// 	point.on('mouseout', (e) => {
	// 		point.setStyle(pointStyle);
	// 		mouseEventCallBack(e)
	// 	})

	// 	point.on('mouseup', (e) => {
	// 		point.selected = false;
	// 		mouseEventCallBack(e)
	// 	})

	// 	point.on('mousemove', (e) => {
	// 		mouseEventCallBack(e)
	// 		// if (circle.selected){
	// 		//     circle.setLatLng(e.latlng)
	// 		// }
	// 	})
	// 	return point
	// }

	useEffect(() => {

	}, [layers]);


	useEffect(() => {
		if (isDrawn) {
			return
		}

		if (mapStatus !== MapStatus.RenderComplete) {
			return
		}

		if (!layers) {
			return
		}
		setIsDrawn(true)
		drawLayers(layers)
	}, [isDrawn, layers, mapStatus, drawLayers]);


	useEffect(() => {
		if (mapRef.current !== null) {
			// mapRef.current.dragging.disable();
		}
	}, [mouseDown]);

	useEffect(() => {

	}, [draggingPoint]);


	const pointEvents = (e: L.LeafletMouseEvent) => {
		if (e.type === "mousedown") {
			mapRef.current!.dragging.disable()
			draggingPoint.current = e.target
		} else if (e.type === "mouseup") {
			mapRef.current!.dragging.enable()
			draggingPoint.current = undefined
		}
	}

	const removeHoverLayer = (featureId: string) => {
		if (!layersOnFocus.current!.hovered) {
			return
		}

		Object.values(layersOnFocus.current!.hovered).forEach((f) => {
			if (featureId === f.feature.uuid!) {
				if (f.points) {
					for (var point of f.points) {
						pointsLayerRef.current.hovered.removeLayer(point)
					}
				}
			}
		})

		delete layersOnFocus.current!.hovered[featureId]
	}

	// const addLinePoints = (tile : Tile) => {
	// 	let points : DraggablePoint[] = getLinePoints(tile, pointEvents)
	// 	addPoints("linepoints", points)
	// }

	const removeLinePoints = (layerKey: string) => {
		if (pointsLayerRef.current[layerKey]) {
			pointsLayerRef.current[layerKey].clearLayers()
			mapRef.current?.removeLayer(pointsLayerRef.current[layerKey])
		}
	}

	const removeLinePoint = (layerKey: string, point: DraggablePoint) => {
		if (pointsLayerRef.current[layerKey]) {
			pointsLayerRef.current[layerKey].removeLayer(point)
		}
	}

	const addPoints = (layerKey: string, points: DraggablePoint[]) => {
		if (!pointsLayerRef.current[layerKey]) {
			pointsLayerRef.current[layerKey] = new L.FeatureGroup()
			mapRef.current!.addLayer(pointsLayerRef.current[layerKey])
		}

		for (var point of points) {
			pointsLayerRef.current[layerKey].addLayer(point)
		}
	}

	const onClicked = (action: string, actions: Set<string>) => {

	}

	const onMouseDown = (e: L.LeafletMouseEvent) => {
		mouseDown.current = { point: e.latlng }
	}

	const onMouseMove = (e: L.LeafletMouseEvent) => {
		if (draggingPoint.current) {
			draggingPoint.current!.setLatLng(e.latlng)
		}
		
		setMousePos(e.latlng as MapPosition)
		// if (mouseDown.current) {
		// 	mouseMove.current = { from: mouseDown.current.point, to: e.latlng }
		// 	onSelected(mouseMove.current.from, mouseMove!.current.to);
		// 	return
		// }

		// if (areaSelectorRef.current === undefined) {
		// 	var circleOptions = {
		// 		color: 'red',
		// 		fillColor: '#f03',
		// 		fillOpacity: 1
		// 	}
		// 	var circle = L.circle(e.latlng, 50, circleOptions);
		// 	circle.addTo(mapRef.current!);
		// 	areaSelectorRef.current = circle
		// } else {
		// 	areaSelectorRef.current.setLatLng(e.latlng)
		// }
	}

	const onMouseUp = (e: L.LeafletMouseEvent) => {
		mouseDown.current = undefined
		mouseMove.current = undefined
		mapRef.current!.fitBounds(selectionLayerRef.current!.getBounds());
	}


	return (
		<>		
			{
				<div id="pp-map" className="pp-map-viewer-container">
					<div className='columns'>
						<div className='column'>
							<div className='columns'>
								<div className='column'>
									<StatusBar>
										<MousePosition position={mousePos}/>
									</StatusBar>
								</div>
							</div>
							<div className='columns'>
								<div className='column'>
									<MapInteraction onClicked={onClicked} />
								</div>
							</div>
						</div>
					</div>
				</div>
			}			
		</>
	)
}

const renderMap = (
	params: {
		map: L.Map,
		controlLayerRef: any,
		overlayLayers: any,
	}) => {

	if (params.controlLayerRef.current) {
		params.controlLayerRef.current.remove(params.map);
	}

	params.controlLayerRef.current = L.control.layers(baseMaps, params.overlayLayers).addTo(params.map);
}


export default MapViewer;