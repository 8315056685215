import { IUserState, UserStates } from "./IUserState"

export function setUserState(userState : IUserState) {
  let userStates: UserStates = {}
  if (localStorage.getItem('userStates') !== null) {
    userStates = JSON.parse(localStorage.getItem('userStates')!);
  }
  userStates[userState.id] = userState;
  localStorage.setItem('userStates', JSON.stringify(userStates))
}

export function getUserState(userId : string) : IUserState {
  let userStates: UserStates = {}
  if (localStorage.getItem('userStates') !== null) {
    userStates = JSON.parse(localStorage.getItem('userStates')!);
  }
  if (userStates.hasOwnProperty(userId)) {
    return userStates[userId]
  }
  return {id : userId}
}

export function removeUserState(userId : string) {
  if (userId === undefined) return
  let userStates: UserStates = {}
  if (localStorage.getItem('userStates') !== null) {
    userStates = JSON.parse(localStorage.getItem('userStates')!);
  }
  if (userStates.hasOwnProperty(userId)){
    delete userStates[userId];
    localStorage.setItem('userStates', JSON.stringify(userStates))
  }
}