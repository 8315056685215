import { IConversionMapping, MatchingType } from "./IConversionMapping"

export class ConversionMapping implements IConversionMapping {
    public id: string
    public input: string
    public values: string[]
    public output: string
    public matchcriteria: MatchingType
    public newproperty?: string

    constructor(i: IConversionMapping) {
        this.id = i.id
        this.input = i.input
        if (i.values.length > 0) {
            this.values = i.values
        } else if (i.value !== undefined && i.value.trim() !== '') {
            this.values = [i.value]
        } else {
            this.values = []
        }
        
        if (i.matchcriteria === undefined) {
            this.matchcriteria = MatchingType.EXACT
        }else{
            this.matchcriteria = i.matchcriteria
        }        
        this.output = i.output
        this.newproperty = i.newproperty
    }

}
