import { sha256 } from "js-sha256"

export enum SUMMARYTYPE {
    count = 'count',
    sum = 'sum',
    max = 'max',
    min = 'min',
};

export  const getSummaryType = (type: string): SUMMARYTYPE => {
    return SUMMARYTYPE[type as keyof typeof SUMMARYTYPE];
}

export interface IAggregate {
    type: SUMMARYTYPE
    value?: number
}

export interface ISummary {
    key: string
    name: string
    value: string
    aggregates : Record<SUMMARYTYPE, IAggregate>
    children? : Summaries 
}

export type Summaries = Record<string, ISummary> 

export const CreateOrUpdateSummary = (parent : string, name : string, value : string, summaries : Summaries | undefined, types : Record<string, Set<SUMMARYTYPE>>) : ISummary => {
    const key : string = sha256(parent + name + value)
    if (summaries && summaries.hasOwnProperty(key)) {
        const summary = UpdateSummary(summaries[key], summaries, types[name])
        return summary
    }

    let aggregates: Record<SUMMARYTYPE, IAggregate> = {
        [SUMMARYTYPE.count] : {type: SUMMARYTYPE.count, value: 1},
        [SUMMARYTYPE.sum] : {type: SUMMARYTYPE.count},
        [SUMMARYTYPE.max] : {type: SUMMARYTYPE.count},
        [SUMMARYTYPE.min] : {type: SUMMARYTYPE.count}
    }
    
    return {key : key, name : name, value : value, aggregates : aggregates}    
}

export const UpdateSummary = (summary : ISummary, summaries : Summaries, rules :  Set<SUMMARYTYPE>) : ISummary => {
    for (let rule of rules){
        switch (rule) {
            case SUMMARYTYPE.count:
                if (!summary.aggregates[rule].value){
                    summary.aggregates[rule].value = 0
                }
                summary.aggregates[rule].value! += 1
                break;        
            default:
                break;
        }
    }
    
    return summary  
}

export const HasSummary = (summary : ISummary) : boolean => {
    for (let aggs of Object.values(summary.aggregates)){
        if (aggs.type === SUMMARYTYPE.count){
            if (aggs.value) {
                if (aggs.value! > 0) {
                    return true
                }
            }
        }
        if (aggs.value) {
            return true
        }
    }
    
    return false
}

export const FlattenSummary = (summary : ISummary, row : Record<string, ISummary>, rows : Record<string, ISummary>[]) : Record<string, ISummary>[] => {
    row[summary.name] = summary     
    
    if (summary.children){
        for (const value of Object.values(summary.children)) {            
            rows = FlattenSummary(value, {...row}, rows)
        }
    } 
    else{
        rows.push(row)
    }   
    return rows
}


export const FlattenSummaries = (summaries : Summaries) : ( Record<string, ISummary>)[] => {
    let flattened  : ( Record<string, ISummary>)[] = []

    for (const value of Object.values(summaries)) {
        let row : Record<string, ISummary> = {}
        const rows = FlattenSummary(value, row, [])
        flattened = [...flattened, ...rows]
    }

    return flattened

}


// export const AddSummary = (summaries : Record<string, ISummary>, summary : ISummary) : Record<string, ISummary> => {
//     let hashed: string = sha256(JSON.stringify(summary.properties));
//     if (!summaries.hasOwnProperty(hashed)){
//         summaries[hashed] = summary;
//     }

//     Object.values(summary.aggregates).forEach(aggregate => {
//         switch (aggregate.type) {
//             case SUMMARYTYPE.count:
//                 if (!summaries[hashed].aggregates[SUMMARYTYPE.count].value){
//                     summaries[hashed].aggregates[SUMMARYTYPE.count].value = 0
//                 }
//                 summaries[hashed].aggregates[SUMMARYTYPE.count].value! += 1
//                 break;        
//             default:
//                 break;
//         }
//     })

//     return summaries
// }