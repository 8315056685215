import Dropdown from 'standard/dropdown/v2/Dropdown';
import { IOptions } from 'standard/forms/interfaces/IOption';
import { IStyling } from 'standard/styling/IStyling';
import Paginator from 'standard/pagination/paginator';
import Search from '../search/search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { uiActions } from 'standard/UI/actions';

const Wrapper = (props: {
    title: string
    onPageSelect: (page: number) => void
    itemCount: number
    pageSize: number
    currentPage: number
    hide?: boolean
    options?: IOptions
    onSearch: (search: string) => void
    onSearchChange?: () => void
    onEdit?: () => any,
    children?: any
}
) => {
    const dispatch = useAppDispatch()
    const ui = useAppSelector(state => state.ui)

    const onMinimize = () => {
        dispatch(uiActions.disableFullscreen())
    }

    const onMaximize = () => {
        dispatch(uiActions.enableFullscreen())
    }
    return (
        <div className={`card is-fullwidth  m-0 p-0 pl-2  ${(props.hide !== undefined && props.hide) && "is-hidden"}`}>
            <div className="card-header ">
                <p className="card-header-title">
                    {props.title}
                </p>
                {props.onEdit &&
                    <button className="card-header-icon" aria-label="more options" onClick={props.onEdit}>
                        Edit
                    </button>
                }

                <div className="card-header-icon">
                    <div className='columns'>
                        {
                            (props.options) &&
                            <div className='column'>
                                <Dropdown title='Actions' options={props.options} />
                            </div>
                        }
                        <div className='column'>
                            {ui.fullscreenenabled ?
                                <FontAwesomeIcon className='link' icon={faMinimize} size="1x" onClick={onMinimize} />
                                :
                                <FontAwesomeIcon className='link' icon={faMaximize} size="1x" onClick={onMaximize} />
                            }
                        </div>
                    </div>

                </div>


            </div>

            <div className="columns m-0 is-centered">
                <div className="column m-1 p-0 is-4">
                    <Search onSearch={props.onSearch} onSearchChange={props.onSearchChange} style={searchStyles} />
                </div>
            </div>
            <Paginator itemCount={props.itemCount} pageSize={props.pageSize} currentPage={props.currentPage} onPageSelect={props.onPageSelect} />
            <hr className='m-2 p-0' />
            {props.children}
        </div>
    )
}

const searchStyles: IStyling = {
    container: 'search',
    text: 'smallText'
}
export default Wrapper;