import { MapWrraper } from "./mapWrapper";
import { IS_ADD_ON_KEY, LAYER_NAME_KEY, LAYER_UUID_KEY, LayerInfo, LayerMetaData } from "standard/geojsonMap/@types/layerInfo";
import L from 'leaflet';
import { sha256 } from 'js-sha256';
import { IMenuItem } from "standard/navigation/interfaces/IMenuItem";
import { DELETE_FEATURE } from "standard/geojsonMap/@types/actions";
import { LayerEvents } from "./enums";

declare module './mapWrapper' {
	interface MapWrraper {
		addEdges(
			features: any[],
			layerKey: string,
			onLayerEvent : (key: string, event : LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			finishLineMouseMove: () => void,
		): L.GeoJSON

		onEachLineFeature(
			feature: any[],
			layer: any,
			info: LayerInfo,
			onLayerEvent : (key: string, event : LayerEvents) => void,
			setContextMenuOptionsInstance: (key: string) => void,
			onMouseUp: () => void,
		): void

		addLayerContextMenuForEdge (layer: L.Layer, setContextMenuOptionsInstance: (key: string) => void) : void
	}
}

MapWrraper.prototype.addEdges = function (
	features: any[],
	layerKey: string,
	onLayerEvent : (key: string, event : LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	finishLineMouseMove: () => void,
): L.GeoJSON {
	let info: LayerInfo = { [LAYER_NAME_KEY]: layerKey, [IS_ADD_ON_KEY]: false }
	const geoJSONLayer = L.geoJSON(features, {
		onEachFeature: ((feature: any, layer: any) => this.onEachLineFeature(
			feature,
			layer,
			info,
			onLayerEvent,
			setContextMenuOptionsInstance,
			finishLineMouseMove))
	})
	this.addLayerContextMenuForEdge(geoJSONLayer, setContextMenuOptionsInstance)

	return geoJSONLayer
}

MapWrraper.prototype.onEachLineFeature = function (
	feature: any,
	layer: any,
	info: LayerInfo,
	onLayerEvent : (key: string, event : LayerEvents) => void,
	setContextMenuOptionsInstance: (key: string) => void,
	onMouseUp: () => void) {

	const layerMetadata: LayerMetaData = {
		info: { ...info, ...{ [LAYER_UUID_KEY]: feature.uuid! } },
		layer: layer,
		feature: feature,
		showing: true,
		layerVersion: sha256(JSON.stringify(feature)),
		renderVersion: this.status.getRenderVersion()
	}

	this.status.featureLayers![feature.uuid!] = layerMetadata
	// const popupNode = document.createElement('div');
	// layer.bindPopup(popupNode)
	// layer.setStyle(this.getDefaultLineStyle(feature?.properties?.layer))
	// let selectedFeatures = Object.keys(this.status.selectedLayers)
	// if (selectedFeatures) {
	// 	if (selectedFeatures!.includes(feature.uuid!)) {
	// 		this.onClickLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
	// 	}
	// }
	// if (selectedFeatures) {
	// 	if (selectedFeatures!.includes(feature.uuid!)) {
	// 		this.onClickLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp)
	// 	}
	// }
	// else {
	// 	this.setDefaultLayerStyle(layerMetadata)
	// }
	const hasFilters : boolean = this.status.filtered.length > 0
	this.setLayerStyle(layerMetadata, onLayerEvent, hasFilters, setContextMenuOptionsInstance, onMouseUp)

	let resetSelectedTimer: any
	layer.on('mouseover', (e) => {
		clearInterval(resetSelectedTimer);
		this.highlightLayer(layerMetadata, onLayerEvent)
		return
	})

	layer.on('mousedown', (e) => {
		clearInterval(resetSelectedTimer);
		this.onClickLayer(layerMetadata, onLayerEvent, setContextMenuOptionsInstance, onMouseUp);
		return
	})

	layer.on('mousemove', (e) => {

	})

	layer.on('dragend', function (event) {
		console.log('Layer was dragged to', event.target.getLatLng());
	});

	// const resetStyle = (layer: any) => {
	// 	layer.setStyle(lineStyle);
	// 	deSelectLayer(layer) 
	// }

	// const resetSelectedStyle = () => {
	// 	clearInterval(resetSelectedTimer);
	// 	if (selectedFeatures.size > 0) {
	// 		resetSelectedTimer = setInterval(() => {
	// 			resetSelectedStyle()
	// 		}, 5000);
	// 		return
	// 	}				
	// 	props.onFeatureHoverEnd()
	// 	clearInterval(resetSelectedTimer);
	// }

	// const scheduleLayerReset = (layer : any) => {
	// 	let t = setInterval(() => {
	// 		resetStyle(layer)
	// 	}, 5000);
	// 	return () => clearInterval(t);
	// }
	layer.on('mouseout', (e) => {
		// scheduleLayerReset(layer)

		// resetSelectedTimer = setInterval(() => {
		// 	resetSelectedStyle()
		// }, 5000);
	})

	// layer.on('mousedown', (e) => {
	// 	// layer.setStyle(lineMouseDownStyle);
	// 	return
	// })
}

let edgeEditMenuItems: IMenuItem[] = [
	{ name: DELETE_FEATURE, label: "Delete Edge", ordinality: 1, parent: undefined },
]

MapWrraper.prototype.addLayerContextMenuForEdge = function (layer: L.Layer, setContextMenuOptionsInstance: (key: string) => void) {
	this.addLayerContextMenu(layer, 'Edit Element', edgeEditMenuItems, setContextMenuOptionsInstance)
}

