import { useState, useEffect } from 'react';
import { IAlert } from 'standard/alert';
import AlertMessage from 'standard/alert/components/alertMessage';
import UploadList from './uploadList';
import { Feature } from 'standard/geojson/@types';
import { useUploads } from '../hooks/useUploads';
import DataPreviewMap from '../dataPreviewMap';
import DataPreviewDetails from '../dataPreviewDetails';
import { Mapping, Upload_Meta } from '../types/uploads';
import Processing from 'standard/loading/processing';
import { updateFeatures } from '../hooks/utils';
import { removeURLParameters, updateURLParameters } from 'standard/navigation/utils';
import Spinner from 'components/utils/Spinner';
// import { withAuthenticationRequired } from "@auth0/auth0-react";

const DataPreview = (props: any) => {
  const { uploads_Meta, loading: loadingUploads, storeNewUpload, updateMapping, deleteUpload } = useUploads()
  let [uploadkey, setUploadKey] = useState<string>();
  let [upload_v2, setUpload_v2] = useState<Upload_Meta>();
  let [error, setError] = useState<IAlert>();
  const [graphChanged, setGraphChanged] = useState<boolean>(false);
  let [changedFeatures, setChangedFeatures] = useState<Feature[]>();
  let [context, setContext] = useState<string>('');
  let [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Data Preview';
  }, [])

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    if (searchParams){
        if (searchParams.has("uploadKey")){
          onUploadSelected(searchParams.get("uploadKey")!, 'details')
        }
    }
  }, [])

  const onUploadSelected = (uploadKey: string, context: string) => {
    setContext(context)
    setUploadKey(uploadKey)
  }

  useEffect(() => {

  }, [loadingUploads])

  useEffect(() => {
    if (uploadkey === undefined) {
      removeURLParameters(['uploadKey'])
    }
    else{

      updateURLParameters({'uploadKey' : `${uploadkey}`})

    }
  }, [uploadkey])

  useEffect(() => {
    if (uploads_Meta && uploadkey) {
      setUpload_v2(uploads_Meta[uploadkey])
    }else{
      setUpload_v2(undefined)
    }

  }, [uploads_Meta, uploadkey])

  useEffect(() => {

  }, [upload_v2])

  useEffect(() => {
    if (changedFeatures) {
      setGraphChanged(true)
    } else {
      setGraphChanged(false)
    }
  }, [changedFeatures])

  const onStoreUpload  = async (name: string, newFeatures:  Feature[], onComplete : () => void ) => {
    setProcessing(true)
    await storeNewUpload(name, newFeatures, onComplete)
    setProcessing(false)
  }

  const onUpdateFeatures  = async (key : string, newFeatures:  Feature[], onComplete : () => void ) => {
    setProcessing(true)
    await updateFeatures(key, newFeatures, onComplete)
    setProcessing(false)
  }

  const onUpdateMapping  = (key: string, mapping: Mapping, onComplete : () => void ) => {
      updateMapping(key, mapping, onComplete)
  }

  useEffect(() => {

  }, [processing])

  // const onFeaturesChanged = (features: Feature[], doUpdate: boolean) => {
  //   if (doUpdate) {
  //     updateFeaturesOnLocal(uploadkey!, features)
  //     setChangedFeatures(undefined)

  //   } else {
  //     setChangedFeatures(features)
  //   }
  // }

  // const onSaveGraph = () => {
  //   updateFeaturesOnLocal(uploadkey!, changedFeatures!)
  //   setChangedFeatures(undefined)
  // }

  // const onMappingChanged = (features: Feature[], doUpdate: boolean) => {
  //   if (doUpdate) {
  //     updateFeaturesOnLocal(uploadkey!, features)
  //     setChangedFeatures(undefined)
  //   } else {
  //     setChangedFeatures(features)
  //   }
  // }

  // const onSaveDetails = () => {

  // }

  const onEditComplete = () => {
    setUploadKey(undefined)
    setError(undefined)
  }

  const backToUploads = () => {
    setUploadKey(undefined)
    setError(undefined)
  }

  const onUploadDeleted = (uploadKey: string) => {
    deleteUpload(uploadKey, onUploadComplete)
  }

  const onUploadComplete = () => {

  }

  const getError = () => {
    return <div className='box columns is-centered'>
      <div className='column is-8'>
        <AlertMessage alert={error} />
        <br />
        <div className='button' onClick={backToUploads}>Back to uploads</div>
      </div>
    </div>
  }

  return <>
    {processing === true ? <Processing loading={processing} /> :  <></>}
    <div className={`${processing === true ? 'hidden' : 'visible'}` }>
      {error ? getError() :
        (!upload_v2 ?
          <div className='columns is-centered'>
            <div className='column is-8'>
              <UploadList
                onSelected={onUploadSelected}
                uploads={uploads_Meta}
                loading={loadingUploads}
                onDelete={onUploadDeleted}
                onNewUpload={storeNewUpload} />
            </div>
          </div>
          :
          <>
            {
              !(context === 'details') || <DataPreviewDetails 
              uploadKey={upload_v2.key}
              updateMapping={onUpdateMapping} 
              onEditComplete={onEditComplete} 
              onNewUpload={onStoreUpload}
              onUpdateFeatures={onUpdateFeatures} 
              setProcessing={setProcessing}
              />
            }
            {!(context === 'map') || <DataPreviewMap uploadKey={uploadkey!} onEditComplete={onEditComplete} />}
          </>
        )
      }
    </div>  
    </>
}

export default DataPreview

// export default withAuthenticationRequired(DataPreview, {
//   onRedirecting: () => <Spinner />,
// });
