import { Fragment, useEffect } from 'react';
import { Link } from "react-router-dom";
import { IMenuItem } from 'standard/navigation/interfaces/IMenuItem';
import "./styles.css";

const RootMenu = (props: any) => {
    const { links } = props;
    const rootNodes = links && links.filter((node: IMenuItem) => node.parent === undefined)

    useEffect(() => {

    })

    const node = (item: IMenuItem) => {
        const children = links.filter((node: IMenuItem) => node.parent === item.name)
        if (item.action !== undefined) {
            return  <Fragment  key={item.name}>
                        <div key={item.name} className="navbar-item clickable" onClick={() => item.action!(item)}>{item.label}</div>
                        <div className="childrenMenu" >
                            {children && children.map((child: IMenuItem) =>
                                node(child)
                            )}
                        </div>
                    </Fragment>
        }
        return <Fragment key={item.name}><Link key={item.name} className="navbar-item clickable" to={item.path!}>{item.label}</Link>
            <div className="childrenMenu" >
                {children && children.map((child: IMenuItem) =>
                    node(child)
                )}
            </div></Fragment>

    }

    const rootNode = (item: IMenuItem) => {
        const children = links.filter((node: IMenuItem) => node.parent === item.name)
        return <div key={item.name} className="navbar-item has-dropdown is-hoverable" >
                <a className="navbar-link clickable" >
                    {item.label}
                </a>
                <div className="navbar-dropdown" >
                    {children && children.map((child: IMenuItem) =>
                        node(child)
                    )}
                </div>
            </div>
        
    }

    return <div id="menu" className="navbar-start" >
            {rootNodes && rootNodes.map((item: IMenuItem) =>
                (item.path || item.action) ? node(item) : rootNode(item)
            )}        
    </div>
};

export default RootMenu;