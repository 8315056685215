import { ActionTypes, IAlert } from "standard/alert";
import { Feature } from "standard/geojson/@types";
import localforage from 'localforage';
import { IIndividual } from "standard/ontology/interfaces";

export function updateFeatures(key: string, newFeatures: Feature[], onComplete?: () => void): Promise<IAlert> {
    const promise = new Promise<IAlert>((res, rej) => {
        localforage.setItem(key, newFeatures).then(function () {
        }).then(function (value) {
            if (onComplete) {
                onComplete()
            }
            res({ message: '', type: ActionTypes.SUCCESS })
        }).catch(function (err) {
            rej({ message: err, type: ActionTypes.ERROR })
        });
    });
    return promise;
}

export function getFeatures(key: string): Promise<Feature[] | undefined> {
    const promise = new Promise<Feature[] | undefined>((res, rej) => {
        localforage.getItem(key, function (err, value) {
            if (err){
                rej(err);
                return
            }
            if (value) {
                let features = (value as Feature[])
                res(features)
                return
            } else {
                res(undefined)
                return
            }
        });       
    })
    return promise;
}

