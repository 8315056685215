import { Input } from 'standard/forms/inputs'
import { InputType } from 'standard/forms/enums'
import { MatchingType } from 'standard/datatools/@types';
import { EnumToOptions } from 'standard/multiselect/@types/IOption';

export const Inputs = (): { [key: string]: Input } => {
    let inputs: { [key: string]: Input } = {};
    // inputs["input"] = { name: 'input', label: 'Property Name', type: InputType.STRING, value: '', required: true, ordinality: 1, data : {liststyle : "is-3" }}
    inputs["input"] = {
        name: 'input',
        label: 'Column Name',
        type: InputType.OPTION,
        value: '',
        required: true,
        ordinality: 1,
        data: { liststyle: "is-3" }
    }

    inputs["matchcriteria"] = {
        name: 'matchcriteria',
        label: 'Match Criteria',
        type: InputType.OPTION,
        value: '',
        options: EnumToOptions(MatchingType),
        required: true,
        ordinality: 2,
        prompt: 'Select Match Criteria',
        data: { liststyle: "is-3" }
    }

    inputs["values"] = {
        name: 'values',
        label: 'Column Values',
        type: InputType.TAGGING,
        value: [],
        required: true,
        ordinality: 3,
        parent: 'input',
        prompt: 'Enter Column Values',
        data: { liststyle: "is-3" }
    }

    inputs["output"] = {
        name: 'output',
        label: 'Converted Value',
        type: InputType.STRING,
        value: '',
        required: true,
        ordinality: 4,
        data: { liststyle: "is-4" }
    }

    inputs["newproperty"] = {
        name: 'newproperty',
        label: 'New Column',
        type: InputType.STRING,
        value: '',
        required: false,
        ordinality: 4,
        data: { liststyle: "is-4" }
    }
    return inputs
}
