import { LatLng } from 'leaflet';
import { IMenuItem } from 'standard/summary/@types/IMenuItem';

export type ContextMenuOptions = {
    visible: boolean
    title?: string
    mapPostion?: LatLng
    clientPosition?: { x: number, y: number }
    menuItems?: IMenuItem[]
}

export const contextMenuDefault: ContextMenuOptions = { visible: false }