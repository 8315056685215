import { Feature, Features } from "standard/geojson/@types";
import shp from "shpjs";
import { getSharedStateValue, useSharedState } from "../state/sharedState";
import { CURRENT_DRAWING_GEO, DATA_FROM_WORKSHEET, DATA_KML, DELETED_INDIVIDUALS,  DRAWED_GEO,    IMPORTING_METADATA, IS_EDITING_METADATA, IS_IMPORTING_METADATA } from "../state/sharedStateName";
// import shpDownload from "../../lib/shpwrite/download";

import Papa from "papaparse";
import JSZip from "jszip";
import { getDomainModelImports } from "standard/ontology/domainModel/service";
import { useAppSelector } from "hooks/redux";
import {  IDomainModel, IIndividual } from "standard/ontology/interfaces";
import { v4 as uuidv4 } from 'uuid';
import { deleteIndividuals, updateIndividuals } from "standard/ontology/digitalTwin/service_individuals";
import { getUUIDGeoFields } from "../helpers/util";
import { IDigitalTwin } from "standard/ontology/interfaces/IDigitalTwin";
import { readTextFile } from "../helpers/fileReaders/txt";
import { readBinaryFile } from "../helpers/fileReaders/bin";
import { readXlsxFile } from "../helpers/fileReaders/xlsx";
import { IAlert, ActionTypes } from "standard/alert";
// importers
import { importShpFiles } from "../importers/filetypes/shp";
import { importKmlFiles } from "../importers/filetypes/kml"
import { importDxfFile } from "../importers/filetypes/dxf"
import { importJsonFile } from "../importers/filetypes/json"

global.Buffer = global.Buffer || require('buffer').Buffer;
// var shpwrite = require("shp-write");



export const useImport = () => {

    const authenticated = useAppSelector(state => state.authentication)

    // var [digitalTwin, setDigitalTwin] = useSharedState(DIGITAL_TWIN, getSharedStateValue(DIGITAL_TWIN));

    var setEditingMetadata: (val: boolean) => void;
    [, setEditingMetadata] = useSharedState(IS_EDITING_METADATA, false);

    var setImportingMetadata: (val: any[]) => void;
    [, setImportingMetadata] = useSharedState(IMPORTING_METADATA, undefined);

    var setCurrentGeo: (val: Feature | undefined) => void;
    [, setCurrentGeo] = useSharedState(CURRENT_DRAWING_GEO, getSharedStateValue(CURRENT_DRAWING_GEO));

    // //Handle change background center
    // var setBackgroundCenter: (val: BackgroundCenter) => void;
    // [, setBackgroundCenter] = useSharedState(BACKGROUND_CENTER, {});

    var [, setDataWorkSheet] = useSharedState(DATA_FROM_WORKSHEET, getSharedStateValue(DATA_FROM_WORKSHEET));

    var [, setDataKML] = useSharedState(DATA_KML, getSharedStateValue(DATA_KML));

    var [, setIsImportingMetadata] = useSharedState(IS_IMPORTING_METADATA, getSharedStateValue(IS_EDITING_METADATA));

    async function getGeoFeatures(typeId: string, files: any[]): Promise<Features> {
        const promise = new Promise<Features>((res, rej) => {
            try {
                if (files) {
                    if (files.length > 1) {
                        importShpFiles(
                            {
                                typeId : typeId, 
                                res : res, 
                                rej : res, 
                                files : files,
                                setEditingMetadata : setEditingMetadata,
                                setCurrentGeo: setCurrentGeo
                            }
                        );
                    } else if (files.length === 1) {
                        let file = files[0];
                        const fileName = file.name.split('.').slice(0, -1).join('.')
                        let importedFeatures: Features = { originName: fileName, features: [] };
                        let fileType = file.name.split('.').pop().toLowerCase();

                        if (fileType === "kml") {
                            readTextFile(file).then((fileContent: any) => {
                                importKmlFiles({
                                    features: importedFeatures,
                                    res: res,
                                    rej: rej,
                                    fileContent: fileContent as string,
                                    setDataKML: setDataKML,
                                    setEditingMetadata : setEditingMetadata,
                                    setCurrentGeo: setCurrentGeo
                                }
                                )
                            });
                        } else if (fileType === 'shp') {
                            importShpFiles({
                                typeId : typeId, 
                                res : res, 
                                rej : res, 
                                files : files,
                                setEditingMetadata : setEditingMetadata,
                                setCurrentGeo: setCurrentGeo
                            });
                        } else if (fileType === 'xlsx') {
                            readXlsxFile(file).then((wb) => { setDataWorkSheet(wb); });
                        } else if (fileType === 'zip' || fileType === 'kmz') {
                            JSZip.loadAsync(file).then((zip) => {
                                var shpFilesData: any[] = [];
                                var kmlFileData: any = undefined;
                                Object.keys(zip.files).forEach((fileName: string) => {
                                    if (!fileName.match('__MACOSX')) {
                                        let fileType = fileName.split('.').pop()?.toLowerCase();
                                        if (fileType === 'shp' || fileType === 'prj' || fileType === 'dbf') {
                                            shpFilesData.push(zip.files[fileName]);
                                        } else if (fileType === 'kml') {
                                            kmlFileData = zip.files[fileName];
                                        }
                                    }
                                });
                                if (shpFilesData.length > 0) {
                                    importShpFiles(
                                        {
                                            typeId : typeId, 
                                            res : res, 
                                            rej : res, 
                                            files : shpFilesData,
                                            setEditingMetadata : setEditingMetadata,
                                            setCurrentGeo: setCurrentGeo,
                                            zip: true
                                        })
                                } else if (kmlFileData) {
                                    kmlFileData.async('string').then((kmlFileContent: any) => importKmlFiles({
                                        features : importedFeatures, 
                                        res : res, 
                                        rej : rej, 
                                        fileContent : kmlFileContent,
                                        setDataKML: setDataKML,
                                        setEditingMetadata : setEditingMetadata,
                                        setCurrentGeo: setCurrentGeo
                                    }));
                                }
                            });

                        } else if (fileType === 'dxf') {
                            readTextFile(file).then((fileContent: any) => { importDxfFile( 
                                {
                                    features : importedFeatures, 
                                    res : res, 
                                    rej: rej, 
                                    fileContent : fileContent as string,
                                    setEditingMetadata : setEditingMetadata,
                                    setCurrentGeo: setCurrentGeo, 
                            })});
                        }
                        else if (fileType === 'geojson') {   
                                                    
                            readTextFile(file).then((fileContent: any) => { importJsonFile(
                                {                           
                                    features : importedFeatures, 
                                    res : res,
                                    rej : rej,
                                    fileContent : fileContent as string
                                } ) 
                            });
                        } else  {                            
                            rej({
                                message: `File ${fileType} type not allowed.`,
                                type: ActionTypes.ERROR,
                            } as IAlert)
                        }
                    }
                } else {
                    rej({
                        message: "No files to upload",
                        type: ActionTypes.ERROR,
                    } as IAlert)
                }
            } catch (error: any) {
                rej({
                    message: error,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })
        return promise;
    };

    async function exportGeoFileToFile(): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                let exportFeatures = getSharedStateValue(DRAWED_GEO);
                const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                    JSON.stringify(exportFeatures)
                )}`;
                const link = document.createElement("a");
                link.href = jsonString;
                link.download = "data.json";
                link.click();
                res({
                    message: "Geo data exported to file",
                    type: ActionTypes.SUCCESS,
                } as IAlert)
            } catch (error: any) {
                rej({
                    message: `Unable to export geo data to file ${error}`,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })
        return promise
    }

    async function importToDB(digitalTwin: IDigitalTwin, importFeatures: Features): Promise<IAlert> {
        const updatePromise = updateGeoIndividuals(digitalTwin, importFeatures)
        const promise = new Promise<IAlert>((res, rej) => {
            Promise.all([updatePromise])
                .then((data) => {
                    res({
                        message: `Exported geo data to db`,
                        type: ActionTypes.SUCCESS,
                    } as IAlert)
                })
                .catch(err => {
                    rej({
                        message: `Unable to export geo data to db ${err}`,
                        type: ActionTypes.ERROR,
                    } as IAlert)
                });
        })
        return promise
    }

    async function updateGeoIndividuals(digitalTwin: IDigitalTwin, importFeatures: Features): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                getDomainModelImports( digitalTwin.imports![0]).then((items: IDomainModel[]) => {
                    let { geoPropsUUID, geoCoordPropsUUID, geoTypePropsUUID } = getUUIDGeoFields(items);
                    let individuals: IIndividual[] = [];
                    importFeatures.features.map((feature: Feature, fIndex: number) => {
                        if (geoPropsUUID && geoCoordPropsUUID && geoTypePropsUUID) {
                            // if (feature.uuid && (edittedIndividuals.indexOf(feature.uuid) < 0 || deletedIndividuals.indexOf(feature.uuid) > 0)) {
                            //     return;
                            // }
                            
                            let geoUUID = (feature.geometry.uuid ? feature.geometry.uuid : uuidv4());
                            let pipeObjProps: { [k: string]: string } = {};
                            pipeObjProps[geoPropsUUID] = geoUUID;
                            let pipeDataProps: { [k: string]: any } = {};
                            if (feature.properties) {
                                for (let prop of Object.keys(feature.properties)) {
                                    pipeDataProps[prop] = "" + feature.properties[prop];
                                }
                            }

                            let pipeInd: IIndividual = {
                                uuid: feature.uuid ? feature.uuid : uuidv4(),
                                iri: `${importFeatures.originName}_${fIndex}`,
                                labels: { "default": `${importFeatures.originName}_${fIndex}` },
                                objectProperties: pipeObjProps,
                                dataProperties: Object.keys(pipeDataProps).length > 0 ? pipeDataProps : undefined,
                                types: feature.type ? [feature.type] : []
                            }
                            individuals.push(pipeInd);
                            let geoDataProps: { [k: string]: any } = {};
                            geoDataProps[geoCoordPropsUUID] = JSON.stringify(feature.geometry.coordinates);
                            geoDataProps[geoTypePropsUUID] = feature.geometry.type;
                            // TODO: TU, why is type not set
                            let geoInd: IIndividual = {
                                uuid: geoUUID,
                                iri: `geo_${feature.type}_${fIndex}`,
                                labels: { "default": `geo_${feature.type}_${fIndex}` },
                                types: [],
                                dataProperties: geoDataProps,
                            }
                            individuals.push(geoInd);
                        }
                    });
                    if (individuals.length > 0) {
                        updateIndividuals(authenticated!.user!.token, digitalTwin.uuid!, individuals).then((result: any) => {
                            res({
                                message: `Updated geo data in db`,
                                type: ActionTypes.SUCCESS,
                            } as IAlert)
                        }).catch((err) => {
                            rej({
                                message: `Unable to delete geo data from db`,
                                type: ActionTypes.ERROR,
                            } as IAlert)
                        });
                    } else {
                        res({
                            message: `No geo data to add`,
                            type: ActionTypes.SUCCESS,
                        } as IAlert)
                    }
                });
            } catch (error: any) {
                rej({
                    message: `Unable to export geo data to db ${error}`,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })
        return promise
    }


    async function deleteGeoIndividuals(digitalTwin: IDigitalTwin): Promise<IAlert> {
        const promise = new Promise<IAlert>((res, rej) => {
            try {
                let deletedIndividuals = getSharedStateValue(DELETED_INDIVIDUALS);

                if (deletedIndividuals.length > 0) {
                    deleteIndividuals(authenticated!.user!.token, digitalTwin.uuid!, deletedIndividuals)
                        .then((result: any) => {                            
                            res({
                                message: `Deleted geo data from db`,
                                type: ActionTypes.SUCCESS,
                            } as IAlert)
                        })
                        .catch((error: any) => {                            
                            rej({
                                message: `Unable to delete geo data from db`,
                                type: ActionTypes.ERROR,
                            } as IAlert)
                        });
                } else {
                    res({
                        message: `No geo data to delete`,
                        type: ActionTypes.SUCCESS,
                    } as IAlert)
                }
            } catch (error: any) {
                rej({
                    message: `Unable to delete geo data from db ${error}`,
                    type: ActionTypes.ERROR,
                } as IAlert)
            }
        })


        return promise
    }

    const importMetadataFile = (files: any[]) => {
        if (files && files.length === 1) {
            let file = files[0];
            let fileType = file.name.split('.').pop().toLowerCase();
            if (fileType === "dbf") {
                readBinaryFile(file).then((dbfBuffer: any) => {
                    let props = shp.parseDbf(dbfBuffer as Buffer, dbfBuffer as Buffer);
                    if (props) {
                        setImportingMetadata(props);
                    }                    
                })
            } else if (fileType === "csv") {
                Papa.parse(files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        setImportingMetadata(results.data);
                    },
                });
            } else if (fileType === "xlsx") {
                readXlsxFile(file).then((wb) => { setIsImportingMetadata(true); setDataWorkSheet(wb); });
            } else {
                alert("Currently just support .dbf and .csv file");
            }
        } else {
            alert("Currently just support .dbf and .csv file");
        }
    }

    return { getGeoFeatures, exportGeoFileToFile: exportGeoFileToFile, importToDB: importToDB, importMetadataFile: importMetadataFile };
}