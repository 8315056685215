import { useEffect } from "react";


const Loader = (props: {
    message: string
}) => {

    useEffect(() => {

    }, [props.message])

    return <div className="columns">
        <div className="column m-0 p-0 ml-1 mt-1">
            <span className="loader"></span>
        </div>
        <div className="column m-0 p-0 ml-1 mb-2 is-full">
            {props.message}
        </div>
    </div>
}

export default Loader;






