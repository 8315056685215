import { useEffect, useState } from 'react';
import { getDomainModel, getDomainModelWithUri } from '../service';
import { useAppSelector } from 'hooks/redux'

import { IDomainModel } from '../../interfaces';

import { IAlert, ActionTypes } from "../../../alert";
import { ApiResponse, createDomainModel } from '../service';
import { DomainModel } from '../domainmodel';

const newModelKey = "newOntologyKey"
const useDomainModel = (props: { OntUUID?: string, URI?: string, inScopeOnly?: boolean, onlyMeta?: boolean  }) => {
    const [domainModel, setDomainModel] = useState<DomainModel>();
    const authenticated = useAppSelector(state => state.authentication)
    const [domainModelAlert, setDomainModelAlert] = useState<IAlert>();
    
    useEffect(() => {
        loadExisting()
    }, [props.OntUUID, props.URI]);

    const loadExisting = () => {
        if (props.OntUUID === undefined && props.URI === undefined) {
            setDomainModel(undefined)
            return
        }
      
        if (props.OntUUID !== undefined) {
            getDomainModel(authenticated.user!.token, props.OntUUID, props.inScopeOnly !== undefined ? props.inScopeOnly : true)
                .then((item: IDomainModel) => {
                    setDomainModel(new DomainModel(item));
                    setDomainModelAlert(undefined);
                })
                .catch((err) => {                    
                    setDomainModel(undefined);
                    setDomainModelAlert({
                        message: err.message,
                        type: ActionTypes.ERROR,
                    } as IAlert)
                });
            return
        }
        if (props.URI !== undefined) {
            getDomainModelWithUri(props.URI)
                .then((item: IDomainModel) => {
                    setDomainModel(new DomainModel(item));
                    setDomainModelAlert(undefined);
                })
                .catch((err) => {
                    let msg : string = err.message
                    if (msg === "record not found"){
                        msg = "Unable to find domain model with uri: " + props.URI
                    }
                    setDomainModel(undefined);
                    setDomainModelAlert({
                        message: msg,
                        type: ActionTypes.ERROR,
                    } as IAlert)
                });
            return
        }
    }

    const reloadModel = () => {
        loadExisting()
    }

    const updateModelState = (newModel: IDomainModel) => {
        setDomainModel(new DomainModel(newModel))
        localStorage.setItem(props.OntUUID ? props.OntUUID : newModelKey, JSON.stringify(newModel))
    }

   

    return { domainModel, updateModelState, reloadModel, domainModelAlert };
}


export default useDomainModel

